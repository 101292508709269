import React from 'react';
import { UilTimesCircle } from '@iconscout/react-unicons';
import { isObject } from "lodash";

function SelectedFiltersTags({
    list,
    onDelete
}) {

    if (!list.length) return null;
    return (
        <div className='selected-metric-tags-container p-0'>
            <div className='tags-list'>
                {
                    list.map(({filterType, filterValue}) => {
                        let value = filterValue;
                        if(isObject(filterValue))
                        {
                            value =  Object.values(filterValue).join(" : ")
                            
                        }
                        return (
                        <span className='tag mr-2 mb-0' key={filterValue}>
                            { `${filterType}: ${value }` }
                            <span className='pl-1'>
                                <UilTimesCircle
                                    size={14}
                                    color='#5330b9'
                                    className='cursor-pointer'
                                    onClick={() => onDelete({filterType, filterValue})}
                                />
                            </span>
                        </span>
                    )})
                }
            </div>
        </div>
    )
}

export default SelectedFiltersTags