import { toast } from 'react-toastify';
import './CustomToast.scss';

const CustomToast = {
	success(msg, options, id = {}) {
		return toast.success(msg, {
			...options,
			className: 'toast-container',
			progressClassName: 'success-progress-bar',
			toastId: id ? id : 'custom-toast',
		});
	},
	error(msg, options, id = {}) {
		return toast.error(msg, {
			...options,
			className: 'toast-container',
			progressClassName: 'error-progress-bar',
			toastId: id ? id : 'custom-toast',
		});
	},
};

export default CustomToast;
