import React, { useState, useContext, useEffect } from 'react';
import './NodeVariables.scss';

import WidgetWrapper from '../../Widgets/WidgetWrapper/WidgetWrapper';
import AdvancedInsightsContext from './../../context/insights-context';
import { getUrlParameterByName } from '../../../../utils/commonFunctions';
import VariablesTable from './VariablesTable/VariablesTable';

function NodeVariables() {
  const {
    getSelectedNodeParamsMetaAndListData,
    selectedNodeParamsListLoading,
    selectedNodeParamsList
  } = useContext(AdvancedInsightsContext);

  const nodeKey = getUrlParameterByName("key");

  useEffect(() => {
    if (selectedNodeParamsList === null) {
      getSelectedNodeParamsMetaAndListData({nodeKey});
    }
  }, [selectedNodeParamsList]);

  return (
    <WidgetWrapper loading={selectedNodeParamsListLoading}
    containerStyles={{padding: '10px', borderRadius: '10px'}}>
      <div className='node-variables-wrapper'>
        { selectedNodeParamsList ? <VariablesTable data={selectedNodeParamsList} /> : null }
      </div>
    </WidgetWrapper>
  )
}

export default NodeVariables