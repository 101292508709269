import React, { useState, useEffect, useMemo, useContext} from 'react';
import { PrimaryLineButton, RefreshButton } from '../../../../components/Button/Button';
import Table from '../../../../components/Table/Table';
import Pagination from '../../../ManageAuthKeys/Pagination/Pagination';
import { getInsightsNodesList, getNodeStatus } from '../../../../utils/diagAPI';
import { Link } from 'react-router-dom';
import StatusInfoPopover from './StatusInfoPopover/StatusInfoPopover';
import SelectDropdown from '../../../../components/SelectDropdown/SelectDropdown';
import { Input } from '../../../../components/Input/Input';
import Notice from '../../../../components/AppNotices/Notice/Notice';
import { trim } from 'lodash';
import { generateHashURL } from '../../../../utils/commonFunctions';
import AdvancedInsightsContext from '../../../AdvancedInsights/context/insights-context';

function InsightsNodes(props) {
    const [ nodesData, setNodesData ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ limit, setLimit ] = useState(15);
    const [ pagetoken, setPagetoken ] = useState("");
    const [ prevPagesTokens, setPrevPagesTokens ] = useState([""]);
    const [ nextPageToken, setNextPageToken ] = useState("");
    const { 
        startTimestamp,
        endTimestamp
    } = useContext(AdvancedInsightsContext);

    const nodeCategories = [
        {
            id: "all",
            label: "All Nodes",
            value: "all",
            interface_key: ""
        },
        {
            id: "mqtt",
            label: "MQTT Based Nodes",
            value: "mqtt",
            interface_key: "RMP"
        },
        {
            id: "rest",
            label: "REST Based Nodes",
            value: "rest",
            interface_key: "NAK"
        }
    ]
    const [ selectedNodeCategory, setselectedNodeCategory ] = useState(nodeCategories[0].id);
    const [ searchKey, setSearchKey ] = useState("");
    const [ nodesStatus, setNodesStatus ] = useState({});
    const [ nodesStatusLoading, setNodesStatusLoading ] = useState(true);

    const getNodesStatus = nodesData => {
        setNodesStatusLoading(true);
        const requests = nodesData.map(node => node.node_id).map(nodeId => getNodeStatus(nodeId));
        Promise.all(requests).then(responses => {
            const status = {};
            responses.map(response => {
                if (response.status) {
                    status[response.node_id] = response.status
                }
            });
            setNodesStatus(status);
            setNodesStatusLoading(false);
        })
    }

    const getCategoryDataById = id => {
        const data = nodeCategories.filter(c => c.id === id);
        if (!data.length) return null;
        return data[0];
    }

    const getNodes = (searchFlag = false) => {
        setLoading(true);
        const categoryData = getCategoryDataById(selectedNodeCategory);
        const interfaceKey = categoryData ? categoryData.interface_key : "";
        const _pagetoken = searchFlag ? "" : pagetoken;
        getInsightsNodesList(limit, _pagetoken, trim(searchKey), interfaceKey)
        .then(({list = [], page_token = "", status = "", description = ""}) => {
            if (status === "failure" && description === "Unauthorized") {
                props.sessionExpire();
            }
            else {
                setLoading(false);
                setNodesData(list);
                setNextPageToken(page_token);
                getNodesStatus(list);
            }
        });
    }

    const handlePrevious = () => {
        const newTokensList = [...prevPagesTokens];
        let newPagetoken = newTokensList[newTokensList.length - 2];
        newTokensList.pop();
        setPrevPagesTokens(newTokensList);
        setPagetoken(newPagetoken);
    }

    const handleNext = () => {
        setPagetoken(nextPageToken);
        setPrevPagesTokens(currentTokens => [...currentTokens, nextPageToken]);
    }

    const handleLimitChange = limit => {
        setLimit(limit);
        setPagetoken("");
        setPrevPagesTokens([""]);
    }

    const handleNodeCategoryChange = categoryData => {
        if (categoryData.id) {
            setselectedNodeCategory(categoryData.id);
        }
    }

    const handleNodeSearch = e => {
        e.preventDefault();
        setPrevPagesTokens([""]);
        getNodes(true);
    }

    useEffect(function(){
        getNodes();
    }, [pagetoken, limit]);

    const getNodeDetailsPageURL = (nodeData) => {
        let nodeDetailsURL = "#";

        if (nodeData.node_id && nodeData.node_key) {
            const hashParams = { start: startTimestamp, end: endTimestamp }
            nodeDetailsURL = generateHashURL(`/home/nodes/${nodeData.node_id}/?key=${nodeData.node_key}`, hashParams);
        }

        return nodeDetailsURL;
    }

    const columns = useMemo(
        () => [
            {
                Header: ' ',
                accessor: (row) => (
                    <StatusInfoPopover 
                        loading={nodesStatusLoading} 
                        nodeId={row.node_id} 
                        statusData={nodesStatus[row.node_id]} 
                    />
                ),
                style: {
                    maxWidth: "40px",
                    minWidth: "40px",
                    width: "40px",
                }
            },
            {
                Header: 'Node Id',
                accessor: (row) => (
					<div className='d-flex align-items-center '>
                        <Link
                            to={{ pathname: getNodeDetailsPageURL(row) }}
                        >
                            <p className='mb-0'>{row.node_id}</p>
                        </Link>
					</div>
				)
            },
            {
                Header: 'Chip',
                accessor: "chip"
            },
            {
                Header: 'Project',
                accessor: "project"
            },
            {
                Header: 'Project Version',
                accessor: "app_version"
            }
        ], [nodesStatus, nodesStatusLoading]
    );

    return (
        <div className="diagnostics-nodes">
            <div className='app--navbar'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='headings--container'>
                        <div className='d-flex'>
                            <p className='main--heading'>Nodes</p>
                            <RefreshButton
                                loading={loading}
                                onClick={() => getNodes()}
                            />
                        </div>
                    </div>
                    <div>
                        <form onSubmit={handleNodeSearch} className="d-flex align-items-center node-search flex-grow-1 justify-content-end">
                            <Input
                                type='text'
                                name='search'
                                placeholder='Search Node'
                                isInline={true}
                                onChange={(e) => {
                                    setSearchKey(e.target.value)
                                }}
                                maxLength={64}
                                id='search-node'
                                value={searchKey}
                            />

                            <SelectDropdown 
                                placeholder='Select Node Type'
                                type='button'
                                id='node-type'
                                defaultValue={getCategoryDataById(selectedNodeCategory)}
                                options={nodeCategories}
                                onChange={handleNodeCategoryChange}
                                className='navbar--dropdown select-dropdown-for-report line-dd ml-2'
                            />

                            <PrimaryLineButton
                                id='search-img-btn'
                                title='Search Package'
                                className='ml-2'
                                type='submit'
                                
                            >
                                Search
                            </PrimaryLineButton>
                        </form>
                    </div>
                </div>
            </div>

            {
                !nodesData.length && !loading ? (
                    <div className='home-card card--container d-flex flex-column justify-content-between'>
                        <Notice 
                            type="info"
                            text="Sorry, we couldn't find any nodes for your search."
                        />
                    </div>
                ) : (
                    <div className='home-card card--container d-flex flex-column justify-content-between'>
                        <div className='row'>
                            <div className='col-lg-12 h-100'>
                                <Table 
                                    data={nodesData}
                                    columns={columns}
                                    loading={loading}
                                    loaders={limit}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-12 h-100'>
                                <Pagination 
                                    onNext={nextPageToken ? handleNext : null}
                                    onPrev={prevPagesTokens.length > 1 ? handlePrevious : null}
                                    pageSize={limit}
                                    minPageSize={15}
                                    maxPageSize={45}
                                    showPageSizeControl={true}
                                    onPageSizeChange={handleLimitChange}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default InsightsNodes;
