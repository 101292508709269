import React, {useState, useContext, useEffect} from 'react';
import AdvancedInsightsContext from '../../../context/insights-context';
import EventTypesButtonGroup from '../../EventTypesButtonGroup/EventTypesButtonGroup';
import './GroupAnalyticsPie.scss';
import { getPieChartDataFromDistribution } from '../../../data-transform/data-transform';
import PieChart from '../../PieChart/PieChart';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import * as generalConfig from './../../../general-config.json';
import { UilChartPie, UilListUl  } from '@iconscout/react-unicons';
import TabsHeader from './TabsHeader/TabsHeader';
import DistributionList from './DistributionList/DistributionList';
import NoDataAvailable from '../../WidgetWrapper/NoDataAvailable/NoDataAvailable';
import Loader from '../../../../../components/Loader/Loader';
import { useElementDimensions } from '../../../hooks/useElementDimensions';

function GroupAnalyticsPie() {
    const { 
        selectedFilters,
        startTimestamp,
        endTimestamp,
        getEventCountData,
        resetEventCountDistributionByCategory,
        eventCountDistributionByCategory,
        messages,
        initialLoadCompleteStatus,
        addMultipleFiltersToSelectedFilters,
        eventCountDistributionByCategoryResetStatus,
    } = useContext(AdvancedInsightsContext);

    const globalEventTypeFilter = selectedFilters.filter(f => f.filterType === generalConfig.event_type_filter_key);
    const globalEventTypeFilterValue = globalEventTypeFilter.length === 1 ? globalEventTypeFilter[0].filterValue : "";

    const [eventType, setEventType] = useState(globalEventTypeFilterValue || "error");
    const [formattedGraphData, setFormattedGraphData] = useState(null);

    const tabsList = [
        {
            id: "chart",
            icon: <UilChartPie size={20} />
        },
        {
            id: "list",
            icon: <UilListUl size={20} />
        }
    ];
    const [activeTab, setActiveTab] = useState("chart"); // chart or list

    const eventTypes = generalConfig.event_types;
    const getEventConfigData = type => eventTypes.filter(f => f.id === type)[0];
    const getPieChartTypeTitle = type => messages.widgets.group_analytics_pie_chart.types[type];

    const [containerElement, elementDimensions] = useElementDimensions();

    useEffect(() => {
        if ((eventCountDistributionByCategory === null && initialLoadCompleteStatus === true) || eventCountDistributionByCategoryResetStatus === true) {
            getEventCountData({eventType});
        }
        return () => resetEventCountDistributionByCategory();
    }, [])

    useDidMountEffect(() => {
            getEventCountData({eventType});
    }, [startTimestamp, endTimestamp, selectedFilters, eventType]);

    useEffect(() => {
        if (globalEventTypeFilterValue) {
            setEventType(globalEventTypeFilterValue);
        }
    }, [globalEventTypeFilterValue]);

    useDidMountEffect(() => {
        if (globalEventTypeFilterValue !== eventType) {
            getEventCountData({eventType});
        }
    }, [eventType]);

    useEffect(() => {
        const graphData = getPieChartDataFromDistribution({
            type: eventType, 
            dist: eventCountDistributionByCategory,
            clickHandler: handlePieClick,
            messages: messages
        });

        setFormattedGraphData(graphData);
    }, [eventCountDistributionByCategory]);

    const handlePieClick = data => {
        const filtersData = [
            {
                filterType: generalConfig.event_type_filter_key,
                filterValue: eventType
            },
            {
                filterType: getEventConfigData(eventType).analytics_subcategory,
                filterValue: data.category
            },
        ]

        addMultipleFiltersToSelectedFilters(filtersData);
    }

    if (eventCountDistributionByCategory === null && initialLoadCompleteStatus === true) return <Loader />

    return (
        <div className='group-analytics-pie' ref={containerElement}>
            <div className='toolbar'>
                <EventTypesButtonGroup 
                    defaultSelectedType={eventType} 
                    globalEventTypeFilterValue={globalEventTypeFilterValue}
                    clickHandler={type => setEventType(type)}
                    labels={{...messages.event_types, ...messages.common_labels}}
                    parentWidth={elementDimensions.width}
                />
            </div>
            
            {
                eventCountDistributionByCategory === null || eventCountDistributionByCategory?.length === 0 ? <NoDataAvailable /> : (
                    <React.Fragment>
                        <TabsHeader  
                            tabsList={tabsList}
                            title={getPieChartTypeTitle(eventType)}
                            activeTab={activeTab}
                            tabChangeHandler={id => setActiveTab(id)}
                        />
                        
                        { 
                            formattedGraphData ? (
                                <div className='details'>
                                    { activeTab === "chart" ? (
                                            <PieChart 
                                                data={formattedGraphData} 
                                            />
                                        ) : (
                                            <DistributionList 
                                                data={eventCountDistributionByCategory} 
                                                clickHandler={handlePieClick}
                                                labels={messages.widgets.group_analytics_pie_chart.list}
                                            />
                                        ) 
                                    }
                                </div>
                            ) : null 
                        }
                    </React.Fragment>
                )
            }
        </div>
    )
}

export default GroupAnalyticsPie