export const DIAG_API = window.DIAG_API_URL;

export const GRAPH_COLORS = [
	'#4895ef', //blue
	'#e6194B', //red
	'#f58231', //orange
	'#3cb44b', //green
	'#fca311', //yellow
	'#5a189a', //purpul
	'#42d4f4', //lightblue
	'#9d0208', //red dark
	'#ff5d8f', //pink
	'#583101', //brown
	'#9381ff', //lavendar
	'#0a9396', //teal
	'#bc6c25', //brick
	'#036666', //dark green
];

export const USER_ROLES_SUPER_ADMIN = 'super_admin';
export const USER_ROLES_CUSTOMER = 'customer';
export const USER_ROLES_ALL = 'user';

export const COMMON_API_ACTIONS = {
	CALL_API: 'call_api',
	RESOLVE: 'resolve',
	REJECT: 'reject',
	RESET: 'reset_state',
	RESET_SEARCH: 'reset_search',
	NEXT: 'next_page',
	PREV: 'prev_page',
	PAGE_SIZE: 'change_page_size',
};
