import React, { useState } from 'react';
import './WidgetExpandableListItem.scss';
import { UilUserSquare, UilAngleDown, UilAngleUp, UilCheck, UilTimes } from '@iconscout/react-unicons';
import DetailsCard from './DetailsCard/DetailsCard';
import Actions from './Actions/Actions';

function WidgetExpandableListItem({
    title,
    tip,
    acceptActionText,
    acceptActionHandler = null,
    rejectActionText,
    rejectActionHandler = null,
    acceptAction = null,
    acceptActionInfoMessage = "",
    detailsData = null
}) {
    const [showDetails, setShowDetails] = useState(false);

    const handleDetailsDisplay = () => {
        setShowDetails(show => !show);
    }

    return (
        <div className={`
            widget-expandable-list-item-wrapper 
            ${acceptAction !== null ? (acceptAction ? 'accepted' : 'rejected') : ''}
        `}>   
            <div className='preview' onClick={handleDetailsDisplay}>
                <div className='user-icon-container'>
                    <div className='icon'>
                        <UilUserSquare />
                    </div>
                </div>
                <div className='primary-content'>
                    <div className='title'>{title}</div>
                    {
                        !showDetails && (
                            <div className='tip'>{tip}</div>
                        )
                    }
                </div>
                {
                    !showDetails && (
                        <div className='action-items button-group'>
                            <Actions 
                                acceptActionText={acceptActionText}
                                acceptActionHandler={acceptActionHandler}
                                rejectActionText={rejectActionText}
                                rejectActionHandler={rejectActionHandler}
                                acceptAction={acceptAction}
                                acceptActionInfoMessage={acceptActionInfoMessage}
                            />
                        </div>
                    )
                }
                <div className='expander-icon'>
                    { showDetails ? <UilAngleUp /> : <UilAngleDown /> }
                </div>
            </div>

            {
                (showDetails && detailsData) && (
                    <div className='details'>
                        <DetailsCard data={detailsData} />
                        <div className='details-actions'>
                            <Actions 
                                acceptActionText={acceptActionText}
                                acceptActionHandler={acceptActionHandler}
                                rejectActionText={rejectActionText}
                                rejectActionHandler={rejectActionHandler}
                                acceptAction={acceptAction}
                                acceptActionInfoMessage={acceptActionInfoMessage}
                            />
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default WidgetExpandableListItem