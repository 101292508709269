import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { getMetricsdata, getNodeLogsList } from "../../../utils/diagAPI";
import TimeSeriesGraph from "./TimeSeriesGraph";
import { TimeRange } from "pondjs";
import WidgetWrapper from "../../AdvancedInsights/Widgets/WidgetWrapper/WidgetWrapper";
import InfoMessageWrapper from "../../AdvancedInsights/Widgets/InfoMessageWrapper/InfoMessageWrapper";
import { 
  getSerializedFiltersList,
} from "./../../../pages/AdvancedInsights/data-transform/data-transform.js";
import { getEventList } from "./../../../utils/diagQueryAPI";

const ListGraphWidgets = (props) => {
  const {
    metricOptions,
    interval,
    metaData,
  } = props;

  // API Responses
  const [metricData, setMetricData] = useState({});
  const [logData, setLogData] = useState([])
  const [chartsTimeRange, setChartsTimeRange] = useState(null)
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if (metricOptions.length <= 0)  return ;
      getMetric(metricOptions, interval);
      getLogData(generateNodeLogsRequestData({
        nodeKey: props.nodeKey, 
        from_ts: interval.start, 
        to_ts: interval.end
      }));
      calculateChartTimeRange(interval);

  }, [metricOptions, interval]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const getMetricByType = async (type, startTime, endTime, pageToken) => {
    let result = await getMetricsdata(
      { node_id: props.nodeId, node_key: props.nodeKey },
      type,
      endTime,
      startTime,
      pageToken
    );

    if (result.description === "Unauthorized") {
      props.sessionExpire();
      return;
    }

    if (result.status !== "failure" && result[0]) {
      return result[0];
    }
  };

  const getTotalMetricData = async (response, startTime, endTime) => {
    return new Promise(async (resolve, reject) => {
      let currentMetricData = response.data;
      let pageToken = response.pagetoken || null;
      while (pageToken !== null) {
        let result = await getMetricByType(
          response.id,
          startTime,
          endTime,
          pageToken
        );
        if (result) {
          currentMetricData.push(...result.data);
          pageToken = result.pagetoken || null;
        } else {
          pageToken = null;
        }
      }

      currentMetricData
        ? resolve(currentMetricData)
        : reject(currentMetricData);
    });
  };

  const getMetric = async (metricType, interval) => {

    let startTime = interval.start;
    let endTime = interval.end;

    let result = await getMetricsdata(
      { node_id: props.nodeId, node_key: props.nodeKey },
      metricType,
      endTime,
      startTime
    );

    if (result.description === "Unauthorized") {
      props.sessionExpire();
      return;
    }

    if (result.status === "failure") {
      return;
    }

    let metricData = {};
    await Promise.all(
      result.map( async (response, index) => {
        if (response?.data?.length > 0) {
          const totalMetricData = await getTotalMetricData(
            response,
            startTime,
            endTime
          );
          
          const sortedTzResponse = totalMetricData.map((resp) => {
            return {
              ...resp,
              ts: resp.ts,
            };
          });
  
          const metricColor = metricType.filter(
            (metric) => metric.id === response.id
          );
  
          if (response.id) {
            metricData = {
              ...metricData,
              [response.id]: sortedTzResponse,
              [`${response.id}-color`]: metricColor[0].color,
            };
          }
        }
      })
    );

    setMetricData(metricData);
    setLoading(false)
    
  };

  const getLogData = async (requestData) => {
    if (!requestData) return false;
    let logdata = [];
    let pageToken = null;
    do{
        let response = await getEventList(pageToken ? {...requestData, pagetoken: pageToken} : requestData);
        
        if (response?.data?.list) {
          logdata.push(...response.data.list);
          pageToken = response.data.pagetoken || null;
        } else {
          pageToken = null;
        }
    }while (pageToken) 

    const _logData = logdata.map((log) => ({
      message: log.Message,
      time: log.Timestamp / 1000,
      type: log.Type,
    }));
    setLogData(_logData)
  };

  const generateNodeLogsRequestData = ({
    nodeKey,
    from_ts,
    to_ts,
    limit = 25,
  }) => {
    if (!nodeKey || nodeKey.split(":").length !== 3) return null;
    const nodeKeyData = nodeKey.split(":");
    return {
      limit,
      from_ts,
      to_ts,
      filters: getSerializedFiltersList(
        [],
        [
          {
            filterType: "Node.Interface.Type",
            filterValue: nodeKeyData[0],
          },
          {
            filterType: "Node.Interface.ID",
            filterValue: nodeKeyData[1],
          },
          {
            filterType: "Node.ID",
            filterValue: nodeKeyData[2],
          },
        ],
        [
          {
            filterType: "Type",
            filterValue: ["crash", "reboot"],
            operator: "keyword",
          },
        ]
      ),
    };
  };
  
  const chartRangeUpdate = (range , index) => {
    setChartsTimeRange(()=>range);
  }
  
  const calculateChartTimeRange = (interval) => {
    if (interval) {
      const beginTime = moment.unix(interval.start).utc();
      const endTime = moment.unix(interval.end).utc();
      const _timeRange = new TimeRange([beginTime, endTime]);
      setChartsTimeRange(_timeRange);
    }
  };
  
  return (
    <> 

      <InfoMessageWrapper wrapperStyle={{margin:"10px 0px"}}>
        Displaying data from <b>{ moment(interval.start*1000).format('LLLL')} </b> to <b>{ moment(interval.end*1000).format('LLLL')} </b> 
      </InfoMessageWrapper>

      {
        !loading ? (
        Object.keys(metricData).length > 0 ? 
        (
          metaData.map((metaData,index) => 
            <TimeSeriesGraph
              key={index}
              data={metricData}
              metaData={[metaData]}
              interval={interval}
              loading={loading}
              logData={logData}
              chartTimeRange={chartsTimeRange}
              setChartsTimeRange={chartRangeUpdate}
            />)
        ):
        <div className="text-center d-flex justify-content-center align-items-center info-message-container"> <p className="mb-0">No data found!</p> </div>
      ) :
        <WidgetWrapper  loading={loading} containerStyles={{padding: '0px', borderRadius: '10px'}} />
      }
    </>
  );
};

export default ListGraphWidgets;
