import React, {useEffect, useContext, useState} from 'react';
import './NodeTimeline.scss';
import AdvancedInsightsContext from './../../../../context/insights-context';
import { getTimelineChartDataFromDistribution, getGraphOptions, searchFiltersList } from '../../../../data-transform/data-transform';
import * as generalConfig from './../../../../general-config.json';
import TimelineToolbar from './TimelineToolbar/TimelineToolbar';
import TimelineChart from './TimelineChart/TimelineChart';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import NoDataMessage from '../../../../Widgets/NoDataMessage/NoDataMessage';

function NodeTimeline() {
  const { 
    getNodeTimelineData,
    selectedNodeTimelineDistributionData,
    selectedNodeTimelineDistributionDataLoading,
    nodeFilters,
    messages,
    filtersData,
    startTimestamp, 
    endTimestamp,
    selectedFilters,
    selectedNodeTimelineAggregatorType,
    setSelectedNodeTimelineAggregatorType
  } = useContext(AdvancedInsightsContext);

  const timelineChartId = "node-timeline";
  const termAggregationFilters = searchFiltersList(filtersData, {"aggregation": ["term"]});
  const termAggregators = termAggregationFilters.map(f => ({label: f.FILTER_KEY, value: f.FILTER_KEY}));
  //const [aggregatorType, setAggregatorType] = useState({label: generalConfig.event_type_filter_key, value: generalConfig.event_type_filter_key});
  const maxBuckets = 175;
  const [graphSeriesData, setGraphSeriesData] = useState(null);
  const [graphOptionsData, setGraphOptionsData] = useState(null);
  const [graphColors, setGraphColors] = useState([]);
  const textLabels = messages.node_details.report.node_timeline;
  
  const supportedChartTypes = generalConfig.node_timeline_chart_options.map(t => ({label: `${textLabels.visualizations_charts[t]}`, value: t}));
  const [chartType, setChartType] = useState(supportedChartTypes[0]);

  useEffect(() => {
    if (selectedNodeTimelineDistributionData === null && nodeFilters.length) {
      getNodeTimelineData(selectedNodeTimelineAggregatorType, maxBuckets);
    }
  }, [nodeFilters]);

  useDidMountEffect(() => {
    getNodeTimelineData(selectedNodeTimelineAggregatorType, maxBuckets);
  }, [startTimestamp, endTimestamp, selectedFilters, selectedNodeTimelineAggregatorType]);

  useEffect(() => {
    const timelineChartDataFromDistribution = getTimelineChartDataFromDistribution({
      dist: selectedNodeTimelineDistributionData?.buckets, 
      filterTypeID: selectedNodeTimelineAggregatorType, 
      messages
    });

    if (timelineChartDataFromDistribution !== null) {
      setGraphSeriesData(timelineChartDataFromDistribution.normalisedData);
      setGraphColors(timelineChartDataFromDistribution.colors);
      setGraphOptions(chartType.value, timelineChartDataFromDistribution.colors, timelineChartDataFromDistribution.normalisedData);
    }
    else {
      setGraphSeriesData(null);
    }
  }, [selectedNodeTimelineDistributionData]);

  useEffect(() => {
    if (graphSeriesData) {
      setGraphOptions(chartType.value, graphColors, graphSeriesData);
    }
  }, [chartType]);

  const setGraphOptions = (type, colors, seriesData) => {
    const timelineChartOptions = getGraphOptions(type, { colors,
      labels: [], 
      id: timelineChartId,
      integerOnlyYAxis: true,
      showMarkers: seriesData?.length ? seriesData.every(s => s.data.length === 1) : false
    });

    setGraphOptionsData(timelineChartOptions);
  }
  
  const setAggregatorType = data => {
    data.value && setSelectedNodeTimelineAggregatorType(data.value);
  }

  if (selectedNodeTimelineDistributionData === null) return null;

  return (
    <div className='node-timeline-wrapper'>
        <TimelineToolbar 
          termAggregators={termAggregators} 
          aggregatorType={{label: selectedNodeTimelineAggregatorType, value: selectedNodeTimelineAggregatorType}} 
          setAggregatorType={setAggregatorType} 
          supportedChartTypes={supportedChartTypes}
          chartType={chartType}
          setChartType={setChartType}
          timelineDataLoading={selectedNodeTimelineDistributionDataLoading}
        />

        {
          (graphSeriesData && graphOptionsData) ? (
            <TimelineChart 
              series={graphSeriesData} 
              options={graphOptionsData} 
            />
          ) : <NoDataMessage text="No data found for the applied filters and selected data range." />
        }
    </div>
  )
}

export default NodeTimeline