import React from 'react';
import './NoDataAvailable.scss';
import * as messages from './../../../messages/en.json';

function NoDataAvailable() {
    return (
        <div className='no-data'>
            <h6>{messages.no_data.primaryText}</h6>
            <p>{messages.no_data.secondaryText}</p>
        </div>
    )
}

export default NoDataAvailable