import React, { useState, useContext, useEffect } from "react";
import "./CommandStatusInfo.scss";
import {
  UilAngleDown,
  UilAngleUp,
  UilCheck,
  UilExclamation,
} from "@iconscout/react-unicons";
import { formatPastTime } from "../../../../../../utils/commonFunctions";
import LoadingSpinner from "../../../../../../components/LoadingSpinner/LoadingSpinner";
import AdvancedInsightsContext from "../../../../context/insights-context";
import { getCommandRequestStatus } from "../../../../../../utils/diagAPI";
import { getTime } from "date-fns";

function CommandStatusInfo({ data }) {
  const [expand, setExpand] = useState(false);
  const [commandRequestStatus, setCommandRequestStatus] = useState("requested");
  const [commandRequestedAt, setCommandRequestedAt] = useState("");

  const [commandRequestStatusCheckedAt, setCommandRequestStatusCheckedAt] =
    useState(getTime(new Date()));

  const { messages } = useContext(AdvancedInsightsContext);

  const requestId = data.requestId;
  const statusCheckIntervalDuration = 10 * 1000; // 10 seconds
  const widgetMessages =
    messages.node_settings.settings_change_history_widget.command_status_info;
  const statusCssClass = data.status;

  const descriptionData = [
    {
      id: "request_id",
      value: requestId,
    },
    {
      id: "new_value",
      value: data.value,
    },
    {
      id: "additional_details",
      value: data.description,
    },
    {
      id: "command_status",
      value: commandRequestStatus,
    },
  ];

  const getStatusIcon = (status) => {
    if (status === "success") {
      return <UilCheck />;
    }
    if (status === "pending") {
      return <LoadingSpinner />;
    }
    return <UilExclamation />;
  };

  const checkStatus = async () => {
    const response = await getCommandRequestStatus(requestId);
    const commandResponseData = (response.total && response.requests[0]) ? response.requests[0] : {};
    
    let currentStatus = commandResponseData.status || "";
    if (commandResponseData.status_description) {
      currentStatus = `${currentStatus} (${commandResponseData.status_description})`
    }
    setCommandRequestStatus(currentStatus);
    setCommandRequestStatusCheckedAt(getTime(new Date()));
  };

  const updateCommandRequestedTimeFormattedText = () => {
    if (!data.ts) return;
    setCommandRequestedAt(formatPastTime(data.ts));
  };

  useEffect(() => {
    let statusCheckInterval = null;
    if (requestId) {
      if (!statusCheckInterval) {
        statusCheckInterval = setInterval(
          checkStatus,
          statusCheckIntervalDuration
        );
      }

      if (commandRequestStatus !== "requested") {
        statusCheckInterval && clearInterval(statusCheckInterval);
      }
    }

    return () => {
      statusCheckInterval && clearInterval(statusCheckInterval);
    };
  }, [requestId, commandRequestStatus]);

  useEffect(() => {
    updateCommandRequestedTimeFormattedText();
    const updateRequestedTimeFormattedTextInterval = setInterval(
      updateCommandRequestedTimeFormattedText,
      30000
    );
    return () => clearInterval(updateRequestedTimeFormattedTextInterval);
  }, []);

  return (
    <div className={`command-status-info-wrapper ${statusCssClass}`}>
      <div className="preview" onClick={() => setExpand(!expand)}>
        <div className="icon-wrapper">{getStatusIcon(data.status)}</div>
        <div className="primary-text">{data.label || ""}</div>
        <div className="secondary-text">{commandRequestedAt}</div>
        <div className="end-icon-wrapper">
          {expand ? <UilAngleUp /> : <UilAngleDown />}
        </div>
      </div>
      {expand ? (
        <div className="details-wrapper">
          <div className="description">
            {descriptionData.map((desc) => (
              <div className="desc-row">
                <div className="column key">
                  {widgetMessages[desc.id] || desc.id}
                </div>
                <div className="column value">{desc.value}</div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CommandStatusInfo;
