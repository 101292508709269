import React, {useContext} from 'react';
import './LeftSection.scss';
import AdvancedInsightsContext from '../../../context/insights-context';
import WidgetWrapper from '../../../Widgets/WidgetWrapper/WidgetWrapper';
import UsageInsights from './UsageInsights/UsageInsights';

function LeftSection() {
    const { 
        
    } = useContext(AdvancedInsightsContext);

    return (
        <section className='left-section-wrapper'>
            <WidgetWrapper  
                wrapperStyles={{background: 'transparent', boxShadow: 'none'}}
                containerStyles={{padding: '0px'}}
            >
                <UsageInsights />
            </WidgetWrapper>
        </section>
    )
}

export default LeftSection