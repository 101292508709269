import React, { useContext } from 'react';
import advancedInsightsContext from '../../context/insights-context';
import WidgetWrapper from '../../Widgets/WidgetWrapper/WidgetWrapper';
import WidgetBox from '../../Widgets/WidgetBox/WidgetBox';
import ManageAccountAccess from './ManageAccountAccess/ManageAccountAccess';

function LeftSection() {
    const { 
        messages
    } = useContext(advancedInsightsContext);

    return (
        <div className='left-section-wrapper'>
            <WidgetWrapper  
                wrapperStyles={{borderRadius: '10px'}} 
                containerStyles={{padding: '0px'}}
            >
                <WidgetBox heading={messages.manage_multi_admins.manage_account_access.heading}>
                    <ManageAccountAccess />
                </WidgetBox>
            </WidgetWrapper>
        </div>
    )
}

export default LeftSection