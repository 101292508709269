import React from 'react';
import './WidgetTabs.scss';
import { Tabs, Tab } from 'react-bootstrap';

function WidgetTabs({data}) {
    return (
        <div className='widget-tabs-wrapper'>
            <Tabs unmountOnExit={true}>
                {
                    data.map(tab => (
                        <Tab 
                            key={tab.id} 
                            eventKey={tab.id} 
                            title={tab.title}
                        >
                            <tab.component />
                        </Tab>
                    ))
                }
            </Tabs>
        </div>
    )
}

export default WidgetTabs