import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import UserWrapper from '../../../components/UserWrapper/UserWrapper';
import {
	isValidEmail,
	isStrongPassword,
} from '../../../utils/validationSchemas';
import { signupCall } from '../../../utils/apiCalls';
import { Input } from '../../../components/Input/Input';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Password from '../../../components/Password/Password';
import { PrimaryDarkButton } from '../../../components/Button/Button';

const Signup = () => {
	let history = useHistory();

	const [termsChecked, setTermsChecked] = useState(false);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSignup = async (e) => {
		e.preventDefault();

		let user_name = e.target.elements.username.value;
		let password = e.target.elements.password.value;
		let confirmPassword = e.target.elements.confirmPassword.value;

		setLoading(true);
		let error = validateSignupRequest(
			user_name,
			password,
			confirmPassword,
			termsChecked
		);
		setError(error);

		if (!error) {
			let response = await signupCall(user_name, password);
			if (response.status === 'failure') {
				setError(response.description);
				setLoading(false);
				return;
			}
			history.push({
				pathname: '/verification',
				state: { user_name, password },
			});
		}
		setLoading(false);
	};

	useEffect(() => {
		return function () {
			setError('');
			setLoading(false);
			setTermsChecked(false);
		};
	}, []);
	return (
		<>
			<UserWrapper>
				<form onSubmit={handleSignup} className='pt-3'>
					<Input
						className='mb-2'
						type='email'
						name='username'
						placeholder='Email'
						id='username'
						onChange={() => error && setError('')}
					/>
					<Password
						className='mb-2'
						name='password'
						placeholder='Password'
						maxLength='64'
						id='password'
						onChange={() => error && setError('')}
					/>
					<Password
						className='mb-2'
						name='confirmPassword'
						placeholder='Confirm Password'
						maxLength='64'
						id='confirm-password'
						onChange={() => error && setError('')}
					/>

					{error && (
						<p id='error-msg' className={'text-error'}>
							{error}
						</p>
					)}

					<div className='d-flex justify-content-start align-items-start mb-2'>
						<Checkbox
							id='terms-checkbox'
							checked={termsChecked}
							onChange={() => {
								setError('');
								setTermsChecked(
									(termsChecked) => !termsChecked
								);
							}}
							label={
								<>
									I have read and agree to the&nbsp;
									<a
										href='https://insights.espressif.com/docs/privacy-policy'
										className='text-link'
										target='_blank'
										rel='noopener noreferrer'
										id='terms-of-use-link'
									>
										Privacy Policy
									</a>
									&nbsp;and &nbsp;
									<a
										href='https://insights.espressif.com/docs/terms-of-use'
										className='text-link'
										target='_blank'
										rel='noopener noreferrer'
										id='terms-of-use-link'
									>
										Terms of use.
									</a>
								</>
							}
						/>
					</div>
					<div className='d-flex justify-content-center align-items-center mt-3'>
						<PrimaryDarkButton
							loading={loading}
							minWidth='70px'
							disabled={loading ? true : false}
							id='signup-btn'
							tabIndex='6'
							type='submit'
						>
							Sign up
						</PrimaryDarkButton>
					</div>
				</form>
				<div
					className='d-flex justify-content-center mt-4'
					id='login-link-text'
				>
					<span className='text-muted'>
						Already have an account?&nbsp;
					</span>
					<a
						href='/login'
						className='text-link cursor-pointer'
						id='login-link'
					>
						Login
					</a>
				</div>
			</UserWrapper>
		</>
	);
};

function validateSignupRequest(user_name, password, confirmPassword, terms) {
	let error = '';
	if (!user_name || !password || !confirmPassword) {
		return (error = 'Please enter all the details.');
	}
	if (!isValidEmail(user_name)) {
		error = 'Please enter a valid email';
		return error;
	}
	if (password !== confirmPassword) {
		error = 'Passwords did not match';
		return error;
	}
	if (!isStrongPassword(password)) {
		error =
			'Password must be atleast 8 characters long. It should contain atleast one uppercase, one lowercase character and a number';
		return error;
	}
	if (!terms) {
		error = 'Please agree to the terms of service.';
		return error;
	}
	return error;
}

export default Signup;
