import React, {useContext, useEffect} from 'react';
import PulseDot from '../../../../Widgets/PrimaryWidgets/TopNodes/PulseDot/PulseDot';
import './NodeInfoCard.scss';
import AdvancedInsightsContext from './../../../../context/insights-context';
import { startsWith, lowerCase } from 'lodash';

function NodeInfoCard(props) {
    const { 
        selectedNodeInfoData,
        nodeFilters,
        getSelectedNodeOnlineStatus,
        selectedNodeOnlineStatus
    } = useContext(AdvancedInsightsContext);

    useEffect(() => {
        if (nodeFilters.length) {
            getSelectedNodeOnlineStatus();
        }
    }, [nodeFilters])

    if (selectedNodeInfoData === null) return null;

    const infoCardStyleClass = props.variant || "regular";

    return (
        <div className={`node-info-card ${infoCardStyleClass}`} style={props.containerStyles || null}>
            <div className='info-container'>
                {
                    selectedNodeInfoData.chip && startsWith(lowerCase(selectedNodeInfoData.chip), "esp") ? (
                        <div className='node-chip'>
                            <div className='indicator'>
                                {selectedNodeOnlineStatus === true ? <PulseDot /> : null}
                            </div>
                            <span className='chip-id'>{selectedNodeInfoData.chip}</span>
                        </div>
                    ) : null
                }

                {
                    (selectedNodeOnlineStatus === true && props.variant === "full-width") && (
                        <div className='data-row status'>
                            <div className='indicator'>
                                <PulseDot />
                            </div>
                        </div>
                    )
                }

                <div className='node-id data-row'>
                    <label>Node ID</label>
                    <h6>{selectedNodeInfoData.node_id}</h6>
                </div>
                <div className='project-details data-row'>
                    <label>Project</label>
                    <div className='details'>
                        <div className="name">{selectedNodeInfoData.project || "Unknown"}</div>
                        {
                            selectedNodeInfoData.app_version ? (
                                <React.Fragment>
                                    <div className='seperator'></div>
                                    <div className="version">v{selectedNodeInfoData.app_version}</div>
                                </React.Fragment>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NodeInfoCard