import React, { useContext } from 'react';
import PageNavBar from '../PageNavBar/PageNavBar';
import { Container, Row, Col } from 'react-bootstrap';
import './ManageMultiAdmins.scss';
import LeftSection from './LeftSection/LeftSection';
import RightSection from './RightSection/RightSection';
import advancedInsightsContext from '../context/insights-context';

function ManageMultiAdmins() {
    const { 
        messages
    } = useContext(advancedInsightsContext);

    return (
        <div className='manage-multi-admins-wrapper'>
            <PageNavBar 
                pageHeading={messages.manage_multi_admins.heading}
                hideTimeSelector={true}
            />
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xl={8} lg={7} md={12} sm={12} xs={12}>
                            <LeftSection />
                        </Col>
                        <Col xl={4} lg={5} md={12} sm={12} xs={12}>
                            <RightSection />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default ManageMultiAdmins