import React from 'react';
import './TabsHeader.scss';

function TabsHeader({tabsList, title, activeTab, tabChangeHandler}) {
    return (
        <div className='tabs-header'>
            <div className='title'>{title}</div>
            <div className='actions'>
                {
                    tabsList.map(tab => (
                        <button 
                            onClick={() => tabChangeHandler(tab.id)} 
                            id={tab.id} 
                            key={tab.id} 
                            className={`tab-selector ${activeTab === tab.id ? 'active' : ''}`}
                        >
                            { tab.icon }
                        </button>
                    ))
                }
            </div>
        </div>       
    )
}

export default TabsHeader