import React from 'react';
import LogMessage from '../../LogMessage/LogMessage';
import LogTrace from '../../LogTrace/LogTrace';
import './OtherTypeLog.scss';

function OtherTypeLog({data}) {
  console.log({data})
    return (
      <div className='other-type-log'>
        <LogMessage message={data.Message} tag={data.Log?.Tag} />
        <LogTrace data={data.Log?.Trace} />
      </div>
    )
}

export default OtherTypeLog