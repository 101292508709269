import React, { useState, useEffect } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { UilExclamationCircle, UilAngleRight, UilArrowUpRight, UilQuestionCircle } from '@iconscout/react-unicons';
import errorMessage from '../../../utils/errorMessages';
import SelectDropdown from '../../../components/SelectDropdown/SelectDropdown';
import MultiSelectDropdown from '../../../components/MultiSelectDropdown/MultiSelectDropdown';
import { getUrlHashParameterByName, updateHashURL } from '../../../utils/commonFunctions';
import ViewMetric from "./ViewMetric";
import { IconButton } from '../../../components/Button/Button';

const EventLogs = (props) => {
	const {
		loading,
		eventLogData,
		handleNext,
		handlePrev,
		nextLogToken,
		remarks,
		prevLogToken,
		filterLogs,
		defaultEFilters,
		nodeKey,
		nodeId,
		mandatoryFilters
	} = props;

	const [cardLoading, setCardLoading] = useState(loading);
	const [btnLoading, setBtnLoading] = useState(loading);

	useEffect(() => {
		if (eventLogData) {
			setCardLoading(false);
			setBtnLoading(false);
		}
	}, [eventLogData]);

	const tagColorsBack = {
		error: '#FF8A65',
		warning: '#FFB74D',
		event: '#81C784',
		crash: '#EF5350',
		reboot: '#82B1FF',
	};
	const tagColors = {
		error: '#c52f13',
		warning: '#986403',
		event: '#07780c',
		crash: '#c30a06',
		reboot: '#1c65de',
	};

	// const get32BitRegister = (register) => {
	// 	let regArray = register.split('');
	// 	let count = 10 - register.length;
	// 	let array = [...regArray, new Array(count).fill(0).join('')];
	// 	return array.join('');
	// };

	const redirectToMetrics = (logTime) => {
		var d = new Date();
		var offset = d.getTimezoneOffset() *- 60;
		const start = (logTime - 1800) + offset ;
		const end = (logTime + 1800) + offset;
		updateHashURL({start,end});
		//history.push(`/home/diagnostics/node/${nodeId}/metrics/?key=${nodeKey}${window.location.hash}`);
	}
	
	const get32BitExRegister = (register) => {
		let regArray = register.split('').slice(2);
		let count = 10 - register.length;
		let array = ['0x', new Array(count).fill(0).join(''), ...regArray];
		return array.join('');
	};

	const tableEventOptions = [
		{
			label: 'Events',
			value: 'event',
		},
		{
			label: 'Crashes',
			value: 'crash',
		},
		{
			label: 'Errors',
			value: 'error',
		},
		{
			label: 'Warnings',
			value: 'warning',
		},
		{
			label: 'Reboots',
			value: 'reboot',
		}
	]
	
	return (
		<div className='card--container diagnostic--card flex-column'>
			<div className='d-flex align-items-center'>
				<div><p className='card--title'>Node Logs</p></div>
				
				{remarks &&
					remarks.missingelfs &&
					remarks.missingelfs.length > 0 && (
						<p className='mb-0 d-flex align-items-center ml-3 remark-text'>
							<UilExclamationCircle size='20' color='#c52f13' />
							<span className='ml-1'>
								Some information is missing! There is a chance
								that some{' '}
								<Link
									to='/home/firmware-packages/'
									className='text-link font-12'
								>
									firmware package
								</Link>{' '}
								is not present. Please refer the esp-insights
								README for more information.
							</span>
						</p>
					)}
					
					<MultiSelectDropdown
						placeholder='Filter by'
						options={tableEventOptions}
						className='navbar--dropdown select-dropdown-for-report px-1 ml-auto'
						onDropdownClose={filterLogs}
						appendSelectionsToPlaceholder={true}
						values={defaultEFilters}
						requiredValues={mandatoryFilters}
					/>
					{/* Instruction model */}
					<div className="instructions--modal--container ml-2" >
						<IconButton>
              			  <span className={`mx-0 icon-container color-primary`}>
              			    <UilQuestionCircle size="16" />
              			  </span>
              			</IconButton>

            		  	{/* instructions contents */}
            		 	<div className="modal--body mt-2" style={{minWidth:"250px", marginLeft:"-230px"}}>
							<h6 className='my-0'>Timestamp</h6>
							<hr/>
            		    	<ul>
            		        	<li> <div className={`dot mr-1 _0`} ></div> Device Assigned </li>
            		        	<li> <div className={`dot mr-1 _1`} ></div> Server Extrapolated </li>
            		        	<li> <div className={`dot mr-1 _2`} ></div> Server Assigned </li>
            		    	</ul>
            		 	</div>
            		</div>
			</div>
			<hr />
			{cardLoading ? (
				<div
					className='d-flex w-100 justify-content-center align-items-center'
					style={{ minHeight: '80px' }}
				>
					<div className='spinner-border text-link' role='status'>
						<span className='sr-only'>Loading...</span>
					</div>
				</div>
			) : eventLogData && eventLogData.length ? (
				<>
					{eventLogData.map((item, i) => (
						<Accordion key={`${item.time}-${item.type}-${i}`}>
							<div
								style={
									item.type === 'crash'
										? {
												background: '#ef53500d',
										  }
										: null
								}
								className={`${
									item.type !== 'reboot'
										? 'cursor-pointer'
										: ''
								} `}
							>
								<Accordion.Toggle as={Card.Header} eventKey='0'>
									<div className='d-flex node--logs align-items-center'>
										<div className='chevron flex'>
											{item.type !== 'reboot' && (
												<UilAngleRight
													size={20}
													color='#333'
													title='Open'
												/>
											)}
										</div>
										<div className="tooltip-container">
											{item.hasOwnProperty("ts_source") && <span className="tooltiptext" style={{minWidth:"100px"}}> 
													{
														{
															0: "Device Assigned Timestamp",
															1: "Server Extrapolated Timestamp",
															2: "Server Assigned Timestamp"
														}[item.ts_source]
													}
											</span>}
											<div className='logtime flex'>
												<div className={`dot mr-1 _${item.ts_source}`} ></div>
												<p className='log--event--time'>
													{moment(item.time).format('DD MMM, HH:mm:ss')}
												</p>
													
											</div>
										</div>	
										<div className='tag flex'>
											<p
												className='log--type--tag mb-0 ml-2'
												style={{
													background: `${
														tagColorsBack[item.type]
													}33`,
													color: tagColors[item.type],
												}}
											>
												{item.type || ''}
											</p>
										</div>
										<p className='log--event--title'>
											{item.message || ''}
										</p>
										{item.type === 'reboot' &&
											item.data &&
											item.data.prev_app_version && (
												<div className='tag flex'>
													<p
														className='log--type--tag mb-0 ml-2'
														style={{
															background:
																'#5330b91a',
															color: '#5330b9',
														}}
													>
														Detected new firmware.
													</p>
												</div>
										)}
										<div className='ml-auto'>
												<ViewMetric nodeId={nodeId} nodeKey={nodeKey} logTime={item.time}/>
										</div>
									</div>
								</Accordion.Toggle>

								{/* Carsh */}
								{item.type === 'crash' && (
									<Accordion.Collapse eventKey='0'>
										<div className='crash--data'>
											<div className='row p-2'>
												<div className='col-lg-12'>
													<p className='log--event--title'>
														<u>Register Values</u>
													</p>
												</div>
											</div>
											<div className='row p-2'>
												<div className='col-lg-3 mb-1'>
													<p>
														<span className='highlight'>
															PC:{' '}
														</span>
														{(item.data.pc.length <
														10
															? get32BitExRegister(
																	item.data.pc
															  )
															: item.data.pc) ||
															''}
													</p>
												</div>

												{item.data.registers &&
													item.data.registers.a_reg &&
													item.data.registers.a_reg.map(
														(i, index) => (
															<div
																className='col-lg-3 mb-1'
																key={`${i}-${index}`}
															>
																<p>
																	<span className='highlight'>
																		A{index}
																		:{' '}
																	</span>
																	{i.length <
																	10
																		? get32BitExRegister(
																				i
																		  )
																		: i}
																</p>
															</div>
														)
													)}

												<div className='col-lg-3 mb-1'>
													<p>
														<span className='highlight'>
															EXCCAUSE:{' '}
														</span>

														{(item.data.cause.hex &&
														item.data.cause.hex
															.length < 10
															? get32BitExRegister(
																	item.data
																		.cause
																		.hex
															  )
															: item.data.cause
																	.hex) ||
															'0x00000000'}
													</p>
												</div>

												<div className='col-lg-3 mb-1'>
													<p>
														<span className='highlight'>
															EXCVADDR:{' '}
														</span>

														{(item.data.vaddr &&
														item.data.vaddr.length <
															10
															? get32BitExRegister(
																	item.data
																		.vaddr
															  )
															: item.data
																	.vaddr) ||
															'0x00000000'}
													</p>
												</div>
											</div>
											<div className='row p-2'>
												<div className='col-lg-12'>
													<p className='log--event--title mb-2'>
														<u>Backtrace</u>
														{
															item.type === 'crash' &&
															item.data &&
															item.data.bt_corrupt && (
																<span className="remark-text">(CORRUPTED)</span>
															)
														}
													</p>
												</div>
												{item.data.bt ? (
													item.data.bt.map(
														(btitem, i) => (
															<div
																className='col-lg-12 my-1'
																key={`${btitem.address}-${i}`}
															>
																<p>
																	<span className='highlight'>
																		{
																			btitem.address
																		}
																		:{' '}
																	</span>
																	{btitem.method &&
																		`${btitem.method} at ${btitem.file} : ${btitem.line}`}
																</p>
															</div>
														)
													)
												) : (
													<div className='col-lg-12 my-2'>
														<p className='mb-0'>
															We could not find
															the firmware image
															to cross-reference
															this log entry.
															Please update a{' '}
															<Link
																to='/home/firmware-images/'
																className='text-link'
															>
																firmware image
															</Link>{' '}
															to get
															cross-referenced
															information for this
															log entry
														</p>
													</div>
												)}
											</div>
										</div>
									</Accordion.Collapse>
								)}
								{/* other events */}
								{item.type !== 'crash' &&
									item.type !== 'reboot' &&
									item.data && (
										<Accordion.Collapse eventKey='0'>
											<div className='crash--data'>
												<div className='row p-2'>
													<div className='col-lg-12'>
														<p>
															<span className='highlight'>
																<u>
																	{item.data
																		.address ||
																		'??'}
																</u>
															</span>
															&nbsp;:&nbsp;
															{item.data.file ||
															item.data.line ||
															item.data.method ? (
																<>
																	{item.data
																		.file ||
																		'??'}
																	{' : '}
																	{item.data
																		.line ||
																		'??'}
																	{' in '}
																	{item.data
																		.method ||
																		'??'}
																</>
															) : (
																'We could not find the firmware image to cross-reference this log entry. Please update a firmware image to get cross-referenced information for this log entry'
															)}
														</p>
													</div>
												</div>
											</div>
										</Accordion.Collapse>
									)}
							</div>
						</Accordion>
					))}

					<div className='row'>
						<div className='col-lg-12 d-flex justify-content-between align-items-center'>
							<button
								className={`btn primary-button mt-3 ml-2 ${
									btnLoading ? 'primary-dark-button' : ''
								} `}
								onClick={() => {
									setBtnLoading(true);
									handlePrev();
								}}
								disabled={prevLogToken === "" ? true : false}
								id='prev-btn'
							>
								<div style={{ minWidth: '38px' }}>
									{btnLoading ? (
										<div
											className='spinner-border text-light'
											role='status'
										>
											<span className='sr-only'>
												Loading...
											</span>
										</div>
									) : (
										'Prev'
									)}
								</div>
							</button>
							<button
								className={`btn primary-button mt-3 ml-2 ${
									btnLoading ? 'primary-dark-button' : ''
								} `}
								onClick={() => {
									setBtnLoading(true);
									handleNext();
								}}
								id='next-btn'
								disabled={
									[...nextLogToken].length === 1
										? true
										: false
								}
							>
								<div style={{ minWidth: '38px' }}>
									{btnLoading ? (
										<div
											className='spinner-border text-light'
											role='status'
										>
											<span className='sr-only'>
												Loading...
											</span>
										</div>
									) : (
										'Next'
									)}
								</div>
							</button>
						</div>
					</div>
				</>
			) : (
				<div
					className='d-flex w-100 justify-content-center align-items-center'
					style={{ minHeight: '80px' }}
				>
					<p className='mb-0'>
						{errorMessage.DIAGNOSTIC.enableDiagnostic}
					</p>
				</div>
			)}
		</div>
	);
};



export default EventLogs;
