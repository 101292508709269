import React from 'react';
import './TopNode.scss';
import { ListGroup } from 'react-bootstrap';
import { UilMobileAndroidAlt, UilMobileVibrate, UilNotes, UilArrowGrowth, UilGold } from '@iconscout/react-unicons';
import { generateHashURL } from '../../../../../../utils/commonFunctions';
import { useHistory } from 'react-router';
import CustomTooltip from '../../../../../../components/CustomTooltip/CustomTooltip';
import StatusInfoPopover from '../../../../../OTA/Nodes/InsightsNodes/StatusInfoPopover/StatusInfoPopover';

function TopNode({nodeData, online, hashValues}) {
    const history = useHistory();
    const iconProps = { size: 18 };

    const actions = [
        {
            id: "report",
            tooltip: "View Node Report",
            icon: <UilNotes {...iconProps} />,
            disabled: false
        },
        {
            id: "metrics",
            tooltip: "View Node Metrics",
            icon: <UilArrowGrowth {...iconProps} />,
            disabled: false
        },
        {
            id: "variables",
            tooltip: "View Node Variables",
            icon: <UilGold {...iconProps} />,
            disabled: false
        }
    ];

    const handleRedirection = (event, actionId) => {
        event.stopPropagation();

        const view = actionId || actions[0].id;
        const hashParams = {...hashValues, view};
        const redirectURL = generateHashURL(`/home/nodes/${nodeData.node_id}/?key=${nodeData.node_key}`, hashParams);
        history.push(redirectURL);
    }

    return (
        <ListGroup.Item className={`top-node-wrapper ${online ? 'online' : ''}`} action onClick={e => handleRedirection(e)}>
            <div className='box d-flex'>
                <div className='node item icon'>
                    <StatusInfoPopover loading={false} nodeId={nodeData.node_id} statusData={{connectivity: {connected: online}}} />
                </div>
                <div className='text item'>{ nodeData.node_id }</div>
                <div className='count item'>{ nodeData.count.toLocaleString() } </div>
                <div className='actions item'>
                    {
                        actions.map(btn => {
                            const tipId = `${nodeData.node_id}-${btn.id}-tip`;
                            return (
                                <CustomTooltip 
                                    key={tipId}
                                    id={tipId}
                                    title={btn.tooltip}
                                >
                                    <button 
                                        onClick={e => handleRedirection(e, btn.id)}
                                        disabled={btn.disabled === true}
                                    >
                                        {btn.icon}
                                    </button>
                                </CustomTooltip>
                            )
                        })
                    }
                </div>
            </div>
        </ListGroup.Item>
    )
}

export default TopNode