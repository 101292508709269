import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import {
	getNodeInfo,
	getNode1LogByInterval
} from '../../../utils/diagAPI';
import EventLogs from './EventLogs';
import NodeDetailsCard from './NodeDetailsCard';
import CountCards from './CountCards';
import NodeHistogram from './NodeHistogram';
import { xor, isEmpty } from 'lodash-es';
import { getUrlParameterByName, getUrlHashParameterByName, updateHashURL } from '../../../utils/commonFunctions';
import AdvancedTimeSelector from '../../AdvancedInsights/PageNavBar/AdvancedTimeSelector/AdvancedTimeSelector';
import AdvancedInsightsContext from '../../AdvancedInsights/context/insights-context';
import { getEventList, getFilterSuggestions } from '../../../utils/diagQueryAPI';
import { generateInvalidIntervalsList, getSerializedFiltersList, transformNodeLogsData } from '../../AdvancedInsights/data-transform/data-transform';
import * as generalConfig from './../../../pages/AdvancedInsights/general-config.json';
import { getUnixTime, sub } from 'date-fns';
import useDidMountEffect from '../../AdvancedInsights/hooks/useDidMountEffect';
import GlobalFilters from '../../AdvancedInsights/GlobalFilters/GlobalFilters';

const Report = (props) => {

	const { 
		nodeFilters,
		setNodeFilters,
		clearNodeFilters,
		selectedFilters,
		dashboardAvailableStatus,
        messages
    } = useContext(AdvancedInsightsContext);
	
	const defaultCountInterval = 86400;
	const [countInterval, setCountInterval] = useState(defaultCountInterval);
	
	const [nodeInfo, setNodeInfo] = useState();
	const [eventCounter, setEventCounter] = useState();
	const [graphData, setGraphData] = useState();
	const [eventLogsData, setEventLogsData] = useState();
	const [remarks, setRemarks] = useState();
	const [loading, setLoading] = useState(true);
	
	const [prevLogToken, setPrevLogToken] = useState('');
	const [nextLogToken, setNextLogToken] = useState(new Set(['']));
	const [pageNo, setPageNo] = useState(1);
	
	const efilterHashValue = getUrlHashParameterByName("efilter");
	const [logFilters, setLogFilters] = useState(efilterHashValue ? efilterHashValue.split("|") : []);
	const [locationState, setLocationState] = useState(() => props.location.state);
	
	const nodeId = props.nodeId || props.match.params.node_id;
	const nodeKey = locationState && locationState.nodeKey ? locationState.nodeKey : getUrlParameterByName("key") || null;

	const defaultStartTimestamp = getUnixTime(sub((new Date()), { months: 1 }));
	const defaultEndTimestamp = getUnixTime(new Date());

	const [startTimestamp, setStartTimestamp] = useState(()=>{
		const startTimestamp = getUrlHashParameterByName("start");
		return startTimestamp ? Math.trunc(startTimestamp) : defaultStartTimestamp;
	});
	const [endTimestamp, setEndTimestamp] = useState(()=>{
		const endTimestamp = getUrlHashParameterByName("end");
		return endTimestamp ? Math.trunc(endTimestamp)  : defaultEndTimestamp;
	});
	const [logsLimit, setlogsLimit] = useState(35);

	const globalEventTypeFilter = selectedFilters.filter(f => f.filterType === generalConfig.event_type_filter_key);
    const globalEventTypeFilterValue = globalEventTypeFilter.length === 1 ? globalEventTypeFilter[0].filterValue : "";
	const [disabledIntervalsList, setDisabledIntervalsList] = useState([]);

	useEffect(() => {
		if (globalEventTypeFilterValue && !logFilters.includes(globalEventTypeFilterValue)) {
			setLogFilters(logFilters => [...logFilters, globalEventTypeFilterValue]);
		}
	}, [globalEventTypeFilterValue])

	useEffect(() => {
		if (!props.nodeId) {
			props.setNodeId(nodeId);
		}
		if(!props.nodeKey){
			props.setNodeKey(nodeKey)
		}
	}, []);

	useEffect(() => {
		if (nodeInfo && nodeInfo.nodeId) {
			const nodeDefaultFilters = [
				{
					filterType: "Node.ID",
					filterValue: nodeInfo.nodeId
				}
			];

			if (nodeInfo.interface_id) {
				nodeDefaultFilters.push({
					filterType: "Node.Interface.ID",
					filterValue: nodeInfo.interface_id
				})
			}
			
			if (nodeInfo.interface_id) {
				nodeDefaultFilters.push({
					filterType: "Node.Interface.Type",
					filterValue: nodeInfo.interface_type
				})
			}

			setNodeFilters(nodeDefaultFilters);
			return () => clearNodeFilters();
		}
	}, [nodeInfo]);

	useEffect(() => {
		if (nodeFilters.length) {
			getCountData();
			getEventLogsData();
		}
	}, [nodeFilters])
	
	const getCountData = async (node) => {
		let response = await getFilterSuggestions({
            from_ts: startTimestamp,
            to_ts: endTimestamp,
            filters: getSerializedFiltersList(selectedFilters, nodeFilters),
            fieldname: generalConfig.event_type_filter_key
        });

		let countData = {};
		if (response.data?.list) {
			response.data.list.forEach(e => countData[e.key] = e.count)
		}

		setEventCounter(countData);
	};

	const getNodeData = async () => {
		let response = await getNodeInfo({node_id: nodeId, node_key: nodeKey});
		let node = { nodeId: nodeId, ...response };
		setNodeInfo(node);
	};

	const getGraphData = async () => {
		let response = await getNode1LogByInterval({node_id: nodeId, node_key: nodeKey}, startTimestamp, endTimestamp, countInterval);
		if (response.description === 'Unauthorized') {
			props.sessionExpire();
			return;
		}

		const graphData = getFormattedGraphData(response, getIntervalDataByCount(countInterval).format);
		setGraphData(() => graphData);
	};

	const getEventLogsData = async (pagetoken = '', filters) => {
		const eventFilters = filters || logFilters;
		const updatedFiltersList = globalEventTypeFilterValue && !eventFilters.includes(globalEventTypeFilterValue) ? [...eventFilters, globalEventTypeFilterValue] : [...eventFilters];
		const eventTypeMultiValues = updatedFiltersList.length ? [{filterType: generalConfig.event_type_filter_key, filterValue: updatedFiltersList}] : null;

		let response = await getEventList({
            from_ts: startTimestamp,
            to_ts: endTimestamp,
            filters: getSerializedFiltersList(selectedFilters, nodeFilters, eventTypeMultiValues),
            limit: logsLimit,
            pagetoken: pagetoken
        });
		const data = response.data;

		const listData = data.list ? transformNodeLogsData(data.list) : [];
		setEventLogsData(listData);
		setRemarks(data.remarks);
		setPrevLogToken(data.pagetoken);
	};

	useEffect(() => {
		getNodeData();

		return () => {
			setPrevLogToken('');
			setNextLogToken(new Set(['']));
			setPageNo(1);
		};
	}, []);

	useEffect(() => {
		if(dashboardAvailableStatus) return ;
		setLoading(true);

		if (nodeFilters.length) {
			getCountData();
			getEventLogsData();
		}

		if (startTimestamp && endTimestamp) {
			let invalidIntervals = generateInvalidIntervalsList(startTimestamp, endTimestamp, 30);
			if (!invalidIntervals.includes(countInterval)) {
				getGraphData();
			}
			setDisabledIntervalsList(invalidIntervals);
		}

		setLoading(false);
	}, [startTimestamp, endTimestamp, selectedFilters])
	
	useDidMountEffect(() => {
		getGraphData();
	}, [countInterval])

	const handlePrevLogs = () => {
		setPageNo(pageNo + 1);
		setNextLogToken(nextLogToken.add(prevLogToken));
		getEventLogsData(prevLogToken);
	};

	const handleNextLogs = () => {
		setPageNo(pageNo - 1);
		let newNextLogToken = [...nextLogToken];
		if (newNextLogToken.length === 2) {
			getEventLogsData(newNextLogToken[pageNo - 2]);
		} else {
			getEventLogsData(newNextLogToken[pageNo - 2]);
		}
		setPrevLogToken(newNextLogToken[pageNo - 2]);
		nextLogToken.delete([...nextLogToken].pop());
	};

	const filterLogs = filters => {
		if (!isEmpty(xor(logFilters, filters))) {
			setLogFilters(filters);
			setPrevLogToken('');
			setNextLogToken(new Set(['']));
			getEventLogsData(null, filters);
			updateHashURL({efilter: filters.join("|")});
		}
	}

	const setDateTimeRange = range => {
        const { start, end } = range;
		if (start && end) {
			setStartTimestamp(start);
			setEndTimestamp(end);
			updateHashURL({start, end});
		}
    }

	return (
		<>
			<div className='diagnostic--page--wrapper'>
				<div className='row mb-3'>
					<div className='col-lg-12 d-flex justify-content-between'>
						<AdvancedTimeSelector 
                            messages={messages} 
                            startTimestamp={startTimestamp}
                            endTimestamp={endTimestamp}
							onSelect={setDateTimeRange}
                        />
					</div>
				</div>

				<div className='row mb-3'>
					<div className='col-lg-12 d-flex'>
						<GlobalFilters 
							containerStyles={{padding: '0px'}}
							fetchList={true}
							hideFiltersWithPrefixes={["Node"]}
						/>
					</div>
				</div>

				<div className='row'>
					<div className='col-lg-6'>
						<CountCards
							loading={loading}
							eventCounter={eventCounter}
						/>
					</div>
					<div className='col-lg-6'>
						<NodeDetailsCard
							loading={loading}
							nodeInfo={nodeInfo}
						/>
					</div>
				</div>
				<NodeHistogram
					loading={loading}
					graphData={graphData}
					time={getIntervalDataByCount(countInterval).id}
					intervalSelectorLabels={messages.interval_selection}
					intervalSelectorClickHandler={interval => setCountInterval(interval)}
					defaultSelectedInterval={countInterval}
					disabledIntervalsList={disabledIntervalsList}
				/>

				<div className='row'>
					<div className='col-lg-12'>
						<EventLogs
							eventLogData={eventLogsData}
							handleNext={() => handleNextLogs()}
							handlePrev={() => handlePrevLogs()}
							loading={loading}
							nextLogToken={nextLogToken}
							prevLogToken={prevLogToken}
							remarks={remarks}
							filterLogs={filters => filterLogs(filters)}
							defaultEFilters={logFilters}
							nodeId={nodeId}
							nodeKey={nodeKey}
							mandatoryFilters={[globalEventTypeFilterValue]}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

function getFormattedGraphData(object, dateFormat) {
	let emptyObj = {
		error: 0,
		warning: 0,
		reboot: 0,
		crash: 0,
	};

	if (object.data) {
		let emptyValues = object.intervals.filter((grp) => {
			return !object.data.some(
				(item) => item.timestamp.toString() === grp.toString()
			);
		});

		let emptyObjects = emptyValues.map((item) => ({
			timestamp: item,
			...emptyObj,
		}));

		let dataArray = [...object.data, ...emptyObjects].sort(function (a, b) {
			return a.timestamp < b.timestamp
				? -1
				: b.timestamp < a.timestamp
				? 1
				: 0;
		});

		let filteredArray = dataArray.filter((item) => {
			return item.timestamp.toString().length === 10;
		});

		let graphDataArray = filteredArray.map((item) => {
			let date;
			date = moment.unix(item.timestamp).utc().format(dateFormat);
			return { ...item, timestamp: date, startTime: item.timestamp };
		});

		return graphDataArray;
	}
	return [];
}

function getIntervalDataByCount (count) {
	const intervals = [
		{
			id: "1h",
			count: 3600,
			format: "HH:mm"
		},
		{
			id: "1d",
			count: 86400,
			format: "DD MMM"
		},
		{
			id: "1w",
			count: 604800,
			format: "DD MMM"
		},
		{
			id: "1m",
			count: 2592000,
			format: "MMM YYYY"
		}
	]

	const data = intervals.filter(i => i.count === count);
	if (data.length) return data[0];
	return intervals[0];
}

export default Report;
