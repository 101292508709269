import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getOtaImagesCall } from '../../../utils/apiCalls';
import { UilArrowLeft } from '@iconscout/react-unicons';
import {
	DeleteButton,
	PrimaryLineButton,
} from '../../../components/Button/Button';
import StartOTA from '../../../components/CustomModal/StartOTA';
import DeleteImage from '../../../components/CustomModal/DeleteImage';

const OtaImageDetails = (props) => {
	const [showStartOTA, setShowStartOTA] = useState(false);

	const otaImage = props.match.params.ota_image_id;
	const [response, setResponse] = useState({});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setError('');
		setLoading(true);
		getOtaImagesCall(otaImage, 'id')
			.then((resp) => {
				if (resp.statusCode === 401) {
					props.sessionExpire();
					return;
				}
				setLoading(false);
				if (resp.status === 'failure') {
					setError(resp.description);
				} else {
					setResponse(resp);
				}
			})
			.catch(() => {
				setError('Something went wrong!');
				setLoading(false);
			});
	}, []);

	return (
		<>
			<div className='app--navbar'>
				<div className='d-flex justify-content-between align-items-center'>
					<div>
						<p className='small--heading'>Overview</p>
						<div className='d-flex'>
							<p className='main--heading'>Image Details</p>
						</div>
					</div>
				</div>
			</div>
			<hr className='mx-3 my-0' />

			<div className='home-card card--container d-flex flex-column justify-content-between'>
				<div className='d-flex flex-column justify-content-between'>
					<div className='row'>
						<div className='col-lg-12 d-flex justify-content-between mb-2'>
							<Link
								to={`/home/firmware-images`}
								className='d-flex align-items-center'
								id='back-to-page-link'
							>
								<span className='icon-container ml-0'>
									<UilArrowLeft color='#5330b9' size={16} />
								</span>
								<p className='back-link'>
									Back to Firmware Images
								</p>
							</Link>

							<div className='d-flex align-items-center'>
								<DeleteButton
									onClick={() => setShowDeleteModal(true)}
								/>

								<PrimaryLineButton
									onClick={() => {
										setShowStartOTA(true);
									}}
									className='ml-2'
									title='Push Firmware Image'
									key='pushotaimage'
									id='push-img-btn'
								>
									Start OTA
								</PrimaryLineButton>
							</div>
						</div>
					</div>

					{loading ? (
						<div className='w-100'>
							<div className='placeholder transition'>
								<div
									className='animated-background d-flex align-items-center justify-content-center'
									style={{ height: '65vh' }}
								>
									<p className='mb-0'>Loading...</p>
								</div>
							</div>
						</div>
					) : error ? (
						<div className='error-container'>
							<p className='color-primary'>{error}</p>
						</div>
					) : (
						<div className='row'>
							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Name:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{response.image_name}</p>
							</div>

							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Image Id:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{response.ota_image_id}</p>
							</div>

							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Type:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{response.type}</p>
							</div>

							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Model:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{response.model}</p>
							</div>

							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Image URL:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>
									<a
										href={response.image_url}
										target='_blank'
										rel='noopener noreferrer'
										id='img-download-link'
									>
										{response.image_url}
									</a>
								</p>
							</div>

							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Firmware Version:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{response.fw_version}</p>
							</div>

							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Uploaded Date:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>
									{moment
										.unix(response.upload_timestamp)
										.format('DD MMM YY, HH:mm')}
								</p>
							</div>

							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>File Size:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{`${(
									response.file_size /
									(1024 * 1024)
								).toFixed(2)} MB`}</p>
							</div>
							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>MD5 Checksum:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{response.file_md5}</p>
							</div>
						</div>
					)}
				</div>

				{showStartOTA && (
					<StartOTA
						showModal={showStartOTA}
						setShowModal={setShowStartOTA}
						otaImage={response}
						sessionExpire={props.sessionExpire}
					/>
				)}

				{showDeleteModal && (
					<DeleteImage
						showModal={showDeleteModal}
						setShowModal={setShowDeleteModal}
						response={response}
						sessionExpire={props.sessionExpire}
					/>
				)}
			</div>
		</>
	);
};

export default OtaImageDetails;
