import moment from 'moment';
const dataPointsCount = 150;

export const getIntervalsList = (fromTime, toTime, timeInterval) => {
    if (timeInterval === '1w') {
        fromTime = moment.unix(fromTime).startOf('week').unix()
        toTime = moment.unix(toTime).startOf('week').unix()
    }
    else if (timeInterval === '1m') {
        fromTime = moment.unix(fromTime).startOf('month').unix()
        toTime = moment.unix(toTime).startOf('month').unix()
    }
    else if (timeInterval === '1y') {
        fromTime = moment.unix(fromTime).startOf('year').unix()
        toTime = moment.unix(toTime).startOf('year').unix()
    }
    else if (timeInterval === '1h') {
        fromTime = moment.unix(fromTime).startOf('day').unix()
        toTime = moment.unix(toTime).startOf('hour').unix()
    }
    else {
        fromTime = moment.unix(fromTime).startOf('day').unix()
        toTime = moment.unix(toTime).startOf('day').unix()
    }

    let intervalList = fromTime ? [fromTime] : [];
    let item = fromTime;
    let count = 1;
    let timeGroup = timeInterval;
    if (timeGroup === '1h') {
        while (item !== toTime) {
            item = moment.unix(fromTime).add(count, 'hours').unix()
            intervalList.push(item);
            count++;
        }
        intervalList = intervalList.map((item) => moment.unix(item).startOf('hour').unix())
    }
    else if (timeGroup === '1d') {
        while (item !== toTime) {
            item = moment.unix(fromTime).add(count, 'days').unix()
            intervalList.push(item);
            count++;
        }
        intervalList = intervalList.map((item) => moment.unix(item).startOf('day').unix())

    }
    else if (timeGroup === '1w') {
        while (item !== toTime) {
            item = moment.unix(fromTime).add(count, 'weeks').unix()
            intervalList.push(item);
            count++;
        }
        intervalList = intervalList.map((item) => moment.unix(item).startOf('weeks').unix())
    }
    else if (timeGroup === '1m') {
        while (item !== toTime) {
            item = moment.unix(fromTime).add(count, 'months').unix()
            intervalList.push(item);
            count++;
        }
        intervalList = intervalList.map((item) => moment.unix(item).startOf('months').unix())
    }
    else {
        while (item !== toTime) {
            item = moment.unix(fromTime).add(count, 'years').unix()
            intervalList.push(item);
            count++;
        }
        intervalList = intervalList.map((item) => moment.unix(item).startOf('years').unix())
    }
    return intervalList;
};

export const getGraphData = (timeInterval, intervalsList, data) => {
    const mergedIntervals = mergeIntervals(data, timeInterval, intervalsList);
    const graphData = getFormattedGraphData(intervalsList, timeInterval, mergedIntervals);
    return graphData;
};

function mergeIntervals(chartdata, time, intervals) {
    if (time === '1h') {
        chartdata.forEach(item => {
            item.ts = moment.unix(item.ts / 1000).startOf('hour').unix()
        })
    }
    else if (time === '1d') {
        chartdata.forEach(item => {
            item.ts = moment.unix(item.ts / 1000).startOf('day').unix()
        })
    }
    else if (time === '1w') {
        chartdata.forEach(item => {
            item.ts = moment.unix(item.ts / 1000).startOf('week').unix()
        })
    }
    else if (time === '1m') {
        chartdata.forEach(item => {
            item.ts = moment.unix(item.ts / 1000).startOf('month').unix()
        })
    }
    else {
        chartdata.forEach(item => {
            item.ts = moment.unix(item.ts / 1000).startOf('year').unix()
            item.ts = moment.unix(item.ts).add(1, 'years').unix()
        })
    }
    const data = [];
    intervals.forEach(item => {
        const object = {
            "timestamp": item,
            "count": 0
        };
        if (chartdata.length > 0) {
            chartdata.forEach(element => {
                if (element.ts === item) {
                    object["count"] = element.count;
                }
            })
        }
        if (object.count) {
            data.push(object);
        }
    })
    return data;
}

function getFormattedGraphData(intervals, time, chartData) {
    let emptyObj = {
        count: 0
    };
    if (chartData) {
        let emptyValues = chartData.length > 0 ? intervals.filter((grp) => {
            return !chartData.some(
                (item) => item.timestamp.toString() === grp.toString()
            );
        }) : [intervals];
        let emptyObjects = emptyValues.map((item) => ({
            timestamp: item,
            ...emptyObj,
        }));
        let dataArray = [...chartData, ...emptyObjects].sort(function (a, b) {
            return a.timestamp < b.timestamp
                ? -1
                : b.timestamp < a.timestamp
                    ? 1
                    : 0;
        });
        let filteredArray = dataArray.filter((item) => {
            return item.timestamp.toString().length === 10;
        });
        let data = [];
        let count = 0;
        filteredArray.forEach(item => {
            let object = {
                "timestamp": item.timestamp,
                "count": item.count
            };
            data.push(object)
        })
        let graphData = getGraphDataByTimeWindow(time, data);
        return graphData;
    }
    return [];
}

function getGraphDataByTimeWindow(timeInterval, data) {
    let time = timeInterval;
    if (time === '1h') {
        let graphDataArray = data.map((item) => {
            let date = moment.unix(item.timestamp).format('HH:mm DD MMM');
            return { "timestamp": date, "count": item.count };
        });
        // if (graphDataArray.length < dataPointsCount) {
        return graphDataArray;
        // }
        // time = '1d';
        // alert("Too many data points to plot, automatically adjusting the plot to accommodate data points");
    }
    if (time === '1d') {
        let graphDataArray = data.map((item) => {
            let date = moment.unix(item.timestamp).format('DD MMM');
            return { "timestamp": date, "count": item.count };
        });
        // if (graphDataArray.length < dataPointsCount) {
        return graphDataArray;
        // }
        // time = '1w';
        // alert("Too many data points to plot, automatically adjusting the plot to accommodate data points");
    }
    if (time === '1w') {
        let graphDataArray = data.map((item) => {
            return { "timestamp": moment(moment.unix(item.timestamp).startOf('week')).format("DD-MMM-YY"), "count": item.count };
        })
        // if (graphDataArray.length < dataPointsCount) {
        return graphDataArray;
        // }
        // time = '1m';
        // alert("Too many data points to plot, automatically adjusting the plot to accommodate data points");
    }
    if (time === '1m') {
        let graphDataArray = data.map((item) => {
            return { "timestamp": moment(moment.unix(item.timestamp).startOf('month')).format("MMM-YY"), "count": item.count };
        })
        // if (graphDataArray.length < dataPointsCount) {
        return graphDataArray;
        // }
        // time = '1y';
        // alert("Too many data points to plot, automatically adjusting the plot to accommodate data points");
    }
    if (time === '1y') {
        let graphDataArray = data.map((item) => {
            return { "timestamp": moment(moment.unix(item.timestamp).startOf('year')).format("YYYY"), "count": item.count };
        })
        return graphDataArray
    }
}

export const getNodeIdentifierFromNodeData = nodeData => {
    // Keys appearing first in the below list will have the highest priority
    // For example, if node_key is the first key in the list and if it exists
    // in nodeData, all other keys will be ignored
    const priorityKeys = ["node_key", "node_id"];

    let identifierKey = "";
    let identifierValue = "";

    for (let i = 0; i < priorityKeys.length; i++) {
        const currentKey = priorityKeys[i];
        if (nodeData.hasOwnProperty(currentKey) && nodeData[currentKey]) {
            identifierKey = currentKey;
            identifierValue = nodeData[currentKey];
            break;
        }
    }
    
    return {
        nodeIdentifierKey: identifierKey,
        nodeIdentifierValue: identifierValue
    }
}

export const addPropertiesOfObjects = (obj1, obj2) => {
    let result = {};
    for (let key in obj1) {
        const currentObj = obj1[key];
        const updatedObj = {...currentObj};
        if (obj2[key]) {
            for (let prop in currentObj) {
                if (obj2[key][prop] && typeof obj2[key][prop] === "number") {
                    updatedObj[prop] = currentObj[prop] + obj2[key][prop];
                }
            }
        }
        result[key] = updatedObj;
    }
    return result;
}