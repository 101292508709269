import React from 'react';
import './LinkIconButton.scss';
import { Button } from 'react-bootstrap';

function LinkIconButton({
    iconComponent,
    prependIcon = true,
    text,
    clickHandler,
    buttonIntent = "default",
    size = "default",
    variant = "",
    ...restButtonProps
}) {
    return (
        <Button onClick={clickHandler} className={`link-icon-button ${size} ${variant}`} {...restButtonProps}>
            {
                prependIcon && <ButtonIcon buttonIntent={buttonIntent} iconComponent={iconComponent} />
            }
            
            <span>{text}</span>

            {
                !prependIcon && <ButtonIcon buttonIntent={buttonIntent} iconComponent={iconComponent} />
            }
        </Button>
    )
}

const ButtonIcon = ({buttonIntent, iconComponent}) => {
    return (
        <div className={`button-icon-container ${buttonIntent}`}>
            {iconComponent}
        </div>
    )
}

export default LinkIconButton   