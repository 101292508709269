import React from 'react';
import './LogDetails.scss';
import CrashLog from './CrashLog/CrashLog';
import RebootLog from './RebootLog/RebootLog';
import OtherTypeLog from './OtherTypeLog/OtherTypeLog';

function LogDetails({data}) {
    const getLogComponent = () => {
        switch(data.Type) {
            case "crash":
                return <CrashLog data={data} />

            case "reboot":
                return <RebootLog data={data} />

            default: 
                return <OtherTypeLog data={data} />
        }
    }
    return (
        <div className='log-details-wrapper'>
            { getLogComponent() }
        </div>
    )
}

export default LogDetails