import React, {useState, useEffect} from 'react';
import { isValidAuthKeyName } from '../../utils/validationSchemas';
import { PrimaryLineButton } from '../Button/Button';
import { Input } from '../Input/Input';
import Modal from './Modal';

export default function AddAuthKeyModal(props) {
    const { showModal, setShowModal, onCreate } = props;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
    const [state, setState] = useState({});

    const modalTitle = `Generate New Auth Key`;

    useEffect(function(){
        return function cleanup() {
            setLoading(false);
        };
    }, [showModal]);

    const resetState = () => {
        const newState = Object.keys(state).reduce((acc, curr) => acc[curr] = "", {});
        setState(newState);
    }

    const handleInputChange = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		setError('');
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

    const handleNewKeyCreation = () => {
        if (!state.name || (state.name && !isValidAuthKeyName(state.name))) {
            setError('Please provide a valid auth key name.');
			return;
        }
        setLoading(true);
        onCreate && onCreate(state.name, state.description);
        resetState();
    }

    return (
        <Modal showModal={showModal} setShowModal={setShowModal} title={modalTitle}>
            <div className='p-3'>
                <div className='w-75 mb-3'>
                    <Input
                        type='text'
                        name='name'
                        placeholder='Enter letters or numbers or a combination of both. E.g. - key123'
                        label='Name'
                        onChange={(e) => handleInputChange(e)}
                        maxLength='64'
                        required
                    />
                </div>
                <div className='w-75 mb-3'>
                    <Input
                        type='text'
                        name='description'
                        placeholder='Enter a description (optional)'
                        label='Description'
                        onChange={(e) => handleInputChange(e)}
                        maxLength='64'
                    />
                </div>

                <div className='w-75 mb-3'>
                    {
                        error && (
							<p className='text-error mt-3 mb-0'>{error}</p>
                        )
                    }
                </div>

                <div className='modal--footer d-flex justify-content-end'>
                    <PrimaryLineButton
                        onClick={handleNewKeyCreation}
                        id='add-auth-key-btn'
                        loading={loading}
                    >
                        Generate
                    </PrimaryLineButton>
                </div>
            </div>
        </Modal>
    )
}
