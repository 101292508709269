import React, {useContext, useEffect, useState} from 'react';
import './CurrentMonthUsage.scss';
import AdvancedInsightsContext from './../../../../context/insights-context';
import UsageBreakdown from '../../UsageBreakdown/UsageBreakdown';
import Chart from "react-apexcharts";
import WidgetBox from '../../../../Widgets/WidgetBox/WidgetBox';
import { transformTranslationText } from '../../../../data-transform/data-transform';

function CurrentMonthUsage() {
    const { 
        currentMonthUsageCreditsData,
        messages
    } = useContext(AdvancedInsightsContext);

    const maxCurrentMonthUsages = 5;
    const [chartSeriesData, setChartSeriesData] = useState([]);
    const [chartOptionsData, setChartOptionsData] = useState({
        chart: {
            height: 165,
            type: 'area',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            animations: {
                enabled: true,
                easing: 'easein',
                speed: 1500
            },
            redrawOnWindowResize: false,
            redrawOnParentResize: true
        },
        stroke: {
            curve: "smooth",
            width: 0.75,
            colors: ["#d4cff7"]
        },
        colors: ["#dbd8f0"],
        fill: {
            type: "gradient",
                "pattern": {
                    "style": "verticalLines",
                    "width": 3,
                    "height": 3,
                    "strokeWidth": 1
                },
                "gradient": {
                    "shade": "light",
                    "type": "vertical",
                    "opacityFrom": 1,
                    "opacityTo": 0.2
                }
        },
        grid: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        tooltip: {
            enabled: false
        }
    });

    useEffect(() => {
        if (currentMonthUsageCreditsData !== null) {
            setChartSeriesData([{
                name: "usage",
                data: currentMonthUsageCreditsData.usageDataByInterval.map(i => i.creditUsage)
            }])
        }
    }, [currentMonthUsageCreditsData])

    if (currentMonthUsageCreditsData === null) return null;

    return (
        <div className='current-month-usage-wrapper'>
            <WidgetBox heading={messages.billing_usage.current_month_usage.heading}>
                <div className='current-usage-data row'>
                    <div className="col">
                        <div className="chart">
                            <Chart 
                                options={chartOptionsData} 
                                series={chartSeriesData} 
                                type="area" 
                                height={165}
                            />
                        </div>
                        <div className="total-count">
                            <label>{messages.billing_usage.current_month_usage.title}</label>
                            <h1>{currentMonthUsageCreditsData.totalUsageCreditsUnit.toLocaleString()}</h1>
                        </div>
                    </div>
                </div>

                {
                    currentMonthUsageCreditsData.totalUsageCreditsUnit > 0 && (
                        <div className='usage-data-breakdown row'>
                            <div className="col">
                                <label>{transformTranslationText(messages.billing_usage.current_month_usage.last_usages, [maxCurrentMonthUsages])}</label>
                                <UsageBreakdown 
                                    data={currentMonthUsageCreditsData.usageDataByInterval} 
                                    maxRecords={maxCurrentMonthUsages}
                                    textLabels={messages.billing_usage.usage_insights.widgets.usage_breakdown}
                                />
                            </div>
                        </div>
                    )
                }
            </WidgetBox>
        </div>
    )
}

export default CurrentMonthUsage