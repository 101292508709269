import React, {useState, useEffect} from 'react';
import './StatsPreview.scss';
import { Container, Row, Col } from 'react-bootstrap';
import { UilMoneyBill } from '@iconscout/react-unicons';
import moment from 'moment';
import Chart from "react-apexcharts";
import * as generalConfig from './../../../../../general-config.json';
import { UilBox } from '@iconscout/react-unicons';
import SimpleWidgetBox from '../../../../../Widgets/SimpleWidgetBox/SimpleWidgetBox';
import { transformTranslationText } from '../../../../../data-transform/data-transform';

function StatsPreview({
    totalUsage = 0,
    usageData = {},
    timePeriod,
    textLabels
}) {
    const usageKeys = Object.keys(usageData || {});
    const usageSeriesData = [];
    usageKeys.forEach(k => usageSeriesData.push({name: usageData[k].label, data: [usageData[k].credit_units]}));

    const [chartSeriesData, setChartSeriesData] = useState(usageSeriesData);
    const chartHeight = chartSeriesData.length * 20 < 100 ? 100 : chartSeriesData.length * 20;
    const defaultChartConfigOptions = {
        chart: {
            type: 'bar',
            height: chartHeight,
            stacked: true,
            stackType: '100%',
            toolbar: {
                show: false
            },
            redrawOnWindowResize: true,
            redrawOnParentResize: true
          },
          plotOptions: {
            bar: {
              horizontal: true
            },
          },
          xaxis: {
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        grid: {
            show: false
        },
          yaxis: {
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center'
          },
        colors: generalConfig.chart_colors,
        tooltip: {
            x: {
              show: false
            }
        }
    };

    const [chartOptionsData, setChartOptionsData] = useState(defaultChartConfigOptions);

    useEffect(() => {
        setChartSeriesData(usageSeriesData);
    }, [usageData])
    
    return (
        <div className='stats-preview-wrapper'>
            <Container fluid>
                <Row>
                    <Col xl={4} lg={4} md={12} sm={12} xs={12}>
                        <div className='total-usage'>
                            <div className='icon'><UilMoneyBill size={24} /></div>
                            <h3 className='count'>{totalUsage.toLocaleString()}</h3>
                            <div className='text'>
                                {transformTranslationText(textLabels.widgets.stats.secondary_text, [moment.unix(timePeriod).format("MMMM YYYY")])}
                            </div>
                        </div>
                    </Col>
                    <Col xl={8} lg={8} md={12} sm={12} xs={12} className="stacked-chart-col">
                        <div className='stacked-chart'>
                            <SimpleWidgetBox title={textLabels.widgets.credits_utilization.title} titleIcon={<UilBox />}>
                                <Chart 
                                    options={chartOptionsData} 
                                    series={chartSeriesData} 
                                    type="bar" 
                                    height={chartHeight}
                                />
                            </SimpleWidgetBox>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default StatsPreview