import { format } from 'date-fns';
import React, {useState, useRef} from 'react';
import { OverlayTrigger, Popover, Overlay } from 'react-bootstrap';
import './StatusInfoPopover.scss';

export default function StatusInfoPopover({loading, nodeId, statusData}) {
    let status = "unknown";
    let lastUpdatedAt = "";
    if (!loading && statusData && statusData.connectivity) {
        status = statusData.connectivity.connected === true ? "online" : "offline";
        if (statusData.connectivity.timestamp) {
            const updatedDate = new Date(statusData.connectivity.timestamp);
            lastUpdatedAt = format(updatedDate, "dd/MM/yyyy HH:mm:ss");
        }
    }

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleHover = event => {
        setShow(!show);
        setTarget(event.target);
    }

    if (loading) {
        return <div className={`dot ${status} loading`}></div>
    }
    else {
        return (
            <div 
                className={`dot ${status}`} 
                ref={ref}
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
            > 
                <Overlay
                    show={show}
                    target={target}
                    container={ref.current}
                    containerPadding={20}
                >
                    <Popover id="popover-contained">
                    <Popover.Title as="div">Status: <span>{status}</span></Popover.Title>
                    {
                        lastUpdatedAt && (
                            <Popover.Content>
                                Last updated at { lastUpdatedAt }
                            </Popover.Content>
                        )
                    }
                    </Popover>
                </Overlay>
            </div>
        )
    }
}
