import React, { useEffect, useReducer } from 'react';
import LinkIconButton from '../Widgets/LinkIconButton/LinkIconButton';
import { UilAngleLeftB, UilAngleRightB } from '@iconscout/react-unicons';

function usePagination(nextPagetoken) {
    const initialState = {
        pagetoken: "",
        nextPagetoken: nextPagetoken || "",
        previousPagetokens: []
    }

    const [state, updateState] = useReducer((state, newState) => ({
        ...state, ...newState
    }), initialState);

    useEffect(function(){
        const _token = nextPagetoken || "";
        updateState({
            nextPagetoken: _token
        });
    }, [nextPagetoken])

    const onPrevious = () => {
        const newTokensList = [...state.previousPagetokens];
        let newPagetoken = newTokensList[newTokensList.length - 2] || "";
        newTokensList.pop();
        updateState({
            pagetoken: newPagetoken,
            previousPagetokens: newTokensList
        });
    }

    const onNext = () => {
        updateState({
            pagetoken: state.nextPagetoken,
            previousPagetokens: [...state.previousPagetokens, state.nextPagetoken]
        });
    }

    const PaginationControls = () => {
        return (state.previousPagetokens.length || nextPagetoken) ? (
            <div className='pagination-wrapper d-flex justify-content-end'>
                <LinkIconButton 
                    iconComponent={<UilAngleLeftB />}
                    text={"Previous"}
                    clickHandler={onPrevious}
                    disabled={!state.previousPagetokens.length}
                />
                <LinkIconButton 
                    iconComponent={<UilAngleRightB />}
                    text={"Next"}
                    prependIcon={false}
                    clickHandler={onNext}
                    disabled={!nextPagetoken}
                />
            </div>
        ) : null
    }
    
    return {
        pagetoken: state.pagetoken,
        Pagination: PaginationControls
    }
}

export default usePagination