import { UilSave, UilCreateDashboard, UilTrash, UilFavorite, UilPen } from '@iconscout/react-unicons';
const iconProps = { size: 15 };

export const dashboardSettingsMenu  = {
  label : "Dashboard Settings",
  actions : [
    {
      id:"rename",
      label: "Rename Dashboard",
      icon : <UilPen {...iconProps} />,
      enableForType : ['default', 'custom']
    },
    {
      id: "save",
      label: "Save Dashboard",
      icon: <UilSave {...iconProps} />,
      enableForType : ['default', 'custom']
    },
    {
      id: "save-as-new",
      label: "Save As New Dashboard",
      icon: <UilCreateDashboard {...iconProps} />,
      enableForType : ['default', 'custom', 'system']
    },
    {
      id: "set-default",
      label: "Set As Default Dashboard",
      icon: <UilFavorite {...iconProps} />,
      enableForType : ['custom']
      
    },
    {
      id: "delete",
      label: "Delete Dashboard",
      icon: <UilTrash {...iconProps} color="red" />,
      enableForType : ['custom']
    },
]}