import awsconfig from '../aws-exports';

const cognito_URL = awsconfig.cognito.cognito_URL;
const redirect_URI = awsconfig.cognito.redirect_URI;
const client_ID = awsconfig.cognito.client_ID;
const github = 'Github';
const google = 'Google';
const apple = 'SignInWithApple';

export const GithubLogin = `${cognito_URL}/oauth2/authorize?identity_provider=${github}&redirect_uri=${redirect_URI}&response_type=TOKEN&client_id=${client_ID}&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile`;

export const GoogleLogin = `${cognito_URL}/oauth2/authorize?identity_provider=${google}&redirect_uri=${redirect_URI}&response_type=TOKEN&client_id=${client_ID}&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile`;

export const AppleLogin = `${cognito_URL}/oauth2/authorize?identity_provider=${apple}&redirect_uri=${redirect_URI}&response_type=TOKEN&client_id=${client_ID}&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile`;
