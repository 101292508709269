import React, {useState} from 'react';
import './TenantViewInfoBar.scss';
import Cookies from 'js-cookie';
import { ErrorLineButton } from '../../../../components/Button/Button';
import ConfirmEndAccess from './ConfirmEndAccess/ConfirmEndAccess';

function TenantViewInfoBar({show, customClassName = ""}) {
    const tenantAccountId = Cookies.get("ta_id");
    const tenantAccountEmailId = Cookies.get("ta_eid");
    const tenantToken = Cookies.get("TATK");
    const [showConfirmEndAccessModal, setShowConfirmEndAccessModal] = useState(false);

    if (!show || !tenantAccountId || !tenantToken) return null;

    const confirmEndAccess = () => {
        setShowConfirmEndAccessModal(true);
    }
    
    const handleEndAccess = () => {
        setShowConfirmEndAccessModal(false);
        Cookies.remove('TATK');
	    Cookies.remove('ta_id');
	    Cookies.remove('ta_eid');
        window.location.reload();
    }

    return (
        <div className={`${customClassName}`}>
            <div className='info-content'>
                <div className='message'>
                    You are viewing account of {tenantAccountEmailId}.
                </div>
                <div className='actions'>
                    <ErrorLineButton
                        onClick={confirmEndAccess}
                    >
                        End Session
                    </ErrorLineButton>
                </div>
            </div>
            <ConfirmEndAccess 
                showModal={showConfirmEndAccessModal} 
                setShowModal={setShowConfirmEndAccessModal} 
                onConfirm={handleEndAccess}
            />
        </div>
    )
}

export default TenantViewInfoBar