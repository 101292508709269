import React, { useState, useEffect, useMemo, useContext } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import {
	PrimaryLineButton,
	RefreshButton,
	PrimarySmallButton,
	SuccessButton,
} from '../../../components/Button/Button';
import { Input } from '../../../components/Input/Input';
import { firmwareImageContext } from '../../../context/FirmwareImageProvider';
import AddImageModal from '../../../components/CustomModal/AddImageModal';
import StartOTA from '../../../components/CustomModal/StartOTA';
import Table from '../../../components/Table/Table';

const OtaImage = (props) => {
	const [showStartOTA, setShowStartOTA] = useState(false);
	const [showAddImageModal, setShowAddImageModal] = useState(false);

	const {
		imageState,
		getImages,
		searchedImageReset,
		next,
		prev,
		changePageSize,
	} = useContext(firmwareImageContext);

	const {
		loading,
		error,
		allItems,
		searchedItem,
		totalPages,
		pageSize,
		nextKey,
		pageNo,
	} = imageState;

	const [images, setImages] = useState([]);
	const [pushImage, setpushImage] = useState('');

	useEffect(() => {
		return () => {
			searchedImageReset();
		};
	}, []);

	useEffect(() => {
		if (allItems.length === 0) {
			handleGetImages(nextKey, pageSize);
			return;
		}
	}, [nextKey, pageSize]);

	const handleGetImages = async (nextKey, pageSize) => {
		await getImages(nextKey, pageSize);
	};

	useEffect(() => {
		searchedItem ? setImages(searchedItem) : setImages(allItems);
	}, [JSON.stringify(imageState)]);

	useEffect(() => {
		if (error == 401) {
			props.sessionExpire();
		}
	}, [error]);

	const handleImageSearch = (e) => {
		e.preventDefault();
		let image = e.target.elements.search.value;
		let searchParam = e.target.elements.searchBy.value;

		if (image.trim() !== '') {
			getImages(image, searchParam);
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Image Name',
				// eslint-disable-next-line react/display-name
				accessor: (row) => (
					<Link to={`/home/image-details/${row.ota_image_id}`}>
						{row.image_name}
					</Link>
				),
			},
			{
				Header: 'Type',
				accessor: 'type',
			},
			{
				Header: 'Version',
				accessor: 'fw_version',
			},
			{
				Header: 'Model',
				accessor: 'model',
			},
			{
				Header: 'Uploaded At',
				accessor: (row) =>
					moment
						.unix(row.upload_timestamp)
						.format('DD MMM YY, HH:mm'),
			},
			{
				Header: 'Actions',
				// eslint-disable-next-line react/display-name
				accessor: (row) => (
					<PrimarySmallButton
						onClick={() => {
							setpushImage(row);
							setShowStartOTA(true);
						}}
						title='Start OTA'
						key='startOTAWithImage'
					>
						Start OTA
					</PrimarySmallButton>
				),
			},
		],
		[]
	);

	return (
		<>
			<div className='app--navbar'>
				<div className='d-flex justify-content-between align-items-center'>
					<div>
						<p className='small--heading'>Overview</p>
						<div className='d-flex'>
							<p className='main--heading'>Firmware Images</p>
							<RefreshButton
								loading={loading}
								onClick={() => handleGetImages('', pageSize)}
							/>
						</div>
					</div>
					<div className='d-flex align-items-center image-search'>
						<form
							onSubmit={handleImageSearch}
							className='d-flex align-items-center'
						>
							<Input
								type='text'
								name='search'
								placeholder='Search Image'
								isInline={true}
								onChange={(e) => {
									if (e.target.value === '') {
										setImages(allItems);
										searchedImageReset();
									}
								}}
								maxLength={64}
								id='search-img'
							/>
							<Form.Control
								as='select'
								custom
								name='searchBy'
								defaultValue={'search-by'}
								id='search-params'
							>
								<option value='name'>Name</option>
								<option value='type'>Type</option>
								<option value='model'>Model</option>
							</Form.Control>
							<PrimaryLineButton
								id='search-img-btn'
								title='Search Image'
								className='mx-2'
								type='submit'
							>
								Search
							</PrimaryLineButton>
						</form>
						<SuccessButton
							onClick={() => setShowAddImageModal(true)}
							title='Add Image'
							id='add-img-btn'
						>
							Add Image
						</SuccessButton>
					</div>
				</div>
			</div>
			<hr className='mx-3 my-0' />

			<div className='home-card card--container d-flex flex-column justify-content-between'>
				{error ? (
					<div className='error-container'>
						<p className='color-primary' id='error-msg'>
							{error}
						</p>
					</div>
				) : images ? (
					<div className='row'>
						<div className='col-lg-12 h-100'>
							<Table
								data={images}
								columns={columns}
								loading={loading}
								loaders={pageSize}
							></Table>
						</div>
					</div>
				) : (
					<div className='error-container'>
						<p className='mt-3 color-primary'>
							Firmware image not found.
						</p>
					</div>
				)}

				{!error &&
					!loading &&
					images &&
					totalPages > 0 &&
					!searchedItem && (
						<div className='pagination-button-container'>
							<PrimaryLineButton
								onClick={() => prev()}
								disabled={nextKey === '' ? true : false}
								id='prev-btn'
							>
								Prev
							</PrimaryLineButton>
							<p>
								Page {pageNo} of {totalPages}
							</p>
							<PrimaryLineButton
								onClick={() => next()}
								disabled={pageNo >= totalPages ? true : false}
								id='next-btn'
							>
								Next
							</PrimaryLineButton>
							<Form.Control
								as='select'
								custom
								name='searchBy'
								onChange={(e) => {
									changePageSize(e.target.value);
								}}
								defaultValue={pageSize}
								id='page-size-option'
							>
								<option value='search-by' disabled>
									No. of Images
								</option>
								<option value={5}>5</option>
								<option value={10}>10</option>
								<option value={25}>25</option>
							</Form.Control>
						</div>
					)}
			</div>

			{showAddImageModal && (
				<AddImageModal
					showModal={showAddImageModal}
					setShowModal={setShowAddImageModal}
					refreshParent={() => handleGetImages('', pageSize)}
					sessionExpire={props.sessionExpire}
				/>
			)}

			{showStartOTA && (
				<StartOTA
					showModal={showStartOTA}
					setShowModal={setShowStartOTA}
					otaImage={pushImage}
					sessionExpire={props.sessionExpire}
				/>
			)}
		</>
	);
};

export default OtaImage;
