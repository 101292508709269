import React, { useState, useEffect} from 'react';
import './WidgetWrapper.scss';
import { UilAngleDown, UilAngleUp, UilExpandArrowsAlt } from '@iconscout/react-unicons';

function WidgetWrapper(props) {
    const title = props.title;
    const titleClass = title ? "title" : "no-title";    
    const loadingClass = props.loading === true ? "loading" : "complete";
    const [show, setShow] = useState(false);

    useEffect(() => {
      setShow(!props.loading)
    }, [props.loading])
    

    return (
        <div className={`widget-wrapper ${titleClass} ${loadingClass}`} style={props.wrapperStyles || null}>
            {
                title && loadingClass === "complete" ? (
                    <div className='heading'>
                        <div className='text'>{title}</div>
                        <div className='expand-icon'>
                            <button onClick={() => setShow(!show)}>
                                { show ? <UilAngleUp size={32} /> : <UilAngleDown size={32} /> }
                            </button>
                        </div>
                    </div>
                ) : null
            }

            <div 
                className={`component-container ${show ? 'show': 'hide'}`}
                style={props.containerStyles || null}
            >
                <div className='loader'>
                    { props.backgroundDataRefreshStatus ? <div className="bar"></div> : null }
                </div>
                <div className='main-content'>
                    { props.children }
                </div>
            </div>
            
        </div>
  )
}

export default WidgetWrapper