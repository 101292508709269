import React, { useState, useEffect, useContext, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import Cookies from 'js-cookie';
import {
	UilProcessor,
	UilUser,
	UilFileGraph,
	UilArchive,
	UilLock,
	UilKeySkeletonAlt,
	UilLinkAlt,
	UilSignout,
	UilAngleDoubleLeft,
	UilAngleDoubleRight,
	UilFavorite,
	UilAngleDown, 
	UilAngleUp,
	UilLayers,
	UilBill,
	UilUserArrows
} from '@iconscout/react-unicons';
import './Sidebar.scss';
import {
	USER_ROLES_ALL
} from '../../utils/constants';
import LogoutModal from '../CustomModal/LogoutModal';
import InsightsLogo from './../../assets/insightslogo.svg';
import CheckVersionModal from '../CustomModal/CheckVersionModal';
import InsightsLogoSmall from './../../assets/insightslogo-small.png';
import CustomTooltip from './../CustomTooltip/CustomTooltip';
import AdvancedInsightsContext from '../../pages/AdvancedInsights/context/insights-context';
import { useHistory } from 'react-router';

const Sidebar = (props) => {
	const history = useHistory();
	const { userPermission, sessionExpire, enableESPLogin = false } = props;
	const [dashboardListMenu, setdashboardListMenu] = useState([]);
	const [childMenuVisbility, setChildMenuVisbility] = useState({});

	const useForceUpdate = () => {
		const [, updateState] = useState();
		return useCallback(() => updateState({}), []);
	}

	const forceUpdate = useForceUpdate();

	const { 
        dashboardList,
		selectedDashboard
    } = useContext(AdvancedInsightsContext);

	useEffect(() => {
		if(dashboardList?.length <= 0) return;
		if(!selectedDashboard) return;
	    const dashboard = dashboardList?.filter(d => d.dashboard_id === selectedDashboard)[0];
		if(dashboard){
			const url = `/home/insights/${dashboard.dashboard_name}?dashboard-id=${dashboard.dashboard_id}`
			history.push(url)
		}
		
	}, [selectedDashboard])
	
	useEffect(() => {
	  if(dashboardList)
	  {
		const _dashboardListMenu = [
			{
				name : "System Default",
				label: `/insights`,
				id: "default-dashboard",
				icon: false,
			}
		].concat( dashboardList.map(d => {
			const dashboard = {
				name : d.dashboard_name,
				label: `/insights/${d.dashboard_name}?dashboard-id=${d.dashboard_id}`,
				id:d.dashboard_id,
				icon: d.is_default && <UilFavorite className="icon-primary"  size="15" />
			}
			return dashboard
		}))

		setdashboardListMenu(_dashboardListMenu)
		setChildMenuVisbility(prev => ({
			...prev,
			Dashboards:false
		}))
	  }
	}, [dashboardList, dashboardList?.length])
	
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const [showCheckVersion, setShowCheckVersion] = useState(false);
	const sidebarTabs = [
		{
			name: 'Dashboards',
			label: '/insights',
			icon: <UilLayers />,
			permissions: [USER_ROLES_ALL],
			isNestedMenu: true,
			childMenu: dashboardListMenu,
		},
		{
			name: 'Nodes',
			label: '/nodes',
			icon: <UilProcessor />,
			isNestedMenu:false,
			permissions: [USER_ROLES_ALL]
		},
		{
			name: 'Firmware Packages',
			label: '/firmware-packages',
			icon: <UilArchive />,
			isNestedMenu:false,
			permissions: [USER_ROLES_ALL],
		},
	];

	const handleChildMenuVisibility = (menuName) =>{
		if(props.collapsed) props.onCollapseToggle()
		setChildMenuVisbility(prev => {
			prev[menuName] = !prev[menuName];
			return prev;
		})
		forceUpdate()
	}
	return (
		<>
			<div className={`sidebar d-flex flex-column justify-content-between ${props.collapsed === true ? 'collapsed' : ''}`}>
				<ul className='list-group'>
					<NavLink
						to={`/home/insights/`}
						className="brand-logo"
					>
						<img
							alt='espressif-icon'
							src={props.collapsed ? InsightsLogoSmall : InsightsLogo}
							height={42}
							className='logo'
							id='logo'
						/>
					</NavLink>
					{sidebarTabs.map((tab) => {
						if (
							userPermission.some((role) =>
								tab.permissions.includes(role)
							)
						) 
						{
							return (

								tab?.childMenu ? 
								<ul className='list-group'>
									<div className='menu-item'>
									<li className='list-group-item'>
										<Dropdown as={ButtonGroup} className='w-100 d-block'>
											<Dropdown.Toggle
												id='menu-dropdown'
												key={`menu-dropdown-${tab.name}`}
												variant='light'
												className='w-100 d-block m-0'
											>
												<CustomTooltip 
													id={props.collapsed ? `sidebar-${tab.name}` : null} 
													title={tab.name} 
													key={`sidebar-${tab.name}`}
												>
													<div className='d-flex align-items-center' onClick={()=>handleChildMenuVisibility(tab.name)}>
														<div className='icon'>
															{tab.icon}
														</div>
														<p className='mb-0'>{tab.name}</p>
														{ !props.collapsed  && 
														<div className='expand-icon ml-auto'>
															<button >
																{ childMenuVisbility?.[`${tab.name}`] ? <UilAngleUp size={25} /> : <UilAngleDown size={25} /> }
															</button>
														</div>
														}	
													</div>
												</CustomTooltip>
											</Dropdown.Toggle>
											
										</Dropdown>	
									</li>
									</div>
									{ !props.collapsed  && childMenuVisbility?.[tab.name] && 
										<div className='dropdown-child-menu'>
										{
											tab?.childMenu.map(t =>(
												<NavLink
													exact
													to={`/home${t.label}`}
													activeClassName='active'
													className="menu-item"
													id={t.name}
													key={`sidebar-${t.name}`}
												>
													<li className='list-group-item'>
														<p className='mb-0'>{t.name}</p>
														{t.icon ? <div className='icon'>{t.icon}</div> : null}
													</li>
												</NavLink>
											))
										}
										</div>
									}
								</ul>
								: 

								(<CustomTooltip 
									id={props.collapsed ? `sidebar-${tab.name}` : null} 
									title={tab.name} 
									key={`sidebar-${tab.name}`}
								>
									<NavLink
										exact
										to={`/home${tab.label}`}
										activeClassName='active'
										className="menu-item"
										id={tab.name}
										key={`sidebar-${tab.name}`}
									>
										<li className='list-group-item'>
											<span className='icon'>
												{tab.icon}
											</span>
											<p className='mb-0'>{tab.name}</p>
										</li>
									</NavLink>
								</CustomTooltip>)
							)
						}
					})}
				</ul>

				<div>
					<ul className='list-group'>
						<div className='menu-item'>
							<li
								className={
									window.location.pathname
										.split('/')
										.includes('change-password')
										? 'list-group-item active'
										: 'list-group-item'
								}
							>
								<Dropdown as={ButtonGroup} className='w-100'>
									<Dropdown.Toggle
										id='dropdown-custom-1'
										variant='light'
									>
										<CustomTooltip 
											id={props.collapsed ? `sidebar-account-settings` : null} 
											title="Account Settings" 
										>
											<div className='d-flex align-items-center'>
												<div className='icon'>
													<UilUser />
												</div>
												<p className='mb-0'>Account Settings</p>
											</div>
										</CustomTooltip>
									</Dropdown.Toggle>

									<Dropdown.Menu className='super-colors' align="right">
										<Dropdown.Item
											eventKey='0'
											className='cursor-default user-info'
											as="div"
										>
											<div>
												<span className='font-weight-500'>
													Signed-In as
												</span>
												<div className='email'>
													{Cookies.get('user_name')}
												</div>
											</div>
										</Dropdown.Item>
										{localStorage.getItem('email') ===
											'true' && (
											<Dropdown.Item eventKey='1' as="div">
												<NavLink
													to={`/home/change-password/`}
													id={'user-settings'}
													className="menu-item"
												>
													<UilLock size={16} />Change Password
												</NavLink>
											</Dropdown.Item>
										)}

										<Dropdown.Item eventKey='3' as="div">
											<NavLink
												to={`/home/manage-auth-keys/`}
												id={'manage-auth-keys'}
												className="menu-item"
											>
												<UilKeySkeletonAlt size={16} />Manage Auth Keys
											</NavLink>
										</Dropdown.Item>

										<Dropdown.Item eventKey='4' as="div">
											<NavLink
												to={`/home/manage-connector-auth-keys/`}
												id={'manage-connector-auth-keys'}
												className="menu-item"
											>
												<UilLinkAlt size={16} />Manage Connector Auth Keys
											</NavLink>
										</Dropdown.Item>

										<Dropdown.Item eventKey='5' as="div">
											<NavLink
												to={`/home/billing-usage/`}
												id={'billing-usage'}
												className="menu-item"
											>
												<UilBill size={16} />Billing And Usage
											</NavLink>
										</Dropdown.Item>
										
										<Dropdown.Item eventKey='6' as="div">
											<NavLink
												to={`/home/manage-multi-admins/`}
												id={'manage-multi-admins'}
												className="menu-item"
											>
												<UilUserArrows size={16} />Manage Multi Admins
											</NavLink>
										</Dropdown.Item>

										<Dropdown.Item
											eventKey='2'
											onClick={() => setShowLogoutModal(true)}
											as="button"
										>
											<div className="menu-item">
												<UilSignout size={16} />Logout
											</div>
										</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</li>
						</div>
						<div className='menu-item'>
							<li className='list-group-item collapse-togler'>
								<CustomTooltip 
									id={props.collapsed ? `sidebar-collapse-toggler` : null} 
									title={props.collapsed ? "Expand Sidebar" : "Collapse Sidebar"} 
								>
									<button onClick={props.onCollapseToggle}>
										{
											props.collapsed ? <UilAngleDoubleRight /> : <UilAngleDoubleLeft />
										}
										<p>Collapse Sidebar</p>
									</button>
								</CustomTooltip>
							</li>
						</div>
					</ul>
				</div>
			</div>

			<LogoutModal
				showModal={showLogoutModal}
				setShowModal={setShowLogoutModal}
				enableESPLogin={enableESPLogin}
			/>

			<CheckVersionModal
				showModal={showCheckVersion}
				setShowModal={setShowCheckVersion}
				sessionExpire={sessionExpire}
			/>
		</>
	);
};

export default Sidebar;
