import { useState } from "react";
import { UilExclamationTriangle } from '@iconscout/react-unicons';
import { PieChart, Pie, Tooltip, Cell, Legend } from "recharts";
import { isArray } from "lodash";
import PieDetailsModal from "./PieDetailsModal/PieDetailsModal";
import { LinkButton } from "../../components/Button/Button";

const PieCharts = (props) => {
	const [show, setShow] = useState(false);
	const COLORS = props.colors || ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
	let data = props.topNodes ? [] : props.data;
	const invalidChartData = props.data === null || (isArray(props.data) && props.data.length === 0);

	function handleClick(e) {
		if (props.topNodes && props.data) {
			props.onPieChartClick(e.name, props.text)
		}
	}
	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);
	if (props.topNodes) {
		if (!invalidChartData) {
			let totalCount = 0;
			props.data.forEach(item => {
				totalCount = totalCount + item.count;
			})
			let min = totalCount / 10;
			props.data.forEach(item => {
				if (item.count >= min) {
					data.push(item)
				}
			})
			data = data.map(elm => ({ name: elm.category, count: elm.count }));
		}
		else {
			data = [{ name: "no data", count: 1 }];
		}
	}

	const handleMicroCategoryClick = e => {
		if (e.target.id) {
			handleClick({name: e.target.id});
			handleClose();
		}
	}
 
	return (

		<div className=" px-1 py-2 bg-white d-inline-flex  flex-column align-items-center justify-content-center">
			<h6 className='card--title mb-1'>{props.text}</h6>
			<div>
				{
					!invalidChartData && (
						<PieDetailsModal 
							show = {show}
							title = {props.text}
							data = {props.data}
							categoryClickHandler = {handleMicroCategoryClick}
							closeHandler = {handleClose}
						/>
					)
				}
			</div>
			<div className={invalidChartData ? 'position-relative' : ''}>
				<div>
					<PieChart width={props.legend ? 230 : 200} height={props.legend ? 230 : 200} className={invalidChartData ? 'grayout' : ''}>
						<Pie
							dataKey="count"
							data={data}
							innerRadius={50}
							outerRadius={80}
							fill="#1979e0"
							onClick={handleClick}
							className={!invalidChartData ? "cursor-pointer" : "cursor-not-allowed"}
						>
							{
								props.data ? props.data.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
								)) : null
							}
						</Pie>
						{!invalidChartData && <Tooltip />}
						{props.legend && <Legend className="mt-2" payload={
							props.data.map(
								(item, index) => ({
									id: item.name,
									color: COLORS[index % COLORS.length],
									type: "square",
									value: `${item.name} (${item.count})`,
								})
							)
						} />}

					</PieChart>
				</div>
				{invalidChartData ? <div className='data-info d-flex flex-column justify-content-center align-items-center my-5 position-absolute top-0 left-0 right-0'>
					<UilExclamationTriangle size='55' color='#5330b980' className='mt-1' />
					<h6 className='font-weight-bold text-muted mt-2'>No Data Available</h6>
				</div> : null}
			</div>
			{props.topNodes && !invalidChartData ? <LinkButton type="button" onClick={handleShow} >View All</LinkButton> : null}
		</div>
	);
};

export default PieCharts;
