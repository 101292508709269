import React, {useState, useEffect} from 'react';
import './UsageTrend.scss';
import { Container, Row, Col } from 'react-bootstrap';
import {getGraphOptions, getUsageTimelineChartData} from './../../../../../data-transform/data-transform';
import * as generalConfig from './../../../../../general-config.json';
import TimelineChart from '../../../../../NodeDetails/NodeReport/PrimaryWidgets/NodeTimeline/TimelineChart/TimelineChart';
import UsageBreakdown from '../../../UsageBreakdown/UsageBreakdown';
import { UilArrowGrowth, UilListUl } from '@iconscout/react-unicons';
import SimpleWidgetBox from '../../../../../Widgets/SimpleWidgetBox/SimpleWidgetBox';

function UsageTrend({data, tzOffset, textLabels}) {
    const timelineChartType = "area";
    const timelineChartId = "usage-timeline";

    const [graphSeriesData, setGraphSeriesData] = useState(getUsageTimelineChartData(data, tzOffset));
    const [graphOptionsData, setGraphOptionsData] = useState(getGraphOptions(timelineChartType, { 
        colors: generalConfig.chart_colors,
        labels: [], 
        id: timelineChartId,
        integerOnlyYAxis: false,
        showLegends: true
    }));

    useEffect(() => {
        setGraphSeriesData(getUsageTimelineChartData(data, tzOffset));
        setGraphOptionsData(getGraphOptions(timelineChartType, { 
            colors: generalConfig.chart_colors.map(c => ({color: c})),
            labels: [], 
            id: timelineChartId,
            integerOnlyYAxis: false,
            showLegends: true,
            showMarkers: data?.length === 1
        }));
    }, [data, tzOffset]);

    if (!data) return null;

    return (
        <div className='usage-trend-wrapper'>
            <Container fluid>
                <Row>
                    <Col xl={7} lg={7} md={12} sm={12} xs={12} className="usage-trends-col">
                        <div className='timeline widget-data-wrapper'>
                            <SimpleWidgetBox title={textLabels.widgets.usage_trends.title} titleIcon={<UilArrowGrowth />}>
                                <TimelineChart 
                                    series={graphSeriesData} 
                                    options={graphOptionsData} 
                                    hideNASeries={false}
                                />
                            </SimpleWidgetBox>
                        </div>
                    </Col>
                    <Col xl={5} lg={5} md={12} sm={12} xs={12} className="stacked-chart-col">
                        <div className='list widget-data-wrapper'>
                            <SimpleWidgetBox title={textLabels.widgets.usage_list.title} titleIcon={<UilListUl />}>
                                <UsageBreakdown 
                                    data={data} 
                                    maxRecords={10}
                                    paginateData={true}
                                    textLabels={textLabels.widgets.usage_breakdown}
                                    variant="simple"
                                />
                            </SimpleWidgetBox>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default UsageTrend