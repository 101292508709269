import { differenceInDays, differenceInHours, differenceInMonths, differenceInWeeks, format, fromUnixTime } from "date-fns";
import { escape, cloneDeep, has, includes, lowerCase, uniqBy, isArray, union, startsWith, intersection, flatten, filter, orderBy, sortBy } from "lodash";
import * as generalConfig from '../general-config.json';
import * as messages from '../messages/en.json';
import moment from 'moment';
import { addPropertiesOfObjects } from "../../Insights/insightsUtilityFunctions";
import { utcTimeStampToLocalTimeStamp } from "../../../utils/commonFunctions";
import * as CBOR from "cbor-js";

export const getTransformedFiltersList = list => {
    const topFilters = generalConfig.top_filters;
    const allowFreeTextInFilterTypes = generalConfig.allow_free_text_in_filter_types || [];

    const topFiltersOptionData = [];
    const restAllFiltersOptionData = [];
    const filtersListOptionData = [];

    list.forEach(({filter, FILTER_KEY, attributes,  aggregation}) => {
        const filterData = {
            value: FILTER_KEY, 
            label: FILTER_KEY,
            allowFreeText: intersection(filter, allowFreeTextInFilterTypes).length > 0,
            attributes: attributes,
            isNestedTermFilter : includes(aggregation,"nestedterm"),
            operator: filter
        }

        if (includes(topFilters, FILTER_KEY)) {
            topFiltersOptionData.push(filterData);
        }
        else {
            restAllFiltersOptionData.push(filterData);
        }
    });

    
    filtersListOptionData.push({
        label: messages.filter_selection.group_label_common,
        options: topFiltersOptionData
    });

    filtersListOptionData.push({
        label: messages.filter_selection.group_label_other,
        options: restAllFiltersOptionData
    });

    return filtersListOptionData;
}

export const getSerializedFiltersList = (list = [], secondaryList = [], multiValues = []) => {
    const filtersList = [];

    const consolidatedFiltersList = [...list, ...secondaryList];
    let uniqueFilters = uniqBy(consolidatedFiltersList, "filterType");
    if (multiValues && multiValues.length) {
        multiValues.forEach(({filterType, filterValue, operator}) => {
            const index = uniqueFilters.findIndex(f => f.filterType === filterType);
            if (index === -1) {
                uniqueFilters.push({filterType, filterValue, operator: operator})
            }
            else {
                const currentValues = uniqueFilters[index].filterValue;
                const newFilterValues = isArray(currentValues) ? [...currentValues] : [currentValues];
                const updatedFilterValues = union(newFilterValues, isArray(filterValue) ? [...filterValue] : [filterValue]);

                uniqueFilters = [
                    ...uniqueFilters.slice(0, index),
                    { filterType, filterValue: updatedFilterValues, operator: operator},
                    ...uniqueFilters.slice(index + 1)
                ];
            }
        });
    }

    uniqueFilters.forEach(({filterType, filterValue, operator, customText}) => {
        filtersList.push({
            f: filterType,
            o: operator || (customText ? "text" : "keyword"),
            v: isArray(filterValue) ? filterValue : [filterValue]
        })
    });

    return JSON.stringify(filtersList);
}

export const getDependentFiltersList = (type, value, customText = false) => {
    const list = [];
    list.push({
        filterType: type,
        filterValue: value,
        customText
    });

    const parentType = lowerCase(type.split(".")[0]);
    const matchedEventType = generalConfig.event_types.filter(et => et.id === parentType);
    if (matchedEventType.length) {
        list.push({
            filterType: generalConfig.event_type_filter_key,
            filterValue: matchedEventType[0].id
        });    
    }
    return list;
}

export const removeInapplicableFiltersFromList = (filtersList = [], inapplicableFiltersList = []) => {
    if (!filtersList?.length) return [];
    if (!inapplicableFiltersList?.length) return filtersList;

    return filtersList.filter(({FILTER_KEY}) => {
      for (let i = 0; i < inapplicableFiltersList.length; i++) {
        if (startsWith(FILTER_KEY, inapplicableFiltersList[i])) {
          return false;
        }
      }
      return true;
    });
}

export const getGraphDataFromDistribution = ({type, interval, dist}) => {
    if (!dist?.length) return null;

    const eventCountSeries = [];
    let totalEventCount = 0;

    const labels = [];
    const categories = [];

    const eventTypeLabel = messages?.event_types[type]?.label_plural || type;

    const formatForIntervals = {
        "3600": "dd LLL (HH:mm)",
        "86400": "dd LLL",
        "604800": "dd-LLL-yy",
        "2592000": "LLL-yy"
    }

    dist.forEach(({count, distinct_node_count, ts}) => {
        totalEventCount += count;
        eventCountSeries.push([utcTimeStampToLocalTimeStamp(ts), count]);
        
        const unixTimestamp = utcTimeStampToLocalTimeStamp(ts);
        const category = format(fromUnixTime(unixTimestamp), "do MMM, yyyy (HH:mm:ss)");
        categories.push(category);
    });

    const componentLabels = messages.widgets.events_timeline;

    return {
        chartData: [
            {
                id: "eventCount",
                series: [{
                    name: `${eventTypeLabel} ${componentLabels['tooltip_event_count']}`,
                    data: eventCountSeries
                }],
                options: getGraphOptions("area", {
                    id: `eventCount`,
                    title: `${eventTypeLabel} ${componentLabels['tooltip_event_count']}: ${totalEventCount.toLocaleString()}`,
                    categories: categories,
                    labels: labels
                })
            }
        ]
    }
}

export const getPieChartDataFromDistribution = ({type, dist, clickHandler, messages}) => {
    if (!dist?.length) return null;

    const labels = [];
    const values = [];

    dist.forEach(({category, count}) => {
        labels.push(category);
        values.push(count);
    })

    return {
        series: values,
        options: getGraphOptions("pie", {
            labels: labels,
            values: values
        }, clickHandler)
    }
}

export const getTimelineChartDataFromDistribution = ({dist, subDist, messages, filterTypeID}) => {
    if (!dist?.length) return null;
    
    const isEventTypeData = filterTypeID === generalConfig.event_type_filter_key;
    const eventTypeConfig = generalConfig.event_types;
    const normalisedData = [];
    const colors = [];

    dist.forEach((bucket, index) => {
        if (has(bucket, "count") && bucket.count > 0) {
            const timestamp = utcTimeStampToLocalTimeStamp(bucket.key);

            bucket.distribution.subdist.buckets.forEach(bucketDist => {
                const key = bucketDist.key;
                const count = bucketDist.count;
                const name = isEventTypeData ? messages?.event_types[key]?.label_plural : escape(key);

                const normalisedDataIndex = normalisedData.findIndex(d => d.name === name);
                if (normalisedDataIndex === -1) {
                    normalisedData.push({name, data: [[timestamp, count]]});

                    if (isEventTypeData) {
                        const eventTypeColorIndex = colors.findIndex(c => c.key === key);
                        if (eventTypeColorIndex === -1) {
                            const color = eventTypeConfig.filter(e => e.id === key)[0]?.color;
                            colors.push({key: key, color: color});
                        }
                    }
                }
                else {
                    normalisedData[normalisedDataIndex].data.push([timestamp, count]);
                }
            })
        }
    });

    return { normalisedData, colors };
    
    /*return {
        series: normalisedData,//handleMissingValues(normalisedData, timestamps),
        options: getGraphOptions("stackedColumn", {
            labels, colors, chartType, id,
            stacked: (chartType === "bar" || chartType === "area")
        })
    }*/
}

function handleMissingValues (data, timestamps) {
    const modifiedData = cloneDeep(data);
    timestamps.forEach(timestamp => {
        modifiedData.forEach(type => {
            if (type.data.length < timestamps.length) {
                if (!flatten(type.data).includes(timestamp)) {
                    type.data.push([timestamp, null])
                }
            }
        });
    });
    return modifiedData;
}


export function getGraphOptions(graphType, data, clickHandler) {
    const defaultChartConfigOptions = cloneDeep(generalConfig.chart_configs[graphType]);
    
    let titleConfig = {};
    let chartConfig = {};
    let xAxisConfig = {};
    
    let yAxisConfig = {};
    let yAxisLabelConfig = {};

    let markersConfig = {};
    let strokeConfig = {};
    let tooltipConfig = {};
    let legendConfig = {};

    let chartColors = [];
    if (data?.colors?.length) {
        chartColors = data.colors.map(e => e.hasOwnProperty("color") ? e.color : e);
    }
    else {
        chartColors = has(defaultChartConfigOptions, "colors") ? defaultChartConfigOptions.colors : generalConfig.chart_colors;
    }
    
    if (has(defaultChartConfigOptions, "title")) {
        titleConfig = cloneDeep(defaultChartConfigOptions.title);
    }
    if (data.title) {
        titleConfig.text = data.title;
    }

    if (has(defaultChartConfigOptions, "chart")) {
        chartConfig = cloneDeep(defaultChartConfigOptions.chart);
    }
    
    if (has(defaultChartConfigOptions, "stroke")) {
        strokeConfig = cloneDeep(defaultChartConfigOptions.stroke);
    }
    
    if (has(defaultChartConfigOptions, "xaxis")) {
        xAxisConfig = cloneDeep(defaultChartConfigOptions.xaxis);
    }

    if (has(defaultChartConfigOptions, "yaxis")) {
        yAxisConfig = cloneDeep(defaultChartConfigOptions.yaxis);

        if (has(yAxisConfig, "labels")) {
            yAxisLabelConfig = cloneDeep(yAxisConfig.labels);
        }
    }

    if (has(defaultChartConfigOptions, "markers")) {
        markersConfig = cloneDeep(defaultChartConfigOptions.markers);
    }
    
    if (has(defaultChartConfigOptions, "tooltip")) {
        tooltipConfig = cloneDeep(defaultChartConfigOptions.tooltip);
    }
    
    if (has(defaultChartConfigOptions, "legend")) {
        legendConfig = cloneDeep(defaultChartConfigOptions.legend);
    }

    if (clickHandler) {
        chartConfig.events = { ...chartConfig.events, dataPointSelection: function(event, chartContext, config){
            const selectedIndex = config.dataPointIndex;
            const category = data.labels[selectedIndex];
            const value = data.values[selectedIndex];
            clickHandler({category, value})
        }}
    }

    if (graphType && !chartConfig.type) {
        chartConfig.type = graphType;
    }
    
    if (data.id) {
        chartConfig.id = data.id;
    }
    
    if (data.showLegends === true) {
        legendConfig.show = true;
    }
    
    if (data.showMarkers || data?.labels?.length === 1 || data?.categories?.length === 1) {
        markersConfig.size = 5;
        markersConfig.shape = "circle";
    }
    else {
        markersConfig.size = 0;
    }

    if (graphType === "pie") {
        defaultChartConfigOptions.labels = data.labels;
    }
    
    if (graphType === "heatmap") {
        strokeConfig.show = true;
        strokeConfig.width = 0.3;
        strokeConfig.colors = ["#ffffff"];
        
        yAxisLabelConfig.formatter = val => val ? val.toString().replace(/&lt;/g, '<').replace(/&gt;/g, '>') : "";
    }
    
    if (graphType !== "heatmap") {
        strokeConfig.colors = chartColors.length ? chartColors : undefined;
    }

    if (data.integerOnlyYAxis && graphType !== "heatmap") {
        yAxisLabelConfig.formatter = val => val && val.toFixed(0);
    }

    defaultChartConfigOptions.xaxis = xAxisConfig;
    
    yAxisConfig.labels = yAxisLabelConfig;
    defaultChartConfigOptions.yaxis = yAxisConfig;
    
    defaultChartConfigOptions.markers = markersConfig;
    defaultChartConfigOptions.title = titleConfig;
    defaultChartConfigOptions.chart = chartConfig;
    defaultChartConfigOptions.colors = chartColors;
    defaultChartConfigOptions.stroke = strokeConfig;
    defaultChartConfigOptions.tooltip = tooltipConfig;
    defaultChartConfigOptions.legend = legendConfig;

    return defaultChartConfigOptions;
}

export const transformNodeLogsData = data => {
    return data.map(logItem => {
        let response = {};
        let data = {};
        const type = logItem.Type;
        const tsSource = logItem.TsSource;

        if (type === "crash") {
            const programCounter = logItem?.Crash?.ProgramCounter || "";
            const registers = {};
            registers.a_reg = logItem?.Crash?.ARegisters || [];
            
            const cause = {};
            cause.hex = logItem?.Crash?.Cause?.Hex || "";
            cause.description = logItem?.Crash?.Cause?.Description || "";
            
            const vaddr = logItem?.Crash?.Vaddr || "";

            const btCorrupt = false;

            let bt = [];
            if (logItem?.Crash?.Backtrace?.length) {
                logItem.Crash.Backtrace.forEach(backtraceItem => {
                    bt.push({
                        address: backtraceItem.Address || "",
                        file: backtraceItem.File || "",
                        line: backtraceItem.Line || "",
                        method: backtraceItem.Function || "",
                    })
                });
            }

            data.pc = programCounter;
            data.registers = registers;
            data.cause = cause;
            data.vaddr = vaddr;
            data.bt_corrupt = btCorrupt;
            data.bt = bt.length ? bt : null;
        }

        if (logItem?.Log?.Trace) {
            data.address = logItem.Log.Trace.Address || "";
            data.file = logItem.Log.Trace.File || "";
            data.line = logItem.Log.Trace.Line || "";
            data.method = logItem.Log.Trace.Function || "";
        }

        response.data = data;
        response.time = logItem.Timestamp;
        response.message = logItem.Message;
        response.type = type;
        response.ts_source = tsSource;
        
        return response;
    }).reverse();
}

export const generateInvalidIntervalsList = (start, end, maxPossibleIntervals) => {
    if (!start || !end) return [];
    let invalidIntervals = [];
    if (differenceInHours(fromUnixTime(end), fromUnixTime(start)) > (maxPossibleIntervals || 24)) {
        invalidIntervals.push(3600); // disable hourly interval
    }
    if (differenceInDays(fromUnixTime(end), fromUnixTime(start)) > (maxPossibleIntervals || 30)) {
        invalidIntervals.push(86400); // disable daily interval
    }
    if (differenceInWeeks(fromUnixTime(end), fromUnixTime(start)) > (maxPossibleIntervals || 20)) {
        invalidIntervals.push(604800); // disable weekly interval
    }
    if (differenceInMonths(fromUnixTime(end), fromUnixTime(start)) > (maxPossibleIntervals || 20)) {
        invalidIntervals.push(2592000); // disable monthly interval
    }

    return invalidIntervals;
}

export const transformTranslationText = (text, dynamicValues = []) => {
    if (!text) return "";
    if (!dynamicValues?.length) return text;

    dynamicValues.forEach((v, i) => {
        text = text.replaceAll("$" + i, dynamicValues[i]);
    });

    return text;
}

export const getEventStatAggregateData = ({maxBuckets, subDist}) => {
    let dist = {};

    let subdistData = {};
    subdistData.f = subDist;
    subdistData.t = "term";

    dist.s = {subdist: subdistData};
    dist.f = "Timestamp";
    dist.t = "autodatehist";
    dist.maxbuckets = maxBuckets;

    return {dist};
}

export const searchFiltersList = (filterData, searchProps) => {
    if (!filterData) return [];
    if (!searchProps) return filterData;

    const filteredList = [];
    for (const filterType in filterData) {
        const currentFilter = cloneDeep(filterData[filterType]);
        if (filter([currentFilter], searchProps).length) {
            if (!has(currentFilter, "FILTER_KEY")) {
                currentFilter['FILTER_KEY'] = filterType;
            }
            filteredList.push(currentFilter);
        }
    }
    
    return filteredList;
}

export const getCrashLogRegisterValues = ({
    ProgramCounter,
    ARegisters = [],
    Cause,
    Vaddr
}) => {
    const values = [];

    ProgramCounter && values.push({ title: "PC", value: get32BitExRegister(ProgramCounter) });
    
    if (ARegisters.length) {
        ARegisters.forEach((aReg, index) => {
            values.push({ 
                title: `A${index}`, 
                value: get32BitExRegister(aReg) 
            })
        });
    }

    Cause?.Hex && values.push({ title: "EXCCAUSE", value: get32BitExRegister(Cause.Hex) });
    Vaddr && values.push({ title: "EXCVADDR", value: get32BitExRegister(Vaddr) });

    return values;
}

const get32BitExRegister = (register) => {
    if (register.length >= 10) return register;

    let regArray = register.split('').slice(2);
    let count = 10 - register.length;
    let array = ['0x', new Array(count).fill(0).join(''), ...regArray];
    return array.join('');
};

export const getLogSearchConfigFromOptionsList = (list) => {
    if (!list || !list.length) return [];
    
    let uniqueOptionsList = [];
    
    list.forEach(o => {
        const isCustomText = o.hasOwnProperty("__isNew__");
        const filterType = isCustomText ? generalConfig.message_filter_key : generalConfig.event_type_filter_key;
        const filterValue = o.value;
        const operator = isCustomText ? "text" : "keyword";

        if (uniqueOptionsList.length) {
            const index = uniqueOptionsList.findIndex(f => f.filterType === filterType);
            if (index !== -1) {
                const currentValues = uniqueOptionsList[index].filterValue;
                const newFilterValues = isArray(currentValues) ? [...currentValues] : [currentValues];
                const updatedFilterValues = union(newFilterValues, [filterValue]);

                uniqueOptionsList = [
                    ...uniqueOptionsList.slice(0, index),
                    { filterType, filterValue: updatedFilterValues, operator},
                    ...uniqueOptionsList.slice(index + 1)
                ];
            }
            else {
                uniqueOptionsList.push({ filterType, filterValue, operator });
            }
        }
        else {
            uniqueOptionsList.push({ filterType, filterValue, operator });
        }
    });

    return uniqueOptionsList;
}

export function getConsolidatedParamsList(metaData) {
    let paramIdList = [];
    function getIdsFromParamData(data) {
        if (data.children) {
            data.children.forEach(d => getIdsFromParamData(d));
        }
        else {
            paramIdList.push(data.id)
        }
    }
    metaData.forEach(paramData => getIdsFromParamData(paramData))

    return paramIdList;
}

export function transormNodeParamsData({meta, list}) {
    let _meta = null;

    function updateMetaWithValue(data) {
        if (data.children) {
            data.children.forEach(d => updateMetaWithValue(d));
        }
        else {
            const listIndex = list.findIndex(paramListItem => paramListItem.id === data.id);
            if (listIndex !== -1) {
                const paramData = list[listIndex].data[0];
                if (paramData) {
                    data.value = paramData.val;
                    data.ts = utcTimeStampToLocalTimeStamp(paramData.ts);
                    data.formattedDate = moment(utcTimeStampToLocalTimeStamp(paramData.ts)/1000).format('DD/MM/YY HH:mm:ss');
                }
            }
        }
    }

    if (meta && list) {
        _meta = cloneDeep(meta);
        _meta.forEach(metaData => updateMetaWithValue(metaData));
    }

    return _meta;
}

export const transformNodeTagsData = data => {
    let tagsList = [];
    data.forEach(tag => {
        tag?.value && tag.value.forEach(value => {
            tagsList.push({
                id: `${tag.property}${value}`, property: tag.property, value
            });
        });
    });

    return tagsList;
}

export const getLogSearchOptionsFromFiltersList = list => {
    let optionsList = [];
    list.forEach(({filterType, filterValue}) => {
        const optionData = {
            isFixed: true,
            value: filterValue,
            label: filterValue
        };
        if (filterType === generalConfig.event_type_filter_key) {
            optionData.color = generalConfig.event_types.filter(e => e.id === filterValue)[0]?.color;
        }
        if (filterType === generalConfig.message_filter_key) {
            optionData.__isNew__ = true;
        }
        
        if (generalConfig.applicableFilterTypesForLogSearch.includes(filterType)) {
            optionsList.push(optionData);
        }
    });

    return optionsList;
}

export const getLogSearchDropdownOptions = list => {
    let optionsList = [];
    list.forEach(({filterType, filterValue}) => {
        const optionData = {
            isFixed: true,
            value: filterValue,
            label: filterValue
        };
        if (filterType === generalConfig.event_type_filter_key) {
            optionData.color = generalConfig.event_types.filter(e => e.id === filterValue)[0]?.color;
        }
        if (filterType === generalConfig.message_filter_key) {
            optionData.__isNew__ = true;
        }

        if (generalConfig.applicableFilterTypesForLogSearch.includes(filterType)) {
            optionsList.push(optionData);
        }
    });

    generalConfig.event_types.forEach(({id, color}) => {
        if (list.findIndex(f => f.filterValue === id) === -1) {
            optionsList.push({
                isFixed: false,
                value: id,
                label: id,
                color
            })
        }
    });

    return optionsList;
}

export const transformDashboardStateData = ({dashboard_name =null, dashboard_state, dashboard_default, dashboard_id = null}) => {
    const dashboardState = JSON.stringify(dashboard_state);
    const data = {
        dashboard_name: dashboard_name,
        file: dashboardState,
        is_default: dashboard_default
    }
    if(dashboard_id) data.dashboard_id = dashboard_id
    return data;
}

export const getUsageCreditsUnit = (meta, data, tzOffset = 0) => {
    if (!meta || !data) return null;

    const entityUsageDistribution = data?.entity_usage_distribution || {};
    let allEntitiesDistribution = [];

    for (let key in entityUsageDistribution) {
        const keyData = key.split(":");
        if (keyData.length > 1) {
            const distribution = entityUsageDistribution[key]?.usage_distribution || {};
            const {
                usageData, 
                totalUsageCreditsUnit, 
                usageDataByInterval
            } = getUsageDataByIntervalFromDistribution({distribution, meta, tzOffset}); 

            const entityKey = keyData[0];
            const entityId = keyData[1];
            const entityData = allEntitiesDistribution.find(e => e.id === entityKey);
            if (entityData) {
                entityData.usageData = addPropertiesOfObjects(entityData.usageData, usageData);
                entityData.totalUsageCreditsUnit += totalUsageCreditsUnit;
                const updatedUsageDataByInterval = union(entityData.usageDataByInterval, usageDataByInterval);
                entityData.usageDataByInterval = updatedUsageDataByInterval;
                entityData.entities.push({
                    id: entityId,
                    totalUsageCreditsUnit, usageDataByInterval, usageData
                })
            }
            else {
                allEntitiesDistribution.push({
                    id: entityKey,
                    totalUsageCreditsUnit, usageDataByInterval, usageData,
                    entities: [
                        { 
                            id: entityId,
                            totalUsageCreditsUnit, usageDataByInterval, usageData
                        }
                    ]
                })
            }
        }
    }

    const distribution = data.total_usage?.usage_distribution || {};
    const {
        usageData, 
        totalUsageCreditsUnit, 
        usageDataByInterval
    } = getUsageDataByIntervalFromDistribution({distribution, meta, tzOffset});

    return {
        usageData, 
        totalUsageCreditsUnit, 
        usageDataByInterval,
        allEntitiesDistribution
    };
}

export const getUsageDataByIntervalFromDistribution = ({distribution, meta, tzOffset}) => {
    let consolidatedUsageDistribution = {};
    let usageDataByInterval = [];

    for (let interval in distribution) {
        const intervalData = distribution[interval];
        let creditUsage = 0;
        let breakdown = [];

        for (let key in intervalData) {
            const count = intervalData[key];
            const unit = meta[key] ? meta[key]['unit_credits'] : 0;
            const currentKeyCreditUsage = count * unit;
            creditUsage += count * unit;

            meta[key] && breakdown.push({
                id: key,
                label: meta[key]['label'] || key,
                usage: currentKeyCreditUsage,
                count
            })

            if (consolidatedUsageDistribution[key]) {
                consolidatedUsageDistribution[key] += count;
            }
            else {
                consolidatedUsageDistribution[key] = count;
            }
        }

        usageDataByInterval.push({
            ts: parseInt(interval) + tzOffset,
            creditUsage, breakdown
        })
    }

    let totalUsageCreditsUnit = 0;
    let usageData = {};
    for (let key in consolidatedUsageDistribution) {
        if (meta[key]) {
            const creditUnits = consolidatedUsageDistribution[key] * (meta[key]["unit_credits"]);
            usageData[key] = {
                count: consolidatedUsageDistribution[key],
                credit_units: creditUnits,
                label: meta[key]['label'] || key
            }

            totalUsageCreditsUnit += creditUnits;
        }
    }

    return {
        usageData, totalUsageCreditsUnit,
        usageDataByInterval: orderBy(usageDataByInterval, ['ts'], ['asc'])
    }
}

export const getUsageTimelineChartData = (data, tzOffset = 0) => {
    if (!data?.length) return null;

    let result = [];
    let hashMap = {};
    let labelsHashMap = {};

    data.forEach(intervalData => {
        const { ts, breakdown } = intervalData;
        const millsecondTs = (ts + tzOffset) * 1000;
        breakdown.forEach(event => {
            const { id, label, usage } = event;
            if (hashMap.hasOwnProperty(id)) {
                hashMap[id].push({x: millsecondTs, y: usage});
            }
            else {
                hashMap[id] = [{x: millsecondTs, y: usage}];
                labelsHashMap[id] = label;
            }
        })
    });

    Object.keys(hashMap).forEach(eventKey => {
        result.push({
            name: labelsHashMap[eventKey],
            data: hashMap[eventKey]
        });
    });

    return result;
}

export function filterUsageData({data, primaryFilter, secondaryFilter, returnKeys}) {
    if (!data) return null;
    if (!primaryFilter) return data;

    if (primaryFilter === "none") {
        return getResultObj(data);
    }
    else {
        let primaryFilterData = null;
        if (data.allEntitiesDistribution) {
            primaryFilterData = data.allEntitiesDistribution.find(d => d.id === primaryFilter);
            if (primaryFilterData) {
                if (secondaryFilter) {
                    if (secondaryFilter === "all") {
                        return getResultObj(primaryFilterData);
                    }
                    else {
                        let secondaryFilterData = primaryFilterData.entities.find(e => e.id === secondaryFilter);
                        if (secondaryFilterData) {
                            return getResultObj(secondaryFilterData);
                        }
                        else {
                            return null;
                        }
                    }
                }
                else {
                    return getResultObj(primaryFilterData);
                }
            }
            else {
                return null;
            }
        }
    }

    function getResultObj(data){
        let result = {};
        returnKeys.forEach(key => {
            if (data.hasOwnProperty(key)) {
                result[key] = data[key];
            }
        });
        return result;
    }
}

export function getTransformedNodeConfigMetaDataList(metaData) {
  if (!metaData?.list) return [];
  const transformedData = [];
  const metaDataList = sortBy(metaData.list, [
    function (o) {
      return o?.name?.length;
    },
  ]);

  metaDataList.forEach((item) => {
    const names = item.name;
    let currentMetaItem = transformedData;

    names.forEach((name, index) => {
      const existingItem = currentMetaItem.find((item) => item.key === name);

      if (!existingItem) {
        const newItem = {
          key: name,
          items: [],
        };
        currentMetaItem.push(newItem);
        currentMetaItem = newItem.items;
      } else {
        currentMetaItem = existingItem.items;
      }

      if (index === names.length - 1) {
        currentMetaItem.push(item);
      }
    });
  });

  return transformedData;
}

export function transformJsonToCborBase64String(jsonData) {
  try {
    const cborData = CBOR.encode(jsonData);
    const buffer = Buffer.from(cborData);
    const base64Data = buffer.toString("base64");
    return base64Data;
  } catch (error) {
    return "";
  }
}