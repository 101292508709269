import React, { useState, useEffect } from 'react';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import Loader from '../../components/Loader/Loader';

const MultiLineChart = (props) => {
	const version = props.version
	const timeInterval = props.timeInterval
	const [cardLoading, setCardLoading] = useState(props.loading);

	useEffect(() => {
		setCardLoading(props.loading)
	}, [props.loading]);

	

	return (
		<>
			{cardLoading ? (
				<Loader minHeight='300px' />
			) : (
				props.data && props.data.length > 0 ? <ResponsiveContainer width={'100%'} height={300}>
					<LineChart
						width={500}
						height={300}
						data={props.data}
						margin={{
							top: 5,
							right: 30,
							left: 20,
							bottom: 5
						}}
					>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="timestamp" tick={<CustomizedAxisTick timeInterval={timeInterval} />} textAnchor='end' />
						<YAxis />
						<Tooltip />
						<Legend wrapperStyle={{ position: 'absolute', marginTop: '20px' }} />
						<Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 4 }} className="p-auto" />
					</LineChart>
				</ResponsiveContainer> : <div className='d-flex justify-content-center align-items-center'>
					<h6 className='font-weight-bold text-secondary'>No Data to Display</h6>
				</div>
			)}
		</>
	);
};

function CustomizedAxisTick(props) {
	const { x, y, payload } = props;
	return (
		<g transform={`translate(${x},${y})`}>
			<text
				x={0}
				y={0}
				dy={16}
				textAnchor='middle'
				fill="#666"
				tickSize={4}
				style={{ fontSize: '10px' }}
			>
				{props.timeInterval === '1h' ? (payload.value).slice(0, 5) : payload.value}
			</text>
		</g>
	);
};

export default MultiLineChart;
