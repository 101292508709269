import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getUserCall } from '../../../utils/apiCalls';

const SocialAuth = () => {
	let history = useHistory();

	useEffect(() => {
		let link = window.location.href;
		let access_token = link.substring(
			link.indexOf('access_token=') + 13,
			link.indexOf('&')
		);

		if (access_token) {
			Cookies.set('ATK', access_token);
			localStorage.setItem('email', false);
			getUserCall().then((resp) => {
				if (resp.user_name) {
					history.push('/home/insights');
				}
			});
		}
	}, []);

	return (
		<div className='vh-100 d-flex justify-content-center align-items-center'>
			<div className='spinner-border text-link' role='status'>
				<span className='sr-only'>Loading...</span>
			</div>
		</div>
	);
};

export default SocialAuth;
