import React from 'react';
import { Form } from 'react-bootstrap';
import { PrimaryLineButton } from '../../../components/Button/Button';
import './Pagination.scss';

export default function Pagination(props) {
    const { 
        onPrev = null, 
        onNext = null,
        showPageSizeControl = false,
        pageSize = 10,
        minPageSize = 5,
        maxPageSize = 50,
        onPageSizeChange = null 
    } = props;

    const pageSizeOptions = showPageSizeControl ? Array.from({length: maxPageSize}, (v, i) => i + 1).filter(curr => (curr >= minPageSize && curr % minPageSize === 0) || curr === maxPageSize) : [];

    return (
        <div className='pagination-button-container'>
            <PrimaryLineButton
                id='prev-btn'
                disabled={onPrev === null}
                onClick={onPrev}
            >
                Prev
            </PrimaryLineButton>

            {
            showPageSizeControl && (
                <Form.Control
                    as='select'
                    custom
                    name='searchBy'
                    defaultValue={pageSize}
                    id='page-size-option'
                    onChange={e => onPageSizeChange(e.target.value)}
                >
                    <option value='search-by' disabled>
                        Results per page
                    </option>
                    {
                        pageSizeOptions.map(size => <option value={size}>{size}</option>)
                    }
                </Form.Control>
            )}

            <PrimaryLineButton
                id='next-btn'
                disabled={onNext === null}
                onClick={onNext}
            >
                Next
            </PrimaryLineButton>
        </div>
    )
}
