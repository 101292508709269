import React, {useContext} from 'react';
import './CreditsUsageCalculationHelp.scss';
import AdvancedInsightsContext from './../../../../context/insights-context';
import { Accordion, AccordionContext, useAccordionToggle } from 'react-bootstrap';
import { UilQuestionCircle } from '@iconscout/react-unicons';
import CreditUnitsTable from './CreditUnitsTable/CreditUnitsTable';

function AccordionToggleLink({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);
    const toggleHandler = useAccordionToggle(eventKey,
        () => callback && callback(eventKey),
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <div 
            className={`title ${isCurrentEventKey ? 'expanded' : 'collapsed'}`}
            onClick={toggleHandler}
        >
            {children}
        </div>
    )
}

function CreditsUsageCalculationHelp({textLabels}) {
    const { 
        billingUsageMetaData
    } = useContext(AdvancedInsightsContext);

    if (billingUsageMetaData === null) return null;

    return (
        <div className='credits-usage-calculation-help-wrapper'>
            <Accordion defaultActiveKey="-1">
                <AccordionToggleLink eventKey="0">
                    <UilQuestionCircle size={18} />
                    <label>{textLabels.label}</label>
                </AccordionToggleLink>
                <Accordion.Collapse eventKey="0" className='data'>
                    <CreditUnitsTable 
                        data={billingUsageMetaData} 
                        textLabels={textLabels}
                    />
                </Accordion.Collapse>
            </Accordion>
        </div>
    )
}

export default CreditsUsageCalculationHelp