import React from 'react';
import Notice from './Notice/Notice';
import * as notices from './notices.json';

export default function AppNotices(props) {
    const getNoticeType = type => type === "error" ? "danger" : type;

    return (
        <div className='notices'>
            {
                notices.list.map(notice => notice.enable ? (
                    <Notice type={getNoticeType(notice.type)} text={notice.text} key={notice.id} />
                ) : null)
            }
        </div>
    )
}
