import React, { useEffect, useState, useMemo } from "react";
import {
  RefreshButton,
  SuccessButton,
} from "../../components/Button/Button";
import Table from "../../components/Table/Table";
import {
  listConnectorAuthKeys,
  createConnectorAuthKey,
} from "../../utils/manageAuthKeysAPI";
import KeyActions from "./KeyActions/KeyActions";
import Pagination from "../ManageAuthKeys/Pagination/Pagination";
import moment from "moment";
import {
  isValidConnectorAuthKeyName,
} from "../../utils/validationSchemas";
import { sortBy } from "lodash";
import ToggleConnectorStatus from "./ToggleConnectorStatus/ToggleConnectorStatus";
import CustomToast from '../../components/CustomToast/CustomToast';
import Notice from "../../components/AppNotices/Notice/Notice";
import NoDataMessage from "../AdvancedInsights/Widgets/NoDataMessage/NoDataMessage";

const ManageConnectorAuthKeys = () => {
  const [loading, setLoading] = useState(true);
  const [connectorAuthKeysData, setConnectorAuthKeysData] = useState([]);
  const [pagetoken, setPagetoken] = useState("");
  const [limit, setLimit] = useState(10);
  const [formData, setformData] = useState({
    name: "",
    description: "",
  });
  const [formHasError, setFormHasError] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const [showCreateAuthKeyForm, setShowCreateAuthKeyForm] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [prevPagetoken, setPrevPagetoken] = useState("");
  const [nextPagesTokens, setNextPagesTokens] = useState("");

  const getAllKeys = async () => {
    setLoading(true);
    const res = await listConnectorAuthKeys(limit, pagetoken)
    const { status, data } = res;
    if(status === 200)
    {
      const { data: authKeys = [], pagetoken = ""} = data ;
      setLoading(false);
      setConnectorAuthKeysData(sortBy(authKeys, (key) => key.created_on));
      setNextPagesTokens(pagetoken);
    }
    else {
      setLoading(false);
      setConnectorAuthKeysData([]);
    }
  };

  useEffect(() => {
    getAllKeys();
  }, [pagetoken, limit]);

  const handleDeleteConnectorAuthKey = async (id) => {
    setConnectorAuthKeysData((prev) => {
      const updatedConnectorAuthKeys = prev.filter((key) => key.id !== id);
      return updatedConnectorAuthKeys;
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        style: {
          width: "20%",
        },
      },
      {
        Header: "Description",
        accessor: "description",
        style: {
          width: "20%",
        },
      },
      {
        Header: `${showCreateAuthKeyForm ? "IOT rule name" : "Created on" }`,
        accessor: (row) =>
          moment.unix(row.created_on).format("DD/MM/YY HH:mm:ss"),
        style: {
          width: "15%",
        },
      },
      {
        Header: `${showCreateAuthKeyForm ? "IOT topic name" : "Status" }`,
        accessor: (row) => <ToggleConnectorStatus data={row} />,
        style: {
          width: "30%",
        },
      },
      {
        Header: "Actions",
        accessor: (row) => (
          <KeyActions
            loading={deleteLoading}
            data={row}
            onDelete={handleDeleteConnectorAuthKey}
          />
        ),
        style: {
          width: "15%",
          textAlign: "right",
        },
      },
    ],
    [showCreateAuthKeyForm]
  );

  const generateConnectorAuthKeyForm = {
    inputs: [
      {
        id: "name",
        field: "Name",
        type: "text",
        placeholder: "For ex : connector auth",
        required: true,
      },
      {
        id: "description",
        field: "Description",
        type: "text",
        placeholder: "For ex :  private rainmaker auth",
        required: true,
      },
      {
        id: "iot_rule_name",
        field: "iot_rule_name",
        type: "text",
        placeholder: "( optional )",
        required: false
      },
      {
        id: "iot_topic_name",
        field: "iot_topic_name",
        type: "text",
        placeholder: "( optional )",
        required: false
      },

      {
        id: "connector_auth_key_submit",
        field: "Generate key",
        type: "submit",
      },
    ],
  };

  const handleFormFieldValidation = (e) => {
    const { id, value } = e.target;
    const isValueValid = isValidConnectorAuthKeyName(value);
    
    if( id === "name"){
      setFormHasError((prev) => {
        return { ...prev, [id]: !isValueValid && "Invalid Connector Auth key Name. Please input value in [ 0-9 A-Z a-z ]" };
      });
    }

    setformData((prev) => {
      return { ...prev, [id]: value };
    });
  };

  const formDataValiation = () => {
    const formValues = Object.entries(formData);
    let isValidConnectorAuthKey = true;

    formValues.forEach(([id, value]) => {    
      if (id === "name" && !isValidConnectorAuthKeyName(value)) {
        setFormHasError((prev) => {
          return { ...prev, [id]: "Invalid Connector Auth key Name. Please input value in [ 0-9 A-Z a-z ]" };
        });
        isValidConnectorAuthKey = false;
      }
    });
    return isValidConnectorAuthKey;
  };

  const resetForm = () => {
    setformData({
      name: "",
      description: "",
      iot_rule_name: "",
      iot_topic_name: ""
    });
    setFormHasError({});
  };

  const handleGenerateConnectorAuthKey = async () => {
    if (formDataValiation() === false) return;
    setFormLoading(true);
    const res = await createConnectorAuthKey(formData);

    //if connector auth key generation failed
    if (res.status !== 200) {
      CustomToast.error( res.data.msg,{ autoClose: 5000},'invalid-connector-auth-key-toast');
      setFormLoading(false);
      return;
    }
    // if connector auth key created successfully
    const { data } = res;
    setFormLoading(false);
    setShowCreateAuthKeyForm(false);
    setConnectorAuthKeysData((prev) => {
      const updatedAuthKeysData = [
        ...prev,
        {
          id: data.connector_auth_key_id,
          created_on: data.created_on,
          ...formData,
        },
      ];
      return sortBy(updatedAuthKeysData, (key) => key.created_on);
    });
    resetForm();

  };

  // pageToken Handlers
  const handlePrevious = () => {
    const newTokensList = [...prevPagetoken];
    const currentPageToken = newTokensList.pop();
    setPagetoken(currentPageToken);
    setPrevPagetoken(newTokensList)
  };

  const handleNext = () => {
    setPrevPagetoken(prev => [...prev, pagetoken])
    setPagetoken(nextPagesTokens);
  };

  const handleLimitChange = limit => {
    setLimit(limit);
    setPagetoken("");
    setNextPagesTokens([""]);
}

  return (
    <div className="manage-auth-keys">
      <div className="app--navbar">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="d-flex">
              <p className="main--heading">Manage Connector Auth Keys</p>
              <RefreshButton loading={loading} onClick={() => getAllKeys()} />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <SuccessButton
              title="Generate New Connector Auth Key"
              id="create-new-key"
              onClick={() => setShowCreateAuthKeyForm(true)}
            >
              Generate Connector Auth Key
            </SuccessButton>
          </div>
        </div>
      </div>
      <hr className="mx-3 my-0" />

      <div className="home-card card--container d-flex flex-column justify-content-between">
        <div className="row">
          <div className="col-lg-12 h-100">
            <Table
              data={connectorAuthKeysData}
              columns={columns}
              loading={loading}
              loaders={limit}
              form={showCreateAuthKeyForm && generateConnectorAuthKeyForm}
              formError={formHasError}
              onFieldChange={handleFormFieldValidation}
              formLoading={formLoading}
              onSubmit={handleGenerateConnectorAuthKey}
            />
            {
              (!loading && connectorAuthKeysData?.length === 0) ? (
                <NoDataMessage 
                  text="Sorry, we couldn't find any connector auth keys for your account."
                />
              ) : null
            }
          </div>
        </div>
      </div>

      <Pagination
        onPrev={prevPagetoken.length > 0 ? handlePrevious : null}
        onNext={nextPagesTokens ? handleNext : null}
        pageSize={limit}
        minPageSize={10}
        maxPageSize={25}
        showPageSizeControl={true}
        onPageSizeChange={handleLimitChange}
      />
    </div>
  );
};

export default ManageConnectorAuthKeys;
