import { has } from 'lodash';
import React, {useContext, useState, useEffect} from 'react';
import { ListGroup } from 'react-bootstrap';
import AdvancedInsightsContext from '../../../context/insights-context';
import * as generalConfig from './../../../general-config.json';
import './EventsTable.scss';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import NoDataAvailable from '../../WidgetWrapper/NoDataAvailable/NoDataAvailable';
import Loader from '../../../../../components/Loader/Loader';
import EventIcon from './EventIcon/EventIcon';

function EventsTable() {
    const { 
        allEventsData, 
        getAllEventsData,
        startTimestamp,
        endTimestamp,
        selectedFilters,
        messages,
        addMultipleFiltersToSelectedFilters,
        initialLoadCompleteStatus,
        resetAllEventsData,
        allEventsDataResetStatus,
    } = useContext(AdvancedInsightsContext);
    
    const eventTypes = generalConfig.event_types;

    useEffect(() => {
        if ((allEventsData === null && initialLoadCompleteStatus === true) || allEventsDataResetStatus === true) {
            getAllEventsData();
        }

        return () => resetAllEventsData();
    }, [])

    useDidMountEffect(() => {
            getAllEventsData();
    }, [startTimestamp, endTimestamp, selectedFilters]);

    if (allEventsData === null && initialLoadCompleteStatus === true) return <Loader />
    if (allEventsData === null || !allEventsData?.length) return <NoDataAvailable />;

    const eventsData = eventTypes.filter(e => allEventsData.some(ed => ed.key === e.id));
    const getEventCount = key => allEventsData.filter(e => e.key === key)[0].count.toLocaleString();

    const handleRowClick = type => {
        const eventTypeFilterKey = generalConfig.event_type_filter_key;

        addMultipleFiltersToSelectedFilters([{
            filterType: eventTypeFilterKey,
            filterValue: type,
        }]);
    }

    return (
        <div className='events-table'>
            <ListGroup>
                {
                    eventsData.map(({id, highlightInDashboard}) => (
                        <ListGroup.Item 
                            className={`event-row ${highlightInDashboard === true ? 'highlight': ''}`} 
                            key={id}
                            onClick={() => handleRowClick(id)}
                        >
                            <div className='icon'><EventIcon type={id} /></div>
                            <div className='label pl-3'><span>{ messages.event_types[id].label_plural }</span></div>
                            <div className='value'>{ getEventCount(id) }</div>
                        </ListGroup.Item>
                    ))
                }
            </ListGroup>
        </div>
    )
}

export default EventsTable