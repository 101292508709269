import React from 'react';
import Loader from '../../components/Loader/Loader';

export default function ErrorPage(props) {
    const errorMessage = props.message || "";

    return props.loading ? <Loader minHeight='470px' /> : (
        <div className='row'>
            <div className='col-lg-12'>
                <div className="d-flex bg-white justify-content-between p-2 mb-3 page-title">
                    { errorMessage }
                </div>
            </div>
        </div>
    )
}
