/* eslint-disable react/react-in-jsx-scope */
import { addMonths, fromUnixTime, isEqual, isAfter } from 'date-fns';
import Cookies from 'js-cookie';
import { isArray } from 'lodash';
import React from 'react';
import { Route } from 'react-router-dom';
import { 
    getUnixTime, 
    startOfToday,
    endOfToday,
    startOfYesterday,
    endOfYesterday,
    sub,
	differenceInSeconds
} from 'date-fns';
import moment from 'moment';

export const getBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const { result } = reader;
			const stripped = result.split(',').pop();
			resolve(stripped);
		};
		reader.onerror = (error) => reject(error);
	});
};

export const clearCookies = () => {
	Cookies.remove('ATK');
	Cookies.remove('RTK');
	Cookies.remove('user_name');
	Cookies.remove('id');
	Cookies.remove('admin');
	Cookies.remove('cst');
	Cookies.remove('TATK');
	Cookies.remove('ta_id');
	Cookies.remove('ta_eid');
};

export const RouteWithSubRoutes = (route) => {
	return (
		<Route
			path={route.path}
			render={(props) => (
				<route.component
					{...props}
					sessionExpire={route.sessionExpire}
					routes={route.routes}
				/>
			)}
			exact={route.exact === true}
		/>
	);
};

export const GetDateInYYMMDD = (noOfDaysBefore) => {
	let date = new Date();
	let lastDate = new Date(
		date.getTime() - noOfDaysBefore * 24 * 60 * 60 * 1000
	);
	let day = lastDate.getDate();
	let dayString = day < 10 ? `0${day}` : day;
	let month = lastDate.getMonth() + 1;
	let monthString = month < 10 ? `0${month}` : month;
	let year = lastDate.getFullYear();
	return `${year}-${monthString}-${dayString}`;
};

export const createFormAndSubmit = (method, formTarget, actionURL, fieldsData) => {
	const defaultMethod = (new RegExp(/\?.+=.*/g)).test(actionURL) ? "POST" : "GET";
	const formMethod = method || defaultMethod;
  
	if (actionURL) {
	  let formHTMLString = "<form id='tempForm' ";
	  formHTMLString += "method='" + formMethod + "' ";
	  formHTMLString += "action='" + actionURL + "' ";
	  formHTMLString += "target='" + formTarget + "' ";
	  formHTMLString += "enctype='application/x-www-form-urlencoded' ";
	  formHTMLString += ">";
  
	  if (fieldsData) {
		for (var field in fieldsData) {
		  let inputHTMLString = "<input type='hidden' ";
		  inputHTMLString += "name='" + field + "' ";
		  inputHTMLString += "value='" + fieldsData[field] + "' ";
		  inputHTMLString += " />";
  
		  formHTMLString += inputHTMLString;
		}
	  }
  
	  formHTMLString += "</form>";
  
	  document.getElementsByTagName("div")[0].insertAdjacentHTML( 'beforeend', formHTMLString );
	  document.getElementById("tempForm").submit();
	  document.getElementById("tempForm").remove();
	}
  }

export const getmaxAllowedEndDate = ({fromTime, maxAllowedRangeInMonths, disableFutureDatesSelection}) => {
	const today = new Date();
	
	let maxAllowedEndDate = addMonths(fromUnixTime(fromTime), maxAllowedRangeInMonths);
	if (disableFutureDatesSelection) {
        if (isEqual(maxAllowedEndDate, today) || isAfter(maxAllowedEndDate, today)) {
            maxAllowedEndDate = today;
        }
    }

	return maxAllowedEndDate;
}

export const getUrlParameterByName = (name, url) => {
	if (!url) url = window.location.href;
	
	name = name.replace(/[[\]]/g, "\\$&");
	var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
		results = regex.exec(url);
	if (!results) return "";
	if (!results[2]) return "";
	return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const updateUrlParameterByName = (name, value) => {
	const url = new URL(window.location.href);
	url.searchParams.set(name, value);
	window.history.pushState({}, '', url.toString());
};

export const generateHashURL = (url, hashValues) => {
	const nonHashURL = url.split("#")[0];
	let hashString = "";
	for (const hashProp in hashValues) {
		const hashValue = hashValues[hashProp];
		if (hashValue) {
			let currentHashValue = hashValue;
			if (isArray(hashValue)) {
				currentHashValue = hashValue.filter(hv => hv).join("|");
			}

			hashString += `${hashProp}=${currentHashValue}&`
		};
	}

	return `${nonHashURL}#${hashString}`;
}

export const updateHashURL = hashValues => {
	let updatedHashValues = "";

	if (!window.location.hash) {
		updatedHashValues = new URLSearchParams(hashValues).toString();
	}
	else {
		const currentHashParams = Object.fromEntries(new URLSearchParams(window.location.hash.slice(1)));
		updatedHashValues = new URLSearchParams({...currentHashParams, ...hashValues}).toString();
	}

	const currentURL = new URL(document.URL);
	currentURL.hash = `#${decodeURIComponent(updatedHashValues)}`;

	document.location.href = currentURL.href;
}

export const getUrlHashParameterByName = name => {
	if (!window.location.hash) return "";

	const matches = window.location.hash.match(new RegExp(name + '=([^&]*)'));
	return matches ? matches[1] : "";
};

export const formatLargeNumbers = num => {
    num = num.toString().replace(/[^0-9.]/g, '');
    
	if (num < 1000) {
        return num;
    }

    let suffixData = [
		{value: 1E3, suffix: "k"},
		{value: 1E6, suffix: "m"},
		{value: 1E9, suffix: "b"},
		{value: 1E12, suffix: "t"},
		{value: 1E15, suffix: "p"},
		{value: 1E18, suffix: "e"}
	];

    let index;
    for (index = suffixData.length - 1; index > 0; index--) {
        if (num >= suffixData[index].value) {
            break;
        }
    }
    return (num / suffixData[index].value).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + suffixData[index].suffix;
}

export const highlightElementText = (ref) => {
	const selection = window.getSelection();
	const range = document.createRange();
	range.selectNodeContents(ref.current);
	selection.removeAllRanges();
	selection.addRange(range);
}

export const handleQuickLinkAction = id => {
	if(id===null) return {};
	const actionType = id;
	const date = new Date();
	let startDateTimestamp = 0;
	let endDateTimestamp = 0;

	const quickLinks = [
		{ id: "today", label: "Today" },
		{ id: "yesterday", label: "Yesterday" },
		{ id: "last-24-hours", label: "Last 24 hours", diff: { hours: 24 }},
		{ id: "last-7-days", label: "Last 7 days", diff: { days: 7 }},
		{ id: "last-30-days", label: "Last 30 days", diff: { days: 30 }},
		{ id: "last-3-months", label: "Last 3 months", diff: { months: 3 }},
	]

	if (actionType === "today") {
		startDateTimestamp = getUnixTime(startOfToday());
		endDateTimestamp = getUnixTime(endOfToday());
	} 
	else if (actionType === "yesterday") {
		startDateTimestamp = getUnixTime(startOfYesterday());
		endDateTimestamp = getUnixTime(endOfYesterday());
	}
	else {
		const quickLinkData = quickLinks.filter(ql => ql.id === actionType)[0];
		if (quickLinkData && quickLinkData.diff) {
			startDateTimestamp = getUnixTime(sub(date, quickLinkData.diff));
			endDateTimestamp = getUnixTime(date);
		}
	}
	return {
		startTimestamp: startDateTimestamp, 
		endTimestamp: endDateTimestamp
	}
}

export const localTimeStampToUtcTimeStamp = ts => {
	if (!ts) return 0;

	const tzOffset = (moment().utcOffset()) * -60;
	
	let multiplier = 1;
	if (String(ts).length === 13) multiplier = 1000;
	if (String(ts).length === 16) multiplier = 1000000;
	
	return ts + (tzOffset * multiplier);
}

export const utcTimeStampToLocalTimeStamp = ts => {
	if (!ts) return 0;

	const tzOffset = (moment().utcOffset()) * 60;
	
	let multiplier = 1;
	if (String(ts).length === 13) multiplier = 1000;
	if (String(ts).length === 16) multiplier = 1000000;
	
	return ts + (tzOffset * multiplier);
}

export const formatPastTime = (ts) => {
	const timePeriods = {
		month: 30 * 24 * 60 * 60,
		week: 7 * 24 * 60 * 60,
		day: 24 * 60 * 60,
		hour: 60 * 60,
		minute: 60,
		seconds: 1
	};

	let createdOn = ts.toString().length === 10 ? ts * 1000 : ts;

	const currentDate = new Date();
	const diff = differenceInSeconds(currentDate, createdOn);
	
	if (diff > timePeriods.month) {
		return Math.floor(diff / timePeriods.month) + " month ago";
	} 
	else if (diff > timePeriods.week) {
		return Math.floor(diff / timePeriods.week) + "w ago";
	} 
	else if (diff > timePeriods.day) {
		return Math.floor(diff / timePeriods.day) + "d ago";
	}
	 else if (diff > timePeriods.hour) {
		return Math.floor(diff / timePeriods.hour) + "h ago";
	}
	else if (diff > timePeriods.minute) {
		return Math.floor(diff / timePeriods.minute) + "m ago";
	}
	
	return "Just now";
}
