import React, { useContext, useEffect } from "react";
import WidgetWrapper from "../../Widgets/WidgetWrapper/WidgetWrapper";
import AdvancedInsightsContext from "./../../context/insights-context";
import TagsTable from "./TagsTable/TagsTable";
import { getUrlParameterByName } from "../../../../utils/commonFunctions";

const NodeTags = () => {
  const {
    selectedNodeTagsList,
    selectedNodeTagsListLoading,
    getSelectedNodeTagsListData,
    removeFromSelectedNodeTagsList,
    updateTagFromSelectedNodeTagsList,
    createTagForSelecetedNode,
    messages,
  } = useContext(AdvancedInsightsContext);

  const nodeKey = getUrlParameterByName("key") || null;

  useEffect(() => {
    if(nodeKey)
      getSelectedNodeTagsListData(nodeKey)
  }, [nodeKey])
  
  return (
    <WidgetWrapper
      loading={selectedNodeTagsListLoading}
      containerStyles={{ padding: "10px", borderRadius: "10px" }}
    >
    {  selectedNodeTagsList && 
        <TagsTable 
            tagsList={selectedNodeTagsList} 
            removeTag={removeFromSelectedNodeTagsList} 
            updateTag={updateTagFromSelectedNodeTagsList}
            createTag={createTagForSelecetedNode}
            loading={selectedNodeTagsListLoading}
            nodeKey={nodeKey}
            messages={messages}
          />
    }
    </WidgetWrapper>
  );
};

export default NodeTags;
