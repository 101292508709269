import { trim } from "lodash";
import React from "react";
import './ExpandableTable.scss';
import { useTable, useExpanded } from "react-table";
import { UilCornerDownRight } from "@iconscout/react-unicons";

function ExpandableTable({ columns: userColumns, data, defaultExpanded }) {
    const tableInstance = useTable(
      {
        columns: userColumns,
        data,
        getSubRows: (row) => row.children,
        initialState: {
          //expanded: { "0": true, "0.0": true, "0.0.0": true, "1": true, "1.0": true, "1.0.0": true, "2": true, "2.0": true, "2.0.0": true }
        }
      },
      useExpanded
    );
    
    const { 
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
     } = tableInstance;

    return (
        <table {...getTableProps()} className="expandable-table">
            <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                            headerGroup.headers.map((column) => 
                                (column.originalId !== "expander_placeholder" && trim(column.Header) !== "") ? (
                                    <th 
                                        {...column.getHeaderProps()}
                                        style={{width: column.width}}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ) : null
                            )
                        }
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {
                    rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} className={row.depth === 0 ? 'category' : ''}>
                            {
                                row.cells.map((cell) => {
                                    let cssClasses = [];
                                    if (cell.row.depth === 0) cssClasses.push('main-heading');
                                    if (cell.row.isExpanded) cssClasses.push('expanded');
                                    if (cell.row.canExpand === true) cssClasses.push('expandable');
                                    if (cell.row.canExpand !== true) cssClasses.push('non-expandable');
                                    if (cell.column.indicateHeirarchy === true) cssClasses.push('indicate-heirarchy');

                                    const precedingIcon = (cell.row.canExpand === true && cell.row.depth !== 0 && cell.column.indicateHeirarchy) ? <UilCornerDownRight size={16} /> : null;
                                    let leftPaddingMultiplier = 0;
                                    if (cell.column.indicateHeirarchy) {
                                        leftPaddingMultiplier = cell.row.canExpand !== true ? 10 : 5;
                                    }

                                    return (
                                        <td 
                                            {...cell.getCellProps()} 
                                            style={{
                                                width: cell.column.width
                                            }}
                                            className={cssClasses.join(" ")}
                                        >
                                            <span style={{
                                                marginLeft: `${cell.row.depth * leftPaddingMultiplier}px`
                                            }}>
                                                {precedingIcon} {cell.render("Cell")}
                                            </span>
                                        </td>
                                    );
                                })
                            }
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    );
  }

  export default ExpandableTable