import React from 'react';
import './BackTrace.scss';
import LogTrace from './../LogTrace/LogTrace';

function BackTrace({data}) {
    if (!data || !data.length) return null;

    return (
        <div className='backtrace-wrapper'>
            <mark>Backtrace</mark>
            <div className='values'>
                { data.map(trace => <LogTrace type="backtrace" data={trace} />) }
            </div>
        </div>
    )
}

export default BackTrace