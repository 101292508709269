import React, {useContext, useEffect, useState} from 'react';
import './UsageInsights.scss';
import WidgetBox from '../../../../Widgets/WidgetBox/WidgetBox';
import AdvancedInsightsContext from './../../../../context/insights-context';
import UsageInsightsToolbar from './UsageInsightsToolbar/UsageInsightsToolbar';
import { fromUnixTime, getUnixTime } from 'date-fns';
import { find, concat, union } from 'lodash';
import Cookies from 'js-cookie';
import StatsPreview from './StatsPreview/StatsPreview';
import UsageTrend from './UsageTrend/UsageTrend';
import NoDataMessage from './../../../../Widgets/NoDataMessage/NoDataMessage';
import { filterUsageData } from '../../../../data-transform/data-transform';

function UsageInsights() {
    const { 
        messages,
        usageInsightsDate,
        setUsageInsightsDate,
        usageInsightsFilter,
        setUsageInsightsFilter,
        usageInsightsSubFilter,
        setUsageInsightsSubFilter,
        authKeysList,
        getAuthKeysList,
        transformedUsageInsightsData,
        usageInsightsDataLoading,
        getUsageInsightsData,
        tzOffset
    } = useContext(AdvancedInsightsContext);

    const usageDataKeys = ["usageData", "totalUsageCreditsUnit", "usageDataByInterval"];

    const defultFilterMenuItems = [{value: "none", label: "none"}];
    const [filterMenuItems, setFilterMenuItems] = useState(defultFilterMenuItems);

    //const defultFilterSubMenuItems = [{value: "all", label: "all"}];
    const defultFilterSubMenuItems = [];
    const [filterSubMenuItems, setSubFilterMenuItems] = useState(defultFilterSubMenuItems);

    const [filteredUsageData, setfFilteredUsageData] = useState(null);

    const [menuLabels, setMenuLabels] = useState({
        admin: {
            [Cookies.get("id")]: "current user"
        },
        authkey: {}
    })

    useEffect(() => {
        if (transformedUsageInsightsData?.allEntitiesDistribution?.length) {
            const menuItems = transformedUsageInsightsData.allEntitiesDistribution.map(e => ({value: e.id, label: e.id}));
            const updatedMenuItems = union(defultFilterMenuItems, menuItems);
            setFilterMenuItems(updatedMenuItems);
            setUsageInsightsFilter(updatedMenuItems[0].value);
            setfFilteredData();
        }
    }, [transformedUsageInsightsData]);

    useEffect(() => {
        if (transformedUsageInsightsData?.allEntitiesDistribution?.length) {
            const entityData = transformedUsageInsightsData.allEntitiesDistribution.find(e => e.id === usageInsightsFilter);
            if (entityData) {
                const menuItems = entityData.entities.map(e => ({value: e.id, label: menuLabels?.[usageInsightsFilter]?.[e.id] || e.id}));
                const updatedMenuItems = union(defultFilterSubMenuItems, menuItems);
                setSubFilterMenuItems(updatedMenuItems);
                setUsageInsightsSubFilter(updatedMenuItems[0].value);
            }
            setfFilteredData();
        }
    }, [usageInsightsFilter]);

    useEffect(() => {
        setfFilteredData();
    }, [usageInsightsSubFilter])

    useEffect(() => {
        if (authKeysList === null) {
            getAuthKeysList();
        }
        else {
            let authKeysData = {};
            authKeysList.forEach(k => {
                authKeysData[k.id] = k.name
            });
            setMenuLabels({
                ...menuLabels,
                authkey: { ...menuLabels.authkey, ...authKeysData }
            })
        }
    }, [authKeysList]);

    useEffect(() => {
        getUsageInsightsData();
    }, [usageInsightsDate]);

    const setfFilteredData = () => {
        setfFilteredUsageData(filterUsageData({
            data: transformedUsageInsightsData, 
            primaryFilter: usageInsightsFilter, 
            secondaryFilter: usageInsightsSubFilter,
            returnKeys: usageDataKeys
        }));
    }

    return (
        <div className='usage-insights-wrapper'>
            <WidgetBox heading={messages.billing_usage.usage_insights.heading}>
                <UsageInsightsToolbar 
                    selectedDate={fromUnixTime(usageInsightsDate)}
                    handleSelectedDateChange={d => setUsageInsightsDate(getUnixTime(d))}
                    filterMenuItems={filterMenuItems}
                    selectedFilter={find(filterMenuItems, {'value': usageInsightsFilter})}
                    handleFilterChange={({value}) => setUsageInsightsFilter(value)}
                    filterSubMenuItems={filterSubMenuItems}
                    selectedSubFilter={find(filterSubMenuItems, {'value': usageInsightsSubFilter})}
                    handleSubFilterChange={({value}) => setUsageInsightsSubFilter(value)}
                    dataLoading={usageInsightsDataLoading}
                    textLabels={messages.billing_usage.usage_insights.toolbar}
                />

                {
                    transformedUsageInsightsData !== null && (
                        <div className='usage-insights-data'>
                            {
                                transformedUsageInsightsData?.totalUsageCreditsUnit > 0 ? (
                                    <React.Fragment>
                                        <StatsPreview 
                                            usageData={filteredUsageData?.usageData}
                                            totalUsage={filteredUsageData?.totalUsageCreditsUnit}
                                            timePeriod={usageInsightsDate}
                                            textLabels={messages.billing_usage.usage_insights}
                                        />

                                        <UsageTrend 
                                            data={filteredUsageData?.usageDataByInterval} 
                                            tzOffset={tzOffset}
                                            textLabels={messages.billing_usage.usage_insights}
                                        />
                                    </React.Fragment>
                                ) : (
                                    <NoDataMessage text="No usage data available for the applied filters and selected month." />
                                )
                            }
                        </div>
                    )
                }
            </WidgetBox>
        </div>
    )
}

export default UsageInsights