import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router';
import { UilPower } from '@iconscout/react-unicons';
import { PrimaryLineButton } from '../Button/Button';
import { logoutCall, redirectUserToESPLogin } from '../../utils/apiCalls';
import CustomToast from '../CustomToast/CustomToast';
import { clearCookies } from '../../utils/commonFunctions';
import Modal from './Modal';
import AdvancedInsightsContext from './../../pages/AdvancedInsights/context/insights-context'

const LogoutModal = ({ showModal, setShowModal, enableESPLogin }) => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);

	const { 
        resetInitialLoadCompleteStatus
    } = useContext(AdvancedInsightsContext);

	const handleLogout = () => {
		if (enableESPLogin) {
			redirectUserToESPLogin("logout")
		}
		else {
			logoutCall().then((resp) => {
				CustomToast.success(
					'Logged out.',
					{
						autoClose: 5000,
					},
					'logout-toast'
				);
				clearCookies();
				resetInitialLoadCompleteStatus();
				window.location.reload();
				return resp;
			});
		}
	};

	return (
		<Modal showModal={showModal} setShowModal={setShowModal} title={null}>
			<div>
				<span
					className='icon-container delete-icon-container mx-auto mb-3'
					id='modal-delete-btn'
				>
					<UilPower size={16} color='#c2303e' title='Logout' />
				</span>
				<p className='title mt-3 text-center'>
					You are about to logout from the dashboard
				</p>
				<p className='text-center mt-2 mb-3'>Are you sure?</p>
			</div>
			<div className='modal--footer d-flex justify-content-end'>
				<PrimaryLineButton
					onClick={() => {
						setLoading(true);
						handleLogout();
					}}
					id='logout-btn'
					loading={loading}
					disabled={loading ? true : false}
				>
					Yes, Logout
				</PrimaryLineButton>
			</div>
		</Modal>
	);
};

export default LogoutModal;
