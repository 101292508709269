import React from 'react';
import './LogTypeTag.scss';
import * as generalConfig from './../../../../../../general-config.json';

function LogTypeTag({type}) {
    if (!type) return null;

    const getTypeLabel = () => type === "warning" ? "WARN" : type.toUpperCase();
    const getBackgroundColor = () => generalConfig.event_types.filter(e => e.id === type)[0].color;

    return (
        <div 
            className={`log-type-tag ${type}`}
            style={{backgroundColor: getBackgroundColor()}}
        >
            {getTypeLabel()}
        </div>
    )
}

export default LogTypeTag;