import React, { useState, useContext, useEffect } from 'react';
import {
	UilTrashAlt,
	UilCheck,
	UilExclamationTriangle,
} from '@iconscout/react-unicons';
import { useHistory } from 'react-router';
import Modal from './Modal';
import { PrimaryLineButton } from '../Button/Button';
import { firmwarePackageContext } from '../../context/FirmwarePackageProvider';
import { deleteFirmwarePackageCall } from '../../utils/diagAPI';
import Notice from '../AppNotices/Notice/Notice';
import { lowerCase } from 'lodash';

const DeletePackageModal = ({ showModal, setShowModal, packageId, sessionExpire }) => {
	const history = useHistory();

	const { packageState, getPackages } = useContext(firmwarePackageContext);

	const { allItems } = packageState;

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState('');

	const handleDeletePackage = () => {
		setLoading(true);
		deleteFirmwarePackageCall(packageId)
        .then((resp) => {
            if (resp.statusCode === 401) {
                sessionExpire();
                return;
            }
            setLoading(false);
            if (lowerCase(resp.status) === 'success') {
                setSuccess(true);
            } else {
                setError(resp.description);
            }
        })
        .catch(() => {
            setError('Something went wrong!');
            setLoading(false);
        })
	};

	const close = () => {
		if (success) {
			history.push('/home/firmware-packages/');
			getPackages();
		}
		setError('');
		setSuccess('');
		setShowModal(false);
	};

	return (
		<Modal showModal={showModal} setShowModal={setShowModal} title={null}>
			{!success && !error && (
				<div>
					<div>
						<span
							className='icon-container delete-icon-container mx-auto mb-3'
							id='modal-delete-btn'
						>
							<UilTrashAlt
								size={16}
								color='#c2303e'
								title='Delete Group'
							/>
						</span>
						<p className='title mt-3 text-center'>
							You are about to delete a Firmware Package.
						</p>
						<p className='text-center mt-2 mb-3'>Are you sure?</p>
						<div className='m-3'>
							<Notice 
								type="warning" 
								text="Please note deleting this package may result in future references not getting resolved. " 
								alignText="center" 
							/>
						</div>
					</div>
				</div>
			)}
			{success && (
				<div className='text-center py-3'>
					<span className='icon-container success-check'>
						<UilCheck color='#fff' size={16} />
					</span>
					<p>Firmware package deleted successfully.</p>
				</div>
			)}
			{error && (
				<div className='text-center py-3'>
					<span className='icon-container delete-icon-container mx-auto mb-3'>
						<UilExclamationTriangle color='#c2303e' size={16} />
					</span>
					<p className='text-error'>{error}</p>
				</div>
			)}

			<div className='modal--footer d-flex justify-content-end'>
				{!success && !error ? (
					<PrimaryLineButton
						onClick={handleDeletePackage}
						id='delete-btn'
						loading={loading}
						disabled={loading ? true : false}
					>
						Yes, Delete
					</PrimaryLineButton>
				) : (
					<PrimaryLineButton onClick={close}>Ok</PrimaryLineButton>
				)}
			</div>
		</Modal>
	);
};

export default DeletePackageModal;
