import React from "react";
import SettingIcon from "../SettingIcon/SettingIcon";
import "./SettingHeader.scss";

function SettingHeader({
  id,
  label,
  startContent,
  endContent,
  variant = "secondary",
}) {
  return (
    <div className={`setting-header-wrapper ${variant}`}>
      {startContent && (
        <div className="start-content-wrapper">{startContent}</div>
      )}
      <div className="setting-icon-wrapper">
        <SettingIcon id={id} />
      </div>
      <label htmlFor={id}>{label}</label>
      <div className="end-content-wrapper">{endContent}</div>
    </div>
  );
}

export default SettingHeader;
