import React, { useState } from 'react';
import './FilterSelector.scss';
import { Dropdown } from 'react-bootstrap';
import AddFilterInterface from './AddFilterInterface/AddFilterInterface';
import { UilPlus } from '@iconscout/react-unicons';

function FilterSelector({
    filtersList, 
    getSuggestions,
    filterSuggestionData,
    addToSelectedFilters,
    selectedFilters,
    messages,
    filtersData
}) {
    const [ show, setShow ] = useState(false);
    const toggleHandler = showState => {
        setShow(showState);
    }
    const componentLabels = messages && messages.filter_selection ? messages.filter_selection : {};

    return (
        <div className="add-filter-container">
            <Dropdown 
                className="add-filter"
                show={show}
                onToggle={toggleHandler}
            >
                <Dropdown.Toggle 
                    id="dropdown-autoclose-false" 
                    className='primary-button primary-dark-button' 
                    disabled={filtersList?.length === 0}
                >
                    <UilPlus size={18} />
                    {messages.filter_selection.label_add_filter}
                    
                </Dropdown.Toggle>
                <Dropdown.Menu className="menu-content" rootCloseEvent={null}>
                    <AddFilterInterface 
                        setShow={setShow}
                        filtersData={filtersData}
                        filtersList={filtersList}
                        getSuggestions={getSuggestions}
                        filterSuggestionData={filterSuggestionData}
                        messages={componentLabels} 
                        addToSelectedFilters={addToSelectedFilters} 
                        selectedFilters={selectedFilters} 
                    />
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default FilterSelector