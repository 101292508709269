import React from 'react';
import './SimpleWidgetBox.scss';

function SimpleWidgetBox(props) {
    return (
        <div className='simple-widget-box-wrapper'>
            <div className='title'>
                {
                    props.titleIcon && (
                        <div className='icon'>{props.titleIcon}</div>
                    )
                }
                <label>{ props.title }</label>
            </div>
            <div className='data'>
                { props.children }
            </div>
        </div>
    )
}

export default SimpleWidgetBox