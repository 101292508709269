import React from 'react';
import Select from 'react-select';
import './SimpleSelect.scss';

function SimpleSelect({
    width,
    icon,
    label,
    options,
    placeholder,
    value,
    onChange,
    isSearchable = true
}) {
    const fontStyles = {
        fontSize: '12px',
        fontWeight: '500',
        color: '#5330b9'
    }
    const customSelectStyles = {
        control: (base, state) => ({
            ...base,
            border: '1px solid transparent',
            width: width,
            padding: '0px',
            minHeight: 'auto',
            ...fontStyles,
            cursor: 'pointer',
            boxShadow: 'none !important',
            '&:hover': {
                borderColor: 'transparent'
            }
        }),
        placeholder: (base, state) => ({
            ...base,
            border: '1px solid transparent',
            ...fontStyles
        }),
        indicatorSeparator: (base, state) => ({
            ...base,
            display: 'none'
        }),
        indicatorsContainer: (base, state) => ({
            ...base,
            display: 'none',
            padding: '0px',
            '& > div': {
                padding: '0px'
            }
        }),
        dropdownIndicator: (base, state) => ({
            ...base
        }),
        option: (base, state) => ({
            ...base,
            ...fontStyles,
            color: '#2c2e37',
			backgroundColor: state.isSelected ? '#edebf8' : '#fff',
            wordBreak: 'break-all',
            '&:hover': {
				backgroundColor: '#edebf8'
			},
        }),
        input: (base, state) => ({
            ...base,
            ...fontStyles,
            padding: '0px',
            '& input': {
                ...fontStyles
            }
        }),
        valueContainer: (base, state) => ({
            ...base,
            ...fontStyles,
            color: '#5330b9',
            padding: '0px',
            '& > div': {
                ...fontStyles,
                borderBottom: '1px solid #5330b9'
            }
        }),
        singleValue: (base, state) => ({
            ...base,
            '&:after': {
                ...fontStyles,
                borderBottom: '1px solid #5330b9'
            }
        })
    }

    return (
        <div className='simple-select-dd'>
            {
                icon ? (
                    <div className='icon'>
                        {icon}
                    </div>
                ) : null
            }
            <label>{label}</label>
            <div className='control'>
                <Select 
                    options={options} 
                    placeholder={placeholder || "Select"}
                    className="simple-select-dd"
                    value={value}
                    styles={customSelectStyles}
                    onChange={onChange}
                    isSearchable={isSearchable}
                />
            </div>
        </div>
    )
}

export default SimpleSelect