import React, { useState } from "react";
import { LinkButton } from "../../../components/Button/Button";
import "./ToggleConnectorStatus.scss";
import moment from "moment";

export default function ToggleConnectorStatus(props) {
  const { confirmation_status, aws_account_id, aws_region, confirm_on, confirmation_err_msg } = props.data;
  const [show, setShow] = useState(false);

  const handleConnectorAuthKeyDisplayData = () => {
    setShow((prev) => !prev);
  };

  return (
    <div className="toggle-token-display">
      <div className="d-flex align-items-bottom mb-2">
        <p className="m-0">{confirmation_status}</p>
        {confirmation_status === "ENABLED" && (
          <LinkButton
            style={{ minWidth: "auto", marginBottom: "0px", marginLeft:"20px", fontSize:"90%" }}
            onClick={handleConnectorAuthKeyDisplayData}
          >
            {show ? "Hide" : "More details"}
          </LinkButton>
        )}
      </div>

      {show && (
        <div className="connector-status-container">
          <table>
            <tbody>
              <tr>
                <td>Connected on</td>
                <td>{moment.unix(confirm_on).format("DD/MM/YY HH:mm:ss ")}</td>
              </tr>
              <tr>
                <td> AWS account</td>
                <td> {aws_account_id}</td>
              </tr>
              <tr>
                <td> AWS region</td>
                <td> {aws_region}</td>
              </tr>
              <tr>
                <td> Connector state</td>
                <td> {confirmation_err_msg}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
