import React from 'react';
import { PrimaryLineButton } from '../../../../../components/Button/Button';

function EventButton({
    id,
    clickHandler,
    selected,
    disabled,
    label
}) {
  return (
    <PrimaryLineButton 
        onClick={clickHandler}
        key={id}
        id={id}
        className={selected ? "selected" : ""}
        disabled={disabled}
    >
        {label}
    </PrimaryLineButton>
  )
}

export default EventButton