import React from 'react';

const FilterPanel = (props) => {
	let { metadata, userSelection, updateUserSelection } = props;
	const handleUserSelection = (element) => {
		updateUserSelection(element.id);
	};
	return (
		<div className='card-settings-panel'>
			<div className='d-flex flex-wrap nestedlist-wrapper'>
				{metadata.map((item) => {
					return (
						<List
							key={item.label}
							data={item}
							handleUserSelection={handleUserSelection}
							selectedFilters={userSelection}
						/>
					);
				})}
			</div>
		</div>
	);
};

function List({ data, handleUserSelection, selectedFilters }) {

	const nestedSelection = (data) => {
		if(data?.children.length == 0 ) return ;
		data?.children.forEach( metric => {
			metric.type === "file" ? handleUserSelection(metric) : nestedSelection(metric) ;
		});
	}

	return (
		<ul className='nestedlist-ul mb-2 mr-4'>
			{data.type === 'folder' ? (
				<li>
					<p className='mb-0 nestedlist-btn cursor-pointer' onClick={()=>nestedSelection(data)}>{data.label}</p>
					<div
						className='pl-2 ml-2'
						style={{
							borderLeft: '1px solid #e3ebf6',
						}}
					>
						{data.children &&
							data.children.length > 0 &&
							data.children.map((child) => (
								<List
									data={child}
									key={child.label}
									handleUserSelection={handleUserSelection}
									selectedFilters={selectedFilters}
								/>
							))}
					</div>
				</li>
			) : (
				<li>
					<p
						onClick={() => handleUserSelection(data)}
						className='d-flex mb-0 cursor-pointer'
					>
						<span
							className={`${
								selectedFilters[data.id]
									? 'check checked'
									: 'check'
							}`}
						/>
						{data.label}
					</p>
				</li>
			)}
		</ul>
	);
}

export default FilterPanel;
