import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import CustomTooltip from '../../../components/CustomTooltip/CustomTooltip';
import './NavigationTabs.scss';
function NavigationTabs({
  data,
  selectedTab,
  onTabChange
}) {
  const handleTabChange = tabId => {
    if (tabId === selectedTab) {
      window.scrollTo(0, 0);
    }
    else {
      onTabChange(tabId);
    }
  }
  return (
    <div className='tabs-container'>
      <ButtonGroup className='tabs-group'>
        {
          data.map(({id, label, tabIcon, disabled = false}) => (
            <CustomTooltip id={`nav-tab-${id}`} title={label} key={id}>
              <Button 
                id={id} 
                className={id === selectedTab ? "selected" : ""}
                onClick={()=> handleTabChange(id)}
                disabled={disabled}
              >
                { tabIcon } { label }
              </Button>
            </CustomTooltip>
          ))
        }
      </ButtonGroup>
    </div>
  )
}

export default NavigationTabs