import Cookies from 'js-cookie';
import { getNodeIdentifierFromNodeData } from '../pages/Insights/insightsUtilityFunctions';
import { DIAG_API } from './constants';
import { saveAs } from 'file-saver';

var d = new Date();
var offset = d.getTimezoneOffset() * -60;

export const getEventCounts = async (node, time, start) => {
	const { nodeIdentifierKey, nodeIdentifierValue } = getNodeIdentifierFromNodeData(node);

	let api = start
		? `${DIAG_API}/node/log/count?${nodeIdentifierKey}=${nodeIdentifierValue}&time=${time}&start=${start}&tz=${offset}`
		: `${DIAG_API}/node/log/count?${nodeIdentifierKey}=${nodeIdentifierValue}&time=${time}&tz=${offset}`;
	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});

	return res.json();
};

export const getNodeInfo = async (node) => {
	const { nodeIdentifierKey, nodeIdentifierValue } = getNodeIdentifierFromNodeData(node);

	let res = await fetch(`${DIAG_API}/node/info?${nodeIdentifierKey}=${nodeIdentifierValue}`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});
	return res.json();
};

export const getLogByInterval = async (node, time, start) => {
	const { nodeIdentifierKey, nodeIdentifierValue } = getNodeIdentifierFromNodeData(node);

	let api = start
		? `${DIAG_API}/node/log/byinterval/count?${nodeIdentifierKey}=${nodeIdentifierValue}&time=${time}&start=${start}&tz=${offset}`
		: `${DIAG_API}/node/log/byinterval/count?${nodeIdentifierKey}=${nodeIdentifierValue}&time=${time}&tz=${offset}`;
	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});

	return res.json();
};

export const getNode1LogByInterval = async (node, start, end, interval) => {
	const { nodeIdentifierKey, nodeIdentifierValue } = getNodeIdentifierFromNodeData(node);

	let api = `${DIAG_API}/node1/log/byinterval/count?${nodeIdentifierKey}=${nodeIdentifierValue}&start=${start}&end=${end}&tz=${offset}&interval=${interval}`
	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});

	return res.json();
};

export const getLogsList = async (node, time, start, pagetoken = '') => {
	let api = start
		? pagetoken
			? `${DIAG_API}/node/log/list?node_id=${node}&time=${time}&start=${start}&tz=${offset}&page_token=${pagetoken}`
			: `${DIAG_API}/node/log/list?node_id=${node}&time=${time}&start=${start}&tz=${offset}`
		: `${DIAG_API}/node/log/list?node_id=${node}&time=${time}&tz=${offset}`;

	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});
	return res.json();
};

export const getNodeLogsList = async (node, start, end, pagetoken = '', filters = []) => {
	const { nodeIdentifierKey, nodeIdentifierValue } = getNodeIdentifierFromNodeData(node);

	let api = `${DIAG_API}/node1/log/list?${nodeIdentifierKey}=${nodeIdentifierValue}&start=${start}&end=${end}`;

	if (pagetoken) api += `&page_token=${pagetoken}`;
	if (filters.length) api += filters.reduce((prev, curr) => `${prev}&efilter=${curr}`, "");
	
	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});
	return res.json();
};

export const getMetricsMetadata = async (node) => {
	const { nodeIdentifierKey, nodeIdentifierValue } = getNodeIdentifierFromNodeData(node);

	let api = `${DIAG_API}/node/metric/meta?${nodeIdentifierKey}=${nodeIdentifierValue}`;

	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});
	return res.json();
};

export const getMetricsdata = async (node, metricType, end, start, pagetoken = "") => {
	const { nodeIdentifierKey, nodeIdentifierValue } = getNodeIdentifierFromNodeData(node);

	let api = new URL(
		`${DIAG_API}/node/metric/list?${nodeIdentifierKey}=${nodeIdentifierValue}&start=${start}&end=${end}&limit=500&page_token=${pagetoken}`
	);

	if (typeof metricType === 'object') {
		metricType.map((item) => {
			api.searchParams.append('id', item.id);
		});
	} else {
		api.searchParams.append('id', metricType);
	}

	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});
	return res.json();
};

export const getParamValue = async (node, metricType, end, start) => {
	let url = new URL(
		`${DIAG_API}/node/metric/list?node_id=${node}&start=${start}&end=${end}&limit=150`
	);
	metricType.map((item) => {
		url.searchParams.append('id', item);
	});
	let res = await fetch(url, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});
	return res.json();
};

export const getParamsMetadata = async (node) => {
	const { nodeIdentifierKey, nodeIdentifierValue } = getNodeIdentifierFromNodeData(node);

	let api = `${DIAG_API}/node/param/meta?${nodeIdentifierKey}=${nodeIdentifierValue}`;

	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});
	return res.json();
};

export const getParamsdata = async (node, metricType, end, start) => {
	const { nodeIdentifierKey, nodeIdentifierValue } = getNodeIdentifierFromNodeData(node);
	
	let url = new URL(
		`${DIAG_API}/node/param/list?${nodeIdentifierKey}=${nodeIdentifierValue}&start=${start}&end=${end}&limit=150`
	);
	metricType.map((item) => {
		url.searchParams.append('id', item);
	});
	let res = await fetch(url, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});
	return res.json();
};

// -------------- FIRMWARE PACKAGES ----------------
export const getFirmwarePackages = async (pagetoken, limit, packageName) => {
	let api = `${DIAG_API}/user/package/list?`;
	
	if (packageName) api += `package_name_search_key=${packageName}&`; 
	if (limit) api += `limit=${limit}&`; 
	if (pagetoken) api += `page_token=${pagetoken}`; 

	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});

	return res.json();
};

export const getFirmwarePackageDetails = async (packageId) => {
	let api = `${DIAG_API}/user/package?package_id=${packageId}`; 

	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});

	return res.json();
};

export const getUploadedPackagePresignedURLCall = async (fileName, packageSize) => {
	let api = `${DIAG_API}/user/package/uploadurl?file_name=${fileName}&package_size=${packageSize}`; 

	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});

	return res.json();
};

export const uploadFirmwarePackageURLCall = async (url, data) => {
	let res = await fetch(url, {
		method: 'put',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: data,
	});
	
	return res;
};

export const uploadOtaFirmwarePackageCall = async (packageDetails, name) => {
	let res = await fetch(`${DIAG_API}/user/package`, {
		method: 'post',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
		body: JSON.stringify({
			temp_package: name,
			package_name: packageDetails.package_name,
			fw_version: packageDetails.fw_version,
			type: packageDetails.type,
			model: packageDetails.model,
		}),
	});
	return res.json();
};

export const deleteFirmwarePackageCall = async (packageId) => {
	let api = `${DIAG_API}/user/package?package_id=${packageId}`; 

	let res = await fetch(api, {
		method: 'delete',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});

	return res.json();
}

export const getInsightsNodesList = async (limit = 10, pagetoken = "", searchKey = "", interfaceKey = "") => {
    let res = await fetch(`${DIAG_API}/user/node/list?limit=${limit}&page_token=${encodeURIComponent(pagetoken)}&search_key=${searchKey}&interface_key=${interfaceKey}`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});
	return res.json();
}

export const getRainmakerNodesList = async (limit = 10, pagetoken = "") => {
	const RAINMAKER_API = window.BASE_URL;
    let res = await fetch(`${RAINMAKER_API}/admin/nodes?num_records=${limit}&start_id=${encodeURIComponent(pagetoken)}`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});
	return res.json();
}

export const getNodeStatus = async (node_id) => {
	const RAINMAKER_API = window.BASE_URL;
    let res = await fetch(`${RAINMAKER_API}/admin/nodes?node_id=${node_id}`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			//...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});
	return res.json();
}

export const getDownloadPackageUrl = async (packageId) => {
	let res = await fetch(`${DIAG_API}/user/package/download?package_id=${packageId}`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		},
	});
	return res.json();
}

export const downloadPackage = async (url) => {
    let res = await fetch(url, {
		method: 'post',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })
		}
	});

    const blob = await res.blob();
    const newBlob = new Blob([blob]);
  
    saveAs(newBlob, "test.zip");
}

export const sendCommandToDevice = async (data) => {
  const RAINMAKER_API = window.BASE_URL;
  try {
    let res = await fetch(`${RAINMAKER_API}/admin/nodes/cmd`, {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        Authorization: Cookies.get("ATK"),
      },
    });
    return res.json();
  } catch (error) {
    return { status: "failure" };
  }
};

export const getCommandRequestStatus = async (requestId) => {
  const RAINMAKER_API = window.BASE_URL;
  try {
    let res = await fetch(`${RAINMAKER_API}/admin/nodes/cmd?request_id=${requestId}`, {
      method: "get",
      headers: {
        Authorization: Cookies.get("ATK"),
      },
    });
    return res.json();
  } catch (error) {
    return { status: "failure" };
  }
};