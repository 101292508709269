import React, { useEffect, useContext } from 'react';
import { ListGroup } from 'react-bootstrap';
import AdvancedInsightsContext from '../../../context/insights-context';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import './TopNodes.scss';
import NoDataAvailable from '../../WidgetWrapper/NoDataAvailable/NoDataAvailable';
import Loader from '../../../../../components/Loader/Loader';
import { some } from 'lodash';
import TopNode from './TopNode/TopNode';

function TopNodes() {
    const { 
        getTopNodes,
        selectedFilters,
        startTimestamp,
        endTimestamp,
        topNodesDistributionData,
        initialLoadCompleteStatus,
		resetTopNodesDistributionData,
		topNodesDistributionDataResetStatus,
		messages,
		onlineTopNodesData,
		getTopNodesOnlineStatus,
    } = useContext(AdvancedInsightsContext);

    useEffect(() => {
        if ((topNodesDistributionData === null && initialLoadCompleteStatus === true) || topNodesDistributionDataResetStatus === true) {
            getTopNodes();
        }

		return () => resetTopNodesDistributionData();
    }, [])

    useDidMountEffect(() => {
        	getTopNodes();
    }, [startTimestamp, endTimestamp, selectedFilters]);

	useEffect(() => {
		topNodesDistributionData?.length && getTopNodesOnlineStatus();
	}, [topNodesDistributionData]);

	const hashValues = {
		start: startTimestamp,
		end: endTimestamp
	}

	const isNodeOnline = nodeId => some(onlineTopNodesData, {"Node.ID": nodeId});

	if (topNodesDistributionData === null && initialLoadCompleteStatus === true) return <Loader />
	if (topNodesDistributionData === null || topNodesDistributionData?.length === 0) return <NoDataAvailable />;

    return (
		<div className='nodes-list'>
			<ListGroup variant="flush">
				<ListGroup.Item className='item-row header'>
					<div className='box d-flex'>
						<div className='node item icon'></div>
						<div className='text item'>{messages.widgets.top_nodes.column_node_id}</div>
						<div className='count item'>{messages.widgets.top_nodes.column_count}</div>
						<div className='actions item'></div>
					</div>
				</ListGroup.Item>
				{
					topNodesDistributionData.map(item => (
						<TopNode 
							nodeData={item} 
							key={item.node_key} 
							online={isNodeOnline(item.node_id)} 
							hashValues={hashValues}
						/>
					))
				}
			</ListGroup>
		</div>
    )
}

export default TopNodes