import React from 'react';
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import routes from '../Routes';
import { RouteWithSubRoutes } from '../../utils/commonFunctions';
import InsightsStore from '../AdvancedInsights/context/InsightsStore';
import TenantViewInfoBar from '../AdvancedInsights/ManageMultiAdmins/TenantViewInfoBar/TenantViewInfoBar';
import Cookies from 'js-cookie';

//init
moment.updateLocale('en', {
	calendar: {
		lastDay: '[Yesterday, at] LT',
		sameDay: '[Today, at] LT',
		nextDay: '[Tomorrow, at] LT',
		lastWeek: 'DD-MMM-YYYY',
		nextWeek: 'DD-MMM-YYYY',
		sameElse: 'DD-MMM-YYYY',
	},
});

const App = () => {
	let isCustomer = window.CUSTOMER_DEPLOYMENT;
	const tenantView = !!(Cookies.get("TATK") && Cookies.get("ta_id"));

	return (
		<div className={`h-100 ${tenantView && "tenant-view"}`}>
			<TenantViewInfoBar 
				show={tenantView} 
				customClassName="tenant-view-info-bar-wrapper"
			/>
			<InsightsStore>
			<ToastContainer />
			<Router basename={process.env.PUBLIC_URL}>
				<Switch>
					{isCustomer && (
						<Route
							exact
							path='/sign-up'
							render={() => <Redirect to='login' />}
						/>
					)}
					{routes.map((route) => {
						return (
							<RouteWithSubRoutes key={route.key} {...route} />
						);
					})}

					<Route
						exact
						path='/'
						render={() => <Redirect to='login' />}
					/>
				</Switch>
			</Router>
			</InsightsStore>
		</div>
	);
};

export default App;
