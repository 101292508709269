import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './NodeReport.scss';
import PrimaryWidgets from './PrimaryWidgets/PrimaryWidgets';
import SideWidgets from './SideWidgets/SideWidgets';

function NodeReport() {
  return (
    <div className='node-report'>
      <Container fluid>
        <Row>
          <Col xl={8} lg={7} md={12} sm={12} xs={12}>
            <PrimaryWidgets />
          </Col>
          <Col xl={4} lg={5} md={12} sm={12} xs={12}>
            <SideWidgets />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default NodeReport