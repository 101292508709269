import React from 'react';

const Loader = (props) => {
	const { minHeight, minWidth, colorClass = 'text-link' } = props;
	return (
		<div
			className='d-flex w-100 justify-content-center align-items-center'
			style={{ minHeight: minHeight, minWidth: minWidth }}
		>
			<div className={`spinner-border ${colorClass}`} role='status'>
				<span className='sr-only'>Loading...</span>
			</div>
		</div>
	);
};

export const InlineLoader = (props) => {
	const { colorClass = 'text-link', className = '' } = props;
	return (
		<div
			className={`spinner-border ${colorClass} ${className}`}
			role='status'
		>
			<span className='sr-only'>Loading...</span>
		</div>
	);
};

export default Loader;
