import React, {useEffect, useContext, useState} from 'react';
import LogTimeDetails from './LogTimeDetails/LogTimeDetails';
import LogTypeTag from './LogTypeTag/LogTypeTag';
import './NodeLog.scss';
import CustomTooltip from './../../../../../../../components/CustomTooltip/CustomTooltip';
import { UilArrowGrowth, UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import LogDetails from './LogDetails/LogDetails';
import moment from 'moment';
import { generateHashURL, utcTimeStampToLocalTimeStamp } from '../../../../../../../utils/commonFunctions';

function NodeLog({log, expandAllLogs, nodeData}) {
    const logUniqId = `${log.Message.replace(/\s/g, "").toLowerCase()}-${log.Timestamp}`;
    const [showDetails, setShowDetails] = useState(expandAllLogs || false);
    
    const handleExpand = e => {
        e.stopPropagation();
        setShowDetails(showDetails => !showDetails);
    }

    useEffect(() => {
        setShowDetails(expandAllLogs);
    }, [expandAllLogs])

    if (!log || !nodeData) return null;

    const viewLogMetricsStartTimestamp = moment(log.Timestamp).subtract(30, 'minutes').unix();
    const viewLogMetricsEndTimestamp = moment(log.Timestamp).add(30, 'minutes').unix();
    const viewLogMetricsHashURL = generateHashURL(`/home/nodes/${nodeData.node_id}/?key=${nodeData.node_key}`, {
        mstart: viewLogMetricsStartTimestamp,
        mend: viewLogMetricsEndTimestamp,
        view: "metrics"
    });


    return (
        <div className={`node-log-wrapper ${showDetails ? 'detailed-view' : ''}`}>
            <div className='preview' onClick={handleExpand}>
                <div className='tag'>
                    <LogTypeTag type={log.Type} />
                </div>
                <div className='ts'>
                    <LogTimeDetails 
                        ts={log.Timestamp} 
                        tsSource={log.TsSource} 
                    />
                </div>
                <div className='msg'>
                    {log.Message}
                </div>
                <div className='actions'>
                    
                    <CustomTooltip id={`view-metric-${logUniqId}`} title="View this event in Metrics">
                        <a href={viewLogMetricsHashURL} target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()}>
                            <UilArrowGrowth size={18} />
                        </a>
                    </CustomTooltip>
                    
                    <CustomTooltip id={`view-details-${logUniqId}`} title="View log details">
                        <button onClick={handleExpand}>
                            { showDetails ? <UilAngleUp size={24} /> : <UilAngleDown size={24} /> }
                        </button>
                    </CustomTooltip>
                </div>
            </div>
            {
                showDetails ? (
                    <div className='details'>
                        <LogDetails data={log} />
                    </div>
                ) : null
            }
        </div>
    )
}

export default NodeLog