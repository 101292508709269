import React from 'react';
import './PulseDot.scss';

function PulseDot() {
  return (
    <div className="dot-container">
        <div className="ring"></div>
        <div className="circle"></div>
    </div>
  )
}

export default PulseDot