import React, { useState, useEffect, useContext, useReducer } from 'react';
import './UserAccounts.scss';
import advancedInsightsContext from '../../../../context/insights-context';
import NoDataMessage from '../../../../Widgets/NoDataMessage/NoDataMessage';
import CustomToast from '../../../../../../components/CustomToast/CustomToast';
import WidgetExpandableListItem from '../../../../Widgets/WidgetExpandableListItem/WidgetExpandableListItem';
import { format, fromUnixTime } from 'date-fns';
import { deleteTenantsAccount } from '../../../../../../utils/diagQueryAPI';
import ConfirmReleaseAccess from './ConfirmReleaseAccess/ConfirmReleaseAccess';
import ConfirmAccessAccount from './ConfirmAccessAccount/ConfirmAccessAccount';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import usePagination from '../../../../hooks/usePagination';

function UserAccounts() {
    const { 
        messages,
        accountsList,
        accountsListLoading,
        getAccountsList
    } = useContext(advancedInsightsContext);

    const componentInitialLocalState = {
        userActions: {},
        showConfirmReleaseAccessModal: false,
        showConfirmAccessModal: false,
        selectedAccountId: null,
        nextPagetoken: ""
    }

    const [componentLocalState, updateComponentLocalState] = useReducer((state, newState) => ({
        ...state, ...newState
    }), componentInitialLocalState);

    const { pagetoken, Pagination } = usePagination(componentLocalState.nextPagetoken);

    useEffect(() => {
        getAccountsList(pagetoken);
    }, [pagetoken]);

    useEffect(() => {
        updateComponentLocalState({
            nextPagetoken: accountsList?.page_token || ""
        });
    }, [accountsList]);

    const userAccountsList = (accountsList && accountsList.list) ? accountsList.list.filter(u => u.account_id) : [];

    const confirmReleaseAccess = (e, account_id) => {
        e.stopPropagation();
        updateComponentLocalState({
            selectedAccountId: account_id,
            showConfirmReleaseAccessModal: true
        });
    }
    
    const confirmAccessAccount = (e, account_id) => {
        e.stopPropagation();
        updateComponentLocalState({
            selectedAccountId: account_id,
            showConfirmAccessModal: true
        });
    }

    const handleReleaseAccess = async (account_id) => {
        const releaseAccessResponse = await deleteTenantsAccount({account_id});
        if (!releaseAccessResponse?.status || (releaseAccessResponse?.status !== 200) || (releaseAccessResponse?.data?.status === "failure")) {
            CustomToast.error('Error revoking access of this account. Please try again!', { autoClose: 5000 }, 'release-access-toast');
            return;
        }
        
        updateComponentLocalState({
            userActions: { ...componentLocalState.userActions, [account_id]: false },
            showConfirmReleaseAccessModal: false
        });
    }

    if (accountsListLoading) return <LoadingSpinner wrapperClassName='user-accounts-wrapper' />
    
    return (
        <div className='user-accounts-wrapper'>
            <div className='accounts-list'>
                {
                    (accountsList && accountsList.list) ? (
                        <>
                            {
                                userAccountsList.map(userAccount => (
                                    <WidgetExpandableListItem 
                                        key={userAccount.account_id}
                                        title={userAccount.account_email_id}
                                        text={userAccount.role}
                                        tip={`Created at: ${format(fromUnixTime(userAccount.created_at), "dd/MM/yy")}`}
                                        acceptActionText={"Access account"}
                                        acceptActionHandler={e => confirmAccessAccount(e, userAccount.account_id)}
                                        rejectActionText={"Release access"}
                                        rejectActionHandler={e => confirmReleaseAccess(e, userAccount.account_id)}
                                        acceptAction={
                                            componentLocalState.userActions.hasOwnProperty(userAccount.account_id) ? 
                                            componentLocalState.userActions[userAccount.account_id] : 
                                            null
                                        }
                                        acceptActionInfoMessage={
                                            componentLocalState.userActions[userAccount.account_id] ? 
                                            "" : 
                                            "Access released"
                                        }
                                        detailsData={userAccount}
                                    />
                                ))
                            }
                        </>
                    ) : (
                        <NoDataMessage text="You don't have access to anyone's account" />
                    )
                }
            </div>

            <Pagination />
            
            <ConfirmReleaseAccess 
                showModal={componentLocalState.showConfirmReleaseAccessModal} 
                setShowModal={() => updateComponentLocalState({showConfirmReleaseAccessModal: !componentLocalState.showConfirmReleaseAccessModal})} 
                onConfirm={() => handleReleaseAccess(componentLocalState.selectedAccountId)}
            />

            <ConfirmAccessAccount 
                showModal={componentLocalState.showConfirmAccessModal} 
                setShowModal={() => updateComponentLocalState({showConfirmAccessModal: !componentLocalState.showConfirmAccessModal})} 
                accountDetails={userAccountsList.find(u => u.account_id === componentLocalState.selectedAccountId)}
            />

        </div>
    )
}

export default UserAccounts