import React, { createContext, useReducer } from 'react';
import { getFirmwareImages } from '../utils/api';
import { COMMON_API_ACTIONS as ACTIONS } from '../utils/constants';

export const firmwareImageContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.CALL_API:
			return {
				...state,
				loading: true,
				error: '',
			};
		case ACTIONS.RESOLVE: {
			let newState;
			if (action.searchedItem) {
				newState = {
					...state,
					loading: false,
					error: '',
					searchedItem: action.searchedItem,
				};
			} else {
				newState = {
					...state,
					loading: false,
					error: '',
					response: action.response,
					allItems: action.allItems,
					totalPages: action.totalPages,
					searchedItem: null,
				};
			}
			return newState;
		}
		case ACTIONS.REJECT:
			return {
				...state,
				loading: false,
				error: action.error,
			};
		case ACTIONS.RESET_SEARCH:
			return {
				...state,
				searchedItem: null,
				error: '',
			};
		case ACTIONS.NEXT:
			return {
				...state,
				allItems: [],
				prevKey: state.prevKey.add(state.response.next_id),
				pageNo: state.pageNo + 1,
				nextKey: state.response.next_id,
			};
		case ACTIONS.PREV: {
			let newPrevKey = [...state.prevKey];
			newPrevKey.pop();
			return {
				...state,
				allItems: [],
				prevKey: new Set(newPrevKey),
				pageNo: state.pageNo - 1,
				nextKey: [...state.prevKey][state.pageNo - 2],
			};
		}
		case ACTIONS.PAGE_SIZE: {
			return {
				...state,
				allItems: [],
				nextKey: '',
				pageNo: 1,
				pageSize: action.pageSize,
			};
		}
		case ACTIONS.RESET:
			return initialState;
		default:
			return state;
	}
};

const initialState = {
	loading: false,
	error: '',
	response: {},
	allItems: [],
	searchedItem: null,
	totalPages: 0,
	pageSize: 10,
	prevKey: new Set(['']),
	nextKey: '',
	pageNo: 1,
};

const FirmwareImageProvider = (props) => {
	const [state, setState] = useReducer(reducer, initialState);

	const handleGetItems = async (image, pagesize = null) => {
		setState({ type: ACTIONS.CALL_API });
		try {
			let response = await getFirmwareImages(image, pagesize);
			let data = response.data;
			let state;
			if (data.ota_images) {
				if (Number.isInteger(pagesize) || !isNaN(parseInt(pagesize))) {
					state = {
						type: ACTIONS.RESOLVE,
						response: data,
						allItems: data.ota_images.sort(function (a, b) {
							return a.upload_timestamp > b.upload_timestamp
								? -1
								: b.upload_timestamp > a.upload_timestamp
								? 1
								: 0;
						}),
						searchedItem: null,
						contextPageSize: pagesize,
						totalPages: data.total
							? Math.ceil(data.total / pagesize)
							: 1,
					};
				} else {
					state = {
						type: ACTIONS.RESOLVE,
						response: data,
						searchedItem: data.ota_images.sort(function (a, b) {
							return a.upload_timestamp > b.upload_timestamp
								? -1
								: b.upload_timestamp > a.upload_timestamp
								? 1
								: 0;
						}),
					};
				}
			} else {
				state = {
					type: ACTIONS.RESOLVE,
					response: data,
					searchedItem: [data],
				};
			}
			setState(state);
		} catch (error) {
			let errorText;
			if (error.response) {
				errorText = error.response.data.description;
				if (error.response.status === 404) {
					errorText = '';
				}
			}
			if (error.response.status === 401) {
				errorText = 401;
			}
			setState({
				type: ACTIONS.REJECT,
				error: errorText,
			});
		}
	};

	const handleReset = () => {
		setState({
			type: ACTIONS.RESET_SEARCH,
		});
	};

	const handleNext = () => {
		setState({
			type: ACTIONS.NEXT,
		});
	};

	const handlePrev = () => {
		setState({
			type: ACTIONS.PREV,
		});
	};

	const handlePageSizeChange = (newPageSize) => {
		setState({
			type: ACTIONS.PAGE_SIZE,
			pageSize: newPageSize,
		});
	};

	const handleChangeImages = (updatedImages) => {
		setState({
			type: ACTIONS.RESOLVE,
			allItems: updatedImages,
		});
	};

	return (
		<firmwareImageContext.Provider
			value={{
				imageState: state,
				getImages: handleGetItems,
				searchedImageReset: handleReset,
				next: handleNext,
				prev: handlePrev,
				changePageSize: handlePageSizeChange,
				updateImageList: handleChangeImages,
			}}
		>
			{props.children}
		</firmwareImageContext.Provider>
	);
};

export default FirmwareImageProvider;
