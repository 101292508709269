import React, { useState } from 'react';
import { UilEye, UilEyeSlash } from '@iconscout/react-unicons';
const Password = (props) => {
	const {
		id,
		placeholder,
		hasError,
		className,
		value,
		...inputProps
	} = props;

	const [hidden, setHidden] = useState(true);

	return (
		<div
			className={`${hasError ? 'has-error' : ''}`}
			style={{ position: 'relative', width: '100%' }}
		>
			<input
				type={hidden ? 'password' : 'text'}
				className={`input ${className}`}
				id={id}
				placeholder={placeholder}
				value={value}
				{...inputProps}
			/>
			<span
				onClick={() => {
					setHidden((hidden) => !hidden);
				}}
				className='btn show-hide-psw'
				id='show-hide-password'
			>
				{hidden ? (
					<UilEyeSlash size={16} color='#aaa' title='Show' />
				) : (
					<UilEye size={16} color='#aaa' title='Show' />
				)}
			</span>
		</div>
	);
};

export default Password;
