import React, { useState } from 'react';
import { UilTrashAlt, UilCloudDownload, UilCopy } from '@iconscout/react-unicons';
import { getConnectorConfigUrlByID, deleteConnectorAuthKey } from '../../../utils/manageAuthKeysAPI';
import CustomToast from '../../../components/CustomToast/CustomToast';
import CustomTooltip from '../../../components/CustomTooltip/CustomTooltip'
import DeleteAuthKeyModal from "../../../components/CustomModal/DeleteAuthKeyModal"
import { ButtonGroup, Button } from 'react-bootstrap';
import "./KeyActions.scss"
export default function KeyActions(props) {
    const { data, onDelete, loading } = props;
    const { id, name } = data;

    const [copyUrlLoading, setCopyUrlLoading] = useState(false)
    const [downloadAuthKeyConfigFileLoading, setDownloadAuthKeyConfigFileLoading] = useState(false)
    const [deleteWarning, setDeleteWarning] = useState(false)

    const getConfigUrl = async () => {
        const {status, data} = await getConnectorConfigUrlByID(id);
        if(status === 200)
            return data.presigned_url
    }

    const copyUrl = (configUrl) => {
        // to do - use fallback
        if (navigator.clipboard) {
            navigator.clipboard.writeText(configUrl).then(function() {
                CustomToast.success(
                    'Url copied to clipboard',
                    {
                        autoClose: 2000,
                    },
                    'copy-url-success'
                );
            }, function(err) {
                CustomToast.error(
                    'Error copying Url to clipboard',
                    {
                        autoClose: 2000,
                    },
                    'copy-url-error'
                );
            });
        }
    }

    const handleConfigUrlCopy = async () => {
        setCopyUrlLoading(true);
        const preSignedUrl =  await getConfigUrl();
        copyUrl(preSignedUrl)
        setCopyUrlLoading(false);
    }

    const downloadConfigFile = async ( ) => {
        setDownloadAuthKeyConfigFileLoading(true)
        const configUrl = await getConfigUrl()
        window.open(configUrl,"_blank")
        setDownloadAuthKeyConfigFileLoading(false)
    }

    const handleDeleteConnectorAuthKey = async (id) => {
        setDeleteWarning(true);
        const response = await deleteConnectorAuthKey(id);
        if (response) {
            onDelete(id)
          setDeleteWarning(false);
        }
    };
    return (
        <div className="d-flex connector-key-actions">
            <ButtonGroup aria-label="Basic example" className='ml-auto'>
                {/* copy button  */}
                <Button className="primary-button border" disabled={copyUrlLoading} onClick={handleConfigUrlCopy}>
                    <CustomTooltip key={"connector-copy-btn"} id={"connector-copy-btn"} title={"Copy configuration url"}>
                        <UilCopy size={20} />
                    </CustomTooltip>
                </Button>
                {/* Download button */}
                <Button className="primary-button border" disabled={downloadAuthKeyConfigFileLoading} onClick={downloadConfigFile}>
                    <CustomTooltip key={"connector-download-btn"} id={"connector-copdownload-btn"} title={"Download configuration file"}>
                        <UilCloudDownload size={20} />
                    </CustomTooltip>
                </Button>
                {/* Delete Button */}
                <Button className="primary-button  error-button-small border" disabled={deleteWarning} onClick={() => setDeleteWarning(true)}>
                    <CustomTooltip key={"connector-delete-btn"} id={"connector-delete-btn"} title={"Delete Connector auth key"}> 
                        <UilTrashAlt size={20} />
                    </CustomTooltip>
                </Button>     
            </ButtonGroup>
            {
               <DeleteAuthKeyModal showModal={deleteWarning} setShowModal={setDeleteWarning} authKeyData = {{name}} onConfirm={()=>handleDeleteConnectorAuthKey(id)}/>
            }
        </div>
    )
}
