import React, {useReducer} from 'react';
import InfoMessageWrapper from '../../../../../../Widgets/InfoMessageWrapper/InfoMessageWrapper';
import { Input } from '../../../../../../../../components/Input/Input';
import { PrimaryDarkButton } from '../../../../../../../../components/Button/Button';

function InviteUserForm({
    onSubmit, loading
}) {
    const componentInitialLocalState = {
        email: "",
        message: "",
        error: ""
    }

    const [componentLocalState, updateComponentLocalState] = useReducer((state, newState) => ({
        ...state, ...newState
    }), componentInitialLocalState);

    const handleInputChange = e => {
        updateComponentLocalState({[e.target.name]: e.target.value})
    }

    const handleOnSubmit = () => {
        if (!componentLocalState.email) {
            updateComponentLocalState({error: "Please provide a valid email address."});
            return;
        }
        if (!componentLocalState.message) {
            updateComponentLocalState({error: "Please provide some message with the invite."});
            return;
        }

        updateComponentLocalState({error: ""});

        onSubmit({
            email: componentLocalState.email,
            message: componentLocalState.message
        });
    }

    return (
        <div className='invite-user-form-wrapper'>
            <div className='invite-new-user-wrapper'>
                {
                    componentLocalState.error && (
                        <div className='pl-3 pr-3 pt-3'>
                            <InfoMessageWrapper type="error">{componentLocalState.error}</InfoMessageWrapper>
                        </div>
                    )
                }
                <div className='p-3'>
                    <div className='w-75 mb-3'>
                        <Input
                            type='email'
                            name='email'
                            placeholder='Enter email address of the user'
                            label='Email Address'
                            onChange={(e) => handleInputChange(e)}
                            maxLength='128'
                            required={true}
                            disabled={loading}
                        />
                    </div>
                    <div className='w-75 mb-3'>
                        <label>Message</label>
                        <textarea
                            className='input'
                            name='message'
                            placeholder='e.g. Hi, I invite you to have limited access to my account'
                            onChange={(e) => handleInputChange(e)}
                            maxLength='256'
                            disabled={loading}
                            required={true}
                        />
                    </div>
                    <div className='modal--footer d-flex justify-content-end'>
                        <PrimaryDarkButton 
                            id='send-button'
                            onClick={handleOnSubmit}
                            loading={loading}
                        >
                            Send Invite
                        </PrimaryDarkButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InviteUserForm