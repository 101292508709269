import React, { useEffect, useState, useContext } from 'react';
import AdvancedInsightsContext from '../../../context/insights-context';
import EventTypesButtonGroup from '../../EventTypesButtonGroup/EventTypesButtonGroup';
import './EventsCount.scss';
import IntervalSelectorTags from '../../IntervalSelectorTags/IntervalSelectorTags';
import { getGraphDataFromDistribution } from '../../../data-transform/data-transform';
import AreaCharts from '../../AreaCharts/AreaCharts';
import NoDataAvailable from './../../WidgetWrapper/NoDataAvailable/NoDataAvailable';
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import * as generalConfig from './../../../general-config.json';
import Loader from '../../../../../components/Loader/Loader';
import { useElementDimensions } from '../../../hooks/useElementDimensions';


function EventsCount() {
    const { 
        getEventCountData,
        resetEventCountDistributionByInterval,
        selectedFilters,
        startTimestamp,
        endTimestamp,
        eventCountDistributionByInterval,
        messages,
        initialLoadCompleteStatus,
        disabledIntervalsList,
        eventCountDistributionByIntervalResetStatus,
    } = useContext(AdvancedInsightsContext);

    const globalEventTypeFilter = selectedFilters.filter(f => f.filterType === generalConfig.event_type_filter_key);
    const globalEventTypeFilterValue = globalEventTypeFilter.length === 1 ? globalEventTypeFilter[0].filterValue : "";

    const [eventType, setEventType] = useState(globalEventTypeFilterValue || "error");
    const [countInterval, setCountInterval] = useState(86400);
    const [formattedGraphData, setFormattedGraphData] = useState(null);

    useEffect(() => {
        if ((eventCountDistributionByInterval === null && initialLoadCompleteStatus === true) || eventCountDistributionByIntervalResetStatus === true) {
            getEventCountData({eventType, interval: countInterval});
        }

        return () => resetEventCountDistributionByInterval();
    }, [])

    useDidMountEffect(() => {
            getEventCountData({eventType, interval: countInterval});
    }, [startTimestamp, endTimestamp, selectedFilters, countInterval]);

    useEffect(() => {
        if (globalEventTypeFilterValue) {
            setEventType(globalEventTypeFilterValue);
        }
    }, [globalEventTypeFilterValue]);

    useDidMountEffect(() => {
        if (globalEventTypeFilterValue !== eventType) {
            getEventCountData({eventType, interval: countInterval});
        }
    }, [eventType]);

    useEffect(() => {
        setFormattedGraphData(getGraphDataFromDistribution({
            type: eventType, 
            interval: countInterval, 
            dist: eventCountDistributionByInterval
        }));
    }, [eventCountDistributionByInterval])

    const [eventTypesButtonGroupContainer, elementDimensions] = useElementDimensions();

    if (eventCountDistributionByInterval === null && initialLoadCompleteStatus === true) return <Loader />

    return (
        <div className='events-count-chart'>
            <div className='toolbar'>
                <div className='events-types-selector' ref={eventTypesButtonGroupContainer}>
                    <EventTypesButtonGroup 
                        defaultSelectedType={eventType} 
                        globalEventTypeFilterValue={globalEventTypeFilterValue}
                        clickHandler={type => setEventType(type)}
                        labels={{...messages.event_types, ...messages.common_labels}}
                        parentWidth={elementDimensions.width}
                    />
                </div>
                <div className='interval-selector'>
                    <IntervalSelectorTags 
                        defaultSelectedInterval={countInterval} 
                        clickHandler={interval => setCountInterval(interval)}
                        labels={messages.interval_selection}
                        disabledIntervalsList={disabledIntervalsList}
                    />
                </div>
            </div>
            <div className='chart'>
                {
                    eventCountDistributionByInterval === null || eventCountDistributionByInterval?.length === 0 ? 
                    <NoDataAvailable /> : <AreaCharts data={formattedGraphData?.chartData} />
                }
            </div>
        </div>
    )
}

export default EventsCount