import React, {forwardRef} from 'react';
import WidgetStickyToolbar from '../../../../../Widgets/WidgetStickyToolbar/WidgetStickyToolbar';
import './UsageInsightsToolbar.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleSelect from '../../../../../Widgets/WidgetStickyToolbar/FormComponents/SimpleSelect/SimpleSelect';
import { UilAlignRight, UilUserCircle, UilAlignCenterAlt, UilKeySkeletonAlt, UilTable } from '@iconscout/react-unicons';

function UsageInsightsToolbar({
    selectedDate,
    handleSelectedDateChange,
    filterMenuItems,
    selectedFilter,
    handleFilterChange,
    filterSubMenuItems,
    selectedSubFilter,
    handleSubFilterChange,
    dataLoading,
    textLabels
}) {
    
    const MonthSelectButton = forwardRef(({ value, onClick }, ref) => (
        <button className="month-picker-input" onClick={onClick} ref={ref}>
          <UilTable size={15} /> 
          <label>{value}</label>
        </button>
    ));

    const subFilterIcon = (key, size) => {
        const defaultIcon = <UilAlignRight size={size} />;
        let icon = null;

        switch(key) {
            case "admin":
                icon = <UilUserCircle size={size} />;
                break;

            case "authkey":
                icon = <UilKeySkeletonAlt size={size} />;
                break;

            default:
                icon = defaultIcon;
        }
        
        return icon;
    }

    return (
        <WidgetStickyToolbar containerStyles={{padding: '0px'}} loading={dataLoading}>
            <div className='usage-insights-toolbar-wrapper'>
                <div className='actions'>
                    <div className='month-select action'>
                        <DatePicker 
                            selected={selectedDate}
                            onChange={handleSelectedDateChange}
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showFourColumnMonthYearPicker
                            customInput={<MonthSelectButton />}
                        />
                    </div>
                    
                    {
                        selectedFilter !== null && (
                            <div className='group-select action'>
                                <SimpleSelect 
                                    label={textLabels.filters.primary_label}
                                    options={filterMenuItems} 
                                    onChange={handleFilterChange}
                                    icon={<UilAlignCenterAlt size={16} />}
                                    width="80px"
                                    value={selectedFilter}
                                />
                            </div>
                        )
                    }
                    
                    {
                        selectedFilter?.value !== "none" && (
                            <div className='group-select action'>
                                <SimpleSelect 
                                    label={textLabels.filters.secondary_label}
                                    options={filterSubMenuItems} 
                                    onChange={handleSubFilterChange}
                                    icon={subFilterIcon(selectedFilter?.value, 16)}
                                    width="200px"
                                    value={selectedSubFilter}
                                />
                            </div>
                        )
                    }

                </div>
            </div>
        </WidgetStickyToolbar>
    )
}

export default UsageInsightsToolbar