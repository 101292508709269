import React from 'react';
import BackTrace from '../../BackTrace/BackTrace';
import LogMessage from '../../LogMessage/LogMessage';
import RegisterValues from '../../RegisterValues/RegisterValues';
import './CrashLog.scss';

function CrashLog({data}) {
    return (
        <div className='crash-log'>
            <LogMessage message={data.Message} tag={data.Log?.Tag} />
            <RegisterValues data={data.Crash} />
            <BackTrace data={data.Crash.Backtrace} />
        </div>
    )
}

export default CrashLog