import React, {useState, useEffect, useContext} from 'react';
import PageNavBar from '../PageNavBar/PageNavBar';
import './NodeDetails.scss';
import GlobalFilters from './../GlobalFilters/GlobalFilters';
import { getUrlHashParameterByName, getUrlParameterByName, updateHashURL } from '../../../utils/commonFunctions';
import { UilNotes, UilArrowGrowth, UilTagAlt, UilGold, UilSetting } from '@iconscout/react-unicons';
import NodeReport from './../NodeDetails/NodeReport/NodeReport';
import NodeMetrics from './../NodeDetails/NodeMetrics/NodeMetrics';
import NodeVariables from './../NodeDetails/NodeVariables/NodeVariables';
import NodeTags from "./NodeTags/NodeTags"
import TabContent from './TabContent/TabContent';
import { useHistory } from 'react-router-dom';
import AdvancedInsightsContext from './../context/insights-context';
import useNetworkInterceptor from '../hooks/useNetworkInterceptor';
import NodeInfoCard from './NodeReport/SideWidgets/NodeInfoCard/NodeInfoCard';
import NodeSettings from './../NodeDetails/NodeSettings/NodeSettings';

function NodeDetails() {
    useNetworkInterceptor();
    
    const {
        setNodeFilters,
        getNodeInfoData,
        selectedNodeInfoData,
        resetSelectedNodeData,
        selectedNodeTagsList
    } = useContext(AdvancedInsightsContext);

    const history = useHistory();
    const nodeKey = getUrlParameterByName("key");
    const tabId = getUrlHashParameterByName("view");
    const tabsData = [
        {
            id: "report",
            label: "Node Report",
            tabIcon: <UilNotes size={21} />,
            disabled: false,
            enableFilterSelection: true,
            component: NodeReport
        },
        {
            id: "metrics",
            label: "Metrics",
            tabIcon: <UilArrowGrowth size={21} />,
            disabled: false,
            component: NodeMetrics
        },
        {
            id: "variables",
            label: "Variables",
            tabIcon: <UilGold size={21} />,
            disabled: false,
            component: NodeVariables
        },
        {
            id: "tags",
            label: "Tags",
            tabIcon: <UilTagAlt size={21}/>,
            disabled: false,
            component: NodeTags
        },
        {
            id: "settings",
            label: "Settings",
            tabIcon: <UilSetting size={21}/>,
            disabled: false,
            component: NodeSettings
        }
    ];

    const urlTabId = getUrlHashParameterByName("view");
    const defaultTab = tabsData.some(t => t.id === urlTabId) ? urlTabId : tabsData[0].id;
    const [selectedTab, setSelectedTab] = useState(defaultTab);

    useEffect(() => {
        if(tabId) setSelectedTab(tabId)
    }, [tabId])

    const handleTabChange = tabId => {
        setSelectedTab(tabId)
        updateHashURL({view: tabId})
    }
    let enableFilterSelectionIn = [];
    tabsData.forEach(t => t.enableFilterSelection && enableFilterSelectionIn.push(t.id));

    useEffect(() => {
        if (!nodeKey) {
            history.push("/home/nodes");
        }
        else {
            if (selectedNodeInfoData === null) {
                getNodeInfoData(nodeKey);
            }
        }

        return () => {
            resetSelectedNodeData();
        };
    }, []);

    useEffect(() => {
        getNodeInfoData(nodeKey);
    }, [selectedNodeTagsList?.length])
    
    useEffect(() => {
        if (selectedNodeInfoData?.node_id) {
            setDefaultNodeFilters(selectedNodeInfoData);
        }
    }, [selectedNodeInfoData]);

    const setDefaultNodeFilters = nodeInfo => {
        const nodeDefaultFilters = [
            {
                filterType: "Node.ID",
                filterValue: nodeInfo.node_id
            }
        ];

        if (nodeInfo.interface_id) {
            nodeDefaultFilters.push({
                filterType: "Node.Interface.ID",
                filterValue: nodeInfo.interface_id
            })
        }
        
        if (nodeInfo.interface_type) {
            nodeDefaultFilters.push({
                filterType: "Node.Interface.Type",
                filterValue: nodeInfo.interface_type
            })
        }

        setNodeFilters(nodeDefaultFilters);
    }

    return (
        <div>
            <PageNavBar 
                tabsData={tabsData} 
                selectedTab={selectedTab}
                onTabChange={handleTabChange}
                hideTimeSelector={selectedTab === "variables"}
                disableRangeSelection={selectedTab === "metrics"}
                updateMetricsRange={selectedTab === "metrics"}
            />

            {
                enableFilterSelectionIn.includes(selectedTab) && (
                    <GlobalFilters 
                        fetchList={true}
                        hideFiltersWithPrefixes={["Node"]}
                    />
                )
            }

            {
                selectedTab !== "report" && (
                    <NodeInfoCard 
                        variant="full-width" 
                        containerStyles={{
                            margin: '0px 18px', 
                            paddingLeft: '10px', 
                            borderRadius: '5px'
                        }} 
                    />
                )
            }

            <TabContent data={tabsData.filter(t => t.id === selectedTab)[0]} />
        </div>
    )
}

export default NodeDetails