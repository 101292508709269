import { diagnostics } from "./axios";

export const getDefaultDashboard = (requestData = {}) => {
    return diagnostics.get("/user/dashboard/default", { params: requestData});
}

export const getDashboard = (requestData = {}) => {
    return diagnostics.get("/user/dashboard/download", { params: requestData});
}

export const getDashboardList = (requestData = {}) => {
    return diagnostics.get("/user/dashboard/list", { params: requestData});
}

export const deleteDashboard = (requestData = {}) => {
    return diagnostics.delete("/user/dashboard", { params: requestData})
}

export const setDefault =  (data = {}) => {
    return diagnostics.put("/user/dashboard/default", data)
}

export const createDashboard = async (data) => {
    return diagnostics.post("/user/dashboard", data)
}

export const saveDashboard = async (data) => {
    return diagnostics.put("/user/dashboard", data)
}