import React, { useState, useEffect, useMemo, useContext } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import {
	PrimaryLineButton,
	RefreshButton,
	PrimarySmallButton,
	SuccessButton,
	ErrorButton,
	DeleteButton,
} from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import { firmwarePackageContext } from '../../context/FirmwarePackageProvider';
import AddPackageModal from '../../components/CustomModal/AddPackageModal';
import Table from '../../components/Table/Table';
import DeletePackageModal from '../../components/CustomModal/DeletePackageModal';
import { UilTrashAlt } from '@iconscout/react-unicons';
import Pagination from '../ManageAuthKeys/Pagination/Pagination';

const FirmwarePackages = (props) => {
	const [showAddPackageModal, setShowAddPackageModal] = useState(false);
	const [showDeletePackageModal, setShowDeletePackageModal] = useState(false);
	
	const {
		packageState,
		getPackages,
		searchedPackageReset,
		onNext,
		onPrev,
		changePageSize,
	} = useContext(firmwarePackageContext);

	const {
		loading,
		error,
		allItems,
		searchedItem,
		pageSize,
		pagetoken,
		prevPagesTokens,
		nextPageToken
	} = packageState;

	const [packages, setPackages] = useState([]);
	const [pushPackage, setpushPackage] = useState('');
	const [interactedPackageId, setInteractedPackageId] = useState(null);

	useEffect(() => {
		return () => {
			searchedPackageReset();
		};
	}, []);

	useEffect(() => {
		if (allItems.length === 0) {
			handleGetPackages();
			return;
		}
	}, [pagetoken, pageSize]);

	const handleGetPackages = async (packageName) => {
		await getPackages(pagetoken, pageSize, packageName);
	};

	useEffect(() => {
		searchedItem ? setPackages(searchedItem) : setPackages(allItems);
	}, [JSON.stringify(packageState)]);

	useEffect(() => {
		if (error === 401) {
			props.sessionExpire();
		}
	}, [error]);

	const handlePackageSearch = (e) => {
		e.preventDefault();
		let packageName = e.target.elements.search.value;

		if (packageName.trim() !== '') {
			getPackages(pagetoken, pageSize, packageName);
		}
	};
	
	const handleShowDeletePackageModal = packageId => {
		setShowDeletePackageModal(true);
		setInteractedPackageId(packageId)
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Package Name',
				// eslint-disable-next-line react/display-name
				accessor: (row) => (
					<Link to={`/home/package-details/${row.package_id}`}>
						{row.package_name}
					</Link>
				),
			},
			{
				Header: 'Type',
				accessor: 'type',
			},
			{
				Header: 'Project',
				accessor: 'project',
			},	
			{
				Header: 'Firmware Version',
				accessor: 'fw_version',
			},	
			{
				Header: 'Actions',
                style: {
                    textAlign: "right"
                },
				// eslint-disable-next-line react/display-name
				accessor: (row) => row && row.package_id ? (
					<div className="d-flex justify-content-end">
						<ErrorButton onClick={() => handleShowDeletePackageModal(row.package_id)}>
							<UilTrashAlt size={20} /> Delete
						</ErrorButton>
					</div>
				) : null,
			},
		],
		[]
	);

	return (
		<>
			<div className='app--navbar'>
				<div className='d-flex justify-content-between align-items-center'>
					<div>
						{/* <p className='small--heading'>Overview</p> */}
						<div className='d-flex'>
							<p className='main--heading'>Firmware Packages</p>
							<RefreshButton
								loading={loading}
								onClick={() => handleGetPackages()}
							/>
						</div>
					</div>
					<div className='d-flex align-items-center package-search'>
						<form
							onSubmit={handlePackageSearch}
							className='d-flex align-items-center'
						>
							<Input
								type='text'
								name='search'
								placeholder='Search Package'
								isInline={true}
								onChange={(e) => {
									if (e.target.value === '') {
										setPackages(allItems);
										searchedPackageReset();
									}
								}}
								maxLength={64}
								id='search-img'
							/>
							
							<PrimaryLineButton
								id='search-img-btn'
								title='Search Package'
								className='mx-2'
								type='submit'
							>
								Search
							</PrimaryLineButton>
						</form>
						<SuccessButton
							onClick={() => setShowAddPackageModal(true)}
							title='Add Package'
							id='add-img-btn'
						>
							Add Package
						</SuccessButton>
					</div>
				</div>
			</div>
			<hr className='mx-3 my-0' />

			<div className='home-card card--container d-flex flex-column justify-content-between'>
				{error ? (
					<div className='error-container'>
						<p className='color-primary' id='error-msg'>
							{error}
						</p>
					</div>
				) : packages ? (
					<div className='row'>
						<div className='col-lg-12 h-100'>
							<Table
								data={packages}
								columns={columns}
								loading={loading}
								loaders={pageSize}
							></Table>
						</div>
					</div>
				) : (
					<div className='error-container'>
						<p className='mt-3 color-primary'>
							Firmware package not found.
						</p>
					</div>
				)}

				{!error &&
					!loading &&
					packages &&
					!searchedItem && (
						<Pagination 
							showPageSizeControl={true}
							pageSize={pageSize}
							minPageSize={10}
							maxPageSize={50}
							onPageSizeChange={changePageSize}
							onNext={nextPageToken ? onNext : null}
							onPrev={prevPagesTokens.length > 1 ? onPrev : null}
						/>
					)}
			</div>

			{showAddPackageModal && (
				<AddPackageModal
					showModal={showAddPackageModal}
					setShowModal={setShowAddPackageModal}
					refreshParent={() => handleGetPackages()}
					sessionExpire={props.sessionExpire}
				/>
			)}

			{showDeletePackageModal && (
					<DeletePackageModal
						showModal={showDeletePackageModal}
						setShowModal={setShowDeletePackageModal}
						packageId={interactedPackageId}
						sessionExpire={props.sessionExpire}
					/>
			)}
		</>
	);
};

export default FirmwarePackages;
