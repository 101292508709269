import React, { useState } from 'react';
import { useHistory } from 'react-router';
import Cookies from 'js-cookie';
import UserWrapper from '../../../components/UserWrapper/UserWrapper';
import CustomToast from '../../../components/CustomToast/CustomToast';
import { verificationCall, loginCall } from '../../../utils/apiCalls';
import { Input } from '../../../components/Input/Input';
import { PrimaryDarkButton } from '../../../components/Button/Button';

const VerificationCode = (props) => {
	const history = useHistory();
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleVerificationCode = (e) => {
		e.preventDefault();
		const verification_code = e.target.elements.verificationcode.value;
		let user_name, password;

		if (verification_code === '' || verification_code === ' ') {
			setError('Please enter a verification code.');
			return;
		}
		setLoading(true);
		props.location.state
			? ({ user_name, password } = props.location.state)
			: (user_name = Cookies.get('user_name'));

		verificationCall(user_name, verification_code).then((response) => {
			if (!response) {
				if (user_name && password) {
					CustomToast.success(
						'User verified successfully. Logging you in.',
						{
							autoClose: 5000,
						},
						'verification-toast'
					);

					loginCall(user_name, password).then((response) => {
						setError(response);

						if (!response) {
							setError('');
							history.push('/home/insights');
						}
					});
				} else {
					CustomToast.success(
						'User verified successfully. Please login.',
						{
							autoClose: 5000,
						},
						'verification-toast'
					);
					history.push('/login');
				}
			} else {
				setError(response);
			}
			setLoading(false);
		});
	};

	return (
		<>
			<UserWrapper>
				<p className='text-muted'>
					Please enter 6 digit verification code sent to your email.
				</p>

				<form onSubmit={handleVerificationCode}>
					<Input
						type='text'
						name='verificationcode'
						placeholder='Verification Code'
						onChange={() => {
							error && setError('');
						}}
						id='verification-code'
					/>

					{error && (
						<p className={'text-error'} id='error-msg'>
							{error}
						</p>
					)}

					<div className='d-flex justify-content-center mt-3'>
						<PrimaryDarkButton
							loading={loading}
							minWidth='70px'
							disabled={loading ? true : false}
							id='verification-btn'
							type='submit'
						>
							Verify Email
						</PrimaryDarkButton>
					</div>
				</form>
			</UserWrapper>
		</>
	);
};

export default VerificationCode;
