export default {
	aws_project_region: 'us-east-1',
	aws_cloud_logic_custom: [
		{
			name: 'user',
			endpoint: window.BASE_URL,
			region: window.REGION,
		},
		{
			name: 'admin',
			endpoint: `${window.BASE_URL}/admin`,
			region: window.REGION,
		},
	],
	cognito: {
		cognito_URL: window.COGNITO_DOMAIN_URL,
		redirect_URI: window.REDIRECT_URI,
		client_ID: window.COGNITO_CLIENT_ID,
		region: window.REGION,
	},
};
