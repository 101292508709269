import React, { useEffect, useState } from 'react';
import { getMetricsMetadata } from '../../../utils/diagAPI';
import { getUrlHashParameterByName } from '../../../utils/commonFunctions';
import { GRAPH_COLORS } from '../../../utils/constants';
import moment from 'moment';
import ListGraphWidgets from "./ListGraphWidgets";
import Notice from "../../../components/AppNotices/Notice/Notice";
import WidgetWrapper from "../../AdvancedInsights/Widgets/WidgetWrapper/WidgetWrapper";
import InfoMessageWrapper from '../../AdvancedInsights/Widgets/InfoMessageWrapper/InfoMessageWrapper';

var d = new Date();
var offset = d.getTimezoneOffset() * -60; //timezone offset in microseconds

const DiagMetrics = (props) => {

	const nodeId = props.nodeId || props.match.params.node_id;
	const [nodeKey] = useState(() => props.nodeKey || (props.location.state && props.location.state.nodeKey));

	// For Chart Interval { start_timestamp , end_timestamp }
	const [chartInterval, setChartInterval] = useState(null);

	// For multiple widget options
	const [widgetCount, setWidgetCount] = useState(1);

	// API Responses
	const [metaData, setMetaData] = useState(null);

	// Loaders
	const [loading, setLoading] = useState(true);

	// All selectable metric options
	const [allMetricOptions, setAllMetricOptions] = useState([]);

	const UnixToDateUtil = ( date ) => {
		return moment(
			moment
			.unix(date).utc()
			.format('YYYY-MM-DD HH:mm:ss')
		).toDate()
	}

	useEffect(() => {
		// passing prop to parent
		if (!props.nodeId) {
			props.setNodeId(props.match.params.node_id);
		}
	}, []);

	useEffect(() => {
		if (props.nodeId) {
			setLoading(true);
			getMetadata();
		}
	}, [props.nodeId]);

	const getMetadata = async () => {
		let response = await getMetricsMetadata({node_id: nodeId, node_key: nodeKey});
		
		if (response.description === 'Unauthorized') {
			props.sessionExpire();
			return;
		}
		if (response.status === 'failure') {
			setMetaData([]);
			setWidgetCount(1);
		} else {
			let allMetric = getMetricTypes(response);
			setAllMetricOptions(allMetric);
			setMetaData(response);
		}
		setLoading(false);
	};

	
	const defaultEndTimestamp = (moment(d.getTime()).unix());
	const defaultStartTimestamp = defaultEndTimestamp - (3*60*60);

	const [startTimestamp, setStartTimestamp] = useState(()=>{
		const _startTimestamp = getUrlHashParameterByName("start");
		// _startTimestamp is divided by 1, to stop component maximum rerenders 
		return _startTimestamp ? Math.trunc(_startTimestamp) : defaultStartTimestamp;
	});
	const [endTimestamp, setEndTimestamp] = useState(()=>{
		const _endTimestamp = getUrlHashParameterByName("end");
		// _endTimestamp is divided by 1, to stop component maximum rerenders
		return _endTimestamp ? Math.trunc(_endTimestamp)  : defaultEndTimestamp;
	});

	const sanitizeChartInternval = (e) => {
		const sanitizeTime  = {
			start : moment(e[0]).unix() + offset,
			end :  moment(e[1]).unix() + offset
		}
		setChartInterval(sanitizeTime);
	}

	useEffect(() => {
	
		let startT = UnixToDateUtil(startTimestamp);
		let endT = UnixToDateUtil(endTimestamp); 
		let dayDifference = (endTimestamp - startTimestamp)/60/60 
		
		if(dayDifference > 24 )
		{
			// if difference in startTimestamp and EndTimeStamp is greater that 1 day then set endTimeStamp to startTimestamp + 3 hours
			endT = UnixToDateUtil(startTimestamp + (24*60*60))
		}

		sanitizeChartInternval([ startT, endT])
	}, [startTimestamp, endTimestamp ]);

	useEffect(() => {
	  setStartTimestamp(props.startTimestamp);
	  setEndTimestamp(props.endTimestamp)
	}, [props.startTimestamp, props.endTimestamp])
	

	return (
		<>
			<div className='diagnostic--page--wrapper metrics'>
				{
					!loading ? (
						metaData?.length > 0 ? 
						<ListGraphWidgets 
							metricOptions={allMetricOptions}
							interval={chartInterval}
							nodeId={nodeId}
							nodeKey={nodeKey}
							metaData={metaData}
							widgetCount={widgetCount}
							setLoading={props.setLoading}
						/> :
							<InfoMessageWrapper>
								<div>Sorry, we couldn't find any metrics for this node.</div>
							</InfoMessageWrapper>
						) :
						<WidgetWrapper  loading={true} containerStyles={{padding: '0px', borderRadius: '10px'}} />
				}
			</div>
		</>
	);
};

function getMetricTypes(data) {
	let alltypes = [];

	function getTypes(data) {
		let metricType = data.map((dataItem) => {
			if (dataItem.type === 'folder' && dataItem.children.length > 0) {
				getTypes(dataItem.children);
			}
			if (dataItem.type === 'file' && dataItem.storageType === 'Metric') {
				return dataItem;
			}
		});
		let sortMetricType = metricType.filter((i) => i !== undefined);
		if (sortMetricType.length > 0) {
			alltypes.push(...sortMetricType);
		}
	}
	getTypes(data);

	let allMetrics = alltypes.map((type, index) => {
		return {
			id: type.id,
			label: type.label,
			color: GRAPH_COLORS[index],
		};
	});
	return allMetrics;
}

export default DiagMetrics;
