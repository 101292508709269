import React from 'react'
import { useTable, usePagination } from 'react-table'
import { PrimaryLineButton } from '../../components/Button/Button';
import { Form } from 'react-bootstrap';

const Table = ({ columns, data, loading, loaders }) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    )
    return (
        <>
            <div>
                <table {...getTableProps()} style={{ opacity: loading ? 0.5 : 1 }}
                    className='transition' >
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} >
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} >{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {!loading &&
                        (<tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td {...cell.getCellProps()} >{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                        )}
                </table>
                {loading && (
                    <div className='placeholder transition'>
                        {[...Array(Number(loaders))].map((i, index) => (
                            <div
                                key={`loader-${index}`}
                                className='animated-background'
                            ></div>
                        ))}
                    </div>
                )}
            </div>

            {data.length > 0 && <div className='row'>
                <div className='col-lg-12'>
                    <div className='pagination-button-container '>
                        <PrimaryLineButton
                            onClick={() => previousPage()}
                            id='prev-btn'
                            disabled={!canPreviousPage}
                            title='Previous'
                        >
                            Prev
                        </PrimaryLineButton>
                        <p>
                            Page {pageIndex + 1} of {pageOptions.length}
                        </p>
                        <PrimaryLineButton
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                            id='next-btn'
                            title='Next'
                        >
                            Next
                        </PrimaryLineButton>

                        <Form.Control
                            as='select'
                            custom
                            name='searchBy'
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                            defaultValue={pageSize}
                            id='page-size-list'
                        >
                            <option value='search-by' disabled>
                                No. of Nodes
                            </option>
                            <option value='5'>5</option>
                            <option value='10'>10</option>
                            <option value='15'>15</option>
                            <option value='25'>25</option>
                        </Form.Control>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Table;