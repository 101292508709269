import React, { useEffect, useState } from 'react';

const NodeDetailsCard = (props) => {
	const { loading, nodeInfo } = props;
	const [cardLoading, setCardLoading] = useState(loading);

	useEffect(() => {
		if (nodeInfo) {
			setCardLoading(false);
		}
	}, [nodeInfo]);

	return (
		<div
			className='card--container diagnostic--card align-items-center'
			style={{ height: 'calc(100% - 26px)' }}
		>
			{cardLoading ? (
				<div className='d-flex justify-content-center w-100'>
					<div className='spinner-border text-link' role='status'>
						<span className='sr-only'>Loading...</span>
					</div>
				</div>
			) : (
				nodeInfo && (
					<div className='w-100'>
						<p className='card--title'>Node Details</p>
						<hr />
						<div className='d-flex align-items-center'>
							<p className='card--headings'>Node ID: </p>
							<p className='card--text'>{nodeInfo.node_id}</p>
						</div>
						<div className='d-flex align-items-center'>
							<p className='card--headings'>Chip: </p>
							<p className='card--text'>{nodeInfo.chip}</p>
						</div>
						<div className='d-flex align-items-center'>
							<p className='card--headings'>Project: </p>
							<p className='card--text'>{nodeInfo.project}</p>
						</div>
						<div className='d-flex align-items-center'>
							<p className='card--headings'>Project Version: </p>
							<p className='card--text'>{nodeInfo.app_version}</p>
						</div>
					</div>
				)
			)}
		</div>
	);
};

export default NodeDetailsCard;
