import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import UserWrapper from '../../../components/UserWrapper/UserWrapper';
import { validateToken } from '../../../utils/apiCalls';
import { getUrlParameterByName } from '../../../utils/commonFunctions';

export default function Authorise() {
    let history = useHistory();
    const urlAccessToken = getUrlParameterByName("state");
    const nodeId = getUrlParameterByName("node");
    const nodeKey = nodeId ? `RMP:0:${nodeId}` : "";
    const defaultRedirectRoute = "/home/insights";

    const handleAuthorisationSuccess = () => {
        if (nodeId) {
            history.push({
                pathname: `/home/nodes/${nodeId}/?key=${nodeKey}`,
                state: { nodeId, nodeKey, onlineStatus: null }
            });
        }
        else {
            history.push(defaultRedirectRoute);
        }
    }

    const handleAuthorisationFailure = () => {
        history.push("/unauthorised");
    }

    useEffect(() => {
        if (urlAccessToken) {
            validateToken(urlAccessToken)
            .then(response => {
                if (response.status === "failure") {
                    handleAuthorisationFailure();
                }
                else {
                    handleAuthorisationSuccess();
                }
            })
            .catch(error => handleAuthorisationFailure());
        }
        else {
            handleAuthorisationFailure();
        }
    }, []);

    return (
        <UserWrapper>
            <div className='d-flex justify-content-center align-items-center flex-grow-1 h-100 w-100 '>
                <div className='spinner-border text-link' style={{height: '50px', width: '50px'}}>
                    <span className='sr-only'>Loading...</span>
                </div>
            </div>
        </UserWrapper>
    )
}
