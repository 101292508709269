import React from 'react';
import './Checkbox.scss';

const Checkbox = props => {
	const { label, checked, id, chLabelStyle, ...checkboxProps } = props;
	return (
		<div className='r-checkbox'>
			<input
				className='styled-checkbox'
				id={id}
				type='checkbox'
				checked={checked}
				{...checkboxProps}
			/>
			<label
				className={`${chLabelStyle ? chLabelStyle : ''} checkbox-label`}
				htmlFor={id}
			>
				{label ? label : ''}
			</label>
		</div>
	);
};

export default Checkbox;
