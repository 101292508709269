import React, { useEffect, useMemo, useRef } from 'react';
import Chart from "react-apexcharts";
import * as generalConfig from './../../../../../general-config.json';

function TimelineChart({
    series = null,
    options = null,
    hideNASeries = true
}) {

    const optionsData = useMemo(() => options, [options]);
    const seriesData = useMemo(() => series, [series]);
    const chartRef = useRef();
    
    const defaultChartHeight = 350;
    const chartHeight = (function(){
        if (optionsData?.chart?.type === "heatmap" && seriesData?.length) {
            const height = seriesData.length * 35;
            return height > defaultChartHeight ? height : defaultChartHeight;
        }
        return defaultChartHeight;
    })();

    useEffect(() => {
        hideNASeries !== false && resetNASeries(seriesData);
    }, [seriesData]);

    const resetNASeries = (series) => {
        if (generalConfig.hide_NA_series_in_node_timeline === true && series !== null) {
            const encodedSeriesName = "&lt;NA&gt;";
            
            if (series?.length && series.findIndex(s => s.name === encodedSeriesName) !== -1) {
                chartRef.current.chart.ctx.hideSeries(encodedSeriesName);
            }
            else {
                chartRef.current.chart.ctx.resetSeries();
            }
        }
    }

    if (!seriesData || !optionsData) return null;
    return (
        <div className='column-chart'>
          <Chart
                options={optionsData}
                series={seriesData}
                height={chartHeight}
                type={optionsData.chart.type}
                ref={chartRef}
            />
        </div>
    )
}

export default TimelineChart