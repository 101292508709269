import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import ReactTooltip from 'react-tooltip';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import { RouteWithSubRoutes, clearCookies } from '../../utils/commonFunctions';
import {
	USER_ROLES_SUPER_ADMIN,
	USER_ROLES_CUSTOMER,
	USER_ROLES_ALL,
} from '../../utils/constants';
import CustomToast from '../../components/CustomToast/CustomToast';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
import NodeGroupProvider from '../../context/NodeGroupProvider';
import NodesProvider from '../../context/NodesProvider';
import JobsProvider from '../../context/JobsProvider';
import FirmwarePackageProvider from '../../context/FirmwarePackageProvider';
import AdvancedInsightsContext from './../AdvancedInsights/context/insights-context';
import * as generalConfig from './../AdvancedInsights/general-config.json';
import { getUrlParameterByName } from "../../utils/commonFunctions"
import useNetworkInterceptor from '../AdvancedInsights/hooks/useNetworkInterceptor';
import { getUnixTime } from 'date-fns';
import { refreshSessionCall } from '../../utils/apiCalls';

const Home = (props) => {
	let { routes } = props;
	useNetworkInterceptor();
	let history = useHistory();
	let location = useLocation();
	const [isAuthorised, setIsAuthorised] = useState(false);
	const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(localStorage.getItem(generalConfig['sidebar-collapsed-local-storage-key']) === "true" || false);

	const [userPermission, setUserPermission] = useState([]);
	const enableESPLogin = location.state ? location.state.enableESPLogin : false;
	const autoExtendSessionCheckInterval = 10 * 1000; // interval to check if session extension is required (in milliseconds)

	const { 
        resetInitialLoadCompleteStatus,
		getAllDashboardList
    } = useContext(AdvancedInsightsContext);

	useEffect(() => {
		let userRole = [USER_ROLES_ALL];
		if (Cookies.get('admin')) {
			userRole.push(USER_ROLES_SUPER_ADMIN);
		}
		if (Cookies.get('cst')) {
			userRole.push(USER_ROLES_CUSTOMER);
		}
		userRole.length > 0 && setUserPermission(userRole);
		if(Cookies.get('ATK'))
		{
			setIsAuthorised(true)
			const isGroupAnalyticsPage = history.location.pathname.includes("insights")
			const dashboard_id = getUrlParameterByName("dashboard-id") || !isGroupAnalyticsPage
			getAllDashboardList({dashboard_id})

			if (Cookies.get(generalConfig.keep_signed_in_cookie_key) === "yes") {
				if (!window.AUTO_EXTEND_SESSION) {
					window.AUTO_EXTEND_SESSION = setInterval(autoExtendSession, autoExtendSessionCheckInterval)
				}
			}
			else {
				window.AUTO_EXTEND_SESSION && clearInterval(window.AUTO_EXTEND_SESSION);
			}
		}
		else {
			history.push('/login');
		}
		
		return () => window.AUTO_EXTEND_SESSION && clearInterval(window.AUTO_EXTEND_SESSION); // clear interval on component unmount
	}, []);

	const handleUnauthorised = () => {
		CustomToast.error(
			'Session expired. Please login again!',
			{
				autoClose: 5000,
			},
			'session-toast'
		);
		clearCookies();
		resetInitialLoadCompleteStatus();
		history.push('/login');
	};

	const handleOnCollapseToggle = () => {
		setIsSidebarCollapsed(collapsedState => {
			localStorage.setItem(generalConfig['sidebar-collapsed-local-storage-key'], !collapsedState);
			return !collapsedState;
		});
	}

	const autoExtendSession = async () => {
		const currentTs = getUnixTime(new Date());
		const lastLoggedInTs = parseInt(Cookies.get("last_logged_in_ts") || 0);
		const buffer = 120; // number of seconds before the actual timeout to extend session
		if (lastLoggedInTs > 0) {
			const sessionTimeout = generalConfig.session_timeout;
			if ((currentTs - lastLoggedInTs + buffer) > sessionTimeout) {
				// session will timeout soon, extend the session
				let response = await refreshSessionCall();
				if (response?.status === "failure") {
					// refresh session failed, send user to the login page
					handleUnauthorised();
				}
			}
		}
	}

	return (
		<>
			{isAuthorised && (
				<div className='wrapper'>
					<Sidebar
						sessionExpire={() => handleUnauthorised()}
						userPermission={userPermission}
						enableESPLogin={enableESPLogin}
						collapsed={isSidebarCollapsed}
						onCollapseToggle={handleOnCollapseToggle}
					></Sidebar>
					<div className={`main-container d-flex flex-column justify-content-between ${isSidebarCollapsed ? 'sb-collapsed' : ''}`}>
						<div>
							<Switch>
								<NodesProvider>
									<NodeGroupProvider>
										<FirmwarePackageProvider>
											<JobsProvider>
												{routes.map((route, i) => {
													return (
														<RouteWithSubRoutes
															key={i}
															{...route}
															sessionExpire={() =>
																handleUnauthorised()
															}
														/>
													);
												})}
											</JobsProvider>
										</FirmwarePackageProvider>
									</NodeGroupProvider>
								</NodesProvider>
							</Switch>
						</div>
						<Footer />
						<ReactTooltip effect='solid' />
					</div>
				</div>
			)}
		</>
	);
};

export default Home;
