import React, {useEffect, useState, useContext} from 'react';
import Calendar from 'react-calendar';
import AdvancedInsightsContext from '../../../context/insights-context';
import { PrimaryLineButton, LinkButton } from '../../../../../components/Button/Button';
import { Input } from '../../../../../components/Input/Input';
import './DateTimeRangeSelectionInterface.scss';
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';

import { 
    getUnixTime, 
    fromUnixTime, 
    format, 
    isValid, 
    parse, 
    getDate,
    getMonth,
    getYear ,
    startOfToday,
    endOfToday,
    startOfYesterday,
    endOfYesterday,
    sub
} from 'date-fns';
import moment from 'moment';
import { 
    UilAngleLeft,
    UilAngleRight

} from '@iconscout/react-unicons';
import { isArray } from 'lodash';

export default function DateTimeRangeSelectionInterface({
    setShow,
    messages = {},
    defaultStart,
    defaultEnd,
    onSelect,
    disableRangeSelection
}) {
    const { 
        setIntervalLabel,
        intervalLabel
      } = useContext(AdvancedInsightsContext);

    const [startTimestamp, setStartTimestamp] = useState(defaultStart);
    const [endTimestamp, setEndTimestamp] = useState(defaultEnd);

    const [error, setError] = useState("");

    const calendarStyleClass = disableRangeSelection === true ? "single-date" : "multi-date";

    const formattedDate = timestamp => {
        if (!timestamp) return "";

        const date = fromUnixTime(timestamp);
        if (!isValid(fromUnixTime(date))) return "";
        
        return format(date, "dd/MM/yyyy");
    }
    
    const handleCalendarDateChange = (value, event) => {
        if (value) {
            setError("");

            if (isArray(value)) {
                const [startDate, endDate] = value;
                setStartTimestamp(getUnixTime(startDate));
                endDate && setEndTimestamp(getUnixTime(endDate));
            }
            else {
                const selectedDateInUnixTimestamp = getUnixTime(value);
                setStartTimestamp(selectedDateInUnixTimestamp);
                setEndTimestamp(selectedDateInUnixTimestamp);
            }
        }
    }

    const [startDate, setStartDate] = useState(formattedDate(startTimestamp));
    const [startTime, setStartTime] = useState(moment.unix(startTimestamp));
    const [endDate, setEndDate] = useState(formattedDate(endTimestamp));
    const [endTime, setEndTime] = useState(moment.unix(endTimestamp));

    const calendarProps = {
        selectRange: disableRangeSelection !== true,
        showNeighboringMonth: false,
        calendarType: "US",
        showDoubleView: disableRangeSelection !== true,
        goToRangeStartOnSelect: true,
        maxDate: new Date(),
        allowPartialRange: true,
        value: disableRangeSelection === true ? fromUnixTime(startTimestamp) : [fromUnixTime(startTimestamp), fromUnixTime(endTimestamp)],
        onChange: handleCalendarDateChange,
        className: ["insights-calendar", calendarStyleClass],
        prevLabel: <UilAngleLeft />,
        nextLabel: <UilAngleRight />,
        prev2Label: null,
        next2Label: null
    }

    const quickLinks = [
        { id: "today", label: "Today" },
        { id: "yesterday", label: "Yesterday" },
        { id: "last-24-hours", label: "Last 24 hours", diff: { hours: 24 }},
        { id: "last-7-days", label: "Last 7 days", diff: { days: 7 }},
        { id: "last-30-days", label: "Last 30 days", diff: { days: 30 }},
        { id: "last-3-months", label: "Last 3 months", diff: { months: 3 }},
    ]

    useEffect(() => {
        if (startTimestamp) {
            setStartDate(formattedDate(startTimestamp));
            disableRangeSelection !== true && setStartTime(moment.unix(startTimestamp));
        }
        
        if (endTimestamp) {
            setEndDate(formattedDate(endTimestamp));
            disableRangeSelection !== true && setEndTime(moment.unix(endTimestamp));
        }
    }, [startTimestamp, endTimestamp]);

    useEffect(() => {
        setDefaultDates();
    }, [defaultStart, defaultEnd]);

    
    const handleManualDateUpdate = e => {
        const dateType = e.target.id;

        if (dateType === "startDate") {
            setStartDate(e.target.value);
        }
        
        if (dateType === "endDate") {
            setEndDate(e.target.value);
        }
    }

    const handleManualTimeUpdate = (time, type) => {
        if (type === "startTime") {
            setStartTime(time);
        }
        
        if (type === "endTime") {
            setEndTime(time);
        }
    }

    const validateDateRange = () => {
        setError("");
        setIntervalLabel(null);
        if (isValidDateFormat(startDate) && isValidDateFormat(endDate)) {
            const parsedStartDate = parse(startDate, 'dd/MM/yyyy', new Date());
            const parsedEndDate = parse(endDate, 'dd/MM/yyyy', new Date());

            if (isValid(parsedStartDate) && isValid(parsedEndDate)) {
                const startDay = getDate(parsedStartDate);
                const startMonth = getMonth(parsedStartDate);
                const startYear = getYear(parsedStartDate);
                
                const endDay = getDate(parsedEndDate);
                const endMonth = getMonth(parsedEndDate);
                const endYear = getYear(parsedEndDate);

                const appliedStartTime = startTime || moment(parsedStartDate);
                const appliedEndTime = endTime || moment(parsedEndDate);
                const appliedStartDate = (new Date(startYear, startMonth, startDay, appliedStartTime.get('hour'), appliedStartTime.get('minute'), appliedStartTime.get('second')));
                const appliedEndDate = (new Date(endYear, endMonth, endDay, appliedEndTime.get('hour'), appliedEndTime.get('minute'), appliedEndTime.get('second')));

                const appliedStartDateTimestamp = getUnixTime(appliedStartDate);
                const appliedEndDateTimestamp = getUnixTime(appliedEndDate);

                if (appliedStartDateTimestamp < appliedEndDateTimestamp) {
                    isValid(appliedStartDate) && setStartTimestamp(appliedStartDateTimestamp);
                    isValid(appliedEndDate) && setEndTimestamp(appliedEndDateTimestamp);
                    
                    applyDateRange(appliedStartDateTimestamp, appliedEndDateTimestamp);
                }
                else {
                    setError("invalid_date_range");
                }
            }
            else {
                setError("invalid_date_format");
            }
        }
        else {
            setError("invalid_date_format");
        }
    }

    const handleQuickLinkAction = e => {
        const action = e.target.id
        setError("");
        setIntervalLabel(action)
        setShow(false)
    }

    const applyDateRange = (startDateTimestamp, endDateTimestamp) => {
        if (onSelect) {
            onSelect({
                start: startDateTimestamp, 
                end: endDateTimestamp
            });
            setShow(false);
        }
    }

    const isValidDateFormat = dateString => {
        if (!dateString) return false;
        if (typeof dateString !== "string") return false;
        
        const dateStringData = dateString.split("/");
        if (dateStringData.length !== 3) return false;
        if (dateStringData[0].length !== 2 || dateStringData[1].length !== 2 || dateStringData[2].length !== 4) return false;

        return true;
    }

    const setDefaultDates = () => {
        setStartTimestamp(defaultStart);
        setStartTime(moment.unix(defaultStart));
        setEndTimestamp(defaultEnd);
        setEndTime(moment.unix(defaultEnd));
    }

    const closeInterface = () => {
        setDefaultDates();
        setShow(false);
    }

    return (
        <div className={`menu-content ${calendarStyleClass}`}>
            <div className="calendar">
                {
                    disableRangeSelection === true ? <Calendar {...calendarProps} key="month-calendar" /> : <Calendar {...calendarProps} key="range-calendar" />
                }
            </div>
            <div className="time-range-inputs">
                <div className="start date-time-contianer">
                    <div>
                        <label>{messages.label_start_datetime[calendarStyleClass]}</label>
                        <div className="date-time d-flex">
                            {
                                disableRangeSelection !== true && (
                                    <Input 
                                        type="text" 
                                        isInline={true} 
                                        placeholder="DD/MM/YYYY" 
                                        className="date" 
                                        value={startDate} 
                                        id="startDate" 
                                        onChange={handleManualDateUpdate} 
                                    />
                                )
                            }
                            <TimePicker
                                showSecond={true}
                                className="advanced-time-selector"
                                placeholder="HH:MM:SS"
                                value={startTime}
                                id="startTime"
                                onChange={e => handleManualTimeUpdate(e, "startTime")}
                            />
                        </div>
                    </div>
                </div>
                <div className="end date-time-contianer">
                    <div>
                        <label>{messages.label_end_datetime[calendarStyleClass]}</label>
                        <div className="date-time d-flex">
                            {
                                disableRangeSelection !== true && (
                                    <Input 
                                        type="text" 
                                        isInline={true} 
                                        placeholder="DD/MM/YYYY" 
                                        className="date" 
                                        value={endDate} 
                                        id="endDate" 
                                        onInput={handleManualDateUpdate} 
                                    />
                                )
                            }
                            <TimePicker
                                showSecond={true}
                                className="advanced-time-selector"
                                placeholder="HH:MM:SS"
                                value={endTime}
                                id="endTime"
                                onChange={e => handleManualTimeUpdate(e, "endTime")}
                            />
                        </div>
                    </div>
                </div>
            </div>

            { error ? <div className="error-message"> {messages[error] || error} </div> : null }

            <div className="footer">
                <div className="quick-links d-flex">
                    {
                        disableRangeSelection !== true && quickLinks.map(({id, label}) => (
                                <LinkButton 
                                    onClick={handleQuickLinkAction} 
                                    key={id} 
                                    id={id} 
                                    className={`${ id === intervalLabel && "selected" }`}>
                                        {messages.quick_links[id]}
                                    </LinkButton>
                            ))
                    }
                </div>
                <div className="actions d-flex">
                    <LinkButton id='cancel-btn' onClick={closeInterface}>{messages.button_cancel}</LinkButton>
                    <PrimaryLineButton id='apply-btn' onClick={validateDateRange}>{messages.button_apply}</PrimaryLineButton>
                </div>
            </div>
        </div>
    )
}