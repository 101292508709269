export const GET_FILTERS_LIST = "GET_FILTERS_LIST";
export const SET_FILTERS_LIST = "SET_FILTERS_LIST";
export const SET_NODE_FILTERS = "SET_NODE_FILTERS";
export const CLEAR_NODE_FILTERS = "CLEAR_NODE_FILTERS";
export const SET_DATE_TIME_RANGE = "SET_DATE_TIME_RANGE";
export const SET_FILTER_SUGGESTION = "SET_FILTER_SUGGESTION";
export const ADD_TO_SELECTED_FILTERS = "ADD_TO_SELECTED_FILTERS";
export const ADD_MULTIPLE_FILTERS_TO_SELECTED_FILTERS = "ADD_MULTIPLE_FILTERS_TO_SELECTED_FILTERS";
export const REMOVE_FROM_SELECTED_FILTERS = "REMOVE_FROM_SELECTED_FILTERS";
export const CLEAR_ALL_SELECTED_FILTERS = "CLEAR_ALL_SELECTED_FILTERS";
export const SET_ALL_EVENTS_DATA = "SET_ALL_EVENTS_DATA";
export const SET_EVENT_COUNT_BY_INTERVAL_DATA = "SET_EVENT_COUNT_BY_INTERVAL_DATA";
export const SET_ALL_EVENTS_COUNT_BY_INTERVAL_DATA = "SET_ALL_EVENTS_COUNT_BY_INTERVAL_DATA";
export const SET_EVENT_COUNT_BY_CATEGORY_DATA = "SET_EVENT_COUNT_BY_CATEGORY_DATA";
export const SET_TOP_NODES_DATA = "SET_TOP_NODES_DATA";
export const SET_INITIAL_LOAD_COMPLETE_STATUS = "SET_INITIAL_LOAD_COMPLETE_STATUS";
export const RESET_EVENT_COUNT_BY_INTERVAL_DATA = "RESET_EVENT_COUNT_BY_INTERVAL_DATA";
export const RESET_EVENT_COUNT_BY_CATEGORY_DATA = "RESET_EVENT_COUNT_BY_CATEGORY_DATA";
export const RESET_ALL_EVENTS_DATA = "RESET_ALL_EVENTS_DATA";
export const RESET_TOP_NODES_DISTRIBUTION_DATA = "RESET_TOP_NODES_DISTRIBUTION_DATA";
export const RESET_INITIAL_LOAD_COMPLETE_STATUS = "RESET_INITIAL_LOAD_COMPLETE_STATUS";
export const SET_SELECTED_DASHBOARD = "SET_SELECTED_DASHBOARD";
export const SWITCH_DASHBOARD = "SWITCH_DASHBOARD";
export const SET_DASHBOARD_LIST = "SET_DASHBOARD_LIST";
export const SET_INTERVAL_LABEL = "SET_INTERVAL_LABEL"
export const SET_ONLINE_TOP_NODES_DATA = "SET_ONLINE_TOP_NODES_DATA";
export const SET_NODE_INFO_DATA = "SET_NODE_INFO_DATA";
export const RESET_SELECTED_NODE_DATA = "RESET_SELECTED_NODE_DATA";
export const SET_SELECTED_NODE_ALL_EVENTS_DATA = "SET_SELECTED_NODE_ALL_EVENTS_DATA";
export const RESET_SELECTED_NODE_ALL_EVENTS_DATA = "RESET_SELECTED_NODE_ALL_EVENTS_DATA";
export const SET_SELECTED_NODE_LOGS_DATA = "SET_SELECTED_NODE_LOGS_DATA";
export const PREPEND_TO_SELECTED_NODE_LOGS_DATA = "PREPEND_TO_SELECTED_NODE_LOGS_DATA";
export const RESET_SELECTED_NODE_LOGS_DATA = "RESET_SELECTED_NODE_LOGS_DATA";
export const SET_SELECTED_NODE_LOGS_LOADING = "SET_SELECTED_NODE_LOGS_LOADING";
export const SET_SELECTED_NODE_TIMELINE_SUBDIST = "SET_SELECTED_NODE_TIMELINE_SUBDIST";
export const SET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA = "SET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA";
export const RESET_SELECTED_NODE_TIMELINE_SUBDIST = "RESET_SELECTED_NODE_TIMELINE_SUBDIST";
export const RESET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA = "RESET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA";
export const SET_DAHSBOARD_AVAILABLE_STATUS = "SET_DAHSBOARD_AVAILABLE_STATUS"
export const SET_DASHBOARD_DEFAULT_STATUS = "SET_DASHBOARD_DEFAULT_STATUS"
export const SET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA_LOADING = "SET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA_LOADING";
export const SET_SELECTED_NODE_PARAMS_META = "SET_SELECTED_NODE_PARAMS_META";
export const SET_SELECTED_NODE_PARAMS_LIST = "SET_SELECTED_NODE_PARAMS_LIST";
export const SET_SELECTED_NODE_PARAMS_LIST_LOADING = "SET_SELECTED_NODE_PARAMS_LIST_LOADING";
export const SET_SELECTED_NODE_ONLINE_STATUS = "SET_SELECTED_NODE_ONLINE_STATUS";
export const SET_SELECTED_NODE_METRICS_DATE_TIME_RANGE = "SET_SELECTED_NODE_METRICS_DATE_TIME_RANGE";
export const GET_BILLING_USAGE_META_DATA = "GET_BILLING_USAGE_META_DATA";
export const SET_BILLING_USAGE_META_DATA = "SET_BILLING_USAGE_META_DATA";
export const SET_BILLING_USAGE_META_DATA_LOADING = "SET_BILLING_USAGE_META_DATA_LOADING";
export const GET_CURRENT_MONTH_USAGE = "GET_CURRENT_MONTH_USAGE";
export const SET_CURRENT_MONTH_USAGE = "SET_CURRENT_MONTH_USAGE";
export const SET_CURRENT_MONTH_USAGE_LOADING = "SET_CURRENT_MONTH_USAGE_LOADING";
export const RESET_USAGE_DATA = "RESET_USAGE_DATA";
export const SET_USAGE_INSIGHTS_DATE = "SET_USAGE_INSIGHTS_DATE";
export const SET_USAGE_INSIGHTS_FILTER = "SET_USAGE_INSIGHTS_FILTER";
export const SET_USAGE_INSIGHTS_SUB_FILTER = "SET_USAGE_INSIGHTS_SUB_FILTER";
export const SET_USAGE_INSIGHTS_SELECTED_ADMIN_ID = "SET_USAGE_INSIGHTS_SELECTED_ADMIN_ID";
export const SET_USAGE_INSIGHTS_SELECTED_INTERFACE = "SET_USAGE_INSIGHTS_SELECTED_INTERFACE";
export const SET_AUTH_KEYS_LIST = "SET_AUTH_KEYS_LIST";
export const SET_USAGE_INSIGHTS_DATA = "SET_USAGE_INSIGHTS_DATA";
export const SET_USAGE_INSIGHTS_DATA_LOADING = "SET_USAGE_INSIGHTS_DATA_LOADING";
export const SET_SELECTED_NODE_TAGS_LIST = "SET_SELECTED_NODE_TAGS_LIST"
export const SET_SELECTED_NODE_TAGS_LIST_LOADING = "SET_SELECTED_NODE_TAGS_LIST_LOADING";
export const SET_EVENT_COUNT_DISTRIBUTION_BY_INTERVAL_LOADING = "SET_EVENT_COUNT_DISTRIBUTION_BY_INTERVAL_LOADING";
export const SET_TOP_NODES_DISTRIBUTION_DATA_LOADING = "SET_TOP_NODES_DISTRIBUTION_DATA_LOADING";
export const SET_ALL_EVENTS_DATA_LOADING = "SET_ALL_EVENTS_DATA_LOADING";
export const SET_EVENT_COUNT_DISTRIBUTION_BY_CATEGORY_LOADING = "SET_EVENT_COUNT_DISTRIBUTION_BY_CATEGORY_LOADING";
export const SET_SELECTED_NODE_TIMELINE_AGGREGATOR_TYPE = "SET_SELECTED_NODE_TIMELINE_AGGREGATOR_TYPE";
export const SET_INVITATIONS_FOR_USER = "SET_INVITATIONS_FOR_USER";
export const SET_INVITATIONS_FOR_USER_LOADING = "SET_INVITATIONS_FOR_USER_LOADING";
export const SET_INVITATIONS_BY_USER = "SET_INVITATIONS_BY_USER";
export const SET_INVITATIONS_BY_USER_LOADING = "SET_INVITATIONS_BY_USER_LOADING";
export const SET_TENANTS_LIST = "SET_TENANTS_LIST";
export const SET_TENANTS_LIST_LOADING = "SET_TENANTS_LIST_LOADING";
export const SET_ACCOUNTS_LIST = "SET_ACCOUNTS_LIST";
export const SET_ACCOUNTS_LIST_LOADING = "SET_ACCOUNTS_LIST_LOADING";
export const SET_NODE_CONFIG_META = "SET_NODE_CONFIG_META";
export const SET_NODE_CONFIG_META_LOADING = "SET_NODE_CONFIG_META_LOADING";
