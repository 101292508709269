import React, { useContext } from 'react';
import WidgetWrapper from '../../Widgets/WidgetWrapper/WidgetWrapper';
import WidgetBox from '../../Widgets/WidgetBox/WidgetBox';
import PendingInvitations from './PendingInvitations/PendingInvitations';
import advancedInsightsContext from '../../context/insights-context';

function RightSection() {
    const { 
        messages
    } = useContext(advancedInsightsContext);

    return (
        <div className='right-section-wrapper'>
            <WidgetWrapper  
                wrapperStyles={{borderRadius: '10px'}} 
                containerStyles={{padding: '0px'}}
            >
                <WidgetBox heading={messages.manage_multi_admins.pending_invitations.heading}>
                    <PendingInvitations />
                </WidgetBox>
            </WidgetWrapper>
        </div>
    )
}

export default RightSection