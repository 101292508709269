import React, { useContext, useEffect, useMemo } from 'react';
import { ErrorLineButton } from '../../../components/Button/Button';
import AdvancedInsightsContext from '../context/insights-context';
import { removeInapplicableFiltersFromList } from '../data-transform/data-transform';
import FilterSelector from './FilterSelector/FilterSelector';
import './GlobalFilters.scss';
import SelectedFiltersTags from './SelectedFiltersTags/SelectedFiltersTags';

function GlobalFilters(props) {
  const { 
    messages, 
    getAllFiltersList,
    filtersList,
    getSuggestions,
    filterSuggestionData,
    addMultipleFiltersToSelectedFilters,
    removeFromSelectedFilters,
    selectedFilters,
    clearAllSelectedFilters,
    initialLoadCompleteStatus,
    filtersData
} = useContext(AdvancedInsightsContext);

  useEffect(() => {
    if (filtersList === null && (initialLoadCompleteStatus === true || props.fetchList === true)) {
      getAllFiltersList();
    }
  }, []);

  const applicableFiltersList = useMemo(() => removeInapplicableFiltersFromList(filtersList, props.hideFiltersWithPrefixes), [filtersList]);

  return (
    <div className='global-filters-container' style={props.containerStyles || null}>
      <FilterSelector 
        messages={messages} 
        filtersList={applicableFiltersList}
        getSuggestions={getSuggestions}
        filterSuggestionData={filterSuggestionData}
        addToSelectedFilters={addMultipleFiltersToSelectedFilters}
        selectedFilters={selectedFilters}
        filtersData={filtersData}
      />
      {
        selectedFilters.length ? (
          <ErrorLineButton 
            className='clear-filters-button' 
            onClick={clearAllSelectedFilters}
            style={{ minWidth : "122px"}}
          >
            Clear All Filters
          </ErrorLineButton>
        ) : null
      }
      <div className='filter-tags-container'>
        <SelectedFiltersTags 
          list={selectedFilters} 
          onDelete={removeFromSelectedFilters}
        />
      </div>
    </div>
  )
}

export default GlobalFilters