import React, { useEffect, useContext, useState, useRef } from 'react';
import AdvancedInsightsContext from './context/insights-context';
import GlobalFilters from './GlobalFilters/GlobalFilters';
import LoadingPage from './LoadingPage/LoadingPage';
import PageNavBar from './PageNavBar/PageNavBar';
import Widgets from './Widgets/Widgets';
import useNetworkInterceptor from './hooks/useNetworkInterceptor';
import SaveDashboardNotice from "../../components/AppNotices/SaveDashboardNotice/SaveDashboardNotice"
import { getUrlParameterByName } from "../../utils/commonFunctions"
import CustomToast from '../../components/CustomToast/CustomToast';
import {highlightElementText} from "../../utils/commonFunctions"
import { dashboardSettingsMenu } from "./PageNavBar/DashboardMenu/DashboardMenu.js"
import {dashboard_name_length_limit } from './general-config.json';

export default function AdvancedInsights() {
    useNetworkInterceptor();
    const dashboardNameRef = useRef();
    const [ dashboardDetails, setDashboardDetails] = useState(null)
    const { 
        dashboardList,
        initialLoadCompleteStatus,
        messages,
        switchDashboard,
        selectedDashboard,
        deleteDashboard,
        saveAsNewDashboard,
        setAsDefaultDashboard,
        isDashboardDefault,
        saveDashboard,
        renameDashboard,
        resetToDefaultDashboard,
    } = useContext(AdvancedInsightsContext);

    const dashboard_id = getUrlParameterByName("dashboard-id") || null;
    useEffect(() => {
        if(dashboard_id){
            switchDashboard(dashboard_id);}
        else
            resetToDefaultDashboard();
    }, [dashboard_id])


    useEffect(() => {
        // reset dashboard deatils to null if dashboard is not selected 
        if(!selectedDashboard || !dashboardList) {
            setDashboardDetails(null)
            return
        };

        // find dashboard from dashboard list for details { name, id, is_default }
        const dashboardDeatils = dashboardList.find( d => d.dashboard_id === selectedDashboard);
        setDashboardDetails(dashboardDeatils);

    }, [dashboardList, selectedDashboard])

    const handleMenuAction = async (actionType) => {
        let actionSucceed = false;
        switch (actionType) {
            case 'rename' :
                highlightElementText(dashboardNameRef)
                return;
            case 'save':
                actionSucceed =  await saveDashboard(selectedDashboard);
                break;
            case 'save-as-new' :
                actionSucceed = await saveAsNewDashboard();
                break
            case 'set-default':
                actionSucceed = await setAsDefaultDashboard(selectedDashboard);
                break
            case 'delete':
                actionSucceed = await deleteDashboard(selectedDashboard);
                break
            default:
                break;
        }
        if (actionSucceed) {
            CustomToast.success(messages.dashboard_messages[actionType]?.success, { autoClose: 2000 });
            return;
        }
          CustomToast.error(messages.dashboard_messages[actionType]?.failed,{ autoClose: 2000 });
    }

    if (initialLoadCompleteStatus === false) return <LoadingPage primaryText={messages.loading_page.primaryText} />

    // This function handle the dashboard rename
    const handleDashboardRename = async (e) => {
        const textContent = e.target.textContent.trim();
        if(textContent ==  dashboardDetails.dashboard_name) return;
        const response = await renameDashboard(selectedDashboard, textContent)
        if (response) {
          CustomToast.success("Renamed Successfully", { autoClose: 2000 });
          return;
        }
        CustomToast.error("Something went wrong, please try again !",{ autoClose: 2000 });
    }

    const handleKeyDown = (e) => {
        const contentLength = e.target.textContent.length;
        if(contentLength >= dashboard_name_length_limit && e.key !== 'Backspace') 
        {
            e.preventDefault();
        }
        if(e.key === 'Enter'){
            e.preventDefault();
            handleDashboardRename(e)
        }
    }

    return (
        <div>
            <PageNavBar 
                //tabsData={dashboardTabs}
                pageHeading={ 
                    dashboardDetails ?
                        <>
                            Dashboard : <span className='dashboard-lable' 
                                            ref={dashboardNameRef} contentEditable  
                                            onBlur={(e)=> handleDashboardRename(e)}
                                            onKeyDown={handleKeyDown}    
                                            suppressContentEditableWarning={true} 
                        >
                             {dashboardDetails.dashboard_name}
                        </span> 
                        </>
                        
                    : null}
                selectedTab={selectedDashboard}
                onTabChange={switchDashboard}
                onTabRename={renameDashboard}
                allowTabRename
                renameLabel= {"Rename Dashboard"}
                actionHandler= {handleMenuAction}
                settingOptions={dashboardSettingsMenu}
                menuActionHandler={handleMenuAction}
                menuOptionCondition = {{
                    system: dashboard_id === null,
                    default: isDashboardDefault,
                    custom: dashboard_id !== null,
                }}
            />
            { initialLoadCompleteStatus && dashboardList?.length == 0 &&  
                <SaveDashboardNotice notice={messages.no_dashboard.notice} action={saveAsNewDashboard}/> 
            }

            <div className='d-flex'>
                <div className='flex-grow-1'>
                    <GlobalFilters />
                </div>
            </div>
            <Widgets />
        </div>
    )
}
