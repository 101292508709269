import React from 'react';
import './TabContent.scss';

function TabContent({data}) {
    if (!data) return null;

    return (
        <div className={`tab-content-container ${data.id}`}>
            <data.component />
        </div>
    )
}

export default TabContent