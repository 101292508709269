import React, { useState, useEffect, useRef } from 'react';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import './SelectDropdown.scss';

const SelectDropdown = (props) => {
	const node = useRef();

	const {
		className,
		id,
		placeholder,
		options,
		onChange,
		disabled,
		defaultValue,
		projectDropdown,
		autoListWidth = false
	} = props;

	const [showList, setShowList] = useState(false);
	const [value, setValue] = useState({});

	const handleClickOutside = (e) => {
		if (node.current.contains(e.target)) {
			// inside click
			return;
		}
		// outside click
		setShowList(false);
	};

	useEffect(() => {
		if (showList) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [showList]);

	useEffect(() => {
		if (defaultValue?.label) {
			setValue(defaultValue);
		}
	}, [JSON.stringify(defaultValue)]);

	const handleSelect = (item) => {
		setValue(item.label !== placeholder ? item : '');
		onChange(item.label !== placeholder ? item : '');
		setShowList(false);
	};

	const listWidthCssClass = autoListWidth ? "w-auto" : "";
	return (
		<div
			style={{ position: 'relative' }}
			className={`${className}`}
			ref={node}
		>
			<button
				className={`input`}
				id={id}
				type='button'
				onClick={() => setShowList((showList) => !showList)}
				disabled={disabled}
			>
				{value.label ? (
					<span className={projectDropdown ? 'value font-weight-bold' : 'value'}>{value.label}</span>
				) : (
					<span className='placeholder'>{placeholder || ''}</span>
				)}
				{!disabled &&
					(showList ? (
						<UilAngleUp size={projectDropdown ? 15 : 12} color={projectDropdown ? '#000' : '#fff'} title='Close' />
					) : (
						<UilAngleDown size={projectDropdown ? 15 : 12} color={projectDropdown ? '#000' : '#fff'} title='Open' />
					))}
			</button>

			{showList && (
				<ul className={`select-dropdown-list ${listWidthCssClass}`}>
					{options.map((item) => (
						<li
							onClick={() => handleSelect(item)}
							key={`${item.value}${Math.random() * 10}`}
						>
							{item.label}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default SelectDropdown;
