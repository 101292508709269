import { orderBy } from 'lodash';
import React from 'react';
import './CreditUnitsTable.scss';

function CreditUnitsTable({data, textLabels}) {
    if (!data) return null;
    
    const creditsMetaData = orderBy(Object.keys(data).map(key => ({id: key, label: data[key].label, units: data[key].unit_credits})), ['units'], ['desc']);

    return (
        <div className='credit-units-table-wrapper'>
            <div className='headline'>
                {textLabels.headline}
            </div>
            <table>
                <tbody>
                    {
                        creditsMetaData.map(({id, label, units}) => (
                            <tr key={id}>
                                <td className='event-key'>{label}</td>
                                <td className='units'>
                                    <span className='number'>{units}</span> {textLabels.credit_unit}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default CreditUnitsTable