import React, { useState, useEffect } from "react";
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import Table from "./Table";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom";
import InsightsTimeFilter from "./InsightsTimeFilter";
import InsightsMetrics from "./InsightsMetrics";
import InsightsLineChart from "./InsightsLineChart";
import InsightsPieChart from "./InsightsPieChart";
import {
	getProjects,
	getNodesByVersionCount,
	getEventsCounts,
	getOTAUpgrades,
	getEventsData,
	getEventSubTypes,
	getNodesListBySubTypes,
	getEventsSubTypeForPieChart,
	getSubEventsData
} from "../../utils/insightsAPI";
import { get } from "lodash-es";
import { getUnixTime, subMonths } from "date-fns";
import ErrorPage from "./ErrorPage";
import AppNotices from "../../components/AppNotices/AppNotices";
import { Alert } from "react-bootstrap";

const tableEventOptions = [
	{
		label: 'Events',
		value: 'events',
	},
	{
		label: 'Crashes',
		value: 'crashes',
	},
	{
		label: 'Errors',
		value: 'errors',
	},
	{
		label: 'Warnings',
		value: 'warnings',
	},
	{
		label: 'Reboots',
		value: 'reboots',
	}
]

const Insights = (props) => {

	const [cardLoading, setCardLoading] = useState(true);
	const [eventDataLoading, setEventDataLoading] = useState(true);
	const [projectsListLoading, setProjectsListLoading] = useState(true);
	const defaultVersion = null;
	const defaultEvent = "crashes"
	const defaultTimeInterval = "1d";
	const defaultData = [];
	const defaultFromTime = getUnixTime(subMonths(new Date(), 1));
	const defaultToTime = getUnixTime(new Date());

	const chartMetaData =
	{
		'line_chart_nodes': { id: "line_chart_nodes", version: defaultVersion, eventType: defaultEvent, timeInterval: defaultTimeInterval, data: defaultData },
		'line_chart_unique': { id: "line_chart_unique", version: defaultVersion, eventType: defaultEvent, timeInterval: defaultTimeInterval, data: defaultData },
		'line_chart_nodes_pie': { id: "line_chart_nodes_pie", version: defaultVersion, eventType: defaultEvent, subEventType: '', timeInterval: defaultTimeInterval, data: defaultData },
		'line_chart_unique_pie': { id: "line_chart_unique_pie", version: defaultVersion, eventType: defaultEvent, subEventType: '', timeInterval: defaultTimeInterval, data: defaultData },
	}
	const [fromTime, setFromTime] = useState(defaultFromTime);
	const [toTime, setToTime] = useState(defaultToTime);

	/**
	 * metrics & project selection
	 */
	const [selectedProject, setSelectedProject] = useState(null);
	const [projectsList, setProjectsList] = useState([]);
	const [nodesByVersion, setNodesByVersion] = useState(null);
	const [allEventsCount, setAllEventsCount] = useState([])
	/**
	 * contains data for each chart 
	 * as its array element.
	 */
	const [chartMeta, setChartMeta] = useState(chartMetaData)


	/**
	 * Event/Sub-event distribution pie-charts 
	 */
	const [selectedPieVersion, setSelectedPieVersion] = useState(null)
	const [eventsPieChartData, setEventsPieChartData] = useState([])
	const [selectedPieChartSubEventType, setSelectedPieChartSubEventType] = useState(null)
	/**
 * 
 * unique node tabluar data
 * 
 */
	const [tableVersion, setTableVersion] = useState(null);
	const [tableEventType, setTableEventType] = useState(tableEventOptions[0]);
	const [tableEventSubTypeOptions, setTableEventSubTypeOptions] = useState([]);
	const [selectedTableEventSubType, setSelectedTableEventSubType] = useState(null);
	const [tableData, setTableData] = useState([])

	const columns = React.useMemo(
		() => [
			{
				Header: 'Node Id',
				accessor: (row) => (
					<div className='d-flex align-items-center '>
						{window.DIAGNOSTIC_OPTION ? (
							<Link
								to={{
									pathname: `/home/diagnostics/node/${row.node_id}/report`,
									state: { nodeId: row.node_id, nodeKey: row.node_key }
								}}
								params={{ nodeKey: null }}
							>
								<p className='mb-0'>{row.node_id}</p>
							</Link>
						) : (
							row.node_id
						)}
					</div>
				),
			},
			{
				Header: 'Count',
				accessor: 'count',
			},
		],
		[]
	)

	function getTableVersionOptions() {
		const list = get(nodesByVersion, 'distribution', null) ? nodesByVersion.distribution.map(d => ({ value: d.name, label: d.name })) : []
		let options;
		options = list.map(item => ({ label: item.label, value: item.value })) || [];
		options.push({ label: 'All', value: 'all' })
		return options;
	}

	/**
	 * on component mount,
	 * fetch the project list.
	 * this populates initially selected project as well.
	 */
	useEffect(() => {
		function fetchProjects() {
			setProjectsListLoading(true);
			getProjects().then(data => {
				if (data.status === "failure" && data.description === "Unauthorized") {
					props.sessionExpire();
				}
				else {
					if (data.list && data.list.length) {
						setProjectsList(data.list.filter(item => item.length))
						setSelectedProject(data.list[0])
					}
				}

				setProjectsListLoading(false);
			})
		}
		fetchProjects()
	}, [])

	/**
	 * 
	 * fetch count of all events related to that selectedProject.
	 * also filters on the basis of selected fromTime & toTime.
	 * 
	 * ota_upgrade request returns a blank event_type.
	 */
	useEffect(() => {
		function fetchAllEventsCount() {
			const requests = ['crash', 'event', 'error', 'warning', 'reboot'].map(event => {
				return getEventsCounts(selectedProject, event, fromTime, toTime)
			})
			requests.push(getOTAUpgrades(selectedProject, fromTime, toTime))
			Promise.all(requests).then(responses => {
				const sanitiseResponses = responses.map(r => {
					if (!r.event_type?.length) {
						return { ...r, event_type: 'ota_upgrade' }
					}
					return r
				})
				setAllEventsCount(sanitiseResponses)
			})
		}
		function getNodesByVersion() {
			getNodesByVersionCount(selectedProject).then(response => {
				if (response.description === 'Unauthorized') {
					return;
				}
				setNodesByVersion(response)
				if (response && response.distribution) {
					setSelectedPieVersion(response.distribution[0].version)
					setTableVersion(response.distribution[0].name)
				}
				getChartsData(response)
				getEventsBySubtype(response)
				getTableData(response)
			})
		}

		/***
		 * we have to reset the chartMeta when project is changed as same data might be used 
		 * for other projects as well and that option might not be available for that project,
		 * so better to reset it to default values. 
		 */
		function resetChartMeta() {
			const _chartMeta = {
				...chartMeta, 'line_chart_nodes': {
					data: defaultData,
					version: defaultVersion,
					eventType: defaultEvent,
					timeInterval: defaultTimeInterval
				}, 'line_chart_unique': {
					data: defaultData,
					version: defaultVersion,
					eventType: defaultEvent,
					timeInterval: defaultTimeInterval
				}, 'line_chart_nodes_pie': {
					data: defaultData,
					version: defaultVersion,
					eventType: defaultEvent,
					subEventType: "",
					timeInterval: defaultTimeInterval
				}, 'line_chart_unique_pie': {
					data: defaultData,
					version: defaultVersion,
					eventType: defaultEvent,
					subEventType: "",
					timeInterval: defaultTimeInterval
				}
			}

			setChartMeta(_chartMeta)
		}
		if (selectedProject) {
			getNodesByVersion()
			fetchAllEventsCount()
		}
		if (fromTime || toTime) {
			resetChartMeta()
		}
	}, [selectedProject, fromTime, toTime])

	const updateSubTypes = subTypes => {
		if (!subTypes.list) return null;
		
		let list = subTypes.list;
		list.push('all');
		setTableEventSubTypeOptions(list);
		setSelectedTableEventSubType(list[0]);
	}

	const updateTableData = response => {
		if (!response) return null;
		
		setCardLoading(false);
		let data = [];
		if (response.distribution) {
			data = response.distribution.map((item) => ({ node_id: item.node_id, count: item.count, ...(item.node_key) && {node_key: item.node_key} }))
		}
		setTableData(data);
	}

	const getChartsData = async (selectedProject) => {
		setEventDataLoading(true);
		const { distribution, project } = selectedProject
		if (distribution && distribution.length) {
			const response = await getEventsData(project, distribution[0].name, 'crashes', '1d', fromTime, toTime)
			setEventDataLoading(false);
			const _chartMeta = {
				...chartMeta, 'line_chart_nodes': {
					data: response.distribution,
					version: response.version,
					eventType: 'crashes',
					timeInterval: defaultTimeInterval
				}, 'line_chart_unique': {
					data: response.distribution,
					version: response.version,
					eventType: 'crashes',
					timeInterval: defaultTimeInterval
				}
			}

			setChartMeta(_chartMeta)
		}
	}

	const getEventsBySubtype = async (project) => {
		const { distribution } = project
		const requests = ['crash', 'event', 'warning', 'error', 'reboot'].map(event => {
			return getEventsSubTypeForPieChart(selectedProject, distribution[0].name, event, fromTime, toTime)
		})
		const response = await Promise.all(requests)
		setEventsPieChartData(response)
	}
	const getTableData = async (project) => {
		const { distribution } = project
		let subTypes = await getEventSubTypes(selectedProject, tableEventType.value, distribution[0].name)
		let response = await getNodesListBySubTypes(selectedProject, distribution[0].name, tableEventType.value, subTypes.list[0], fromTime, toTime);
		if (response.description === 'Unauthorized') {
			return;
		}
		
		updateSubTypes(subTypes);
		updateTableData(response);
		setTableVersion(distribution[0].name);
	}

	/**
	 * callback handlers START
	 */

	/**
	 * common update chart meta utility
	 * called on dropdown options updated
	 * 
	 * Based on the presence of subtype, set the chartMeta for particular id, 
	 * as charts displayed on pieChart click has subtypes to be displayed
	 * with eventType.
	 * 
	 * Also avoid setting the version, eventType using response as we might
	 * get these values as an empty string from the api response itself. 
	 */
	const updateChartMeta = (response, id, version, eventType, timeInterval, subType) => {
		if (subType) {
			const _chartMeta = {
				...chartMeta, [id]: {
					data: response.distribution,
					version: version,
					eventType: eventType,
					timeInterval: timeInterval,
					subEventType: subType
				}
			}
			setChartMeta(_chartMeta)
		} else {
			const _chartMeta = {
				...chartMeta, [id]: {
					data: response.distribution,
					version: version,
					eventType: eventType,
					timeInterval: timeInterval
				}
			}
			setChartMeta(_chartMeta)
		}
	}

	/**
	 * For timeInterval change in line_chart_nodes_pie and line_chart_unique_pie,
	 * we have to fetch the data using getSubeventsData api, so we have to conditionally call the 
	 * api based on subType availability.
	 */
	const handleTimeIntervalChange = async (id, interval, subType) => {
		try {
			if (subType) {
				const response = await getSubEventsData(selectedProject, chartMeta[id].version, chartMeta[id].eventType, subType, interval, fromTime, toTime)
				updateChartMeta(response, id, chartMeta[id].version, chartMeta[id].eventType, interval, subType)
			}
			else {
				const response = await getEventsData(selectedProject, chartMeta[id].version, chartMeta[id].eventType, interval, fromTime, toTime)
				updateChartMeta(response, id, chartMeta[id].version, chartMeta[id].eventType, interval, subType)
			}
		} catch (error) {
			console.log({ error })
		}
	}
	const handleVersionChange = async (id, version) => {
		try {
			const response = await getEventsData(selectedProject, version, chartMeta[id].eventType, chartMeta[id].timeInterval, fromTime, toTime)
			updateChartMeta(response, id, version, chartMeta[id].eventType, chartMeta[id].timeInterval)
		} catch (error) {
			console.log({ error })
		}
	}

	const handleTypeChange = async (id, type) => {
		try {
			const response = await getEventsData(selectedProject, chartMeta[id].version, type, chartMeta[id].timeInterval, fromTime, toTime)
			updateChartMeta(response, id, chartMeta[id].version, type, chartMeta[id].timeInterval)
		} catch (error) {
			console.log({ error })
		}
	}


	/*
	* We have to set the selectedPieChartSubEventType to null as the
	* selected eventSubType might not be present for the other versions,
	* so displaying that eventSubType details don't make sense 
	*/
	const handleEventsPieChartVersionChange = async (version) => {
		setSelectedPieChartSubEventType(null)
		const requests = ['crash', 'event', 'error', 'warning', 'reboot'].map(event => {
			return getEventsSubTypeForPieChart(selectedProject, version, event, fromTime, toTime)
		})
		const response = await Promise.all(requests)
		setSelectedPieVersion(version)
		setEventsPieChartData(response)
	}
	const handleSubEventPieChartClick = async (eventType, subEvent) => {
		try {
			const response = await getSubEventsData(selectedProject, selectedPieVersion, eventType,
				subEvent, '1d', fromTime, toTime)
			const _chartMeta = {
				...chartMeta, 'line_chart_nodes_pie': {
					data: response.distribution,
					version: selectedPieVersion,
					eventType: eventType,
					subEventType: subEvent,
					timeInterval: defaultTimeInterval
				}, 'line_chart_unique_pie': {
					data: response.distribution,
					version: selectedPieVersion,
					eventType: eventType,
					subEventType: subEvent,
					timeInterval: defaultTimeInterval
				}
			}
			setChartMeta(_chartMeta)
			setSelectedPieChartSubEventType(subEvent)
		} catch (error) {
			console.log(error)
		}
	}
	const handleTableVersionChange = async (version) => {
		try {
			setCardLoading(true);
			let subTypes = await getEventSubTypes(selectedProject, tableEventType.value, version.value)

			if (subTypes.list) {
				let response = await getNodesListBySubTypes(selectedProject, version.value, tableEventType.value, subTypes.list[0], fromTime, toTime);
				if (response.description === 'Unauthorized') {
					return;
				}

				updateSubTypes(subTypes);
				updateTableData(response);
				setTableVersion(version.value);
			}
			else {
				setTableData([])
				setTableVersion(version.value)
				setTableEventSubTypeOptions([])
			}
		} catch (error) {
			console.log({ error })
		}
	}
	const handleTableEventChange = async (event) => {
		setCardLoading(true);
		let subTypes = await getEventSubTypes(selectedProject, event.value, tableVersion)
		if (subTypes.list) {
			let response = await getNodesListBySubTypes(selectedProject, tableVersion, event.value, subTypes.list[0], fromTime, toTime);
			if (response.description === 'Unauthorized') {
				return;
			}

			updateSubTypes(subTypes);
			updateTableData(response);
			setTableEventType(event);
		}
		else {
			setTableData([])
			setTableEventType(event)
			setTableEventSubTypeOptions([])
		}
	}
	const handleTableSubEventChange = async ({ label, value }) => {
		setCardLoading(true);
		let response = await getNodesListBySubTypes(selectedProject, tableVersion, tableEventType.value, value, fromTime, toTime);
		if (response.description === 'Unauthorized') {
			return;
		}
		
		updateTableData(response);
		setSelectedTableEventSubType(value)
	}
	/**
	 * callbacks END
	 */

	return !projectsListLoading && (projectsList?.length > 0) ? (
		<>
			<div>
				<div className='diagnostic--page--wrapper'>
					<div className='row'>
						<div className='col-lg-12'>
							<InsightsTimeFilter
								fromTime={fromTime}
								toTime={toTime}
								projectsList={projectsList}
								selectedProject={selectedProject}
								onFromDateChange={setFromTime}
								onToDateChange={setToTime}
								onProjectChange={setSelectedProject}
								loading={true}
								disableFutureDatesSelection={true}
							/>
							<AppNotices />
							<div>
								<InsightsMetrics
									nodesByVersion={nodesByVersion}
									allEventsCount={allEventsCount}
									project={selectedProject}
									loading={true}
								/>
								<InsightsLineChart
									id='line_chart_nodes'
									fromTime={fromTime}
									toTime={toTime}
									graphData={chartMeta['line_chart_nodes'].data}
									versionList={get(nodesByVersion, 'distribution', null) ? nodesByVersion.distribution.map(d => d.name) : []}
									timeInterval={chartMeta['line_chart_nodes'].timeInterval}
									type={chartMeta['line_chart_nodes'].eventType}
									version={chartMeta['line_chart_nodes'].version}
									onTimeIntervalChange={handleTimeIntervalChange}
									onVersionChange={handleVersionChange}
									onTypeChange={handleTypeChange}
									showTypes
									showVersions
									eventDataLoading={eventDataLoading}
								/>
								<InsightsLineChart
									id='line_chart_unique'
									fromTime={fromTime}
									toTime={toTime}
									graphData={chartMeta['line_chart_unique'].data}
									versionList={get(nodesByVersion, 'distribution', null) ? nodesByVersion.distribution.map(d => d.name) : []}
									timeInterval={chartMeta['line_chart_unique'].timeInterval}
									type={chartMeta['line_chart_unique'].eventType}
									version={chartMeta['line_chart_unique'].version}
									onTimeIntervalChange={handleTimeIntervalChange}
									onVersionChange={handleVersionChange}
									onTypeChange={handleTypeChange}
									showTypes
									showVersions
									uniqueNode
									eventDataLoading={eventDataLoading}
								/>
								{
									eventsPieChartData &&
									<InsightsPieChart
										pieChartData={eventsPieChartData}
										versionList={get(nodesByVersion, 'distribution', null) ? nodesByVersion.distribution.map(d => d.name) : []}
										onSubEventTypeClick={handleSubEventPieChartClick}
										onPieChartVersionChange={handleEventsPieChartVersionChange}
									/>
								}
								{
									selectedPieChartSubEventType &&
									<>
										<InsightsLineChart
											id='line_chart_nodes_pie'
											fromTime={fromTime}
											toTime={toTime}
											graphData={chartMeta['line_chart_nodes_pie'].data}
											versionList={get(nodesByVersion, 'distribution', null) ? nodesByVersion.distribution.map(d => d.name) : []}
											timeInterval={chartMeta['line_chart_nodes_pie'].timeInterval}
											type={chartMeta['line_chart_nodes_pie'].eventType}
											subType={chartMeta['line_chart_nodes_pie'].subEventType}
											version={selectedPieVersion}
											onTimeIntervalChange={handleTimeIntervalChange}
											onVersionChange={handleVersionChange}
											onTypeChange={handleTypeChange}
											showTypes={false}
											showVersions={false}
										/>
										<InsightsLineChart
											id='line_chart_unique_pie'
											fromTime={fromTime}
											toTime={toTime}
											graphData={chartMeta['line_chart_unique_pie'].data}
											versionList={get(nodesByVersion, 'distribution', null) ? nodesByVersion.distribution.map(d => d.name) : []}
											timeInterval={chartMeta['line_chart_unique_pie'].timeInterval}
											type={chartMeta['line_chart_unique_pie'].eventType}
											subType={chartMeta['line_chart_unique_pie'].subEventType}
											version={selectedPieVersion}
											onTimeIntervalChange={handleTimeIntervalChange}
											onVersionChange={handleVersionChange}
											onTypeChange={handleTypeChange}
											showTypes={false}
											showVersions={false}
											uniqueNode
										/>
									</>
								}
								<div className='card--container d-flex flex-column diagnostic--card align-items-center mt-3'>
									<div className='w-100'>
										<div className="p-2 d-flex flex-row">
											<div className='card-heading d-flex flex-grow-1'>
												<div className='title'>
													<span className='primary'>Nodes for</span>
													<span className='highlight'>{selectedProject}</span>
												</div>
											</div>
											<div className='d-flex flex-row'>
												<SelectDropdown
													placeholder='Select Version'
													id='Table version'
													defaultValue={get(nodesByVersion, 'distribution', null) ? ({ value: nodesByVersion.distribution[0].name, label: nodesByVersion.distribution[0].name }) : null}
													// options={get(nodesByVersion, 'distribution', null) ? nodesByVersion.distribution.map(d => ({ value: d.name, label: d.name })) : []}
													options={getTableVersionOptions()}
													className='navbar--dropdown select-dropdown-for-report px-1 ml-auto'
													onChange={handleTableVersionChange}
												/>
												<SelectDropdown
													placeholder='Select Event'
													id='Event'
													defaultValue={tableEventOptions[0]}
													options={tableEventOptions}
													className='navbar--dropdown select-dropdown-for-report '
													onChange={handleTableEventChange}
												/>
												<SelectDropdown
													placeholder="Select Category"
													disabled={tableEventSubTypeOptions.length === 0}
													id='subEvent'
													defaultValue={tableEventSubTypeOptions.length ? {id: tableEventSubTypeOptions[0], label: tableEventSubTypeOptions[0]} : null}
													options={tableEventSubTypeOptions.length ? tableEventSubTypeOptions.map(o => ({ label: o, value: o })) : []}
													className='navbar--dropdown select-dropdown-for-report px-1'
													onChange={handleTableSubEventChange}
												/>
											</div>
										</div>
										<div className='card--container d-flex flex-column justify-content-between'>
											{tableData.length > 0 ? (
												<div className='row'>
													<div className='col-lg-12 h-100'>
														<Table
															data={tableData}
															columns={columns}
															loading={cardLoading}
															loaders={4}
														></Table>
													</div>
												</div>
											) : (<div className='tabledata-container'>
												<p className='color-primary'>
													No Data Available
												</p>
											</div>)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	) : (
		<div className='diagnostic--page--wrapper'>
			<ErrorPage loading={projectsListLoading} message="No projects available" />
		</div>
	);
};
export default Insights;