import React, { useState, useEffect, useContext, useReducer } from 'react';
import './UserTenants.scss';
import advancedInsightsContext from '../../../../context/insights-context';
import NoDataMessage from '../../../../Widgets/NoDataMessage/NoDataMessage';
import CustomToast from '../../../../../../components/CustomToast/CustomToast';
import WidgetExpandableListItem from '../../../../Widgets/WidgetExpandableListItem/WidgetExpandableListItem';
import { UilPlus } from '@iconscout/react-unicons';
import InviteNewUser from './InviteNewUser/InviteNewUser';
import { format, fromUnixTime } from 'date-fns';
import LinkIconButton from '../../../../Widgets/LinkIconButton/LinkIconButton';
import ConfirmRevokeAccess from './ConfirmRevokeAccess/ConfirmRevokeAccess';
import { deleteAccountsTenant } from '../../../../../../utils/diagQueryAPI';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import usePagination from '../../../../hooks/usePagination';

function UserTenants() {
    const { 
        messages,
        tenantsList,
        tenantsListLoading,
        getTenantsList,
        getInvitationsByUser
    } = useContext(advancedInsightsContext);

    const componentInitialLocalState = {
        userActions: {},
        showInviteUserModal: false,
        showConfirmRevokeAccessModal: false,
        selectedTenantId: null,
        nextPagetoken: ""
    }

    const [componentLocalState, updateComponentLocalState] = useReducer((state, newState) => ({
        ...state, ...newState
    }), componentInitialLocalState);

    const { pagetoken, Pagination } = usePagination(componentLocalState.nextPagetoken);

    const userTenantsList = (tenantsList && tenantsList.list) ? tenantsList.list.filter(u => u.tenant_id) : [];

    useEffect(() => {
        getTenantsList(pagetoken);
    }, [pagetoken]);

    useEffect(() => {
        updateComponentLocalState({
            nextPagetoken: tenantsList?.page_token || ""
        });
    }, [tenantsList]);

    const confirmRevokeAccess = (e, tenant_id) => {
        e.stopPropagation();
        updateComponentLocalState({
            selectedTenantId: tenant_id,
            showConfirmRevokeAccessModal: true
         });
    }

    const handleRevokeAccess = async (tenant_id) => {
        const revokeAccessResponse = await deleteAccountsTenant({tenant_id});
        if (!revokeAccessResponse?.status || (revokeAccessResponse?.status !== 200) || (revokeAccessResponse?.data?.status === "failure")) {
            CustomToast.error('Error revoking access of this account. Please try again!', { autoClose: 5000 }, 'revoke-access-toast');
            return;
        }
        
        updateComponentLocalState({
            userActions: { ...componentLocalState.userActions, [tenant_id]: false },
            showConfirmRevokeAccessModal: false
        })
    }

    if (tenantsListLoading) return <LoadingSpinner wrapperClassName='user-tenants-wrapper' />
    
    return (
        <div className='user-tenants-wrapper'>
            <div className='header-actions'>
                <LinkIconButton 
                    iconComponent={<UilPlus />}
                    text="Invite to access"
                    clickHandler={() => updateComponentLocalState({showInviteUserModal: true})}
                />
            </div>
            <div className='tenants-list'>
                {
                    (tenantsList && tenantsList.list) ? (
                        <>
                            {
                                userTenantsList.map(userTenant => (
                                    <WidgetExpandableListItem 
                                        key={userTenant.tenant_id}
                                        title={userTenant.tenant_email_id}
                                        text={userTenant.role}
                                        tip={`Created at: ${format(fromUnixTime(userTenant.created_at), "dd/MM/yy")}`}
                                        rejectActionText={"Revoke access"}
                                        rejectActionHandler={e => confirmRevokeAccess(e, userTenant.tenant_id)}
                                        acceptAction={
                                            componentLocalState.userActions.hasOwnProperty(userTenant.tenant_id) ? 
                                            componentLocalState.userActions[userTenant.tenant_id] : 
                                            null
                                        }
                                        acceptActionInfoMessage={
                                            componentLocalState.userActions[userTenant.tenant_id] ? 
                                            "" : 
                                            "Access revoked"
                                        }
                                        detailsData={userTenant}
                                    />
                                ))
                            }
                        </>
                    ) : (
                        <NoDataMessage text="You've not invited anyone to access your ESP Insights account" />
                    )
                }
            </div>

            <Pagination />

            <InviteNewUser 
                showModal={componentLocalState.showInviteUserModal} 
                setShowModal={() => updateComponentLocalState({showInviteUserModal: !componentLocalState.showInviteUserModal})} 
                refreshTenantsList={getInvitationsByUser}
            />

            <ConfirmRevokeAccess 
                showModal={componentLocalState.showConfirmRevokeAccessModal} 
                setShowModal={() => updateComponentLocalState({showConfirmRevokeAccessModal: !componentLocalState.showConfirmRevokeAccessModal})} 
                onConfirm={() => handleRevokeAccess(componentLocalState.selectedTenantId)}
            />
        </div>
    )
}

export default UserTenants