import React from 'react';
import { Spinner } from 'react-bootstrap';
import './LoadingSpinner.scss';

function LoadingSpinner({size = "sm", wrapperClassName = ""}) {
    return (
        <div className={`loading-spinner-wrapper ${wrapperClassName}`}>
            <Spinner 
                size={size}
                animation='border'
            />
        </div>
    )
}

export default LoadingSpinner