import React, {useState, useEffect} from 'react';
import './IntervalSelectorTags.scss';

function IntervalSelectorTags({
    clickHandler = null,
    defaultSelectedInterval = "hour",
    labels,
    disabledIntervalsList = []
}) {
    const intervals = [
        {
            id: "hour",
            value: 60 * 60
        },
        {
            id: "day",
            value: 24 * 60 * 60
        },
        {
            id: "week",
            value: 7 * 24 * 60 * 60
        },
        {
            id: "month",
            value: 30 * 24 * 60 * 60
        }
    ];

    const applicableIntervals = intervals.filter(({value}) => !disabledIntervalsList.includes(value));

    const [selectedInterval, setSelectedInterval] = useState(defaultSelectedInterval || applicableIntervals[0].value);

    const handleButtonClick = interval => {
        setSelectedInterval(interval);
        clickHandler(interval);
    }

    useEffect(() => {
        if (disabledIntervalsList.includes(selectedInterval) && applicableIntervals.length) {
            const newInterval = applicableIntervals[0].value;
            handleButtonClick(newInterval);
        }
    }, [disabledIntervalsList]);

    return (
        <div className='interval-selector-tags selected-metric-tags-container'>
            {
                applicableIntervals.map(interval => (
                    <button onClick={() => handleButtonClick(interval.value)} key={interval.id}>
                        <span className={interval.value === selectedInterval ? "active tag" : "tag"}>
                            { labels[interval.id] }
                        </span>
                    </button>
                ))
            }
        </div>
    )
}

export default IntervalSelectorTags