import React, { useEffect, useState } from 'react';
import MultiLineChart from './MultiLineChart';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import { getIntervalsList, getGraphData } from './insightsUtilityFunctions';
import { find } from 'lodash-es';

const LineChartHeading = ({
    primary,
    secondary,
    type,
    version,
    graphData,
    showEventType=false
}) => {
    if (!version) return null;
    
    const totalCount = graphData && graphData.length ? graphData.reduce((currentValue, {count}) => currentValue + count, 0) : 0;
    const versionName = version === 'all' ? 'all versions' : "version " + version;

    return (
        <div className='card-heading'>
            <div className='title'>
                <span className='primary'>{primary}</span>
                <span className='secondary highlight'>{secondary}</span>
                for <span className='highlight'>{versionName}</span>
            </div>
            <div className='count'>
                Total count: <span className='highlight'>{totalCount}</span>
            </div>
            {
                showEventType ? (
                    <div className='event-type'>
                        Event type: {type}
                    </div>
                ) : null
            }
        </div>
    )
}

const InsightsLineChart = (props) => {
    const {
        id,
        fromTime,
        toTime,
        timeInterval,
        type,
        subType,
        version,
        versionList,
        graphData,
        eventDataLoading
    } = props

    
    const versionOptions = versionList.map(item => ({ label: item, value: item })) || []
    versionOptions.push({ label: 'All', value: 'all' })
    let data;
    let intervals;
    let formattedGraphData = [];
    if (props.uniqueNode) {
        if (graphData !== null) {
            data = graphData.map((item) => ({ 'ts': item.ts, 'count': item.distinct_node_count }))
        }
        else {
            data = [];
        }
    }
    else {
        if (graphData !== null) {
            data = graphData.map((item) => ({ 'ts': item.ts, 'count': item.count }));
        }
        else {
            data = [];
        }
    }
    const typeOptions = [
        {
            label: 'Crashes',
            value: 'crashes',
        },
        {
            label: 'Events',
            value: 'events',
        },
        {
            label: 'Errors',
            value: 'errors',
        },
        {
            label: 'Warnings',
            value: 'warnings',
        },
        {
            label: 'Reboots',
            value: 'reboots',
        }
    ];
    const timeIntervalOptions = [
        {
            label: 'Hour',
            value: '1h',
        },
        {
            label: 'Day',
            value: '1d',
        },
        {
            label: 'Week',
            value: '1w',
        },
        {
            label: 'Month',
            value: '1m',
        },
        {
            label: 'Year',
            value: '1y',
        },
    ];

    if (data.length > 0) {
        intervals = getIntervalsList(fromTime, toTime, timeInterval);
        formattedGraphData = getGraphData(timeInterval, intervals, data);
    }
    function getTotalCount(data) {
        let count = 0;
        if (data && data.length) {
            count = data.reduce((currentValue, {count}) => currentValue + count, 0);
        }
        return count;
    }

    function handleTimeIntervalChange(value) {
        if (subType) {
            props.onTimeIntervalChange(props.id, value, subType)
        }
        else {
            props.onTimeIntervalChange(props.id, value)

        }
    }
    function handleVersionChange(value) {
        props.onVersionChange(props.id, value)
    }
    function handleTypeChange(value) {
        props.onTypeChange(props.id, value);
    }


    return (
        <>
            <div className='card--container d-flex flex-column diagnostic--card align-items-center mt-3'>
                <div className='w-100'>
                    <div className="p-2 d-flex flex-row">
                        {props.showTypes ? !props.uniqueNode ? (
                                <LineChartHeading 
                                    primary="Count of"
                                    secondary={type}
                                    type={type}
                                    version={version}
                                    graphData={formattedGraphData}
                                    showEventType={false}
                                />
                            ) : (
                                <LineChartHeading 
                                    primary="Number of unique nodes with"
                                    secondary={type}
                                    type={type}
                                    version={version}
                                    graphData={formattedGraphData}
                                    showEventType={false}
                                />
                            ) : props.uniqueNode ? (
                                <LineChartHeading 
                                    primary="Number of unique nodes with"
                                    secondary={subType}
                                    type={type}
                                    version={version}
                                    graphData={formattedGraphData}
                                    showEventType={true}
                                />
                                ) : (
                                    <LineChartHeading 
                                        primary="Count of"
                                        secondary={subType}
                                        type={type}
                                        version={version}
                                        graphData={formattedGraphData}
                                        showEventType={true}
                                    />
                                )}
                        <p className='ml-auto'></p>
                        {props.showVersions && <SelectDropdown
                            placeholder='select version'
                            id='filter-type'
                            defaultValue={versionOptions.length > 0 ? versionOptions[0] : null}
                            options={versionOptions}
                            className='navbar--dropdown select-dropdown-for-report px-1 ml-auto'
                            onChange={(i) => {
                                handleVersionChange(i.value);
                            }}
                        />}
                        
                        {props.showTypes === true && <SelectDropdown
                            value={find(typeOptions, {value: type}) || null}
                            id='filter-type'
                            defaultValue={typeOptions.length > 0 ? typeOptions[0] : null}
                            options={typeOptions}
                            className='navbar--dropdown select-dropdown-for-report px-1'
                            onChange={(i) => {
                                handleTypeChange(i.value);
                            }}
                        />}
                        <SelectDropdown
                            placeholder='select timeInterval'
                            id='time-interval'
                            defaultValue={timeIntervalOptions.filter(option => option.value === timeInterval)[0]}
                            options={timeIntervalOptions}
                            className='navbar--dropdown select-dropdown-for-report '
                            onChange={(i) => {
                                handleTimeIntervalChange(i.value);
                            }}
                        />
                    </div>
                    {<MultiLineChart data={formattedGraphData} version={version} timeInterval={timeInterval} loading={eventDataLoading} />}
                </div>
            </div>
        </>
    )
}
export default InsightsLineChart;