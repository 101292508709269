const errorMessage = {
	NODE_GROUPS: {
		noNodesInGroup: 'No node in this group.',
	},
	OTA_JOB: {
		noNodesInJob: 'No node associated with this job',
	},
	DEPLOYMENT_SETTINGS: {
		updateAPIError: 'Error occurred while deploying API Gateway',
	},
	GLOBAL: {
		wrong: 'Something went wrong!',
	},
	DIAGNOSTIC: {
		enableDiagnostic:
			'Please ensure that the diagnostics support is enabled in your firmware to turn on insights.',
	},
};

export default errorMessage;
