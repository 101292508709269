import React from 'react';
import CustomTooltip from '../../../../../../../components/CustomTooltip/CustomTooltip';
import './BreakdownDetails.scss';
import { transformTranslationText } from '../../../../../data-transform/data-transform';


function BreakdownDetails({ts, data, textLabels}) {
    return (
        <div className='breakdown-details-wrapper'>
            <table className='details'>
                <tbody>
                    {
                        data.map(({label = "", usage = 0, count = 0}) => {
                            const uniqueKey = `${ts}-${label.replace(/\s/g,'')}`;
                            const tooltipText = transformTranslationText(textLabels.tooltip_message, [
                                usage.toLocaleString(), 
                                count.toLocaleString()
                            ]);
                            return (
                                <tr className='item' key={uniqueKey}>
                                    <td className='label'>{label}</td>
                                    <td className='usage'>
                                        <CustomTooltip 
                                            id={`breakdown-details-${ts}`} 
                                            title={tooltipText} 
                                            key={`tooltip-${uniqueKey}`}
                                        >
                                            {usage.toLocaleString()}
                                        </CustomTooltip>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <div className='empty'></div>
        </div>
    )
}

export default BreakdownDetails