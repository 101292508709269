import React, {useState, useContext, useEffect} from 'react';
import './BillingAndUsage.scss';
import { UilDataSharing, UilInvoice } from '@iconscout/react-unicons';
import PageNavBar from '../PageNavBar/PageNavBar';
import { getUrlHashParameterByName, updateHashURL } from '../../../utils/commonFunctions';
import Usage from './Usage/Usage';
import TabContent from '../NodeDetails/TabContent/TabContent';
import AdvancedInsightsContext from './../context/insights-context';
import useNetworkInterceptor from '../hooks/useNetworkInterceptor';
import LoadingPage from '../LoadingPage/LoadingPage';
import NoDataMessage from '../Widgets/NoDataMessage/NoDataMessage';

function BillingAndUsage() {
    useNetworkInterceptor();

    const {
        billingUsageMetaData,
        getBillingUsageMetaData,
        billingUsageMetaDataLoading,
        resetUsageData,
        messages
    } = useContext(AdvancedInsightsContext);

    const tabsData = [
        {
            id: "usage",
            label: "Usage",
            tabIcon: <UilDataSharing size={21} />,
            disabled: false,
            component: Usage
        }
    ];

    const urlTabId = getUrlHashParameterByName("view");
    const defaultTab = tabsData.some(t => t.id === urlTabId) ? urlTabId : tabsData[0].id;
    const [selectedTab, setSelectedTab] = useState(defaultTab);
    const handleTabChange = tabId => {
        setSelectedTab(tabId);
        updateHashURL({view: tabId})
    }

    useEffect(() => {
        getBillingUsageMetaData();

        return () => {
            resetUsageData();
        };
    }, []);

    if (billingUsageMetaDataLoading === true) return <LoadingPage primaryText={messages.billing_usage.main_loading_message} />
    if (billingUsageMetaDataLoading === false && billingUsageMetaData === null) return <div className='no-billing-data'><NoDataMessage text={messages.billing_usage.error_message} /></div>

    return billingUsageMetaData !== null ? (
        <div className='billing-and-usage-wrapper'>
            <PageNavBar 
                tabsData={tabsData} 
                selectedTab={selectedTab}
                onTabChange={handleTabChange}
                hideTimeSelector={true}
            />
            <TabContent data={tabsData.filter(t => t.id === selectedTab)[0]} />
        </div>
    ) : null
}

export default BillingAndUsage