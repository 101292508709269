import React from 'react';
import AsyncSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';

const CustomSelect = (props) => {
	const { options, onChange, onFreeTextSelection, ...rest } = props;
	const selectComponents = {
		default: rest.freeText === true ? CreatableSelect : AsyncSelect
	}

	const customStyles = {
		control: (base, state) => ({
			...base,
			border: state.isFocused ? '1px solid #8181a8' : '1px solid #e3ebf6',
			boxShadow: state.isFocused ? 0 : 0,
			'&:hover': {
				border: state.isFocused
					? '1px solid #8181a8'
					: '1px solid #e3ebf6',
			},
			fontSize: '14px',
			minHeight: '30px',
			borderRadius: '6px',
			padding: '0',
		}),
		option: (styles, { isSelected }) => ({
			...styles,
			backgroundColor: isSelected ? '#edebf8' : '#fff',
			color: isSelected ? '#5330b9' : '#1a1a1a',
			':active': {
				...styles[':active'],
				backgroundColor: isSelected ? '#5330b91a' : '#f6f6fb',
			},
			'&:hover': {
				backgroundColor: '#edebf8'
			},
			fontSize: '14px',
		}),
		container: (provided, state) => ({
			...provided,
			backgroundColor: state.isDisabled ? '#fff' : '#f6f6fb',
		}),
		input: (provided, state) => ({
			...provided,
			backgroundColor: state.isDisabled ? '#fff' : '#f6f6fb',
		}),
		menuList: (base) => ({
			...base,
			'::-webkit-scrollbar': {
				width: '4px',
			},
			'::-webkit-scrollbar-track': {
				background: '#cfcfcf',
			},
			'::-webkit-scrollbar-thumb': {
				background: '#959595',
			},
			'::-webkit-scrollbar-thumb:hover': {
				background: '#555',
			},
		}),
	};

	return (
		<selectComponents.default
			cacheOptions
			options={options ? options : ''}
			onChange={onChange}
			onCreateOption={onFreeTextSelection}
			styles={customStyles}
			formatCreateLabel={freeFormText => <span>{rest.freeFormTextCreateLabel} <b>{freeFormText}</b></span>}
			{...rest}
		/>
	);
};

export default CustomSelect;
