import { cloneDeep, uniqBy, transform, concat } from 'lodash';
import { updateHashURL } from '../../../utils/commonFunctions';
import { generateInvalidIntervalsList, getUsageCreditsUnit, getTransformedNodeConfigMetaDataList } from '../data-transform/data-transform';
import {
    SET_FILTERS_LIST,
    SET_DATE_TIME_RANGE,
    SET_FILTER_SUGGESTION,
    ADD_TO_SELECTED_FILTERS,
    ADD_MULTIPLE_FILTERS_TO_SELECTED_FILTERS,
    REMOVE_FROM_SELECTED_FILTERS,
    CLEAR_ALL_SELECTED_FILTERS,
    SET_ALL_EVENTS_DATA,
    SET_EVENT_COUNT_BY_INTERVAL_DATA,
    SET_ALL_EVENTS_COUNT_BY_INTERVAL_DATA,
    SET_EVENT_COUNT_BY_CATEGORY_DATA,
    SET_TOP_NODES_DATA,
    SET_INITIAL_LOAD_COMPLETE_STATUS,
    RESET_INITIAL_LOAD_COMPLETE_STATUS,
    RESET_EVENT_COUNT_BY_INTERVAL_DATA,
    RESET_EVENT_COUNT_BY_CATEGORY_DATA,
    RESET_ALL_EVENTS_DATA,
    RESET_TOP_NODES_DISTRIBUTION_DATA,
    SET_NODE_FILTERS,
    CLEAR_NODE_FILTERS,
    SET_SELECTED_DASHBOARD,
    SET_DASHBOARD_LIST,
    SWITCH_DASHBOARD,
    SET_INTERVAL_LABEL,
    SET_ONLINE_TOP_NODES_DATA,
    SET_NODE_INFO_DATA,
    RESET_SELECTED_NODE_DATA,
    SET_SELECTED_NODE_ALL_EVENTS_DATA,
    RESET_SELECTED_NODE_ALL_EVENTS_DATA,
    SET_SELECTED_NODE_LOGS_DATA,
    PREPEND_TO_SELECTED_NODE_LOGS_DATA,
    RESET_SELECTED_NODE_LOGS_DATA,
    SET_SELECTED_NODE_LOGS_LOADING,
    SET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA,
    SET_SELECTED_NODE_TIMELINE_SUBDIST,
    SET_DAHSBOARD_AVAILABLE_STATUS,
    SET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA_LOADING,
    SET_SELECTED_NODE_PARAMS_META,
    SET_SELECTED_NODE_PARAMS_LIST,
    SET_SELECTED_NODE_PARAMS_LIST_LOADING,
    SET_SELECTED_NODE_ONLINE_STATUS,
    SET_SELECTED_NODE_METRICS_DATE_TIME_RANGE,
    SET_DASHBOARD_DEFAULT_STATUS,
    SET_CURRENT_MONTH_USAGE,
    SET_CURRENT_MONTH_USAGE_LOADING,
    SET_BILLING_USAGE_META_DATA,
    SET_BILLING_USAGE_META_DATA_LOADING,
    RESET_USAGE_DATA,
    SET_USAGE_INSIGHTS_DATE,
    SET_USAGE_INSIGHTS_FILTER,
    SET_USAGE_INSIGHTS_SUB_FILTER,
    SET_USAGE_INSIGHTS_SELECTED_ADMIN_ID,
    SET_USAGE_INSIGHTS_SELECTED_INTERFACE,
    SET_AUTH_KEYS_LIST,
    SET_USAGE_INSIGHTS_DATA,
    SET_USAGE_INSIGHTS_DATA_LOADING,
    SET_SELECTED_NODE_TAGS_LIST_LOADING,
    SET_SELECTED_NODE_TAGS_LIST,
    SET_EVENT_COUNT_DISTRIBUTION_BY_INTERVAL_LOADING,
    SET_TOP_NODES_DISTRIBUTION_DATA_LOADING,
    SET_ALL_EVENTS_DATA_LOADING,
    SET_EVENT_COUNT_DISTRIBUTION_BY_CATEGORY_LOADING,
    SET_SELECTED_NODE_TIMELINE_AGGREGATOR_TYPE,
    SET_INVITATIONS_FOR_USER,
    SET_INVITATIONS_FOR_USER_LOADING,
    SET_INVITATIONS_BY_USER,
    SET_INVITATIONS_BY_USER_LOADING,
    SET_TENANTS_LIST,
    SET_TENANTS_LIST_LOADING,
    SET_ACCOUNTS_LIST,
    SET_ACCOUNTS_LIST_LOADING,
    SET_NODE_CONFIG_META,
    SET_NODE_CONFIG_META_LOADING,
} from './actions';

const reducer = (state, action) => {
    switch (action.type) {
        case SET_DATE_TIME_RANGE: {
            const { start, end } = action.payload;
            if (start && end) {
                updateHashURL({start, end});

                return {
                    ...state,
                    startTimestamp: start,
                    endTimestamp: end,
                    disabledIntervalsList: generateInvalidIntervalsList(start, end)
                }
            }
            else {
                return state;
            }
        }
        case SET_FILTERS_LIST: {
            const filtersListData = action.payload;
            const list = [];
            transform(filtersListData, (result, value, key) => {
                if (value?.filter?.length && (value.filter.includes("keyword") || value.filter.includes("nestedkeyword"))) {
                    value['FILTER_KEY'] = key; 
                    list.push(value);
                }
            });

            return {
                ...state,
                filtersData: filtersListData,
                filtersList: list
            }
        }
        case SET_FILTER_SUGGESTION: {
            return {
                ...state,
                filterSuggestionData: action.payload
            }
        }
        case ADD_TO_SELECTED_FILTERS: {
            const { filterType, filterValue } = action.payload;
            const currentFiltersList = cloneDeep(state.selectedFilters);
            let updatedFiltersList = [];
            if (currentFiltersList.length) {
                currentFiltersList.forEach(f => {
                    const filter = {...f};
                    if (filter.filterType !== filterType) {
                        filter.filterType = filterType;
                    }

                    filter.filterValue = filterValue;
                    updatedFiltersList.push(filter);
                });
            }
            else {
                updatedFiltersList = [{filterType, filterValue}];
            }

            return {
                ...state,
                selectedFilters: updatedFiltersList
            }
        }
        case ADD_MULTIPLE_FILTERS_TO_SELECTED_FILTERS: {
            const consolidatedFiltersList = [...action.payload, ...state.selectedFilters];
            const updatedFiltersList = uniqBy(consolidatedFiltersList, "filterType");

            return {
                ...state,
                selectedFilters: updatedFiltersList
            }
        }
        case REMOVE_FROM_SELECTED_FILTERS: {
            const { filterType, filterValue } = action.payload;
            const updatedFilters = state.selectedFilters.filter(f => f.filterType !== filterType);
            return {
                ...state,
                selectedFilters: updatedFilters
            }
        }
        case CLEAR_ALL_SELECTED_FILTERS: {
            return {
                ...state,
                selectedFilters: []
            }
        }
        case SET_NODE_FILTERS: {
            const consolidatedFiltersList = [...action.payload, ...state.nodeFilters];
            const updatedFiltersList = uniqBy(consolidatedFiltersList, "filterType");

            return {
                ...state,
                nodeFilters: updatedFiltersList
            }
        }
        case CLEAR_NODE_FILTERS: {
            return {
                ...state,
                nodeFilters: []
            }
        }
        case SET_ALL_EVENTS_DATA: {
            return {
                ...state,
                allEventsData: action.payload?.list || [],
                allEventsDataResetStatus: false,
                allEventsDataLoading: false
            }
        }
        case RESET_ALL_EVENTS_DATA: {
            return {
                ...state,
                allEventsDataResetStatus: true
            }
        }
        case SET_EVENT_COUNT_BY_INTERVAL_DATA: {
            return {
                ...state,
                eventCountDistributionByInterval: action.payload?.distribution || [],
                eventCountDistributionByIntervalLoading: false,
                eventCountDistributionByIntervalResetStatus: false
            }
        }
        case RESET_EVENT_COUNT_BY_INTERVAL_DATA: {
            return {
                ...state,
                eventCountDistributionByIntervalResetStatus: true
            }
        }
        case SET_EVENT_COUNT_BY_CATEGORY_DATA: {
            return {
                ...state,
                eventCountDistributionByCategory: action.payload?.distribution || [],
                eventCountDistributionByCategoryLoading: false,
                eventCountDistributionByCategoryResetStatus: false
            }
        }
        case RESET_EVENT_COUNT_BY_CATEGORY_DATA: {
            return {
                ...state,
                eventCountDistributionByCategoryResetStatus: true
            }
        }
        case SET_TOP_NODES_DATA: {
            return {
                ...state,
                topNodesDistributionData: action.payload?.distribution || [],
                distinctNodeCount: action.payload?.distinct_node_count || 0,
                topNodesDistributionDataResetStatus: false,
                topNodesDistributionDataLoading: false
            }
        }
        case RESET_TOP_NODES_DISTRIBUTION_DATA: {
            return {
                ...state,
                topNodesDistributionDataResetStatus: true
            }
        }
        case SET_INITIAL_LOAD_COMPLETE_STATUS: {
            return {
                ...state,
                initialLoadCompleteStatus: action.payload
            }
        }
        case RESET_INITIAL_LOAD_COMPLETE_STATUS: {
            return {
                ...state,
                initialLoadCompleteStatus: false
            }
        }
        case SET_ALL_EVENTS_COUNT_BY_INTERVAL_DATA: {
            const allEventsDistributionData = [];
            action.payload.forEach(response => {
                const filters = response?.config?.params?.filters;
                if (filters) {
                    const parsedFilters = JSON.parse(filters);
                    const eventType = parsedFilters[0]['v'][0];
                    allEventsDistributionData.push({
                        type: eventType,
                        distribution: response.data.distribution
                    })
                }
            })
            return {
                ...state,
                allEventsCountDistributionByInterval: allEventsDistributionData
            }
        }
        case SET_DASHBOARD_LIST : {
            // sort true values first => default dashboard will always be first object
            action.payload.sort((a,b)=> a.is_default - b.is_default).reverse()
            return {
                ...state,
                dashboardList: action.payload
            }
        }
        case SET_SELECTED_DASHBOARD:{
            return{
                ...state,
                selectedDashboard: action.payload
            }
        }
        case SET_DASHBOARD_DEFAULT_STATUS:{
            return{
                ...state,
                isDashboardDefault : action.payload
            }
        }
        case SET_DAHSBOARD_AVAILABLE_STATUS : {
            return {
                ...state,
                dashboardAvailableStatus: action.payload
            }
        }
        case SWITCH_DASHBOARD:{
            return {
                ...state,
                ... action.payload
            }
        }
        case SET_INTERVAL_LABEL: {
            return {
                ...state,
                intervalLabel: action.payload
            }
        }
        case SET_ONLINE_TOP_NODES_DATA: {
            return {
                ...state,
                onlineTopNodesData: action.payload?.list || [],
            }
        }
        case SET_NODE_INFO_DATA: {
            if (action.payload) {
                return {
                    ...state,
                    selectedNodeInfoData: action.payload,
                }
            }
            else {
                return {
                    ...state,
                    selectedNodeInfoData: {},
                    selectedNodeAllEventsData: [],
                    selectedNodeTimelineDistributionData: [],
                    selectedNodeLogsData: []
                }
            }
        }
        case RESET_SELECTED_NODE_DATA: {
            return {
                ...state,
                selectedNodeInfoData: null,
                selectedNodeAllEventsData: null,
                selectedNodeLogsData: null,
                selectedNodeLogsRemarks: null,
                selectedNodeLogsPageToken: "",
                selectedNodeTimelineSubdist: "",
                selectedNodeTimelineDistributionData: null,
                selectedNodeParamsMeta: null,
                selectedNodeParamsList: null,
                selectedNodeOnlineStatus: null,
                nodeFilters: []
            }
        }
        case SET_SELECTED_NODE_ALL_EVENTS_DATA: {
            return {
                ...state,
                selectedNodeAllEventsData: action.payload?.list || [],
            }
        }
        case RESET_SELECTED_NODE_ALL_EVENTS_DATA: {
            return {
                ...state,
                selectedNodeAllEventsData: null,
            }
        }
        case SET_SELECTED_NODE_LOGS_DATA: {
            return {
                ...state,
                selectedNodeLogsData: action.payload?.list?.reverse() || [],
                selectedNodeLogsRemarks: action.payload?.remarks || null,
                selectedNodeLogsPageToken: action.payload?.pagetoken || "",
                selectedNodeLogsLoading: false,
            }
        }
        case PREPEND_TO_SELECTED_NODE_LOGS_DATA: {
            if (action.payload?.list?.length) {
                const selectedNodeLogsData = concat(action.payload.list.reverse(), state.selectedNodeLogsData);
                return {
                    ...state,
                    selectedNodeLogsData: selectedNodeLogsData,
                    selectedNodeLogsPageToken: action.payload?.pagetoken || "",
                    selectedNodeLogsLoading: false
                }
            }
            else {
                return state;
            }
            
        }
        case RESET_SELECTED_NODE_LOGS_DATA: {
            return {
                ...state,
                selectedNodeLogsData: null,
                selectedNodeLogsRemarks: null,
                selectedNodeLogsPageToken: "",
                selectedNodeLogsLoading: false
            }
        }
        case SET_SELECTED_NODE_LOGS_LOADING: {
            return {
                ...state,
                selectedNodeLogsLoading: action.payload
            }
        }
        case SET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA: {
            return {
                ...state,
                selectedNodeTimelineDistributionData: action.payload?.distribution?.dist?.buckets?.length ? action.payload?.distribution?.dist : [],
                selectedNodeTimelineDistributionDataLoading: false
            }
        }
        case SET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA_LOADING: {
            return {
                ...state,
                selectedNodeTimelineDistributionDataLoading: action.payload,
            }
        }
        case SET_SELECTED_NODE_TIMELINE_SUBDIST: {
            return {
                ...state,
                selectedNodeTimelineSubdist: action.payload || "",
            }
        }
        case SET_SELECTED_NODE_PARAMS_META: {
            return {
                ...state,
                selectedNodeParamsMeta: action.payload,
            }
        }
        case SET_SELECTED_NODE_PARAMS_LIST: {
            return {
                ...state,
                selectedNodeParamsList: action.payload,
                selectedNodeParamsListLoading: false,
            }
        }
        case SET_SELECTED_NODE_PARAMS_LIST_LOADING: {
            return {
                ...state,
                selectedNodeParamsListLoading: action.payload,
            }
        }
        case SET_SELECTED_NODE_TAGS_LIST_LOADING: {
            return {
                ...state,
                selectedNodeTagsListLoading : action.payload,
            }
        }
        case SET_SELECTED_NODE_TAGS_LIST : {
            return {
                ...state,
                selectedNodeTagsList : action.payload
            }
        }
        case SET_SELECTED_NODE_ONLINE_STATUS: {
            return {
                ...state,
                selectedNodeOnlineStatus: action.payload,
            }
        }
        case SET_SELECTED_NODE_METRICS_DATE_TIME_RANGE: {
            const { start, end } = action.payload;
            if (start && end) {
                updateHashURL({mstart: start, mend: end});

                return {
                    ...state,
                    selectedNodeMetricsStartTime: start,
                    selectedNodeMetricsEndTime: end
                }
            }
            else {
                return state;
            }
        }
        case SET_BILLING_USAGE_META_DATA: {
            const metaData = action.payload?.data || {};
            return {
                ...state,
                billingUsageMetaData: metaData,
                billingUsageMetaDataLoading: false
            }
        }
        case SET_CURRENT_MONTH_USAGE: { 
            const usageData = action.payload;

            return {
                ...state,
                currentMonthUsageData: usageData,
                currentMonthUsageCreditsData: getUsageCreditsUnit(state.billingUsageMetaData, usageData, state.tzOffset),
                currentMonthUsageDataLoading: false
            }
        }
        case SET_CURRENT_MONTH_USAGE_LOADING: {
            return {
                ...state,
                currentMonthUsageDataLoading: action.payload
            }
        }
        case SET_BILLING_USAGE_META_DATA_LOADING: {
            return {
                ...state,
                billingUsageMetaDataLoading: action.payload
            }
        }
        case RESET_USAGE_DATA: {
            return {
                ...state,
                currentMonthUsageData: null,
                currentMonthUsageDataLoading: false,
            }
        }
        case SET_USAGE_INSIGHTS_DATE: {
            return {
                ...state,
                usageInsightsDate: action.payload,
            }
        }
        case SET_USAGE_INSIGHTS_FILTER: {
            return {
                ...state,
                usageInsightsFilter: action.payload,
            }
        }
        case SET_USAGE_INSIGHTS_SUB_FILTER: {
            return {
                ...state,
                usageInsightsSubFilter: action.payload,
            }
        }
        case SET_USAGE_INSIGHTS_SELECTED_ADMIN_ID: {
            return {
                ...state,
                usageInsightsSelectedAdminId: action.payload,
            }
        }
        case SET_USAGE_INSIGHTS_SELECTED_INTERFACE: {
            return {
                ...state,
                usageInsightsSelectedInterface: action.payload,
            }
        }
        case SET_AUTH_KEYS_LIST: {
            return {
                ...state,
                authKeysList: action.payload && action.payload.length ? action.payload : []
            }
        }
        case SET_USAGE_INSIGHTS_DATA: {
            return {
                ...state,
                usageInsightsData: action.payload,
                transformedUsageInsightsData: getUsageCreditsUnit(state.billingUsageMetaData, action.payload, state.tzOffset),
                usageInsightsFilter: null,
                usageInsightsSubFilter: null,
                usageInsightsDataLoading: false
            }
        }
        case SET_USAGE_INSIGHTS_DATA_LOADING: {
            return {
                ...state,
                usageInsightsDataLoading: action.payload
            }
        }
        case SET_EVENT_COUNT_DISTRIBUTION_BY_INTERVAL_LOADING: {
            return {
                ...state,
                eventCountDistributionByIntervalLoading: action.payload
            }
        }
        case SET_TOP_NODES_DISTRIBUTION_DATA_LOADING: {
            return {
                ...state,
                topNodesDistributionDataLoading: action.payload
            }
        }
        case SET_ALL_EVENTS_DATA_LOADING: {
            return {
                ...state,
                allEventsDataLoading: action.payload
            }
        }
        case SET_EVENT_COUNT_DISTRIBUTION_BY_CATEGORY_LOADING: {
            return {
                ...state,
                eventCountDistributionByCategoryLoading: action.payload
            }
        }
        case SET_SELECTED_NODE_TIMELINE_AGGREGATOR_TYPE: {
            return {
                ...state,
                selectedNodeTimelineAggregatorType: action.payload
            }
        }
        case SET_INVITATIONS_FOR_USER_LOADING: {
            return {
                ...state,
                invitationsForUserLoading: action.payload
            }
        }
        case SET_INVITATIONS_FOR_USER: {
            return {
                ...state,
                invitationsForUserLoading: false,
                invitationsForUser: action.payload
            }
        }
        case SET_INVITATIONS_BY_USER_LOADING: {
            return {
                ...state,
                invitationsByUserLoading: action.payload
            }
        }
        case SET_INVITATIONS_BY_USER: {
            return {
                ...state,
                invitationsByUserLoading: false,
                invitationsByUser: action.payload
            }
        }
        case SET_TENANTS_LIST_LOADING: {
            return {
                ...state,
                tenantsListLoading: action.payload
            }
        }
        case SET_TENANTS_LIST: {
            return {
                ...state,
                tenantsListLoading: false,
                tenantsList: action.payload
            }
        }
        case SET_ACCOUNTS_LIST_LOADING: {
            return {
                ...state,
                accountsListLoading: action.payload
            }
        }
        case SET_ACCOUNTS_LIST: {
            return {
                ...state,
                accountsListLoading: false,
                accountsList: action.payload
            }
        }
        case SET_NODE_CONFIG_META: {
            return {
                ...state,
                nodeConfigMetaLoading: false,
                nodeConfigMeta: action.payload,
                transformedNodeConfigMeta: getTransformedNodeConfigMetaDataList(action.payload)
            }
        }
        case SET_NODE_CONFIG_META_LOADING: {
            return {
                ...state,
                nodeConfigMetaLoading: action.payload
            }
        }

        default:
            return state;
    }
}

export default reducer;