import React, { useState, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import { NavLink, Redirect, Switch, Route, useHistory, useParams } from 'react-router-dom';
import SearchInput from '../../components/FormElements/SearchInput';
import {getNodeStatus} from '../../utils/diagAPI';
import { getUrlParameterByName } from '../../utils/commonFunctions';
import { includes } from "lodash";

const Diagnostic = (props) => {
	const history = useHistory();
	const { routes, sessionExpire } = props;
	const [locationState, setLocationState] = useState(() => props.location.state);
	
	const [nodeId, setNodeId ] = useState(() => props.nodeId || props.match.params.node_id);
	const [nodeKey, setNodeKey] = useState(() => locationState && locationState.nodeKey ? locationState.nodeKey : getUrlParameterByName("key") || null);
	
	
	const [nodeOnlineStatus, setNodeOnlineStatus] = useState(() => props.location.state && props.location.state.onlineStatus);
	let nodeStatusBadgeName = "";
	let nodeStatusBadgeVariant = "";
	if (nodeOnlineStatus && nodeOnlineStatus.connectivity) {
		nodeStatusBadgeName = nodeOnlineStatus.connectivity.connected === true ? "Online" : "Offline";
		nodeStatusBadgeVariant = nodeOnlineStatus.connectivity.connected === true ? "success" : "danger";
	}

	useEffect(() => {
		if (!nodeOnlineStatus && nodeId) {
			getNodeStatus(nodeId).then(response => {
				if (response.status) {
                    setNodeOnlineStatus(response.status);
                }
			}).catch(err => console.error(err))
		}
	}, [nodeId]);

	return (
		<>
			<div className='navbar--with--tabs'>
				<div className='headings--container'>
					<p className='small--heading'>
						Insights
						<span className='ml-1 beta-tag'>Beta</span>
					</p>
					<div className='d-flex'>
						<p className='main--heading'>Node {nodeId} {" "}
							{ nodeStatusBadgeName && <Badge className='ml-1 log--event--title' pill variant={nodeStatusBadgeVariant}> {nodeStatusBadgeName}</Badge> }
						</p>
					</div>
				</div>

				<ul className='navbar--horizontal--menu'>
				<li>
						<NavLink
							to={{
                                pathname: `/home/diagnostics/node/${nodeId}/report/?key=${nodeKey}${window.location.hash}`,
                                state: { nodeId: nodeId, nodeKey: nodeKey }
                            }}
							isActive={(match, location) => {
								return location.pathname.split("/").includes("report");
							}}
							activeClassName='nav--tab--active'
						>
							Node Report
						</NavLink>
					</li>
					<li>
						<NavLink
							to={{
                                pathname: `/home/diagnostics/node/${nodeId}/metrics/?key=${nodeKey}${window.location.hash}`,
                                state: { nodeId: nodeId, nodeKey: nodeKey }
                            }}
							isActive={(match, location) => {
								return location.pathname.split("/").includes("metrics");
							}}
							activeClassName='nav--tab--active'
						>
							Metrics
						</NavLink>
					</li>
					<li>
						<NavLink
							to={{
                                pathname: `/home/diagnostics/node/${nodeId}/paramvalues/?key=${nodeKey}${window.location.hash}`,
                                state: { nodeId: nodeId, nodeKey: nodeKey }
                            }}
							isActive={(match, location) => {
								return location.pathname.split("/").includes("paramvalues");
							}}
							activeClassName='nav--tab--active'
						>
							Variables
						</NavLink>
					</li>
				</ul>
			</div>
			<Switch>
				{routes.map((route) => (
					<Route
						path={route.path}
						render={(props) => (
							<route.component
								{...props}
								nodeId={nodeId}
								nodeKey={nodeKey}
								setNodeId={(n) => setNodeId(n)}
								setNodeKey={(key) => setNodeKey(key)}
								sessionExpire={sessionExpire}
							/>
						)}
						key={route.key}
					/>
				))}
				{/* <Redirect
					to={{
						pathname: `/home/diagnostics/node/${nodeId}/report`,
						state: { nodeId, nodeKey }
					}}
					from={`/home/diagnostics`}
				/> */}
			</Switch>
		</>
	);
};

export default Diagnostic;
