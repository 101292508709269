import React from 'react';
import './WidgetListItem.scss';
import { ButtonGroup, Button } from 'react-bootstrap';
import { UilCheck, UilTimes } from '@iconscout/react-unicons';
import LinkIconButton from './../../../AdvancedInsights/Widgets/LinkIconButton/LinkIconButton';

function WidgetListItem({
    title,
    text,
    title_tip,
    acceptActionText,
    acceptActionHandler = null,
    rejectActionText,
    rejectActionHandler = null,
    acceptAction = null,
    acceptActionInfoMessage = ""
}) {
    
    return (
        <div className={`widget-list-item-wrapper ${acceptAction !== null ? (acceptAction ? 'accepted' : 'rejected') : ''}`}>   
            <div className='content'>
                <div className='header'>
                    <div className='title'>
                        { title }
                    </div>
                    <div className='tip'>
                        { title_tip }
                    </div>
                </div>
                <div className='body'>
                    { text }
                </div>
                <div className='footer button-group'>
                    {
                        acceptAction === null ? (
                            <ButtonGroup>
                                {
                                    (acceptActionText && acceptActionHandler) && (
                                        <LinkIconButton 
                                            iconComponent={<UilCheck />}
                                            text={acceptActionText}
                                            clickHandler={acceptActionHandler}
                                            buttonIntent='success'
                                            size='small'
                                            variant='with-light-border'
                                        />
                                    )
                                }
                                {
                                    (rejectActionText && rejectActionHandler) && (
                                        <LinkIconButton 
                                            iconComponent={<UilTimes />}
                                            text={rejectActionText}
                                            clickHandler={rejectActionHandler}
                                            buttonIntent='error'
                                            size='small'
                                            variant='with-light-border'
                                        />
                                    )
                                }
                            </ButtonGroup>
                        ) : (
                            <div className='info-message'>
                                { acceptActionInfoMessage }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default WidgetListItem