import React, { useCallback, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { UilTimes } from '@iconscout/react-unicons';
import { IconButton } from '../Button/Button';

const Modal = (props) => {
	const { showModal, setShowModal, children, title } = props;
	const modalRef = useRef();

	const closeModal = (e) => {
		if (modalRef.current === e.target) {
			setShowModal(false);
		}
	};

	const animation = useSpring({
		config: {
			duration: 150,
		},
		opacity: showModal ? 1 : 0,
		transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
	});

	const keyPress = useCallback(
		(e) => {
			if (e.key === 'Escape' && showModal) {
				setShowModal(false);
			}
		},
		[setShowModal, showModal]
	);

	useEffect(() => {
		document.addEventListener('keydown', keyPress);

		return () => document.removeEventListener('keydown', keyPress);
	}, [keyPress]);

	return (
		<>
			{showModal && (
				<div
					className='popup--modal--container'
					ref={modalRef}
					onClick={closeModal}
				>
					<animated.div style={animation}>
						<div className='custom--modal'>
							<div
								className='modal--header'
								style={{
									borderWidth: `${title ? '0 0 1px' : '0'}`,
								}}
							>
								<p className='title'>{title}</p>
								<IconButton onClick={() => setShowModal(false)}>
									<UilTimes size={20} color='#9696BB' />
								</IconButton>
							</div>
							<div className='modal--body'>{children}</div>
						</div>
					</animated.div>
				</div>
			)}
		</>
	);
};

export default Modal;
