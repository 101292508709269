import React, { useState , useContext} from 'react';
import './AdvancedTimeSelector.scss';
import { Dropdown } from 'react-bootstrap';
import { UilAngleDown } from '@iconscout/react-unicons';
import DateTimeRangeSelectionInterface from './DateTimeRangeSelectionInterface/DateTimeRangeSelectionInterface';
import { format, fromUnixTime, getUnixTime, subMonths } from 'date-fns';
import AdvancedInsightsContext from '../../context/insights-context';

export default function AdvancedTimeSelector({
    messages,
    startTimestamp = getUnixTime(subMonths(new Date(), 1)),
    endTimestamp = getUnixTime(new Date()),
    onSelect,
    disableRangeSelection = false,
    updateMetricsRange = false,
    selectedNodeMetricsStartTime,
    selectedNodeMetricsEndTime,
    setSelectedNodeMetricsDateTimeRange
}) {
    const [ show, setShow ] = useState(false);
    const toggleHandler = showState => {
        setShow(showState);
    }

    const { intervalLabel } = useContext(AdvancedInsightsContext);
    const componentLabels = messages && messages.advanced_time_selection ? messages.advanced_time_selection : {};

    const getFormattedDateFromTimeStamp = timestamp => {
        if (!timestamp) return "";
        return format(fromUnixTime(timestamp), "do MMM, yyyy (HH:mm:ss)")
    }

    return (
        <div className="date-range-picker-input">
            <Dropdown 
                className="date-range-dropdown"
                show={show}
                onToggle={toggleHandler}
            >
                <Dropdown.Toggle id="dropdown-autoclose-false">
                    <div className="date-range-text-container">
                        <div className="start-date">{getFormattedDateFromTimeStamp(startTimestamp)}</div>
                        <div className="seperator">&#62;</div>
                        <div className="end-date">{getFormattedDateFromTimeStamp(endTimestamp)}</div>
                        { intervalLabel &&  <div className='interval-label'><b>{messages.advanced_time_selection.quick_links[intervalLabel]}</b></div> }
                        <UilAngleDown size={20} />
                    </div>
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="menu-content" rootCloseEvent={null}>
                    <DateTimeRangeSelectionInterface 
                        setShow={toggleHandler} 
                        messages={componentLabels} 
                        defaultStart={updateMetricsRange === true ? selectedNodeMetricsStartTime : startTimestamp}
                        defaultEnd={updateMetricsRange === true ? selectedNodeMetricsEndTime : endTimestamp}
                        onSelect={onSelect}
                        disableRangeSelection={disableRangeSelection}
                        updateMetricsRange={updateMetricsRange}
                        selectedNodeMetricsStartTime={selectedNodeMetricsStartTime}
                        selectedNodeMetricsEndTime={selectedNodeMetricsEndTime}
                        setSelectedNodeMetricsDateTimeRange={setSelectedNodeMetricsDateTimeRange}
                    />
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}