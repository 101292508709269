import axios from './axios';

export const refreshSession = (user_name, refreshtoken) => {
	let path = '/login';
	return axios.post(path, { user_name, refreshtoken });
};

// -------------- NODES ----------------
export const getNodes = (nodeid = '', records) => {
	let path;
	if (records === null) {
		path = `admin/nodes?node_id=${nodeid}`;
	} else if (records) {
		path = `admin/nodes?start_id=${nodeid}&num_records=${records}`;
	}

	return axios.get(path);
};

// -------------- NODES GROUPS ----------------
export const getNodeGroups = (groupid) => {
	let path = `admin/node_group`;
	if (groupid) {
		path = `admin/node_group?group_id=${groupid}`;
	}
	return axios.get(path);
};

export const getGroupNodeDetails = (groupid, records, startid) => {
	let path;
	if (!startid) {
		path = `admin/node_group?group_id=${groupid}&num_records=${records}&node_details=true`;
	} else if (startid) {
		path = `admin/node_group?group_id=${groupid}&start_id=${startid}&num_records=${records}&node_details=true`;
	}
	return axios.get(path);
};

export const createNodeGroups = (data) => {
	let path = `admin/node_group`;
	return axios.post(path, data);
};

export const addNodesToNodeGroup = (groupid, data) => {
	let path = `admin/node_group?group_id=${groupid}`;
	return axios.put(path, data);
};

export const deleteNodeGroup = (groupid) => {
	let path = `admin/node_group?group_id=${groupid}`;
	return axios.delete(path);
};

// -------------- FIRMWARE IMAGES ----------------
export const getFirmwareImages = (imageInfo = '', searchParam) => {
	let path;

	switch (searchParam) {
		case 'type':
			path = `admin/otaimage?type=${imageInfo}`;
			break;
		case 'model':
			path = `admin/otaimage?model=${imageInfo}`;
			break;
		case 'name':
			path = `admin/otaimage?image_name=${imageInfo}`;
			break;
		case 'id':
			path = `admin/otaimage?ota_image_id=${imageInfo}`;
			break;
		default:
			path = `admin/otaimage?start_id=${imageInfo}&num_records=${searchParam}`;
	}
	return axios.get(path);
};

// -------------- OTA JOBS ----------------
export const getOTAJobs = (otajob = '', pagesize) => {
	let path;
	if (pagesize === null) {
		path = `admin/otajob?ota_job_name=${otajob}`;
	} else if (pagesize) {
		path = `admin/otajob?start_id=${otajob}&num_records=${pagesize}`;
	}
	return axios.get(path);
};

// -------------- CUSTOMER DEPLOYMENTS ----------------
export const getDeploymentDetails = () => {
	let path = `admin/rainmaker_deployment_details`;
	return axios.get(path);
};

export const updateApigateway = () => {
	let path = `admin/update_apigateway`;
	return axios.put(path);
};
