import React, { useContext,useState, useEffect} from 'react';
import AdvancedTimeSelector from './AdvancedTimeSelector/AdvancedTimeSelector';
import AdvancedInsightsContext from '../context/insights-context';
import './PageNavBar.scss';
import NavigationTabs from './../NavigationTabs/NavigationTabs';
import { Navbar } from 'react-bootstrap';
import { UilAngleDown } from '@iconscout/react-unicons';
import { Dropdown } from 'react-bootstrap';


export default function PageNavBar({
    pageHeading,
    tabsData,
    selectedTab,
    onTabChange,
    tabMenu,
    onTabRename,
    allowTabRename,
    renameLabel,
    actionHandler,
    hideTimeSelector,
    disableRangeSelection,
    updateMetricsRange,
    settingOptions,
    menuActionHandler,
    menuOptionCondition,
}) {
    const { 
        messages, 
        startTimestamp, 
        endTimestamp,
        setDateTimeRange,
        selectedNodeMetricsStartTime,
        selectedNodeMetricsEndTime,
        setSelectedNodeMetricsDateTimeRange,
    } = useContext(AdvancedInsightsContext);

    const [dashboardType, setDashboardType] = useState('');
    const [settingsMenu, setSettingsMenu] = useState(null)

    const getDashboardType = (menuOptionCondition) => {
        if(!menuOptionCondition) return;
        const type = Object.keys(menuOptionCondition).find(function (type) { 
            return menuOptionCondition[type] === true; 
        });
        setDashboardType(type)
    }
    useEffect(() => {
        if(!menuOptionCondition || !settingOptions) return
        getDashboardType(menuOptionCondition)
        setSettingsMenu(settingOptions)
    }, [menuOptionCondition])
    
    useEffect(() => {
        if(dashboardType === '') return
        setSettingsMenu(prev => {
            prev.actions.forEach(action => {
                const { enableForType } = action;
                action.show = enableForType.includes(dashboardType);
            });
            return prev
        })
     }, [dashboardType])
    

    return (
            <Navbar className='advanced-insights-navbar' sticky="top">
                <div className='d-flex justify-content-between align-items-center main-content'>
                    <div className='d-flex align-items-center heading-container'>
                        {
                            tabsData?.length ? (
                                    <NavigationTabs 
                                        data={tabsData} 
                                        selectedTab={selectedTab}  
                                        onTabChange={onTabChange}
                                        tabMenu={tabMenu}
                                        onTabRename={onTabRename}
                                        allowTabRename={allowTabRename}
                                        renameLabel={renameLabel}
                                        actionHandler= {actionHandler}
                                    />
                                ) : (
                                <h1>{ pageHeading || messages.page_heading}</h1>
                            )
                        }
                    </div>
                    {
                        hideTimeSelector !== true && (
                            <div className='d-flex align-items-center'>
                                <AdvancedTimeSelector 
                                    messages={messages} 
                                    startTimestamp={updateMetricsRange ? selectedNodeMetricsStartTime : startTimestamp}
                                    endTimestamp={updateMetricsRange ? selectedNodeMetricsEndTime : endTimestamp}
                                    onSelect={updateMetricsRange === true ? setSelectedNodeMetricsDateTimeRange : setDateTimeRange}
                                    disableRangeSelection={disableRangeSelection}
                                    updateMetricsRange={updateMetricsRange}
                                    selectedNodeMetricsStartTime={selectedNodeMetricsStartTime}
                                    selectedNodeMetricsEndTime={selectedNodeMetricsEndTime}
                                    setSelectedNodeMetricsDateTimeRange={setSelectedNodeMetricsDateTimeRange}
                                />
                                {
                                    settingsMenu && 
                                    <Dropdown className="menu-button">
                                        <Dropdown.Toggle>
                                            <div>
                                                Settings <UilAngleDown size={20} />
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu align="right">
                                          <Dropdown.Item className='menu-label'>{settingsMenu.label}</Dropdown.Item> 
                                          {
                                              settingsMenu.actions && settingsMenu.actions.map(option => {
                                                    if(!option.show) return;
                                                    return (<Dropdown.Item 
                                                        id={option.id} 
                                                        key={option.id}
                                                        onClick={() => menuActionHandler(option.id) }>
                                                            {option.icon}{option.label}
                                                    </Dropdown.Item>) 
                                                }
                                            )
                                          }
                                        </Dropdown.Menu>
                                     </Dropdown>
                                }
                            </div>
                        ) 
                    }
                </div>
            </Navbar>
  )
}
