import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getCrashLogRegisterValues } from '../../../../../../data-transform/data-transform';
import './RegisterValues.scss';

function RegisterValues({data}) {
    if (!data) return null;

    const registerValues = getCrashLogRegisterValues(data);

    return (
        <div className='register-values-wrapper'>
            <mark>Register Values</mark>
            <div className='values'>
                <Container>
                    <Row>
                        {
                            registerValues.map(({title, value}) => (
                                <Col sm={3} className='value-col'>
                                    <span className='title'>{title}</span>
                                    <span className='seperator'>:</span>
                                    <span className='value'>{value}</span>
                                </Col>
                            ))
                        }
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default RegisterValues