import React, {useEffect, useContext, useState} from 'react';
import './NodeLogs.scss';
import AdvancedInsightsContext from './../../../../context/insights-context';
import NodeLog from './NodeLog/NodeLog';
import LogToolbar from './LogToolbar/LogToolbar';
import InfoMessageWrapper from './../../../../Widgets/InfoMessageWrapper/InfoMessageWrapper';
import { 
  getLogSearchConfigFromOptionsList, 
  getLogSearchDropdownOptions, 
  getLogSearchOptionsFromFiltersList 
} from '../../../../data-transform/data-transform';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';
import NoDataMessage from '../../../../Widgets/NoDataMessage/NoDataMessage';

function NodeLogs() {
  const { 
    getSelectedNodeLogsData,
    selectedNodeLogsData,
    selectedNodeLogsRemarks,
    selectedNodeLogsLimit,
    selectedFilters,
    selectedNodeInfoData,
    nodeFilters,
    selectedNodeLogsPageToken,
    selectedNodeLogsLoading,
    messages,
    startTimestamp, 
    endTimestamp
  } = useContext(AdvancedInsightsContext);
  
  const [expandAllLogs, setExpandAllLogs] = useState(false);
  
  const [logSearchAllOptions, setLogSearchAllOptions] = useState(() => getLogSearchDropdownOptions(selectedFilters));
  const [logSearchOptions, setLogSearchOptions] = useState(() => getLogSearchOptionsFromFiltersList(selectedFilters));
  const [logSearchConfig, setLogSearchConfig] = useState(() => getLogSearchConfigFromOptionsList(logSearchOptions));

  useEffect(() => {
    if (selectedNodeLogsData === null && nodeFilters.length) {
      getSelectedNodeLogsData();
    }
  }, [nodeFilters]);

  useDidMountEffect(() => {
    setLogSearchConfig(getLogSearchConfigFromOptionsList(logSearchOptions));
  }, [logSearchOptions])
  
  useDidMountEffect(() => {
    setLogSearchOptions(getLogSearchOptionsFromFiltersList(selectedFilters));
    setLogSearchAllOptions(getLogSearchDropdownOptions(selectedFilters));
  }, [selectedFilters])

  useDidMountEffect(() => {
    getSelectedNodeLogsData(logSearchConfig);
  }, [startTimestamp, endTimestamp, logSearchConfig]);

  const handleLogSearch = options => {
    setLogSearchOptions(options);
  }

  const handleLoadOlderLogs = () => {
    getSelectedNodeLogsData(logSearchConfig, true);
  }

  if (selectedNodeLogsData === null) return null;

  return (
    <div className={`node-logs-wrapper ${expandAllLogs ? 'expanded' : 'collapsed'}`}>
      <LogToolbar 
        logsLimit={selectedNodeLogsLimit}
        moreLogsAvailable={selectedNodeLogsPageToken !== ""}
        expandAllLogs={expandAllLogs}
        setExpandAllLogs={setExpandAllLogs}
        onSearch={handleLogSearch}
        onLoadOlderLogs={handleLoadOlderLogs}
        logsLoading={selectedNodeLogsLoading}
        searchValues={logSearchOptions}
        dropdownOptions={logSearchAllOptions}
      />

      <InfoMessageWrapper>
        {
          selectedNodeLogsRemarks?.missingelfs?.length ? (
            <div className='info'>
              Some information is missing! There is a chance that some firmware package is not present. Please refer the esp-insights README for more information.
            </div>
          ) : null
        }
      </InfoMessageWrapper>

      {
        selectedNodeLogsData.length ? 
          selectedNodeLogsData.map(log => (
            <NodeLog key={log._id} log={log} expandAllLogs={expandAllLogs} nodeData={selectedNodeInfoData} />
          )) : 
        <NoDataMessage text="No matching logs for the applied filters and selected time range." />
      }
    </div>
  )
}

export default NodeLogs