import React, {useState} from 'react';
import './UsageRow.scss';
import moment from 'moment';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import BreakdownDetails from './BreakdownDetails/BreakdownDetails';

function UsageRow({data, variant, textLabels}) {
    const [expand, setExpand] = useState(false);
    const variantClassName = variant || "";

    return (
        <div className={`usage-row-wrapper ${variantClassName}`}>
            <div className='usage-row'>
                <div className='preview' onClick={() => setExpand(!expand)}>
                    <div className='primary'>
                        <div className='date'>{moment.unix(data.ts).format('MMMM D')}</div>
                        <div className='count'>{data.creditUsage.toLocaleString()}</div>
                    </div>
                    
                    <div className='actions'>
                        <div className='icon'>
                            { expand ? <UilAngleUp size={32} /> : <UilAngleDown size={32} /> }
                        </div>
                    </div>
                </div>
                <div className='details'>
                    { expand ? <BreakdownDetails ts={data.ts} data={data.breakdown} textLabels={textLabels} /> : null}
                </div>
            </div>
        </div>
    )
}

export default UsageRow