import React,{ useMemo, useState, useEffect } from 'react'
import Table from '../../../../../components/Table/Table';
import TagChip from '../../../../../components/TagChip/TagChip';
import AddTagForm from './AddTagForm';
import { IconButton } from "../../../../../components/Button/Button"
import { UilPen, UilTrashAlt } from '@iconscout/react-unicons';
import CustomToast from "../../../../../components/CustomToast/CustomToast"
import { uniqueId } from 'lodash'
import NoDataMessage from "../../../Widgets/NoDataMessage/NoDataMessage"
import "./TagsTable.scss"

const TagsTable = ({tagsList, removeTag, updateTag, createTag, loading, nodeKey, messages}) => {

	const [updateFlag, setUpdateFlag] = useState(false)
	const [tags, setTags] = useState(tagsList)
	const [deleteTagLoading, setDeleteTagLoading] = useState(false)

	useEffect(() => {
	  setTags(tagsList)
	}, [tagsList.length])
	
	const handleRemoveTag = async (tag) => {
		if(deleteTagLoading) return;
		const response = await removeTag(tag)
		if(response) 
    	{
      		CustomToast.success('Tag removed successfully',{ autoClose: 5000,});
    	}else{
      		CustomToast.error('Failed! Please try again',{ autoClose: 5000,});
    	}
		setDeleteTagLoading(false)
	}

    const columns = useMemo(
		() => [
			{
				id:`tag-row-${uniqueId()}`,
				Header: ' ',
				style:{width:"30%"},
				accessor: (row) => {
                    return <TagChip className={"m-0"} tag={row} />
                }
			},
			{
				Header: 'TAG NAME',
				style:{width:"25%"},
				accessor: 'property',
			},
			{
				Header: 'VALUE',
				style:{width:"25%"},
				accessor: 'value',
			},
			{
				Header: ' ',
				style:{width:"20%"},	
				accessor: (row) => {
					return (
						<div className='d-flex justify-content-end align-items-center text-center'>
							<IconButton className="tag-delete-btn" 
								key={row.id}
								disabled={deleteTagLoading}
								onClick={(e)=> {
										e.target.disabled = true;
										setDeleteTagLoading(true)
										handleRemoveTag({...row, nodeKey});
									}
								}
							>
								<UilTrashAlt size={13}/> <span>Delete</span>
							</IconButton>
						</div>
					)
				},
			},
		],
		[tags.length,updateFlag]
	);


    return (
      <div>
          <AddTagForm updateFlag={setUpdateFlag} updateTag={updateTag} createTag={createTag} nodeKey={nodeKey} messages={messages}/>
          <Table
		  		className={"tags-table"}
    			data={tags}
    			columns={columns}
    			loading={false}
    			loaders={3}
    	  ></Table>
		  { !loading && tags.length <=0 && <NoDataMessage text={"We couldn't find any tags for this node."} /> }
      </div>
    )
}

export default TagsTable