import moment from 'moment';
import React from 'react';
import './LogTimeDetails.scss';

function LogTimeDetails({ts, tsSource}) {
    if (!ts) return null;

    return (
        <div className='log-time-details'>
            <div className='tsource_indicator'></div>
            <div className='time'>{moment(ts).format('DD/MM/YY HH:mm:ss')}</div>
        </div>
    )
}

export default LogTimeDetails