import React from 'react'
import './LogTag.scss';

function LogTag({tag}) {
    if (!tag) return null;

    return (
        <div className='log-tag'>{tag}</div>
    )
}

export default LogTag