import React from 'react';
import './InviteSent.scss';
import UilCheckCircle from '@iconscout/react-unicons/icons/uil-check-circle';
import { PrimaryLineButton } from '../../../../../../../../components/Button/Button';

function InviteSent({
    email, onSubmit
}) {
    return (
        <div className='invite-sent-wrapper'>
            <div className='check-icon'>
                <UilCheckCircle />
            </div>
            <div className='text-content'>
                <h5>Invite sent successfully</h5>
                <p>Your invite to {email} was sent successfully.</p>
            </div>
            <div className='action'>
                <PrimaryLineButton onClick={onSubmit}>Close</PrimaryLineButton>
            </div>
        </div>
    )
}

export default InviteSent