import React from 'react';
import './InfoMessageWrapper.scss';

function InfoMessageWrapper(props) {
    if (!props.children) return null;
    
    return (
        <div className={`info-message-wrapper ${props.type || 'warning' }`} style={props.wrapperStyle || null}>
            { props.title ? <h6>{props.title}</h6> : null }
            <div className='content'>
                {props.children}
            </div>
        </div>
    )
}

export default InfoMessageWrapper