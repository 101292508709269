import React from 'react';
import { UilCloudBlock } from '@iconscout/react-unicons';
import { PrimaryLineButton } from '../../../components/Button/Button';
import './OfflineErrorPage.scss';

function OfflineErrorPage() {
    const handleRetry = () => {
        window.location = '/home/insights';
    }

    return (
        <div className='offline-error'>
            <div className='box'>
                <div className='content'>
                    <div className='icon'>
                        <UilCloudBlock size={72} />
                    </div>
                    <div className='message'>
                        <h3>Whoops!</h3>
                        <p>We're having trouble reaching our servers.</p>
                        <p>Please try again later.</p>
                    </div>
                    <div className='action'>
                        <PrimaryLineButton onClick={handleRetry}>Retry</PrimaryLineButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OfflineErrorPage