import React from 'react';

export const Input = (props) => {
	const {
		label,
		type,
		id,
		placeholder,
		helpText,
		required,
		hasError,
		className = '',
		value,
		isInline,
		error,
		...inputProps
	} = props;
	return (
		<div
			className={`${hasError ? 'has-error' : ''}`}
			style={
				isInline
					? { display: 'inline-block' }
					: { display: 'block', width: '100%' }
			}
		>
			{label ? (
				<label htmlFor={id}>
					{label}{' '}
					{required ? <span className='required'>*</span> : null}
				</label>
			) : null}
			<input
				type={type ? type : 'text'}
				className={`input ${className}`}
				id={id}
				placeholder={placeholder}
				value={value}
				{...inputProps}
			/>
			{helpText ? (
				<small className='form-text text-muted'>{helpText}</small>
			) : null}
			{hasError ? (
				<small className='form-text error-lable'>{error}</small>
			) : null}
		</div>
	);
};

export const ControlledInput = (props) => {
	const {
		label,
		type,
		id,
		placeholder,
		helpText,
		required,
		hasError,
		className,
		isInline,
		value,
		...inputProps
	} = props;
	return (
		<div
			className={`${hasError ? 'has-error' : ''}`}
			style={
				isInline
					? { display: 'inline-block' }
					: { display: 'block', width: '100%' }
			}
		>
			{label ? (
				<label htmlFor={id}>
					{label}{' '}
					{required ? <span className='required'>*</span> : null}
				</label>
			) : null}
			<input
				type={type ? type : 'text'}
				className={`input ${className}`}
				id={id}
				placeholder={placeholder}
				value={value || ''}
				{...inputProps}
			/>
			{helpText ? (
				<small className='form-text text-muted'>{helpText}</small>
			) : null}
		</div>
	);
};
