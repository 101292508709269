import React from 'react';
import './LoadingPage.scss';
import LogoSpinner from './../LogoSpinner/LogoSpinner';

function LoadingPage({primaryText}) {
  return (
    <div className='loading-page'>
        <div className='box'>
            <div className='content'>
                <div className='spinner'>
                    <LogoSpinner type="inline" />
                </div>
                <div className='divider'></div>
                <div className='message'> { primaryText } </div>
            </div>
        </div>
    </div>
  )
}

export default LoadingPage