import React from 'react';
import { UilTrashAlt, UilCloudDownload, UilCopy } from '@iconscout/react-unicons';
import { PrimarySmallButton, ErrorButton } from '../../../components/Button/Button';
import './KeyActions.scss';

export default function KeyActions(props) {
    const { data, onDownload, onDelete } = props;
    const { id, name } = data;

    return (
        <div className="key-actions">
            
            {/* <PrimarySmallButton onClick={() => onDownload(id, name)}>
                <UilCopy size={20} /> Copy
            </PrimarySmallButton> */}

            <PrimarySmallButton onClick={() => onDownload(id, name)}>
                <UilCloudDownload size={20} /> Download
            </PrimarySmallButton>
            
            <ErrorButton onClick={() => onDelete(id)}>
                <UilTrashAlt size={20} /> Delete
            </ErrorButton>

        </div>
    )
}
