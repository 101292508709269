import React from 'react';
import './ConfirmRevokeAccess.scss';
import Modal from '../../../../../../../components/CustomModal/Modal';
import { ErrorLineButton } from '../../../../../../../components/Button/Button';

function ConfirmRevokeAccess({ showModal, setShowModal, onConfirm }) {
    return (
        <Modal showModal={showModal} setShowModal={setShowModal} title="Revoke access?">
            <div className='m-3'>
                <p className='mt-3' style={{paddingBottom: "10px"}}>
                    Are you sure you want to revoke access of this account?
				</p>
            </div>
            <div className='modal--footer d-flex justify-content-end'>
				<ErrorLineButton
					onClick={onConfirm}
					id="delete-key"
				>
					Yes, revoke
				</ErrorLineButton>
			</div>
        </Modal>
    )
}

export default ConfirmRevokeAccess