import React, { useState } from 'react';
import { LinkButton, PrimarySmallButton } from '../../../components/Button/Button';
import { getAuthKeyByID } from '../../../utils/manageAuthKeysAPI';
import { UilCopy } from '@iconscout/react-unicons';
import './ToggleKeyTokenDisplay.scss';
import CustomToast from '../../../components/CustomToast/CustomToast';

export default function ToggleKeyTokenDisplay(props) {
    const { id } = props.data;

    const [ show, setShow ] = useState(false);
    const [ tokenText, setTokenText ] = useState("");
    const [ tokenTextLoading, setTokenTextLoading ] = useState(false);

    const getAuthKeyDetails = () => {
        setTokenTextLoading(true);

        getAuthKeyByID(id).then(data => {
            setTokenText(data.token);
            setTokenTextLoading(false);
        });
    }

    const handleKeyTokenDisplay = () => {
        setShow(show => !show);
        
        if (!tokenText) {
            getAuthKeyDetails();
        }
    }

    const copyToken = () => {
        // to do - use fallback
        if (navigator.clipboard) {
            navigator.clipboard.writeText(tokenText).then(function() {
                CustomToast.success(
                    'Token copied to clipboard',
                    {
                        autoClose: 2000,
                    },
                    'copy-token-success'
                );
            }, function(err) {
                CustomToast.error(
                    'Error copying token to clipboard',
                    {
                        autoClose: 2000,
                    },
                    'copy-token-error'
                );
            });
        }
    }

    return (
        <div className="toggle-token-display">
            <LinkButton 
                style={{minWidth: "auto"}}
                onClick={handleKeyTokenDisplay}
            >
                { show ? "Hide" : "Show" }
            </LinkButton>

            <div className="token-text-container">
                { !tokenText && tokenTextLoading ? "Please wait..." : "" }

                { show && tokenText && !tokenTextLoading ? (
                    <div>
                        <div className="text">{ tokenText }</div>
                        <div className="copy-button-container">
                            <PrimarySmallButton onClick={copyToken}>
                                <UilCopy size={20} /> Copy token
                            </PrimarySmallButton>
                        </div>
                    </div>
                ) : "" }
            </div>
        </div>
    )
}
