import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";

function PieChart({
    data = null
}) {
    const [chartData, setChartData] = useState(data);

    useEffect(() => {
        setChartData(data);
    }, [data]);

    if (!chartData) return null;

    return (
        <div className='pie-chart'>
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="donut"
                height="300px"
            />
        </div>
    )
}

export default PieChart