import { diagnostics } from "./../../../utils/axios";
import { useEffect, useRef, useContext } from 'react';
import { clearCookies } from "../../../utils/commonFunctions";
import { useHistory } from "react-router-dom";
import CustomToast from "../../../components/CustomToast/CustomToast";
import AdvancedInsightsContext from '../context/insights-context';

const useNetworkInterceptor = () => {
    const interceptorId = useRef(null);
    let history = useHistory();

    const { 
        resetInitialLoadCompleteStatus
    } = useContext(AdvancedInsightsContext);

    useEffect(() => {
        interceptorId.current = diagnostics.interceptors.response.use(function (response) {
            return response;
        }, 
        function (error) {
            if (!error.response) {
                const errorData = JSON.parse(JSON.stringify(error));
                if (errorData.message === "Network Error") {
                    //history.push('/offline');
                    return error;
                }
                else {
                    return error;
                }
            }

            switch (error.response.status) {
                case 400: {
                    return error.response;
                    break;
                }
                case 401: {
                    CustomToast.error(
                        'Session expired. Please login again!',
                        {
                            autoClose: 5000,
                        },
                        'session-toast'
                    );

                    clearCookies();
                    resetInitialLoadCompleteStatus();
                    history.push('/login');
                    break;
                }
                default: {
                    return error;
                }
            }
        });

        return () => {
            diagnostics.interceptors.response.eject(interceptorId.current);
        };
    }, [])

    return null;
}


export default useNetworkInterceptor;