import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Calendar from 'react-calendar';
import { UilClock, UilAngleDown } from '@iconscout/react-unicons';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import Loader from '../../components/Loader/Loader';
import { getmaxAllowedEndDate } from '../../utils/commonFunctions';
import { isAfter, isBefore, fromUnixTime, getUnixTime } from 'date-fns';

const InsightsTimeFilter = (props) => {
    const {
        selectedProject,
        projectsList = [],
        toTime,
        fromTime,
        loading,
        maxAllowedRangeInMonths = 3,
        disableFutureDatesSelection = false
    } = props
    const [showCalendarStart, setShowCalendarStart] = useState(false);
    const [showCalendarEnd, setShowCalendarEnd] = useState(false);
    const [cardLoading, setCardLoading] = useState(loading);
    
    const minAllowedEndDate = fromUnixTime(fromTime);
    const maxAllowedEndDate = getmaxAllowedEndDate({
        fromTime, maxAllowedRangeInMonths, disableFutureDatesSelection
    });

    const projectOptions = projectsList.map(item => ({ label: item, value: item })) || []
    var d = new Date();
    var offset = d.getTimezoneOffset() * -60;

    useEffect(() => {
        if (projectsList.length > 0) {
            setCardLoading(false);
        }
    }, [projectsList]);

    function handleFromDateChange(fromDate) {
        props.onFromDateChange(fromDate);

        const maxAllowedEndDate = getmaxAllowedEndDate({
            fromTime: fromDate, 
            maxAllowedRangeInMonths, 
            disableFutureDatesSelection
        });

        const formattedFromDate = fromUnixTime(fromDate);
        const formattedToDate = fromUnixTime(toTime);

        if (isAfter(formattedToDate, maxAllowedEndDate) || isBefore(formattedToDate, formattedFromDate)) {
            handleToDateChange(getUnixTime(maxAllowedEndDate))
        }
    }
    function handleToDateChange(toDate) {
        props.onToDateChange(toDate);
    }
    function handleProjectChange(project) {
        props.onProjectChange(project);
    }

    return (
        <>
            <div className="d-flex bg-white justify-content-between  p-2 mb-3">
                <div className='pt-1'>
                    {cardLoading ? (
                        <Loader minHeight='35px' />
                    ) : (<SelectDropdown
                        placeholder={selectedProject}
                        id='projects'
                        defaultValue={projectOptions.length > 0 ? projectOptions[0] : null}
                        options={projectOptions}
                        className='select-dropdown-for-projects'
                        projectDropdown={true}
                        onChange={(i) => {
                            handleProjectChange(i.value);
                        }}
                        autoListWidth={true}
                    />)}
                </div>
                <div className="d-flex flex-column ml-auto">
                    <div
                        className={`d-flex align-items-center position-relative ${'calendar--display--btn'
                            }`}
                    >
                        <span className='icon-container'>
                            <UilClock size='16' color='#5330b9' />
                        </span>
                        <div className="date-container px-2 py-1" onClick={() =>
                            setShowCalendarStart((show) => !show)
                        }>
                            <p className='timestamp '>{fromTime ? moment.unix(fromTime).format("DD MMM YY") : "From"}</p>
                            {(
                                <UilAngleDown size='16' color='#333' className="ml-auto" />
                            )}
                        </div>
                    </div>
                    <div>
                        {showCalendarStart && (
                            <div className='calendar--container'>
                                <Calendar
                                    minDetail='year'
                                    onClickDay={(date) => {
                                        let day = date.getDate();
                                        let month = date.getMonth() + 1;
                                        let year = date.getFullYear();
                                        let fromDate =
                                            moment(
                                                `${year}/${month}/${day}`
                                            ).unix() + offset
                                            ;
                                        handleFromDateChange(fromDate);
                                        setShowCalendarStart(false);
                                    }}
                                    value={fromTime ? moment(
                                        moment
                                            .unix(fromTime + offset)
                                            .utc()
                                            .format('YYYY-MM-DD HH:mm:ss')
                                    ).toDate() : null}
                                    maxDate={disableFutureDatesSelection ? new Date() : null}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <span className='pt-1'>-</span>
                <div className="d-flex flex-column">
                    <div
                        className={`d-flex align-items-center position-relative ${'calendar--display--btn'
                            }`}
                    >
                        <div className="date-container  px-2 py-1" onClick={() =>
                            setShowCalendarEnd((show) => !show)
                        }>
                            <p className='timestamp'>{toTime ? moment.unix(toTime).format("DD MMM YY") : "To"}</p>
                            {(
                                <UilAngleDown size='16' color='#333' className="ml-auto" />
                            )}
                        </div>
                    </div>
                    <div>
                        {showCalendarEnd && (
                            <div className='calendar--container '>
                                <Calendar
                                    minDetail='year'
                                    onClickDay={(date) => {
                                        let day = date.getDate();
                                        let month = date.getMonth() + 1;
                                        let year = date.getFullYear();
                                        let toDate =
                                            moment(
                                                `${year}/${month}/${day}`
                                            ).unix() + offset;
                                        handleToDateChange(toDate);
                                        setShowCalendarEnd(false);
                                    }}
                                    value={toTime ? moment(
                                        moment
                                            .unix(toTime + offset)
                                            .utc()
                                            .format('YYYY-MM-DD HH:mm:ss')
                                    ).toDate() : null}
                                    maxDate={maxAllowedEndDate}
                                    minDate={minAllowedEndDate}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
export default InsightsTimeFilter;