import React, {useState} from 'react';
import './Usage.scss';
import { Container, Row, Col } from 'react-bootstrap';
import LeftSection from './LeftSection/LeftSection';
import RightSection from './RightSection/RightSection';

function Usage() {
    return (
        <div className='usage-wrapper'>
            <Container fluid>
                <Row>
                    <Col xl={8} lg={7} md={12} sm={12} xs={12}>
                        <LeftSection />
                    </Col>
                    <Col xl={4} lg={5} md={12} sm={12} xs={12}>
                        <RightSection />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Usage