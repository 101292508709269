import React, {useContext} from 'react';
import WidgetWrapper from '../../../Widgets/WidgetWrapper/WidgetWrapper';
import NodeInfoCard from './NodeInfoCard/NodeInfoCard';
import NodeStatsCard from './NodeStatsCard/NodeStatsCard';
import AdvancedInsightsContext from '../../../context/insights-context';
import NodeTagsCard from './NodeTagsCard/NodeTagsCard';
import './SideWidgets.scss';

function SideWidgets() {
  const { 
    selectedNodeInfoData,
    selectedNodeAllEventsData
  } = useContext(AdvancedInsightsContext);

  return (
    <div className='side-widgets'>
        <WidgetWrapper 
            wrapperStyles={{borderRadius: '10px'}} 
            containerStyles={{padding: '0px'}}
            loading={selectedNodeInfoData === null}
        >
          <NodeInfoCard />
        </WidgetWrapper>
        
        <WidgetWrapper 
            wrapperStyles={{borderRadius: '10px', boxShadow: 'none'}} 
            containerStyles={{padding: '0px'}}
            loading={selectedNodeAllEventsData === null}
        >
          <NodeStatsCard />
        </WidgetWrapper>

        <WidgetWrapper 
            wrapperStyles={{borderRadius: '10px'}} 
            containerStyles={{padding: '0px'}}
            loading={selectedNodeInfoData === null}
        >
          <NodeTagsCard />
        </WidgetWrapper>
    </div>
  )
}

export default SideWidgets