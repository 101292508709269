import React from 'react';
import ReactTooltip from 'react-tooltip';
import './CustomTooltip.scss';

function CustomTooltip(props) {
    if (!props.children) return null;
    if (!props.id || !props.title) return props.children;

    return (
        <div 
            className='custom-tooltip-wrapper' 
            data-tip={props.title} 
            data-for={props.id}
        >
            { props.children }
            <ReactTooltip 
                effect="solid" 
                id={props.id} 
                place="top" 
                className="custom-tooltip" 
                arrowColor="#584a85"
            />
        </div>
    )
}

export default CustomTooltip