import React, { createContext, useReducer } from 'react';
import { COMMON_API_ACTIONS as ACTIONS } from '../utils/constants';
import { getFirmwarePackages } from '../utils/diagAPI';

export const firmwarePackageContext = createContext();

const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.CALL_API:
			return {
				...state,
				loading: true,
				error: '',
			};
		case ACTIONS.RESOLVE: {
			let newState;
			if (action.error === 401) {
				return {
					...state,
					loading: false,
					error: 401,
				}
			}

			if (action.searchedItem) {
				newState = {
					...state,
					loading: false,
					error: '',
					searchedItem: action.searchedItem,
				};
			} else {
				newState = {
					...state,
					loading: false,
					error: '',
					response: action.response,
					allItems: action.allItems,
                    nextPageToken: action.nextPageToken,
					totalPages: action.totalPages,
					searchedItem: null,
				};
			}
			return newState;
		}
		case ACTIONS.REJECT:
			return {
				...state,
				loading: false,
				error: action.error,
			};
		case ACTIONS.RESET_SEARCH:
			return {
				...state,
				searchedItem: null,
				error: '',
			};
		case ACTIONS.NEXT:
			return {
				...state,
				allItems: [],
                pagetoken: state.nextPageToken,
                prevPagesTokens: [...state.prevPagesTokens, state.nextPageToken]
			};
		case ACTIONS.PREV: {
            const newTokensList = [...state.prevPagesTokens];
            let newPagetoken = newTokensList[newTokensList.length - 2];
            newTokensList.pop();
			return {
				...state,
				allItems: [],
                prevPagesTokens: newTokensList,
                pagetoken: newPagetoken
			};
		}
		case ACTIONS.PAGE_SIZE: {
			return {
				...state,
				allItems: [],
                pagetoken: "",
				pageSize: action.pageSize,
                nextPagesTokens: [""]
			};
		}
		case ACTIONS.RESET:
			return initialState;
		default:
			return state;
	}
};

const initialState = {
	loading: false,
	error: '',
	response: {},
	allItems: [],
	searchedItem: null,
	pageSize: 10,
    pagetoken: "",
    prevPagesTokens: [""],
    nextPageToken: ""
};

const FirmwarePackageProvider = (props) => {
	const [state, setState] = useReducer(reducer, initialState);

	const handleGetItems = async (pagetoken, pagesize, packageName) => {
		setState({ type: ACTIONS.CALL_API });
		try {
			let response = await getFirmwarePackages(pagetoken, pagesize, packageName);
			let data = response;
			let state;
			if (data.list) {
				if (Number.isInteger(pagesize) || !isNaN(parseInt(pagesize))) {
					state = {
						type: ACTIONS.RESOLVE,
						response: data,
						allItems: data.list,
						searchedItem: null,
						contextPageSize: pagesize,
                        nextPageToken: data.page_token,
						totalPages: data.total
							? Math.ceil(data.total / pagesize)
							: 1,
					};
				} else {
					state = {
						type: ACTIONS.RESOLVE,
						response: data,
						searchedItem: data.list,
					};
				}
			} else {
				const error = data.status === "failure" && data.description === "Unauthorized" ? 401 : "";
				state = {
					type: ACTIONS.RESOLVE,
					response: data,
					searchedItem: [data],
					error: error
				};
			}
			setState(state);
		} catch (error) {
			let errorText;
			if (error.response) {
				errorText = error.response.data.description;
				if (error.response?.status === 404) {
					errorText = '';
				}
			}
			if (error.response?.status === 401) {
				errorText = 401;
			}
			setState({
				type: ACTIONS.REJECT,
				error: errorText,
			});
		}
	};

	const handleReset = () => {
		setState({
			type: ACTIONS.RESET_SEARCH,
		});
	};

    const handleNextPage = () => {
		setState({
			type: ACTIONS.NEXT,
		});
	};

	const handlePrevPage = () => {
		setState({
			type: ACTIONS.PREV,
		});
	};

	const handlePageSizeChange = (newPageSize) => {
		setState({
			type: ACTIONS.PAGE_SIZE,
			pageSize: newPageSize,
		});
	};

	const handleChangePackages = (updatedPackages) => {
		setState({
			type: ACTIONS.RESOLVE,
			allItems: updatedPackages,
		});
	};

	return (
		<firmwarePackageContext.Provider
			value={{
				packageState: state,
				getPackages: handleGetItems,
				searchedPackageReset: handleReset,
				onNext: handleNextPage,
				onPrev: handlePrevPage,
				changePageSize: handlePageSizeChange,
				updatePackageList: handleChangePackages,
			}}
		>
			{props.children}
		</firmwarePackageContext.Provider>
	);
};

export default FirmwarePackageProvider;
