import React from 'react';
import './ManageAccountAccess.scss';
import WidgetTabs from '../../../Widgets/WidgetTabs/WidgetTabs';
import UserTenants from './UserTenants/UserTenants';
import UserAccounts from './UserAccounts/UserAccounts';

function ManageAccountAccess() {
    const tabsData = [
        {
            id: "user_tenants",
            title: "People who can access your account",
            component: UserTenants
        },
        {
            id: "user_accounts",
            title: "Accounts that you can access",
            component: UserAccounts
        }
    ]

    return (
        <div className='manage-account-access-wrapper'>
            <WidgetTabs data={tabsData} />
        </div>
    )
}

export default ManageAccountAccess