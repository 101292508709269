import React, { useState, useContext } from 'react';
import {
	UilTrashAlt,
	UilCheck,
	UilExclamationTriangle,
} from '@iconscout/react-unicons';
import { useHistory } from 'react-router';
import Modal from './Modal';
import { PrimaryLineButton } from '../Button/Button';
import { deleteOtaPackageCall, deleteOtaImageCall } from '../../utils/apiCalls';
import { firmwareImageContext } from '../../context/FirmwareImageProvider';

const DeleteImage = ({ showModal, setShowModal, response, sessionExpire }) => {
	const history = useHistory();

	const { imageState, updateImageList } = useContext(firmwareImageContext);

	const { allItems } = imageState;

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState('');

	const handleDeleteImage = () => {
		setLoading(true);
		response.package
			? deleteOtaPackageCall(response.ota_image_id)
					.then((resp) => {
						if (resp.statusCode === 401) {
							sessionExpire();
							return;
						}
						setLoading(false);
						if (resp.status === 'success') {
							setSuccess(true);
						} else {
							setError(resp.description);
						}
					})
					.catch(() => {
						setError('Something went wrong!');
						setLoading(false);
					})
			: deleteOtaImageCall(response.ota_image_id)
					.then((resp) => {
						if (resp.statusCode === 401) {
							sessionExpire();
							return;
						}
						setLoading(false);
						if (resp.status === 'success') {
							setSuccess(resp.description);
						} else {
							setError(resp.description);
						}
					})
					.catch(() => {
						setError('Something went wrong!');
						setLoading(false);
					});
		if (success) {
			let allImages = allItems;
			let updatedImages = allImages.filter(
				(img) => img.ota_image_id !== response.ota_image_id
			);
			updateImageList(updatedImages);
		}
	};

	const close = () => {
		if (success) {
			history.push('/home/firmware-images/');
		}
		setError('');
		setSuccess('');
		setShowModal(false);
	};

	return (
		<Modal showModal={showModal} setShowModal={setShowModal} title={null}>
			{!success && !error && (
				<div>
					<div>
						<span
							className='icon-container delete-icon-container mx-auto mb-3'
							id='modal-delete-btn'
						>
							<UilTrashAlt
								size={16}
								color='#c2303e'
								title='Delete Group'
							/>
						</span>
						<p className='title mt-3 text-center'>
							You are about to delete a Firmware Image.
						</p>
						<p className='text-center mt-2 mb-3'>Are you sure?</p>
					</div>
				</div>
			)}
			{success && (
				<div className='text-center py-3'>
					<span className='icon-container success-check'>
						<UilCheck color='#fff' size={16} />
					</span>
					<p>Firmware image deleted successfully.</p>
				</div>
			)}
			{error && (
				<div className='text-center py-3'>
					<span className='icon-container delete-icon-container mx-auto mb-3'>
						<UilExclamationTriangle color='#c2303e' size={16} />
					</span>
					<p className='text-error'>{error}</p>
				</div>
			)}

			<div className='modal--footer d-flex justify-content-end'>
				{!success && !error ? (
					<PrimaryLineButton
						onClick={handleDeleteImage}
						id='delete-btn'
						loading={loading}
						disabled={loading ? true : false}
					>
						Yes, Delete
					</PrimaryLineButton>
				) : (
					<PrimaryLineButton onClick={close}>Ok</PrimaryLineButton>
				)}
			</div>
		</Modal>
	);
};

export default DeleteImage;
