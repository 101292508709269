import React, {useContext} from 'react';
import WidgetWrapper from '../WidgetWrapper/WidgetWrapper';
import EventsTable from './EventsTable/EventsTable';
import GroupAnalyticsPie from './GroupAnalyticsPie/GroupAnalyticsPie';
import AdvancedInsightsContext from '../../context/insights-context';

function SideWidgets() {
  const { 
    messages,
    allEventsData,
    eventCountDistributionByCategory,
    allEventsDataLoading,
    eventCountDistributionByCategoryLoading
  } = useContext(AdvancedInsightsContext);

  return (
    <div className='side-widgets'>
        <WidgetWrapper 
          title={messages.widgets.events_stats.title} 
          loading={allEventsData === null}
          backgroundDataRefreshStatus={allEventsDataLoading}
        >
            <EventsTable />
        </WidgetWrapper>

        <WidgetWrapper 
          title={messages.widgets.group_analytics_pie_chart.title} 
          loading={eventCountDistributionByCategory === null}
          backgroundDataRefreshStatus={eventCountDistributionByCategoryLoading}
        >
            <GroupAnalyticsPie />
        </WidgetWrapper>
        
    </div>
  )
}

export default SideWidgets