import React, {useState} from 'react';
import './ConfirmAccessAccount.scss';
import Modal from '../../../../../../../components/CustomModal/Modal';
import { PrimaryDarkButton } from '../../../../../../../components/Button/Button';
import InfoMessageWrapper from './../../../../../Widgets/InfoMessageWrapper/InfoMessageWrapper';
import { generateTenantToken } from '../../../../../../../utils/diagQueryAPI';
import Cookies from 'js-cookie';

function ConfirmAccessAccount({ showModal, setShowModal, accountDetails}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const initiateAccessAccount = async () => {
        setError(false);
        setLoading(true);

        const account_id = accountDetails.account_id;
        const account_email_id = accountDetails.account_email_id;
        const generateTokenResponse = await generateTenantToken({account_id});
        
        if (!generateTokenResponse?.status || (generateTokenResponse?.status !== 200) || (generateTokenResponse?.data?.status === "failure")) {
            setError(true);
            setLoading(false);

            return;
        }

        const tenantToken = generateTokenResponse.data?.account_token || "";
        if (tenantToken) {
            Cookies.set('TATK', tenantToken);
            Cookies.set('ta_id', account_id);
            Cookies.set('ta_eid', account_email_id);
            window.location.reload();
        }
        else {
            setError(true);
            setLoading(false);
        }
    }

    const handleModalDisplay = () => {
        setError(false);
        setLoading(false);

        setShowModal(show => !show);
    }

    if (!accountDetails) return null;

    return (
        <Modal showModal={showModal} setShowModal={handleModalDisplay} title="Access account">
            <div className='confirm-access-account-wrapper'>
                {
                    error && (
                        <div className='mt-2'>
                            <InfoMessageWrapper type="error">
                                <b>There was an error giving access. Please try again!</b>
                            </InfoMessageWrapper>
                        </div>
                    )
                }

                <p className='mt-3' style={{paddingBottom: "10px"}}>
                    You are about to get access to select ESP Insights dashboard features for the account mentioned below.
				</p>
                
                <div className='display-data'>
                    <div className='detail-row'>
                        <div className='key'>Account Email ID</div>
                        <div className='value'>{ accountDetails.account_email_id }</div>
                    </div>
                    <div className='detail-row'>
                        <div className='key'>Role</div>
                        <div className='value'>{ accountDetails.role }</div>
                    </div>
                </div>
                
                <div className='mt-2'>
                    <InfoMessageWrapper type="info">
                        To return to your account, click on <b>End Session</b> button at the top.
                    </InfoMessageWrapper>
                </div>
            </div>
            <div className='modal--footer d-flex justify-content-end'>
				<PrimaryDarkButton
					onClick={initiateAccessAccount}
					id="access-account-key"
                    loading={loading}
				>
					Access account
				</PrimaryDarkButton>
			</div>
        </Modal>
    )
}

export default ConfirmAccessAccount