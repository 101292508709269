import Cookies from 'js-cookie';
import { DIAG_API } from './constants';

export const getProjects = async () => {
	let res = await fetch(`${DIAG_API}/projects/list`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
};

export const getProjectsVersions = async (projectName) => {
	let res = await fetch(`${DIAG_API}/project/${projectName}/versions/list`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
};

export const getNodesByVersionCount = async (projectName) => {
	let res = await fetch(`${DIAG_API}/project/${projectName}/nodes/byversion/count`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
};

export const getEventsCounts = async (projectName, event, from, to) => {
	let res = await fetch(`${DIAG_API}/project/${projectName}/event/${event}/count?from_ts=${from}&to_ts=${to}`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
};

export const getOTAUpgrades = async (projectName, from, to) => {
	let res = await fetch(`${DIAG_API}/project/${projectName}/otaupgrade/count?from_ts=${from}&to_ts=${to}`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
};

export const getEventSubTypes = async (projectName, eventType, version) => {
	let type;
	if (eventType === 'crashes') {
		type = 'crash';
	}
	else if (eventType === 'events') {
		type = 'event';
	}
	else if (eventType === 'warnings') {
		type = 'warning';
	}
	else if (eventType === 'errors') {
		type = 'error';
	}
	else {
		type = 'reboot';
	}

	let api;
	if (version === 'all') {
		api = `${DIAG_API}/project/${projectName}/event/${type}/categories/list`
	}
	else {
		api = `${DIAG_API}/project/${projectName}/version/${version}/event/${type}/categories/list`
	}
	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
};

export const getNodesListBySubTypes = async (projectName, version, eventType, subType, from, to) => {
	const limit = 25;
	let type;
	if (eventType === 'crashes') {
		type = 'crash';
	}
	else if (eventType === 'events') {
		type = 'event';
	}
	else if (eventType === 'warnings') {
		type = 'warning';
	}
	else if (eventType === 'errors') {
		type = 'error';
	}
	else {
		type = 'reboot';
	}

	let api;
	if (version === 'all') {
		if (subType === 'all') {
			api = `${DIAG_API}/project/${encodeURIComponent(projectName)}/event/${type}/bynode/top?from_ts=${from}&to_ts=${to}&limit=${limit}`
		}
		else {
			api = `${DIAG_API}/project/${encodeURIComponent(projectName)}/event/${type}/category/${encodeURIComponent(subType)}/bynode/top?from_ts=${from}&to_ts=${to}&limit=${limit}`
		}
	}
	else {
		if (subType === 'all') {
			api = `${DIAG_API}/project/${encodeURIComponent(projectName)}/version/${version}/event/${type}/bynode/top?from_ts=${from}&to_ts=${to}&limit=${limit}`
		}
		else {
			api = `${DIAG_API}/project/${encodeURIComponent(projectName)}/version/${version}/event/${type}/category/${encodeURIComponent(subType)}/bynode/top?from_ts=${from}&to_ts=${to}&limit=${limit}`
		}
	}

	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
};

export const getEventsData = async (projectName, version, eventType, timeInterval, from, to) => {
	let interval;
	if (timeInterval === '1h') {
		interval = 3600;
	}
	else if (timeInterval === '1d') {
		interval = 86400;
	}
	else if (timeInterval === '1w') {
		interval = 604800;
	}
	else if (timeInterval === '1m') {
		interval = 2629743;
	}
	else {
		interval = 31556926;
	}

	let type;
	if (eventType === 'crashes') {
		type = 'crash';
	}
	else if (eventType === 'events') {
		type = 'event';
	}
	else if (eventType === 'warnings') {
		type = 'warning';
	}
	else if (eventType === 'errors') {
		type = 'error';
	}
	else {
		type = 'reboot';
	}

	let api;
	if (version === 'all') {
		api = `${DIAG_API}/project/${projectName}/event/${type}/byinterval/count?from_ts=${from}&to_ts=${to}&interval=${interval}`
	} else {
		api = `${DIAG_API}/project/${projectName}/version/${version}/event/${type}/byinterval/count?from_ts=${from}&to_ts=${to}&interval=${interval}`
	}
	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
};

export const getEventsSubTypeForPieChart = async (projectName, version, eventType, from, to) => {
	let api;
	if (version === 'all') {
		api = `${DIAG_API}/project/${projectName}/event/${eventType}/bycategory/count?from_ts=${from}&to_ts=${to}`
	}
	else {
		api = `${DIAG_API}/project/${projectName}/version/${version}/event/${eventType}/bycategory/count?from_ts=${from}&to_ts=${to}`
	}
	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
};

export const getSubEventsData = async (projectName, version, eventType, eventSubType, timeInterval, from, to) => {
	let interval;
	if (timeInterval === '1h') {
		interval = 3600;
	}
	else if (timeInterval === '1d') {
		interval = 86400;
	}
	else if (timeInterval === '1w') {
		interval = 604800;
	}
	else if (timeInterval === '1m') {
		interval = 2629743;
	}
	else {
		interval = 31556926;
	}
	let api;
	if (version === 'all') {
		api = `${DIAG_API}/project/${projectName}/event/${eventType}/category/${eventSubType}/byinterval/count?from_ts=${from}&to_ts=${to}&interval=${interval}`
	}
	else {
		api = `${DIAG_API}/project/${projectName}/version/${version}/event/${eventType}/category/${eventSubType}/byinterval/count?from_ts=${from}&to_ts=${to}&interval=${interval}`
	}
	let res = await fetch(api, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
};