import React, { useState, useEffect, useMemo } from 'react';
import PieCharts from './PieCharts';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import { shuffle } from 'lodash';
import { Alert } from 'react-bootstrap';

const InsightsPieChart = (props) => {

    const {
        pieChartData = [],
        version
    } = props

    // const [pieChartData, setPieChartData] = useState(props.projectData ? props.projectData : []);
    const [versionList, setVersionList] = useState([]);
    const [versionOptions, setVersionOptions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState("");

    useEffect(() => {
        if (props.versionList) {
            setVersionList(props.versionList);
        }
    }, [props.versionList])

    useEffect(() => {
        if (versionList.length > 0) {
            createVersionOptions(versionList);
            setSelectedVersion(versionList[0]);
        }
    }, [versionList])

    function createVersionOptions(versionList) {
        let options;
        options = versionList.map((item) => ({ label: item, value: item }))
        options.push({ label: 'All', value: 'all' })
        if (options.length > 0) {
            setVersionOptions(options);
        }
    }

    function handleVersionPieChartChange(value) {
        props.onPieChartVersionChange(value);
        setSelectedVersion(value)
    }

    function handlePieChartClick(name, text) {

        // props.onSubCategoryChange(name);
        if (text === 'Crashes by cause') {
            props.onSubEventTypeClick('crash', name);
        }
        else if (text === 'Events by tags') {
            props.onSubEventTypeClick('event', name);
        }
        else if (text === 'Warnings') {
            props.onSubEventTypeClick('warning', name);
        }
        else if (text === 'Errors by logs') {
            props.onSubEventTypeClick('error', name);
        }
        else {
            props.onSubEventTypeClick('reboot', name);
        }
    }

    const pieColorsList = ['#d45087', '#003f5c', '#2f4b7c', '#665191', '#a05195', '#f95d6a', '#006d64', '#a3586d'];
    const pieChartsData = useMemo(
        () => [
            {
                id: "crashes",
                text: "Crashes by cause",
                data: pieChartData && pieChartData[0] && pieChartData[0].distribution,
                colors: ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']
            },
            {
                id: "events",
                text: "Events by tags",
                data: pieChartData && pieChartData[1] && pieChartData[1].distribution,
                colors: ['#00C49F', '#FFBB28', '#FF8042', '#0088FE']
            },
            {
                id: "warnings",
                text: "Warnings",
                data: pieChartData && pieChartData[2] && pieChartData[2].distribution,
                colors: ['#FFBB28', '#FF8042', '#0088FE', '#00C49F']
            },
            {
                id: "errors",
                text: "Errors by logs",
                data: pieChartData && pieChartData[3] && pieChartData[3].distribution,
                colors: ['#FF8042', '#0088FE', '#00C49F', '#FFBB28']
            },
            {
                id: "reboots",
                text: "Reboots by reasons",
                data: pieChartData && pieChartData[4] && pieChartData[4].distribution,
                colors: ['#FFBB28', '#FF8042', '#00C49F', '#0088FE']
            }
        ], [pieChartData]
    );

    if(pieChartData.length === 0) {
        return null
    }

    return (
        <>
            <div className="p-2 mb-1 bg-white">
                <div className="d-flex flex-row p-2 ">
                    <div className='card-heading d-flex flex-grow-1'>
                        <div className='title'>
                            <span className='primary'>Group analytics for</span>
                            <span className='highlight'>{selectedVersion === "all" ? "all versions" : "version " + selectedVersion}</span>
                        </div>
                    </div>
                    <div className='ml-auto'>
                        <SelectDropdown
                            placeholder='select version'
                            id='filter-type'
                            defaultValue={versionOptions.length > 0 ? versionOptions[0] : null}
                            options={versionOptions}
                            className='navbar--dropdown select-dropdown-for-report px-1 ml-auto'
                            onChange={(i) => {
                                handleVersionPieChartChange(i.value);
                            }}
                        />
                    </div>
                </div>
                <div className="d-flex flex-row bg-white p-3 justify-content-between">
                    {
                        pieChartsData.map(pieChart => (
                            <div className="bg-white" key={pieChart.id}>
                                <PieCharts 
                                    data={pieChart.data} 
                                    legend={false} 
                                    text={pieChart.text} 
                                    topNodes={true} 
                                    onPieChartClick={handlePieChartClick} 
                                    colors={pieChart.colors}
                                />
                            </div>
                        ))
                    }
                </div>
                <Alert variant="light" className='text-align-right text-small mb-0'>
                    Click on pie to expand
                </Alert>
            </div>
        </>
    )
}

export default InsightsPieChart;