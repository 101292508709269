import React, { useEffect, useState } from "react";
import { PrimaryLineButton } from "../../../../../components/Button/Button";
import { Input } from "../../../../../components/Input/Input";
import { UilPlus } from "@iconscout/react-unicons";
import CustomToast from "../../../../../components/CustomToast/CustomToast";

const AddTagForm = ({
  updateFlag,
  createTag,
  nodeKey,
  messages,
}) => {
  const [tagProperty, setTagProperty] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [tagPropertyError, setTagPropertyError] = useState("");
  const [tagValueError, setTagValueError] = useState("");

  const resetStates = () => {
    setTagProperty("");
    setTagValue("");
    updateFlag((prev) => !prev);
  };

  const validateString = (value) => {
    if ( value === undefined || value.trim() == "" || value.length > 15 ) return false;
    return true
  };

  const handleFormInput = (e) => {
    const elementId = e.target.id
    const value = e.target.value;
    const isValueValid = validateString(value);
    
    switch (elementId)
    {
      case "tag-property":
        setTagProperty(value)
        if( !isValueValid ) 
        { 
          setTagPropertyError(messages.tags.tag_property_err_message)
          return;
        }
        setTagPropertyError("");
        break;
      case "tag-value":
        setTagValue(value)
        if( !isValueValid ) 
        { 
          setTagValueError(messages.tags.tag_value_err_message)
          return;
        } 
        setTagValueError("");
        break;
    }
  }

  const handleCreateTag = async (e) => {
    e.preventDefault()
    if ( tagPropertyError || tagValueError) {
      return;
    }
    // tag property validation
    if( !validateString(tagProperty) ){
        setTagPropertyError(messages.tags.tag_property_err_message)
        return;
    }
    // tag value validation
    if( !validateString(tagValue) ){
        setTagValueError(messages.tags.tag_value_err_message)
        return;
    }
    const tag = {
      property: tagProperty.trim(),
      value: tagValue.trim(),
    };
    const response = await createTag(nodeKey, tag);
    if (response) {
      CustomToast.success("Tag added successfully", { autoClose: 5000 });
    } else {
      CustomToast.error("Falied! to save tag. Please try again", { autoClose: 5000 });
    }
    resetStates();
  };

  return (
    <div className="add-tag-form">
        <form onSubmit={handleCreateTag} className="d-flex row">
        <div className="col-5 d-flex align-items-center">
          <span className="mr-2 label" style={{flex:"none"}}>Tag Name</span>{" "}
          <Input
            type="text"
            placeholder="For example- Region"
            required
            id="tag-property"
            value={tagProperty}
            onChange={  handleFormInput }
            hasError={  tagPropertyError.length !== 0  }
            error={ tagPropertyError}
          />
        </div>
        <div className="col-5 d-flex align-items-center">
          <span className="mr-2 label"> Value </span>{" "}
          <Input
            type="text"
            placeholder="For example - West"
            id="tag-value"
            error={ tagValueError }
            required
            value={ tagValue  }
            onChange={  handleFormInput }
            hasError={  tagValueError.length !== 0 }
          />
        </div>
        <div className="col">
          <PrimaryLineButton
            id="add-tag-btn"
            type="submit"
            style={{ width: "100%" }}
            onClick={handleCreateTag}
            disabled={tagPropertyError || tagValueError}
          >
            {" "}
            <UilPlus size={16} /> Create a new tag
          </PrimaryLineButton>
        </div>
       </form> 
    </div>
  );
};

export default AddTagForm;
