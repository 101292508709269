import React from "react";
import "./WidgetBox.scss";
import LinkIconButton from "../LinkIconButton/LinkIconButton";
import { UilRedo } from "@iconscout/react-unicons";

function WidgetBox({
  heading,
  showRefreshControl,
  onRefresh,
  refreshLabelText,
  children,
}) {
  return (
    <div className="widget-box-wrapper">
      {heading && (
        <div className="heading">
          <div className="main-text">{heading}</div>
          {showRefreshControl && onRefresh ? (
            <div className="refresh-widget-data">
              <LinkIconButton
                iconComponent={<UilRedo />}
                text={refreshLabelText || "Refresh"}
                clickHandler={onRefresh}
              />
            </div>
          ) : null}
        </div>
      )}
      <div className="data">{children}</div>
    </div>
  );
}

export default WidgetBox;
