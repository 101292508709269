import React from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import logo from './../../assets/insightslogo.svg';
import moment from 'moment';
import './UserWrapper.scss';

const UserWrapper = (props) => {
	return (
		<div className='user-wrapper'>
			<div className='form-container'>
				<div className='form-card'>
					<img
						alt='esp-insights-logo'
						src={logo}
						className='logo'
						id='insights-logo'
					/>
					{props.children}
				</div>
			</div>
			<div className='text-center'>
				{/* <a
					href='https://rainmaker.espressif.com/docs/faqs.html'
					className='text-link'
					id='help-link'
				>
					Help
				</a> 
				<span className='text-muted'>&nbsp; | &nbsp;</span>*/}
				<a
					href='mailto:sales@espressif.com'
					className='text-link'
					id='contact-link'
				>
					Contact Us
				</a>
				<span className='text-muted'>&nbsp; | &nbsp;</span>
				<a
					href='https://insights.espressif.com/'
					className='text-link'
					target='_blank'
					rel='noopener noreferrer'
					id='terms-of-use-link'
				>
					More Info
				</a>
				<div>
					<a
						className='text-muted'
						href='http://www.espressif.com'
						target='_blank'
						rel='noopener noreferrer'
						id='copyright-link'
					>
						&copy; {moment().format('YYYY')} ESPRESSIF SYSTEMS
						(SHANGHAI) CO. LTD
					</a>
				</div>
			</div>

			{
				getCookieConsentValue() !== 'true' && (
					<CookieConsent
						style={{
							background: '#212121',
							padding: '20px 16px',
						}}
						overlay={true}
						hideOnAccept={true}
						buttonText='I accept'
						buttonStyle={{
							background: '#fff',
							borderRadius: '4px',
							color: '#333',
							fontSize: '14px',
							padding: '6px 16px',
						}}
					>
						We use cookies to operate our site, manage your session and
						improve your experience. Click on &apos;I accept&apos; to
						accept all cookies. You can learn more by visiting our&nbsp;
						<a
							href='https://insights.espressif.com/docs/cookie-policy'
							target='_blank'
							rel='noreferrer'
							className='text-white font-weight-500'
						>
							<u>cookie policy</u>
						</a>
						.
					</CookieConsent>
				)
			}

		</div>
	);
};

export default UserWrapper;
