import React, { useState, useEffect, useContext } from 'react';
import './ByUser.scss';
import WidgetListItem from '../../../../Widgets/WidgetListItem/WidgetListItem';
import advancedInsightsContext from './../../../../context/insights-context';
import NoDataMessage from './../../../../Widgets/NoDataMessage/NoDataMessage';
import { formatPastTime } from '../../../../../../utils/commonFunctions';
import { deleteAccountInvite } from '../../../../../../utils/diagQueryAPI';
import CustomToast from '../../../../../../components/CustomToast/CustomToast';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import usePagination from '../../../../hooks/usePagination';

function ByUser() {
    const { 
        messages,
        invitationsByUser,
        invitationsByUserLoading,
        getInvitationsByUser
    } = useContext(advancedInsightsContext);

    const [userActions, setUserActions] = useState({});
    const [nextPagetoken, setNextPagetoken] = useState("");

    const { pagetoken, Pagination } = usePagination(nextPagetoken);

    useEffect(() => {
        getInvitationsByUser(pagetoken);
    }, [pagetoken]);
    
    useEffect(() => {
        setNextPagetoken(invitationsByUser?.page_token || "");
    }, [invitationsByUser]);

    const invitationsList = (invitationsByUser && invitationsByUser.list) ? invitationsByUser.list.filter(u => u.tenant_email_id) : [];

    const handleDeleteInvite = async (tenant_email_id) => {
        const deleteInviteResponse = await deleteAccountInvite({tenant_email_id});
        if (!deleteInviteResponse?.status || (deleteInviteResponse?.status !== 200) || (deleteInviteResponse?.data?.status === "failure")) {
            CustomToast.error('Error withdrawing this invite. Please try again!', { autoClose: 5000 }, 'withdraw-invite-toast');
            return;
        }
        
        setUserActions({ ...userActions, [tenant_email_id]: false })
    }

    if (invitationsByUserLoading) return <LoadingSpinner wrapperClassName="by-user-wrapper" />

    if (!(invitationsByUserLoading && invitationsByUser === null) && invitationsList.length === 0) return (
        <div className='by-user-wrapper'>
            <NoDataMessage text={messages.manage_multi_admins.pending_invitations.no_invites.by_user} />
            <Pagination />
        </div>
    )
    
    return (
        <div className='by-user-wrapper'>
            {
                invitationsList.map(({
                    tenant_email_id, message, created_at
                }) => (
                    <WidgetListItem 
                        key={tenant_email_id}
                        title={tenant_email_id}
                        text={message}
                        title_tip={formatPastTime(created_at)}
                        rejectActionText={messages.common_labels.withdraw}
                        rejectActionHandler={() => handleDeleteInvite(tenant_email_id)}
                        acceptAction={userActions.hasOwnProperty(tenant_email_id) ? userActions[tenant_email_id] : null}
                        acceptActionInfoMessage={userActions[tenant_email_id] ? "" : "You withdrew this invite"}
                    />
                ))
            }

            <Pagination />
        </div>
    )
}

export default ByUser