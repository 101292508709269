import React from 'react'
import './SaveDashboard.scss'
import {UilLightbulbAlt, UilSave} from '@iconscout/react-unicons';
import InfoMessageWrapper from '../../../pages/AdvancedInsights/Widgets/InfoMessageWrapper/InfoMessageWrapper';
import { PrimaryDarkButton } from '../../Button/Button';

const SaveDashboardNotice = ({notice, action}) => {
  return (
    <div className='save-dashboard-notice-wrapper'>
      <InfoMessageWrapper>    
          <div className='text'><UilLightbulbAlt /> {notice}</div>
          <div className='action'>
            <PrimaryDarkButton onClick={action}><UilSave size={18} /> Save Dashboard</PrimaryDarkButton>
          </div>
      </InfoMessageWrapper>
    </div>
  )
}

export default SaveDashboardNotice