import React from 'react';
import './LogTrace.scss';

function LogTrace({data}) {
    if (!data) return null;

    return (
        <div className='log-trace-wrapper'>
            {
                data.Address ? (
                    <div>
                        <span className='address'>{data.Address}</span>
                    </div>
                ) : null
            }
            {
                (data.File || data.Line || data.Function) ? (
                    <div>
                        {
                            data.File ? (
                                <React.Fragment>
                                    <span className='file'>{data.File}</span>
                                    <span className='seperator'>:</span>
                                </React.Fragment>
                            ) : null
                        }
                        
                        {
                            data.Line ? (
                                <span className='line'>{data.Line} in </span>
                            ) : null
                        }


                        {
                            data.Function ? (
                                <span className='function'>{data.Function}</span>
                            ) : null
                        }
                    </div>
                ) : (
                    <div className='info'>
                        <mark>We could not find the firmware image to cross-reference this log entry. Please upload a firmware image to get cross-referenced information for this log entry.</mark>
                    </div>
                )
            }
            <div>
                
            </div>
        </div>
    )
}

export default LogTrace