import { debounce } from "lodash";
import { useLayoutEffect, useState, useEffect, useRef } from "react";

export function useElementDimensions() {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const elementRef = useRef();

  const getElementDimensions = () => {
    if (elementRef.current) {
      setHeight(elementRef.current.clientHeight);
      setWidth(elementRef?.current?.clientWidth);
    }
  };

  useLayoutEffect(() => {
    getElementDimensions();
  });

  const debouncedDimensions = debounce(getElementDimensions, 500);

  useEffect(() => {
    window.addEventListener("resize", debouncedDimensions);

    return () => {
      window.removeEventListener("resize", debouncedDimensions);
    };
  });

  return [elementRef, { height, width }];
}
