import Cookies from 'js-cookie';
import { DIAG_API } from './constants';
import { saveAs } from 'file-saver';
import { diagnostics } from "./axios";

export const listAuthKeys = async (limit = 10, pagetoken = "") => {
    let res = await fetch(`${DIAG_API}/user/authkey/list?limit=${limit}&pagetoken=${pagetoken}`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
}

export const getAuthKeyByID = async id => {
    let res = await fetch(`${DIAG_API}/user/authkey?id=${id}`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
}

export const deleteAuthKeyByID = async id => {
    let res = await fetch(`${DIAG_API}/user/authkey?id=${id}`, {
		method: 'delete',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
}

export const downloadAuthKeyByID = async (id, name) => {
    let res = await fetch(`${DIAG_API}/user/authkey/download?id=${id}`, {
		method: 'get',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		}
	});

    res.headers.forEach(function(value, name) {
		console.log(name + ": " + value);
	});

    const blob = await res.blob();
    const newBlob = new Blob([blob]);
 	
    saveAs(newBlob, `${name}.txt`);
}

export const createAuthKey = async data => {
    let res = await fetch(`${DIAG_API}/user/authkey`, {
		method: 'post',
        body: JSON.stringify(data),
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
}

export const listConnectorAuthKeys = async (limit = 10, pagetoken = "") => {
	return diagnostics.get(`/user/connectorauthkey/list?limit=${limit}&pagetoken=${pagetoken}`);
}

export const getConnectorAuthKeyByID = async id => {
	return diagnostics.get(`/user/connectorauthkey?connector_auth_key_id=${id}`);
}

export const getConnectorConfigUrlByID = async id => {
	return diagnostics.get(`/user/connectorauthkey/presignedurl?connector_auth_key_id=${id}`);
}

export const createConnectorAuthKey = async data => {
	return diagnostics.post(`/user/connectorauthkey`,data);
}

export const deleteConnectorAuthKey = async id => {
    let res = await fetch(`${DIAG_API}/user/connectorauthkey?connector_auth_key_id=${id}`, {
		method: 'delete',
		headers: {
			Authorization: Cookies.get('ATK'),
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	});
	return res.json();
}