import React, { useState, useEffect } from 'react';
import {
	UilSyncExclamation,
	UilExclamationOctagon,
	UilExclamationTriangle,
	UilAndroidPhoneSlash,
	UilProcess,
} from '@iconscout/react-unicons';
import CountUp from 'react-countup';

const CountCards = (props) => {
	const { loading, eventCounter } = props;
	const [cardLoading, setCardLoading] = useState(loading);

	useEffect(() => {
		if (eventCounter) {
			setCardLoading(false);
		}
	}, [eventCounter]);

	let eventCounterOptions = [
		{
			event: 'error',
			color: '#FF8A65',
			icon: <UilExclamationOctagon size='16' color='#FF8A65' />,
		},
		{
			event: 'warning',
			color: '#FFB74D',
			icon: <UilExclamationTriangle size='16' color='#FFB74D' />,
		},
		{
			event: 'event',
			color: '#81C784',
			icon: <UilProcess size='16' color='#81C784' />,
		},
	];

	return (
		<div className='row count-card'>
			<div className='col-lg-6' style={{ paddingRight: '8px' }}>
				<div className='card--container diagnostic--card align-items-center'>
					<div className='d-flex align-items-center '>
						<div
							className='diagnostic--icon--container'
							style={{
								background: `#EF535033`,
							}}
						>
							<UilAndroidPhoneSlash size='16' color='#EF5350' />
						</div>
						<p className='card--title ml-1'>Crash</p>
					</div>
					{cardLoading ? (
						<div className='spinner-border text-link' role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					) : (
						eventCounter && (
							<CountUp
								end={eventCounter.crash || 0}
								duration={1}
								className='card--count '
							/>
						)
					)}
				</div>
			</div>
			<div className='col-lg-6' style={{ paddingLeft: '8px' }}>
				<div className='card--container diagnostic--card align-items-center'>
					<div className='d-flex align-items-center'>
						<div
							className='diagnostic--icon--container'
							style={{
								background: `#82B1FF33`,
							}}
						>
							<UilSyncExclamation size='16' color='#82B1FF' />
						</div>
						<p className='card--title ml-1'>Reboot</p>
					</div>
					{cardLoading ? (
						<div className='spinner-border text-link' role='status'>
							<span className='sr-only'>Loading...</span>
						</div>
					) : (
						eventCounter && (
							<CountUp
								end={eventCounter.reboot || 0}
								duration={3}
								className='card--count'
							/>
						)
					)}
				</div>
			</div>
			{eventCounterOptions.map((option, i) => (
				<div
					className='col-lg-4'
					key={option.event}
					style={
						i === 1
							? { paddingLeft: '8px', paddingRight: '8px' }
							: { paddingLeft: '12px', paddingRight: '12px' }
					}
				>
					<div
						className='card--container diagnostic--card align-items-center p-2'
						style={{ minHeight: '65px' }}
					>
						<div className='d-flex align-items-center'>
							<div
								className='diagnostic--icon--container'
								style={{
									background: `${option.color}33`,
								}}
							>
								{option.icon}
							</div>
						</div>
						{cardLoading ? (
							<div
								className='spinner-border text-link'
								role='status'
							>
								<span className='sr-only'>Loading...</span>
							</div>
						) : (
							eventCounter && (
								<div className='d-flex flex-column justify-content-center align-items-center'>
									<CountUp
										end={eventCounter[option.event] || 0}
										duration={3}
										className='card--count my-0'
									/>
									<p className='card--title ml-1'>
										{option.event}
									</p>
								</div>
							)
						)}
					</div>
				</div>
			))}
		</div>
	);
};

export default CountCards;
