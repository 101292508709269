import React, {useMemo} from 'react';
import { PrimarySmallButton } from '../../../components/Button/Button';
import './PieDetailsModal.scss';
import { UilChartLine } from '@iconscout/react-unicons';
import Table from '../../../components/Table/Table';
import Modal from '../../../components/CustomModal/Modal';

export default function PieDetailsModal({
    show = false,
    title = "",
    data = [],
    categoryClickHandler = null,
    closeHandler = null
}) {

    const columns = useMemo(
        () => [
            {
                Header: '#',
                accessor: 'serial',
                style: {
                    width: "5%"
                }
            },
            {
                Header: 'Reason - Code',
                accessor: 'category',
                style: {
                    width: "65%"
                }
            },
            {
                Header: "Count",
                accessor: 'count',
                style: {
                    width: "15%"
                }
            },
            {
                Header: " ",
                accessor: row => (
                    <PrimarySmallButton id={row.category} onClick={categoryClickHandler}>
                        <UilChartLine size={20} /> Expand
                    </PrimarySmallButton>
                ),
                style: {
                    width: "15%",
                    textAlign: "right"
                }
            }
        ], []
    );

    if (!data.length) return null;

    const tableData = data.map(({category, count}, index) => ({ serial: index + 1, category, count }));

    return (
            <Modal showModal={show} setShowModal={closeHandler} title={title}>
                <div className='p-3'>
                    <div className='w-100 mb-3'>
                        <Table data={tableData} columns={columns} />
                    </div>
                </div>
            </Modal>
    );
}
