import React from 'react';
import './LogoSpinner.scss';

export default function LogoSpinner(props) {
    const displayClass = props.loading && props.loading === false ? "hide" : "show";
    const loaderTypeClass = props.type === "inline" ? "inline" : "full-width";

    return (
        <div className={`logo-spinner ${displayClass} ${loaderTypeClass}`}></div>
    )
}

