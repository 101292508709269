import React, {useEffect, useState} from 'react';
import { ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { PrimaryLineButton } from '../../../../components/Button/Button';
import * as generalConfig from '../../general-config.json';
import EventButton from './EventButton/EventButton';
import './EventTypesButtonGroup.scss';

function EventTypesButtonGroup({
    size = "xs",
    defaultSelectedType = "error",
    globalEventTypeFilterValue = "",
    clickHandler = null,
    labels = {},
    parentWidth = null
}) {
    const allEventTypes = generalConfig.event_types;
    const [type, setType] = useState(defaultSelectedType || allEventTypes[0].id);
    const individualButtonWidth = 100;
    const possibleButtonsToDisplay = parentWidth ? Math.trunc(parentWidth / individualButtonWidth) : allEventTypes.length;
    const dropdownRequired = possibleButtonsToDisplay < allEventTypes.length;

    useEffect(() => {
        const type = globalEventTypeFilterValue || defaultSelectedType
        setType(type);
    }, [globalEventTypeFilterValue]);

    const handleButtonClick = e => {
        const selectedType = e.target.id;
        setType(selectedType);
        clickHandler(selectedType);
    }

    const getButtonProps = id => {
        return {
            id: id,
            key: id,
            clickHandler: handleButtonClick,
            selected: type === id,
            disabled: globalEventTypeFilterValue && globalEventTypeFilterValue !== id,
            label: labels[id]?.label_plural || id
        }
    }

    const visibleButtons = allEventTypes.slice(0, dropdownRequired ? possibleButtonsToDisplay - 1 : possibleButtonsToDisplay).map(({id}) => <EventButton {...getButtonProps(id)} /> );
    const dropdownButtons = allEventTypes.slice(dropdownRequired ? possibleButtonsToDisplay - 1 : possibleButtonsToDisplay, allEventTypes.length).map(({id}) => <Dropdown.Item><EventButton {...getButtonProps(id)} /></Dropdown.Item> );

    return (
        <div className='event-types-button-group'>
            <ButtonGroup size={size} className='group'>
                { visibleButtons }

                {
                    dropdownRequired && dropdownButtons.length ? (
                        <DropdownButton as={ButtonGroup} title={labels.more} id="event-types-button-group-dropdown">
                            { dropdownButtons }
                        </DropdownButton>
                    ) : null
                }
            </ButtonGroup>
        </div>
    )
}

export default EventTypesButtonGroup