export const isStrongPassword = (password) => {
	return RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})').test(password);
};

export const isValidEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

export const isValidOTAImageName = (image) => {
	const re = /^[a-zA-Z0-9\s!*'()._-]+$/;
	return re.test(String(image).toLowerCase());
};

export const isValidAuthKeyName = name => {
	const re = /^[a-zA-Z0-9]+$/;
	return re.test(String(name).toLowerCase());
}

export const isValidConnectorAuthKeyName = name => {
	const re = /^[a-zA-Z0-9]+$/;
	return re.test(String(name).toLowerCase());
}

export const isValidOTAPackageName = name => {
	const re = 	/^[a-zA-Z0-9\s!*'._-]+$/;
	return re.test(String(name).toLowerCase());
}