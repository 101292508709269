import React, { useState, useEffect } from 'react';
import { ErrorLineButton } from '../Button/Button';
import Modal from './Modal';

export default function DeleteAuthKeyModal(props) {
    const { showModal, setShowModal, authKeyData = {}, onConfirm } = props;
	const [loading, setLoading] = useState(false);
    const modalTitle = `Delete ${authKeyData.name}`;

    useEffect(function(){
        return function cleanup() {
            setLoading(false);
        };
    }, [showModal])

    const handleDeleteConfirmation = () => {
        setLoading(true);
        onConfirm && onConfirm();
    }

    if (!authKeyData) return null;

    return (
        <Modal showModal={showModal} setShowModal={setShowModal} title={modalTitle}>
            <div>
                <p className='title mt-3 text-center' style={{paddingBottom: "10px"}}>
                    Are you sure you want to delete this auth key?
				</p>
            </div>
            <div className='modal--footer d-flex justify-content-end'>
				<ErrorLineButton
					onClick={handleDeleteConfirmation}
					id="delete-key"
                    loading={loading}
				>
					Yes, Delete
				</ErrorLineButton>
			</div>
        </Modal>
    )
}
