import { diagnostics } from "./axios";

export const getFiltersList = (requestData = {}) => {
    return diagnostics.get("/query/filters/list", { params: requestData});
}

export const getFilterSuggestions = (requestData = {}) => {
    return diagnostics.get("/query/filters/suggest", { params: requestData});
}

export const getEventCount = (requestData = {}) => {
    const countType = requestData.interval ? "byinterval" : "bycategory";
    return diagnostics.get(`/query/event/${countType}/count`, { params: requestData});
}

export const getEventByNodeTop = (requestData = {}) => {
    return diagnostics.get("/query/event/bynode/top", { params: requestData});
}

export const getEventList = (requestData = {}) => {
    return diagnostics.get("/query/event/list", { params: requestData});
}

export const getQueryStateList = (requestData = {}) => {
    return diagnostics.get("/query/state/list", { params: requestData});
}

export const getQueryEventStat = (requestData = {}) => {
    return diagnostics.get("/query/event/stat", { params: requestData});
}

export const getNodeInfo = (requestData = {}) => {
    return diagnostics.get("/node/info", { params: requestData});
}

export const getNodeParamsMeta = (requestData = {}) => {
    return diagnostics.get("/node/param/meta", { params: requestData});
}

export const getNodeParamsList = (requestData = {}, paramsMetaData = []) => {
    let endPoint = "/node/param/list?";
    if (paramsMetaData.length) {
        paramsMetaData.forEach(param => endPoint = `${endPoint}&id=${param}`);
    }
    return diagnostics.get(endPoint, { params: requestData});
}

export const getBillingUsageMeta = (requestData = {}) => {
    return diagnostics.get("/user/billing/usage/meta", { params: requestData});
}

export const getBillingUsageByInterval = (requestData = {}) => {
    return diagnostics.get("/user/billing/usage/byinterval", { params: requestData});
}

export const getNodeTagsList = (requestData = {}) => {
    return diagnostics.get("/node/tags", { params: requestData });
}

export const addTagToNodeTagsList = (requestData = {}) => {
    return diagnostics.post(`/node/tags?node_key=${requestData.node_key}`, requestData.body)
}

export const removeTagFromNodeTagsList = (requestData = {}) => {
    return diagnostics.delete('/node/tags',{ params: requestData })
}

export const listTenantInvite = (requestData = {}) => {
    return diagnostics.get('/user/tenant/invite/list',{ params: requestData })
}

export const respondTenantInvite = (requestData = {}) => {
    return diagnostics.post('/user/tenant/invite', requestData)
}

export const listAccountInvite = (requestData = {}) => {
    return diagnostics.get('/user/account/invite/list',{ params: requestData })
}

export const createAccountInvite = (requestData = {}) => {
    return diagnostics.post('/user/account/invite', requestData)
}

export const deleteAccountInvite = (requestData = {}) => {
    return diagnostics.delete('/user/account/invite', { params: requestData })
}

export const listTenants = (requestData = {}) => {
    return diagnostics.get('/user/account/tenant/list',{ params: requestData })
}

export const listAccounts = (requestData = {}) => {
    return diagnostics.get('/user/tenant/account/list',{ params: requestData })
}

export const deleteAccountsTenant = (requestData = {}) => {
    return diagnostics.delete('/user/account/tenant',{ params: requestData })
}

export const deleteTenantsAccount = (requestData = {}) => {
    return diagnostics.delete('/user/tenant/account',{ params: requestData })
}

export const generateTenantToken = (requestData = {}) => {
    return diagnostics.get('/user/tenant/account/token',{ params: requestData })
}

export const getNodeConfigMeta = (requestData = {}) => {
    return diagnostics.get("/node/config/meta", { params: requestData });
}