import "./SettingPanel.scss";
import ConfigForm from "./ConfigForm/ConfigForm";
import { orderBy } from "lodash";
import SettingHeader from "./SettingHeader/SettingHeader";
import { useState } from "react";
import { UilAngleRight, UilAngleDown } from "@iconscout/react-unicons";
import { IconButton } from "../../../../../components/Button/Button";

function SettingPanel(props) {
  const settingData = props.data;
  const isRootSetting = props.root === true;
  const isNestedSetting = props.nested === true;
  const [showDescendantsOfRootSetting, setShowDescendantsOfRootSetting] =
    useState(isRootSetting ? false : null);

  const cssClasses = ["setting-panel-wrapper"];
  if (isRootSetting) cssClasses.push("root");
  if (!isRootSetting) cssClasses.push("child");
  if (isNestedSetting) cssClasses.push("nested");

  const getSettingUniqueKey = (settingData) => {
    if (!settingData || !settingData.name) return "";
    return settingData.name.join(".");
  };

  const segregateValidSettingItem = (list) => {
    if (!list) return {};

    const settingItemIndex = list.findIndex(
      (i) => i.data_type || i.data_type === 0
    );
    const settingItem = settingItemIndex !== -1 ? list[settingItemIndex] : null;
    const restItems = list.filter((item, i) => i !== settingItemIndex);
    return {
      settingItem,
      restItems: restItems.length
        ? orderBy(restItems, [
            function (o) {
              return o.items[0].data_type;
            },
          ])
        : null,
    };
  };

  const hasNestedSettings = (settingData) => {
    if (settingData?.items?.length) {
      if (settingData.items[0].items) return true;
    }
    return false;
  };

  const { settingItem = null, restItems = null } = segregateValidSettingItem(
    settingData.items
  );
  const settingItemUniqueKey = getSettingUniqueKey(settingItem);

  const handleShowDescendantsOfRootSetting = () => {
    if (isRootSetting) {
      setShowDescendantsOfRootSetting((v) => !v);
    }
  };

  return (
    <div className={cssClasses.join(" ")}>
      {settingItem ? (
        <SettingHeader
          id={settingItemUniqueKey}
          label={settingItem.label || settingItemUniqueKey}
          endContent={
            <ConfigForm
              data={settingItem}
              id={settingItemUniqueKey}
              onUpdate={(value) => props.onSettingsUpdate(settingItem, value)}
            />
          }
          variant={isRootSetting ? "primary" : "secondary"}
        />
      ) : null}

      {!settingItem && isRootSetting ? (
        <>
          {restItems
            .filter((o, i) => i === 0)
            .map((o) => {
              const itemData = o.items?.length ? o.items[0] : null;
              if (!itemData) return null;

              const key = getSettingUniqueKey(itemData);
              return key ? (
                <SettingHeader
                  id={key}
                  label={itemData.label || key}
                  startContent={
                    <IconButton onClick={handleShowDescendantsOfRootSetting}>
                      {showDescendantsOfRootSetting ? (
                        <UilAngleDown />
                      ) : (
                        <UilAngleRight />
                      )}
                    </IconButton>
                  }
                  endContent={
                    <ConfigForm
                      id={key}
                      data={itemData}
                      onUpdate={(value) =>
                        props.onSettingsUpdate(itemData, value)
                      }
                    />
                  }
                  variant="primary"
                />
              ) : (
                <>
                  <SettingHeader
                    id={settingData.key}
                    label={settingData.label || settingData.key}
                    variant="primary"
                  />
                  <div
                    className={`setting-items ${
                      hasNestedSettings(itemData) ? "tenant" : "owner"
                    }`}
                  >
                    <SettingPanel
                      data={itemData}
                      nested={hasNestedSettings(itemData)}
                      onSettingsUpdate={props.onSettingsUpdate}
                    />
                  </div>
                </>
              );
            })}

          {showDescendantsOfRootSetting &&
            restItems
              .filter((o, i) => i !== 0)
              .map((itemData) => (
                <div className="setting-items">
                  <SettingPanel
                    data={itemData}
                    onSettingsUpdate={props.onSettingsUpdate}
                  />
                </div>
              ))}
        </>
      ) : null}

      {!settingItem && !isRootSetting
        ? restItems.map((itemData) => (
            <div
              className={`setting-items ${
                hasNestedSettings(itemData) ? "tenant" : "owner"
              }`}
            >
              <SettingPanel
                data={itemData}
                nested={hasNestedSettings(itemData)}
                onSettingsUpdate={props.onSettingsUpdate}
              />
            </div>
          ))
        : null}
    </div>
  );
}

export default SettingPanel;
