import React from 'react';
import { generateHashURL } from '../../../utils/commonFunctions';
import {UilArrowUpRight } from '@iconscout/react-unicons';

const ViewMetric = ({nodeId, nodeKey, logTime}) =>{
	const start = Math.trunc((logTime - (30 * 60000)) / 1000);
	const end = Math.trunc((logTime + (30 * 60000)) / 1000);
	const metricsLink = generateHashURL(`/home/diagnostics/node/${nodeId}/metrics/?key=${nodeKey}`, {start, end});

	return(
		<div className="tooltip-container" style={{maxWidth: "16px"}}>
            <span className="tooltiptext">View Metrics</span>
			<a
				href={metricsLink}
				target="_blank"
				rel="noopener noreferrer"
				onClick={e => e.stopPropagation()}
			>
        	  <span className={" icon-container color-primary"}>
        	    <UilArrowUpRight size="16" />
        	  </span>
        	</a>
		</div>
	)
}

export default ViewMetric