import React, { useState } from 'react';
import UserWrapper from '../../../components/UserWrapper/UserWrapper';
import { forgotPasswordCall } from '../../../utils/apiCalls';
import { useHistory } from 'react-router-dom';
import { Input } from '../../../components/Input/Input';
import { isValidEmail } from '../../../utils/validationSchemas';
import { PrimaryDarkButton } from '../../../components/Button/Button';

const ForgotPassword = () => {
	let history = useHistory();
	const isCustomer = window.CUSTOMER_DEPLOYMENT;
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSendResetLink = (e) => {
		e.preventDefault();
		const user_name = e.target.elements.username.value;

		if (!user_name || !isValidEmail(user_name)) {
			setError('Please enter a valid username');
			return;
		}
		setLoading(true);

		forgotPasswordCall(user_name).then((response) => {
			setLoading(false);
			setError(response);
			if (!response) {
				history.push({
					pathname: '/set-new-password',
					state: { user_name },
				});
			}
		});
	};

	return (
		<UserWrapper>
			<p className='text-muted'>
				Please enter the email address associated with your account.
			</p>

			<form onSubmit={handleSendResetLink}>
				<Input
					type='email'
					name='username'
					placeholder='Email'
					id='username'
					onChange={() => error && setError('')}
				/>

				{error && <p className={'text-error'}>{error}</p>}

				<div className='d-flex justify-content-center align-items-center mt-3'>
					<PrimaryDarkButton
						loading={loading}
						minWidth='91px'
						disabled={loading ? true : false}
						id='forget-password-btn'
						tabIndex='6'
						type='submit'
					>
						Continue
					</PrimaryDarkButton>
				</div>
			</form>

			<div className='d-flex justify-content-center mt-4'>
				<a href='/login' className='text-link' id='login-link'>
					Wait, I remember. Take me back!
				</a>
			</div>
			{!isCustomer && (
				<div className='d-flex justify-content-center'>
					<span className='text-muted'>Create an account&nbsp;</span>
					<a href='/sign-up' className='text-link' id='signup-link'>
						Sign Up
					</a>
				</div>
			)}
		</UserWrapper>
	);
};

export default ForgotPassword;
