import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserWrapper from '../../../components/UserWrapper/UserWrapper';
import { forgotPasswordConfirmCall, loginCall } from '../../../utils/apiCalls';
import { Input } from '../../../components/Input/Input';
import CustomToast from '../../../components/CustomToast/CustomToast';
import Password from '../../../components/Password/Password';

const ForgotPassword = (props) => {
	let history = useHistory();
	const [error, setError] = useState('');
	const [state, setState] = useState({});

	const [loading, setLoading] = useState(false);

	const inputs = [
		{
			name: 'password',
			placeholder: 'New Password',
			type: 'password',
			id: 'password',
		},
		{
			name: 'confirm_password',
			placeholder: 'Confirm Password',
			type: 'password',
			id: 'confirm-password',
		},
	];

	const handleInputChange = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		setError('');
		setState((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSendResetLink = (data) => {
		const { user_name } = props.location.state;
		const { password, confirm_password, verification_code } = data;
		setError('');

		if (!password || !confirm_password || !verification_code) {
			setError('Please enter all the details.');
			return;
		} else if (password !== confirm_password) {
			setError('Passwords did not match!');
			return;
		} else if (verification_code.length > 6) {
			setError('Verification code is not valid!');
			return;
		}

		if (!error) {
			setLoading(true);
			forgotPasswordConfirmCall(
				user_name,
				password,
				verification_code
			).then((response) => {
				if (!response) {
					CustomToast.success(
						'Password changed successfully. Signing you in...',
						{
							autoClose: 5000,
						},
						'password-toast'
					);
					loginCall(user_name, password).then((response) => {
						setLoading(false);
						setError(response);
						if (!response) {
							history.push('/home/insights');
						}
					});
				} else {
					setLoading(false);
					setError(response);
				}
			});
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleSendResetLink(state);
		}
	};

	return (
		<>
			<UserWrapper>
				<p className='text-muted'>
					Please enter the new password and verification code sent to
					your email.
				</p>
				{inputs.map((input, index) => {
					return (
						<Password
							className='input'
							name={input.name}
							placeholder={input.placeholder}
							key={`${input.name}${index}`}
							onChange={(e) => handleInputChange(e)}
							maxLength='64'
							id={input.id}
							onKeyPress={handleKeyPress}
							tabIndex={input.tab}
						/>
					);
				})}
				<Input
					className='input'
					type='text'
					name='verification_code'
					placeholder='Verification Code'
					onChange={(e) => handleInputChange(e)}
					id='verification-code'
					onKeyPress={handleKeyPress}
				/>
				{error && <p className={'text-error'}>{error}</p>}
				<div className='d-flex justify-content-end mt-3'>
					<button
						className='btn primary-button primary-dark-button'
						onClick={() => handleSendResetLink(state)}
						id='change-password-btn'
					>
						<div style={{ minWidth: '120px' }}>
							{loading ? (
								<div
									className='spinner-border text-light'
									role='status'
								>
									<span className='sr-only'>Loading...</span>
								</div>
							) : (
								'Change Password'
							)}
						</div>
					</button>
				</div>
			</UserWrapper>
		</>
	);
};

export default ForgotPassword;
