import React from 'react';
import './DetailsCard.scss';
import { format, fromUnixTime } from 'date-fns';

function DetailsCard({data}) {

    const keyIsDateTimestamp = (key) => {
        const possibleDateKeys = ["created_at", "expiration_date"];
        return possibleDateKeys.includes(key);
    }

    const formatValue = (key, value) => {
        if (keyIsDateTimestamp(key)) {
            return value ? format(fromUnixTime(value), "dd/MM/yy") : "NA";
        }
        if (typeof value === 'boolean') {
            return value ? "Yes" : "No";
        }
        return value;
    }
    
    return (
        <div className='details-card-wrapper'>
            {
                Object.keys(data).map(key => (
                    <div className='detail-row' key={key}>
                        <div className='key'>{key}</div>
                        <div className='value'>{formatValue(key, data[key])}</div>
                    </div>
                ))
            }
        </div>
    )
}

export default DetailsCard