import React, { useEffect } from 'react';
import UserWrapper from '../../../components/UserWrapper/UserWrapper';
import { clearCookies } from '../../../utils/commonFunctions';
import { logoutCall } from '../../../utils/apiCalls';
import Notice from '../../../components/AppNotices/Notice/Notice';

const Unauthorised = () => {
	const handleLogout = async () => {
		await logoutCall();
		clearCookies();
	};

	useEffect(() => {
		handleLogout();
	}, []);

	return (
		<>
			<UserWrapper>
				<p className='text-center'>
                    <Notice type="error" text="Sorry, there was an error authorising your request." />
				</p>

				<p className='text-center mt-2 mb-0'>
					<a
						href='/login'
						className='text-link text-center'
						id='login-link'
					>
						Back to Login
					</a>
				</p>
			</UserWrapper>
		</>
	);
};

export default Unauthorised;
