import React, { useState } from 'react';
import Modal from './Modal';
import { Input } from '../Input/Input';
import { getBase64 } from '../../utils/commonFunctions';
import {
	uploadOtaPackagesCall
} from '../../utils/apiCalls';
import {
	UilPlusCircle,
	UilTimesCircle,
	UilCheck,
} from '@iconscout/react-unicons';
import { isValidOTAPackageName } from '../../utils/validationSchemas';
import { LinkButton, PrimaryLineButton } from '../Button/Button';
import { getUploadedPackagePresignedURLCall, uploadFirmwarePackageURLCall, uploadOtaFirmwarePackageCall } from '../../utils/diagAPI';
import { trim } from 'lodash';

// eslint-disable-next-line react/display-name
const AddPackageModal = (props) => {
	const { showModal, setShowModal } = props;

	const [state, setState] = useState({});
	const [firmwarePackage, setFirmwarePackage] = useState(null);
	const [packageName, setPackageName] = useState(null);
	const [packageSize, setPackageSize] = useState(null);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const close = () => {
		if (success) {
			props.refreshParent();
		}
		setError('');
		setState({});
		setSuccess(false);
		setFirmwarePackage(null);
		setPackageName(null);
		setPackageSize(null);
		setShowModal(false);
	};

	const handleInputChange = (event) => {
		let name = event.target.name;
		let value = trim(event.target.value);
		setError('');
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleAddOtaPackage = async () => {
		if (
			!state.package_name ||
			state.package_name === ' ' ||
			!state.type ||
			state.type === ' ' ||
			!firmwarePackage
		) {
			setError('Please provide all details.');
			return;
		}
		if (!isValidOTAPackageName(state.package_name)) {
			setError('Please provide a valid package name that can only include letters, numbers, and the following special characters: _ . -');
			return;
		}
		setLoading(true);
		packageName.indexOf('.zip') > -1 && uploadPackage();
	};

	const uploadPackage = async () => {
		let presignedURL;
		await getUploadedPackagePresignedURLCall(packageName, packageSize)
			.then((response) => {
				if (response.statusCode === 401) {
					props.sessionExpire();
					return;
				}
				if (response.status === 'SUCCESS' || response.statusCode === 200) {
					presignedURL = response.url;
				} else {
					setError(response.description);
					return;
				}
			})
			.catch(() => {
				setError('Something went wrong!');
			});

		const res = await uploadFirmwarePackageURLCall(presignedURL, firmwarePackage);

		if (res && res.status === 200) {
			uploadOtaFirmwarePackageCall(state, packageName)
			.then((response) => {
				if (response.statusCode === 401) {
					props.sessionExpire();
					return;
				}
				
				if (response.status === 'SUCCESS' || response.statusCode === 200) {
					setError('');
					setSuccess(true);
				} else {
					setError(response.description || 'Something went wrong!');
				}

				setLoading(false);
			})
			.catch(() => {
				setError('Something went wrong!');
				setLoading(false);
			});
		}
		else {
			setError('Something went wrong!');
			setLoading(false);
		}
	};

	const handleFileUpload = async (e) => {
		setError('');
		const {
			target: { files },
		} = e;
		if (files[0]) {
			setPackageName(files[0].name);
			setPackageSize(files[0].size);
			setFirmwarePackage(files[0]);
		}
	};

	return (
		<Modal showModal={showModal} setShowModal={close} title='Add Package'>
			{!success ? (
				<>
					<div className='p-3'>
						<div className='w-75 mb-3'>
							<Input
								type='text'
								name='package_name'
								placeholder="e.g. Light-2.0"
								label='Package Name'
								onChange={(e) => handleInputChange(e)}
								maxLength='64'
								required
							/>
						</div>
						<div className='w-75 mb-3'>
							<Input
								type='text'
								name='type'
								placeholder="e.g. LED"
								label='Type'
								onChange={(e) => handleInputChange(e)}
								maxLength='64'
								required
							/>
						</div>

						{packageName ? (
							<span className='modal-imagename'>
								{packageName}
								<span className='close-icon'>
									<UilTimesCircle
										color='#5330b9'
										size={14}
										onClick={() => {
											setPackageName(null);
											setPackageSize(null);
											setFirmwarePackage(null);
										}}
									/>
								</span>
							</span>
						) : (
							<>
								<label
									htmlFor='attachment'
									className='upload-file-label'
									style={{
										display: packageName
											? 'none'
											: 'inline-block',
									}}
								>
									<>
										<span>
											<UilPlusCircle
												color='#5330b9'
												size={16}
											/>
										</span>
										Select Package
									</>
								</label>
								<input
									type='file'
									id='attachment'
									accept='.zip'
									name='package_file'
									onChange={(e) => handleFileUpload(e)}
								/>
							</>
						)}
						{error && (
							<p className='text-error mt-3 mb-0'>{error}</p>
						)}
					</div>
					<div className='modal--footer d-flex justify-content-end'>
						<PrimaryLineButton
							onClick={handleAddOtaPackage}
							id='add-package-btn'
							loading={loading}
						>
							Add Package
						</PrimaryLineButton>
					</div>
				</>
			) : (
				<>
					<div className='text-center py-3'>
						<span className='icon-container success-check'>
							<UilCheck color='#fff' size={16} />
						</span>
						<p>Firmware Package added successfully.</p>
					</div>
					<div className='modal--footer d-flex justify-content-end'>
						<PrimaryLineButton onClick={close} id='ok-btn'>
							Ok
						</PrimaryLineButton>
					</div>
				</>
			)}
		</Modal>
	);
};

export default AddPackageModal;
