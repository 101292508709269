import React from 'react';
import { ListGroup } from 'react-bootstrap';
import './DistributionList.scss';
import { UilAngleRightB } from '@iconscout/react-unicons';

function DistributionList({data, clickHandler, labels}) {

    return (
        <div className='distribution-list'>
            <ListGroup variant="flush">
                <ListGroup.Item className='item-row header'>
                    <div className='box'>
                        <div className='text item'>{labels.column_reason_code}</div>
                        <div className='count item'>{labels.column_count}</div>
                        <div className='expand item'></div>
                    </div>
                </ListGroup.Item>
                {
                    data.map(item => (
                        <ListGroup.Item className='item-row' action onClick={() => clickHandler(item)} key={item.category}>
                            <div className='box'>
                                <div className='text item'>{ item.category }</div>
                                <div className='count item'>{ item.count.toLocaleString() } </div>
                                <div className='expand item'><UilAngleRightB size={18} /></div>
                            </div>
                        </ListGroup.Item>
                    ))
                }
            </ListGroup>
        </div>
    )
}

export default DistributionList