import React, { useState, useEffect, useContext } from 'react';
import { Input } from '../Input/Input';
import { pushOtaImageCall, getNodesCall } from '../../utils/apiCalls';
import { Link } from 'react-router-dom';
import Checkbox from '../Checkbox/Checkbox';
import { UilCheck, UilExclamationCircle } from '@iconscout/react-unicons';
import { PrimaryLineButton } from '../Button/Button';
import Modal from './Modal';
import { nodesContext } from '../../context/NodesProvider';
import { nodeGroupContext } from '../../context/NodeGroupProvider';
import CustomSelect from '../CustomSelect/CustomSelect';
import Loader from '../Loader/Loader';

const StartOTA = (props) => {
	const { showModal, setShowModal, sessionExpire, otaImage } = props;

	const { nodeState, getNodes } = useContext(nodesContext);
	const { allNodes } = nodeState;

	const { groupState, getGroups } = useContext(nodeGroupContext);
	const { allItems } = groupState;

	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);
	const [btnLoading, setBtnLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [jobId, setJobId] = useState('');
	const [customSelectLoading, setCustomSelectLoading] = useState(false);
	const [nodesList, setNodesList] = useState();
	const [groupsList, setGroupsList] = useState();

	const [pushTo, setPushTo] = useState('nodes');
	const [forcePush, setForcePush] = useState(true);
	const [noNodesError, setNoNodesError] = useState(false);

	const [jobName, setJobName] = useState('');
	const [selectedNode, setSelectedNode] = useState('');
	const [selectedGroup, setSelectedGroup] = useState('');

	useEffect(() => {
		if (allNodes.length === 0) {
			getNodes('');
			return;
		}
	}, []);

	let timeout = 0;

	useEffect(() => {
		if (allNodes.length > 0) {
			let option = allNodes.map((node) => ({
				value: node.node_id,
				label: node.node_id,
			}));
			setNodesList(option);
		}
		if (allNodes.length === 0) {
			setNodesList([]);
			setNoNodesError(true);
		}
		if (!allItems || allItems.length === 0) {
			getGroups();
			return;
		}
	}, [JSON.stringify(allNodes)]);

	useEffect(() => {
		if (allItems.length > 0) {
			let options = allItems.map((group) => {
				return {
					label: group.group_name,
					value: group.group_id,
				};
			});
			setGroupsList([
				{
					value: 'FFFF-FFFF',
					label: 'All',
				},
				...options,
			]);
		} else {
			setGroupsList([
				{
					value: 'FFFF-FFFF',
					label: 'All',
				},
			]);
		}
	}, [JSON.stringify(allItems)]);

	useEffect(() => {
		if (nodesList && groupsList) {
			nodesList.length > 0 && setNoNodesError(false);
			setLoading(false);
		}
	}, [JSON.stringify(nodesList), JSON.stringify(groupsList)]);

	const close = () => {
		setSuccess(false);
		setError('');
		setJobName('');
		setSelectedNode('');
		setSelectedGroup('');
		setForcePush(false);
		setPushTo('');
		setShowModal(false);
	};

	const handleInputChange = (event) => {
		let value = event.target.value;
		setError('');
		setJobName(value);
	};

	const searchNodeHandle = (node) => {
		if (node.trim() !== '') {
			if (
				allNodes.length > 0 &&
				allNodes.some((el) => el.node_id === node)
			) {
				return;
			}
			if (timeout) clearTimeout(timeout);
			setCustomSelectLoading(true);
			timeout = setTimeout(() => {
				getNodesCall(node, null)
					.then((resp) => {
						setCustomSelectLoading(false);
						if (resp.statusCode === 401) {
							sessionExpire();
							return;
						}
						resp.node_id &&
							setNodesList([
								{
									value: resp.node_id,
									label: resp.node_id,
								},
							]);
					})
					.catch(() => {
						setNodesList([
							{
								value: '',
								label: '',
							},
						]);
					});
			}, 1000);
		}
	};

	const handlePushOtaImage = () => {
		if (!jobName || jobName === ' ') {
			setError('Please provide job name.');
			return;
		}

		if (!selectedGroup && !selectedNode) {
			setError('Please select atleast one group or one node.');
			return;
		}
		let list = pushTo === 'nodes' ? selectedNode : selectedGroup;
		setBtnLoading(true);
		pushOtaImageCall(
			otaImage.ota_image_id,
			jobName,
			pushTo,
			list,
			forcePush
		)
			.then((response) => {
				setBtnLoading(false);
				if (response.statusCode === 401) {
					sessionExpire();
					return;
				}
				setJobId(response.ota_job_id);
				if (response.status === 'success') {
					setError('');
					setSuccess(true);
				} else {
					setError(response.description);
				}
			})
			.catch(() => {
				setError('Something went wrong!');
				setBtnLoading(false);
			});
	};

	const handleNodeSelect = (nodes) => {
		setError('');
		setSelectedNode(nodes.value);
	};
	const handleGroupSelect = (groups) => {
		setError('');
		setSelectedGroup(groups.value);
	};

	const handleRadioChange = (event) => {
		setPushTo(event.target.value);
		setForcePush(false);
	};

	return (
		<Modal showModal={showModal} setShowModal={close} title='Start OTA'>
			{loading ? (
				<Loader minHeight='470px' />
			) : !success && !noNodesError ? (
				<div>
					<div className='p-3'>
						<div className='d-flex'>
							<label>Image Name: </label>
							<p className='font-weight-500 ml-1 mb-0'>
								{otaImage.image_name}
							</p>
						</div>
						<div className='w-75 mb-3'>
							<Input
								type='text'
								name='ota_job_name'
								placeholder='Enter a job name'
								onChange={(e) => handleInputChange(e)}
								id='ota_job_name'
								label='Job Name'
								required
							/>
						</div>
						<div className='w-75 mb-3'>
							<label className='radio-label label'>
								<input
									type='radio'
									value='groups'
									checked={pushTo === 'groups'}
									onChange={handleRadioChange}
								/>
								<span className='radio-overlay'></span>
								Groups
							</label>
							<CustomSelect
								options={groupsList}
								isLoading={customSelectLoading}
								onChange={(group) => handleGroupSelect(group)}
								isDisabled={pushTo !== 'groups' ? true : false}
							/>
						</div>
						<div className='w-75 mb-3'>
							<label className='radio-label label mt-3'>
								<input
									type='radio'
									value='nodes'
									checked={pushTo === 'nodes'}
									onChange={handleRadioChange}
								/>
								<span className='radio-overlay'></span>
								Nodes
							</label>
							<CustomSelect
								options={nodesList}
								isLoading={customSelectLoading}
								defaultOptions
								onInputChange={(e) => searchNodeHandle(e)}
								onChange={(nodes) => handleNodeSelect(nodes)}
								isDisabled={pushTo !== 'nodes' ? true : false}
							/>
						</div>

						<div className='mb-3'>
							<Checkbox
								id='force-push'
								checked={forcePush}
								onChange={() => {
									setForcePush((forcePush) => !forcePush);
								}}
								label='Force Push'
							/>

							{error && <p className={'text-error'}>{error}</p>}
						</div>
					</div>
					<div className='modal--footer d-flex justify-content-end'>
						<PrimaryLineButton
							onClick={handlePushOtaImage}
							id='start-ota-btn'
							loading={btnLoading}
						>
							Start OTA
						</PrimaryLineButton>
					</div>
				</div>
			) : (
				<>
					<div className='text-center'>
						{!noNodesError && success && (
							<div className='p-3'>
								<span className='icon-container success-check'>
									<UilCheck color='#fff' size={16} />
								</span>
								<p className='success-info'>
									OTA started successfully.
								</p>
								<Link
									to={`/home/job-details/${jobId}`}
									className='text-link'
								>
									Go to job details.
								</Link>
							</div>
						)}

						{noNodesError && !success && (
							<div className='p-3'>
								<span className='icon-container mx-auto mb-3'>
									<UilExclamationCircle
										color='#5330b9'
										size={16}
									/>
								</span>
								<p className='mb-0'>
									You don&apos;t have any claimed nodes to
									start OTA!
								</p>
							</div>
						)}
					</div>
					<div className='modal--footer d-flex justify-content-end'>
						<PrimaryLineButton onClick={close}>
							Ok
						</PrimaryLineButton>
					</div>
				</>
			)}
		</Modal>
	);
};

export default StartOTA;
