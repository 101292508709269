import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";

function AreaCharts({
    data = null
}) {
    const [chartData, setChartData] = useState(data);

    useEffect(() => {
        setChartData(data);
    }, [data]);

    if (!chartData) return null;

    return (
        <div className='area-chart'>
            {
                chartData.map(({id, options, series}) => (
                    <Chart
                        options={options}
                        series={series}
                        type="area"
                        height="250px"
                        key={id}
                    />
                ))
            }
        </div>
    )
}

export default AreaCharts