import { UilArrowUp, UilAngleDown, UilAngleUp, UilDirection } from '@iconscout/react-unicons';
import React, {useEffect, useContext, useState} from 'react';
import './LogToolbar.scss';
import * as generalConfig from './../../../../../general-config.json';
import CreatableSelect from 'react-select/creatable';
import WidgetStickyToolbar from '../../../../../Widgets/WidgetStickyToolbar/WidgetStickyToolbar';
import { UilSearch } from '@iconscout/react-unicons';

function LogToolbar({
        logsLimit, 
        logsLoading,
        moreLogsAvailable,
        expandAllLogs,
        setExpandAllLogs,
        onSearch,
        searchValues,
        onLoadOlderLogs,
        dropdownOptions
    }) {
    const customSelectStyles = {
        option: (styles, { isFocused }) => ({
            ...styles,
            backgroundColor: isFocused ? "#edebf8" : "#ffffff",
            ':hover': {
                backgroundColor: "#edebf8"
            }
        }),
        multiValueLabel: (base, state) => ({
            ...base,
            color: state.data.isFixed ? "#ffffff" : "#2c2e37",
            paddingRight: state.data.isFixed ? 6 : null,
            letterSpacing: "0.5px",
        }),
        multiValue: (base, state) => {
            return { ...base, backgroundColor: (state.data.isFixed ? "#757575" : "#edebf8") } 
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: 'none' } : base;
        },
        menu: (styles, { data }) => ({
            ...styles,
            border: "1px solid #bdbdbd"
        }),
        valueContainer: (styles, { data }) => ({
            ...styles,
            padding: "0px 5px"
        }),
        placeholder: (styles, { data }) => ({
            ...styles,
            '> div': {
                display: 'flex',
                alignItems: 'center'
            }
        })
    }

    const scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    }

    const actions = [
        {
            id: "loadOlderLogs",
            label: "Load older logs",
            disabled: !moreLogsAvailable || logsLoading,
            clickHandler: onLoadOlderLogs,
            icon: UilArrowUp,
            iconSize: 16
        },
        {
            id: "expandAllLogs",
            label: expandAllLogs ? "Collapse all" : "Expand all",
            clickHandler: () => setExpandAllLogs(!expandAllLogs),
            icon: expandAllLogs ? UilAngleUp : UilAngleDown,
            iconSize: 18
        },
        {
            id: "scrollToBottom",
            label: "Scroll to bottom",
            clickHandler: scrollToBottom,
            icon: UilDirection,
            iconSize: 18
        }
    ];

    const searchLogsPlaceHolder = (
        <div>
            <UilSearch size={15} />
            <span className='ml-1'>Search logs</span>
        </div>
    )

    return (
        <WidgetStickyToolbar loading={logsLoading}>
            <div className='actions-container'>
                {
                    actions.map(action => (
                        <button key={action.id} disabled={action.disabled} onClick={action.clickHandler}>
                            { action.icon ? <action.icon size={action.iconSize} /> : null }
                            <div>{action.label}</div>
                        </button>
                    ))
                }
            </div>
            <div className='select-dropdown'>
                <CreatableSelect 
                    options={dropdownOptions} 
                    value={searchValues}
                    placeholder={searchLogsPlaceHolder}
                    className="multi-select-dd"
                    isMulti={true}
                    isSearchable={true}
                    styles={customSelectStyles}
                    isClearable
                    onChange={onSearch}
                    formatCreateLabel={freeFormText => <span>Search <b>{freeFormText}</b></span>}
                />
            </div>
        </WidgetStickyToolbar>
    )
}

export default LogToolbar