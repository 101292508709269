import React from 'react';
import LogTag from '../LogTag/LogTag';
import './LogMessage.scss';

function LogMessage({tag, message}) {
    return (
        <div className='log-message-wrapper'>
            <div className='tag'><LogTag tag={tag} /></div>
            <div className='text'>{message}</div>
        </div>
    )
}

export default LogMessage