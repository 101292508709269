import { useState } from "react";
import * as generalConfig from "./../general-config.json";
import { transformJsonToCborBase64String } from "./../data-transform/data-transform";
import { sendCommandToDevice } from "./../../../utils/diagAPI";
import { getTime } from "date-fns";
import { cloneDeep, toString } from "lodash";

function useCommandResponse(nextPagetoken) {
  const commandResponseConfig = generalConfig.commandResponseConfig;

  const [sentCommandsInCurrentSession, setSentCommandsInCurrentSession] =
    useState([]);

  const getCommandDescriptionFromApiResponse = (apiResponse) => {
    if (apiResponse.description) {
      return apiResponse.description;
    }
    if (apiResponse.responses) {
      let descriptionText = "";
      apiResponse.responses.forEach((r) => {
        if (r.response && r.response.description) {
          descriptionText += r.response.description + ". ";
        }
      });
      return descriptionText;
    }
    return "";
  };

  const updateCommandStatus = (commandId, apiResponse) => {
    setSentCommandsInCurrentSession((commands) => {
      const commandIndex = commands.findIndex(
        (command) => command.id === commandId
      );
      if (commandIndex !== -1) {
        const updatedCommandsList = cloneDeep(commands);
        const newValues = {
          requestId: apiResponse?.request_id || 0,
          status: apiResponse?.status || "failure",
          description: getCommandDescriptionFromApiResponse(apiResponse),
        };
        updatedCommandsList[commandIndex] = {
          ...updatedCommandsList[commandIndex],
          ...newValues,
        };
        return updatedCommandsList;
      }
      return commands;
    });
  };

  const sendCommand = async ({ nodeId, name, value, timeout }) => {
    const currentTimestampInMilliseconds = getTime(new Date());
    const key = name.join(".");
    const commandId = `${key}__${currentTimestampInMilliseconds}`;
    const nodeList = [nodeId];
    let commandsList = cloneDeep(sentCommandsInCurrentSession);
    commandsList.push({
      id: commandId,
      ts: currentTimestampInMilliseconds,
      label: key,
      value: typeof value !== "object" ? toString(value) : "",
      status: "pending",
      nodeList,
    });

    setSentCommandsInCurrentSession(commandsList);

    const commandConfig = {
      config: [{ n: name, v: value }],
    };

    const encodedData = transformJsonToCborBase64String(commandConfig);

    const commandRequestData = {
      node_ids: nodeList,
      cmd: commandResponseConfig.defaultCommandCode,
      timeout: timeout || commandResponseConfig.timeout,
      data: encodedData,
      is_base64: true,
    };

    const response = await sendCommandToDevice(commandRequestData);
    updateCommandStatus(commandId, response);
  };

  return {
    sendCommand,
    sentCommandsInCurrentSession,
  };
}

export default useCommandResponse;
