import { useEffect, useRef } from 'react';

const useDidMountEffect = (func, dependenciesList) => {
    const mounted = useRef(false);

    useEffect(() => {
        if (mounted.current) {
            func();
        }
        else {
            mounted.current = true
        }
    }, dependenciesList);
}

export default useDidMountEffect;