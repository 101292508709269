import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { checkUpdateCall, updateDashboardCall } from '../../utils/apiCalls';
import { PrimaryLineButton } from '../Button/Button';
import CustomToast from '../CustomToast/CustomToast';

const CheckVersionModal = (props) => {
	const { showModal, setShowModal, sessionExpire } = props;
	const [loading, setLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [version, setVersion] = useState();
	const [error, setError] = useState('');

	useEffect(() => {
		if (showModal) {
			setLoading(true);
			checkUpdateCall().then((payload) => {
				if (payload.statusCode === 401) {
					sessionExpire();
					return;
				}
				if (payload.version) {
					setVersion(payload.version);
				}

				if (payload.status === 'failure') {
					setError(
						'Could not check for updates. Please check later.'
					);
				}
				setLoading(false);
			});
		}
	}, [showModal]);

	const handleUpdateDashboard = () => {
		setBtnLoading(true);
		updateDashboardCall(version).then((payload) => {
			if (payload.statusCode === 401) {
				sessionExpire();
				return;
			}

			if (payload.status === 'success') {
				CustomToast.success(
					'Dashboard updated successfully!',
					{
						autoClose: 5000,
					},
					'session-toast'
				);
			} else {
				CustomToast.error(
					'Something went wrong! Please try later',
					{
						autoClose: 5000,
					},
					'session-toast'
				);
			}
			setBtnLoading(false);
			setShowModal(false);
			setError();
			setVersion();
		});
	};

	return (
		<Modal
			show={showModal}
			onHide={() => {
				setVersion();
				setError();
				setShowModal(false);
			}}
			className='version-modal'
		>
			<Modal.Body>
				<div className='pt-3'>
					{loading ? (
						<div className='text-center'>
							<div
								className='spinner-border text-link mb-3'
								role='status'
							>
								<span className='sr-only'>Loading...</span>
							</div>
							<p className='text-center mb-3'>
								Checking for updates...
							</p>
						</div>
					) : (
						<>
							{version ? (
								<p
									className='text-center mb-3'
									style={{ fontSize: '16px' }}
								>
									New version is available : {version}
								</p>
							) : error ? (
								<p className='text-center text-error mb-3'>
									{error}
								</p>
							) : (
								<p className='text-center mb-3'>
									No new version found. Dashboard is already
									updated.
								</p>
							)}
							<div className='d-flex justify-content-end mt-4'>
								{version ? (
									<PrimaryLineButton
										onClick={handleUpdateDashboard}
										loading={btnLoading}
										minWidth='75px'
										id='version-update-btn'
									>
										Update Dashboard
									</PrimaryLineButton>
								) : (
									<PrimaryLineButton
										onClick={() => {
											setVersion();
											setError();
											setShowModal(false);
										}}
										id='version-update-btn'
										minWidth='75px'
									>
										Ok
									</PrimaryLineButton>
								)}
							</div>
						</>
					)}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default CheckVersionModal;
