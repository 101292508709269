import React, {useContext} from 'react';
import { useHistory } from 'react-router';
import BasicLightCardWrapper from '../../../../Widgets/BasicLightCardWrapper/BasicLightCardWrapper';
import './NodeTagsCard.scss';
import AdvancedInsightsContext from './../../../../context/insights-context';
import { UilCloudTimes } from '@iconscout/react-unicons';
import { transformNodeTagsData } from '../../../../data-transform/data-transform';
import NoDataMessage from '../../../../Widgets/NoDataMessage/NoDataMessage';
import TagChip from '../../../../../../components/TagChip/TagChip';
import { updateHashURL } from "../../../../../../utils/commonFunctions"

function NodeTagsCard() {
    const history = useHistory();
    const { 
        selectedNodeInfoData,
        addMultipleFiltersToSelectedFilters,
    } = useContext(AdvancedInsightsContext);

    if (selectedNodeInfoData === null) return null;

    let tags = [];
    if (selectedNodeInfoData.tags) {
        tags = transformNodeTagsData(selectedNodeInfoData.tags).splice(0,15);
    }

    const handleViewAll = () => {
         updateHashURL({view: "tags"})
    }

    const handleTagSelect = ( tag ) => {
        const tagFilter = {
            filterType: "Node.Tag",
            filterValue: {
                Property: tag.property,
                Value: tag.value
            },
            operator: "nestedkeyword"
        }
        addMultipleFiltersToSelectedFilters([tagFilter])
        history.push("/home/insights")
        
    }
    return (
        <BasicLightCardWrapper actions={tags.length ? [{id: "view-all", label: "View all", clickHandler: handleViewAll }] : null}>
            <div className='node-tags-card-wrapper'>
                <h6>Tags</h6>
                {
                    tags.length ? (
                        <div className='tags-container'>
                            {
                                tags.map(tag => (
                                    <TagChip tag={tag} onTagSelect={() => handleTagSelect(tag)} />
                                ))
                            }
                        </div>
                    ) : <NoDataMessage text="We couldn't find any tags for this node." />
                }
            </div>
        </BasicLightCardWrapper>
    )
}

export default NodeTagsCard