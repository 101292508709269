/* eslint-disable react/react-in-jsx-scope */
import Nodes from './OTA/Nodes/Nodes';
import Insights from './Insights/Insights';
import OtaImage from './OTA/OtaImage/OtaImage';
import OtaImageDetails from './OTA/OtaImage/OtaImageDetails';
import Settings from './UserSettings/Settings/Settings';
import Diagnostic from './Diagnostics/Diagnostic';
import Report from './Diagnostics/Report/Report';
import ParamValues from './Diagnostics/ParamValues/ParamValues';
import Login from './UserOnboarding/Login/Login';
import Signup from './UserOnboarding/Signup/Signup';
import VerificationCode from './UserOnboarding/VerificationCode/VerificationCode';
import ForgotPassword from './UserOnboarding/ForgotPassword/ForgotPassword';
import ForgotPasswordConfirm from './UserOnboarding/ForgotPassword/ForgotPasswordConfirm';
import SocialAuth from './UserOnboarding/SocialAuth/SocialAuth';
import Home from './Home/Home';
import DiagMetrics from './Diagnostics/Metrics/DiagMetrics';
import ManageAuthKeys from './ManageAuthKeys/ManageAuthKeys';
import FirmwarePackages from './FirmwarePackages/FirmwarePackages';
import FirmwarePackageDetails from './FirmwarePackages/FirmwarePackageDetails';
import InsightsNodes from './OTA/Nodes/InsightsNodes/InsightsNodes';
import Authorise from './UserOnboarding/Authorise/Authorise';
import Unauthorised from './UserOnboarding/Unauthorised/Unauthorised';
import AdvancedInsights from './AdvancedInsights/AdvancedInsights';
import OfflineErrorPage from './AdvancedInsights/OfflineErrorPage/OfflineErrorPage';
import NodeDetails from './AdvancedInsights/NodeDetails/NodeDetails';
import BillingAndUsage from './AdvancedInsights/BillingAndUsage/BillingAndUsage';
import ManageConnectorAuthKeys from './ManageConnectorAuthKeys/ManageConnectorAuthKeys';
import ManageMultiAdmins from './AdvancedInsights/ManageMultiAdmins/ManageMultiAdmins';

const routes = [
	{
		key: 'AUTH',
		path: '/authorise',
		component: Authorise,
	},
	{
		key: 'UNAUTH',
		path: '/unauthorised',
		component: Unauthorised,
	},
	{
		key: 'LOGIN',
		path: '/login',
		component: Login,
	},
	{
		key: 'SIGNUP',
		path: '/sign-up',
		component: Signup,
	},
	{
		key: 'VERIFICATION',
		path: '/verification',
		component: VerificationCode,
	},
	{
		key: 'FORGOT_PASSWORD',
		path: '/forgot-password',
		component: ForgotPassword,
	},
	{
		key: 'FORGOT_PASSWORD_CONFIRM',
		path: '/set-new-password',
		component: ForgotPasswordConfirm,
	},
	{
		key: 'SOCIALAUTH',
		path: '/socialauth',
		component: SocialAuth,
	},
	{
		key: 'OFFLINE_PAGE',
		path: '/offline',
		component: OfflineErrorPage,
	},
	{
		key: 'HOME',
		path: '/home',
		component: Home,
		routes: [
			{
				key: 'NODES',
				path: '/home/nodes/',
				component: InsightsNodes,
				exact: true
			},
			{
				key: 'NODE_DETAILS',
				path: '/home/nodes/:node_id',
				component: NodeDetails,
			},
			{
				key: 'INSIGHTS',
				path: '/home/insights/',
				component: AdvancedInsights,
			},
			{
				key: 'OLD_INSIGHTS',
				path: '/home/old-insights/',
				component: Insights,
			},
			{
				key: 'OTA_IMAGES',
				path: '/home/firmware-images/',
				component: OtaImage,
			},
			{
				key: 'PACKAGES',
				path: '/home/firmware-packages/',
				component: FirmwarePackages,
			},
			{
				key: 'PACKAGE_DETAILS',
				path: '/home/package-details/:package_id',
				component: FirmwarePackageDetails,
			},
			{
				key: 'OTA_IMAGE_INFO',
				path: '/home/image-details/:ota_image_id',
				component: OtaImageDetails,
			},
			{
				key: 'SETTINGS',
				path: '/home/change-password/',
				component: Settings,
			},
			{
				key: 'MANAGE_AUTH_KEYS',
				path: '/home/manage-auth-keys/',
				component: ManageAuthKeys,
			},
			{
				key: 'MANAGE_CONNECTOR_AUTH_KEYS',
				path: '/home/manage-connector-auth-keys/',
				component: ManageConnectorAuthKeys,
			},
			{
				key: 'BILLING_USAGE',
				path: '/home/billing-usage/',
				component: BillingAndUsage,
			},
			{
				key: 'MANAGE_MULTI_ADMINS',
				path: '/home/manage-multi-admins/',
				component: ManageMultiAdmins,
			},
			{
				key: 'DIAGNOSTIC',
				path: '/home/diagnostics',
				component: Diagnostic,
				routes: [
					{
						key: 'REPORT',
						path: '/home/diagnostics/node/:node_id/report',
						component: Report,
					},
					{
						key: 'METRICS',
						path: '/home/diagnostics/node/:node_id/metrics',
						component: DiagMetrics,
					},
					{
						key: 'PARAMVALUES',
						path: '/home/diagnostics/node/:node_id/paramvalues',
						component: ParamValues,
					},
				]
			},
		],
	},
];

export default routes;
