import React from "react";
import {
  UilSetting,
  UilLayerGroup,
  UilWifi,
  UilChannel,
  UilSearchPlus,
  UilAnalysis,
  UilBracketsCurly,
  UilRedo,
} from "@iconscout/react-unicons";

function SettingIcon(props) {
  if (!props.id) return null;

  const defaultIconSize = 18;
  const iconSize = props.size || defaultIconSize;

  if (props.id.includes("heap")) {
    return <UilLayerGroup size={props.size || iconSize} />;
  }

  if (props.id.includes("wifi")) {
    return <UilWifi size={props.size || iconSize} />;
  }

  if (props.id.includes("ip")) {
    return <UilChannel size={props.size || iconSize} />;
  }

  if (props.id.includes("diag")) {
    return <UilSearchPlus size={props.size || iconSize} />;
  }

  if (props.id.includes("metrics")) {
    return <UilAnalysis size={props.size || iconSize} />;
  }

  if (props.id.includes("params")) {
    return <UilBracketsCurly size={props.size || iconSize} />;
  }

  if (props.id.includes("reboot")) {
    return <UilRedo size={props.size || iconSize} />;
  }

  return <UilSetting size={props.size || iconSize} />;
}

export default SettingIcon;
