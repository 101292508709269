import React, {useContext, useEffect} from 'react';
import WidgetWrapper from '../../../Widgets/WidgetWrapper/WidgetWrapper';
import './RightSection.scss';
import AdvancedInsightsContext from '../../../context/insights-context';
import CurrentMonthUsage from './CurrentMonthUsage/CurrentMonthUsage';
import CreditsUsageCalculationHelp from './CreditsUsageCalculationHelp/CreditsUsageCalculationHelp';

function RightSection() {
    const { 
        currentMonthUsageDataLoading,
        billingUsageMetaDataLoading,
        messages
    } = useContext(AdvancedInsightsContext);

    return (
        <section className='right-section-wrapper'>
            <WidgetWrapper  
                wrapperStyles={{borderRadius: '10px'}} 
                containerStyles={{padding: '0px'}}
                loading={currentMonthUsageDataLoading === true}
            >
                <CurrentMonthUsage />
            </WidgetWrapper>
            
            <WidgetWrapper  
                wrapperStyles={{background: 'transparent', boxShadow: 'none'}}
                containerStyles={{padding: '0px'}}
                loading={billingUsageMetaDataLoading === true}
            >
                <CreditsUsageCalculationHelp textLabels={messages.billing_usage.credits_help} />
            </WidgetWrapper>
        </section>
    )
}

export default RightSection