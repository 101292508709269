import React, { useReducer } from "react";
import { pick, isEmpty } from 'lodash';
import AdvancedInsightsContext from './insights-context';
import reducer from "./reducer";
import * as messages from './../messages/en.json';
import { getTime, getUnixTime, sub, roundToNearestMinutes, startOfMonth, endOfMonth, fromUnixTime, getMonth } from 'date-fns';
import {  
    getDashboardList,
    getDefaultDashboard,
    createDashboard,
    getDashboard,
    setDefault,
    saveDashboard,
    deleteDashboard
} from "../../../utils/dashboardAPI";

import {
    SET_DATE_TIME_RANGE,
    SET_FILTERS_LIST,
    SET_FILTER_SUGGESTION,
    ADD_TO_SELECTED_FILTERS,
    REMOVE_FROM_SELECTED_FILTERS,
    CLEAR_ALL_SELECTED_FILTERS,
    SET_ALL_EVENTS_DATA,
    SET_EVENT_COUNT_BY_INTERVAL_DATA,
    SET_EVENT_COUNT_BY_CATEGORY_DATA,
    ADD_MULTIPLE_FILTERS_TO_SELECTED_FILTERS,
    SET_TOP_NODES_DATA,
    SET_INITIAL_LOAD_COMPLETE_STATUS,
    RESET_INITIAL_LOAD_COMPLETE_STATUS,
    RESET_EVENT_COUNT_BY_INTERVAL_DATA,
    RESET_EVENT_COUNT_BY_CATEGORY_DATA,
    RESET_ALL_EVENTS_DATA,
    RESET_TOP_NODES_DISTRIBUTION_DATA,
    SET_NODE_FILTERS,
    CLEAR_NODE_FILTERS,
    SET_DASHBOARD_LIST,
    SET_SELECTED_DASHBOARD,
    SWITCH_DASHBOARD,
    SET_INTERVAL_LABEL,
    SET_DAHSBOARD_AVAILABLE_STATUS,
    SET_DASHBOARD_DEFAULT_STATUS,
    SET_EVENT_COUNT_DISTRIBUTION_BY_INTERVAL_LOADING,
    SET_TOP_NODES_DISTRIBUTION_DATA_LOADING,
    SET_ALL_EVENTS_DATA_LOADING,
    SET_EVENT_COUNT_DISTRIBUTION_BY_CATEGORY_LOADING,
    SET_SELECTED_NODE_TIMELINE_AGGREGATOR_TYPE,
    SET_ONLINE_TOP_NODES_DATA,
    SET_NODE_INFO_DATA,
    RESET_SELECTED_NODE_DATA,
    SET_SELECTED_NODE_ALL_EVENTS_DATA,
    RESET_SELECTED_NODE_ALL_EVENTS_DATA,
    SET_SELECTED_NODE_LOGS_DATA,
    PREPEND_TO_SELECTED_NODE_LOGS_DATA,
    RESET_SELECTED_NODE_LOGS_DATA,
    SET_SELECTED_NODE_LOGS_LOADING,
    SET_SELECTED_NODE_TIMELINE_SUBDIST,
    SET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA,
    SET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA_LOADING,
    SET_SELECTED_NODE_PARAMS_META,
    SET_SELECTED_NODE_PARAMS_LIST,
    SET_SELECTED_NODE_PARAMS_LIST_LOADING,
    SET_SELECTED_NODE_ONLINE_STATUS,
    SET_SELECTED_NODE_METRICS_DATE_TIME_RANGE,
    SET_BILLING_USAGE_META_DATA,
    SET_BILLING_USAGE_META_DATA_LOADING,
    SET_CURRENT_MONTH_USAGE,
    SET_CURRENT_MONTH_USAGE_LOADING,
    RESET_USAGE_DATA,
    SET_USAGE_INSIGHTS_DATE,
    SET_USAGE_INSIGHTS_FILTER,
    SET_USAGE_INSIGHTS_SUB_FILTER,
    SET_USAGE_INSIGHTS_SELECTED_ADMIN_ID,
    SET_USAGE_INSIGHTS_SELECTED_INTERFACE,
    SET_AUTH_KEYS_LIST,
    SET_USAGE_INSIGHTS_DATA,
    SET_USAGE_INSIGHTS_DATA_LOADING,
    SET_SELECTED_NODE_TAGS_LIST,
    SET_SELECTED_NODE_TAGS_LIST_LOADING,
    SET_INVITATIONS_FOR_USER,
    SET_INVITATIONS_FOR_USER_LOADING,
    SET_INVITATIONS_BY_USER,
    SET_INVITATIONS_BY_USER_LOADING,
    SET_TENANTS_LIST,
    SET_TENANTS_LIST_LOADING,
    SET_ACCOUNTS_LIST,
    SET_ACCOUNTS_LIST_LOADING,
    SET_NODE_CONFIG_META,
    SET_NODE_CONFIG_META_LOADING
} from './actions';
import { 
    getFiltersList, 
    getFilterSuggestions, 
    getEventCount,
    getEventByNodeTop,
    getQueryStateList,
    getNodeInfo,
    getEventList,
    getQueryEventStat,
    getNodeParamsMeta,
    getNodeParamsList,
    getBillingUsageMeta,
    getBillingUsageByInterval,
    getNodeTagsList, 
    addTagToNodeTagsList, 
    removeTagFromNodeTagsList,
    listTenantInvite,
    listAccountInvite,
    listTenants,
    listAccounts,
    getNodeConfigMeta
} from "../../../utils/diagQueryAPI";
import { 
    generateInvalidIntervalsList, 
    getConsolidatedParamsList, 
    getEventStatAggregateData, 
    getSerializedFiltersList,
    transormNodeParamsData,
    transformDashboardStateData,
    transformTranslationText,
    transformNodeTagsData
} from "../data-transform/data-transform";
import { getUrlHashParameterByName, updateUrlParameterByName, handleQuickLinkAction, localTimeStampToUtcTimeStamp} from "../../../utils/commonFunctions";
import { cloneDeep, without} from "lodash";
import * as generalConfig from './../general-config.json';
import Cookies from "js-cookie";
import { listAuthKeys } from "../../../utils/manageAuthKeysAPI";

const InsightsStore = props => {
    const hashStartTimestamp = getUrlHashParameterByName("start");
    const hashEndTimestamp = getUrlHashParameterByName("end");
    const defaultStartTimestamp = hashStartTimestamp ? Math.trunc(hashStartTimestamp) : getUnixTime(sub((new Date()), { days: 30 }));
    const defaultEndTimestamp = hashEndTimestamp ? Math.trunc(hashEndTimestamp) : getUnixTime(new Date());
    
    const hashMetricsStartTimestamp = getUrlHashParameterByName("mstart");
    const hashMetricsEndTimestamp = getUrlHashParameterByName("mend");
    const defaultMetricsStartTimestamp = hashMetricsStartTimestamp ? Math.trunc(hashMetricsStartTimestamp) : getUnixTime(roundToNearestMinutes(sub((new Date()), { hours: 3 }), { nearestTo: 15 }));
    const defaultMetricsEndTimestamp = hashMetricsEndTimestamp ? Math.trunc(hashMetricsEndTimestamp) : getUnixTime(roundToNearestMinutes(new Date(), { nearestTo: 15 }));

    const initialState = {
        startTimestamp: defaultStartTimestamp,
        endTimestamp: defaultEndTimestamp,
        tzOffset: (new Date()).getTimezoneOffset() * -60,
        filtersData: null,
        filtersList: null,
        selectedFilters: [],
        selectedDashboard: null,
        isDashboardDefault: false,
        nodeFilters: [],
        allEventsData: null,
        filterSuggestionData: null,
        eventCountDistributionByInterval: null,
        eventCountDistributionByCategory: null,
        allEventsCountDistributionByInterval: null,
        topNodesDistributionData: null,
        maxTopNodesLimit: 10,
        distinctNodeCount: 0,
        messages: messages.default,
        initialLoadCompleteStatus: false,
        disabledIntervalsList: generateInvalidIntervalsList(defaultStartTimestamp, defaultEndTimestamp),
        eventCountDistributionByIntervalResetStatus: false,
        allEventsDataResetStatus: false,
        topNodesDistributionDataResetStatus: false,
        eventCountDistributionByCategoryResetStatus: false,
        dashboardList : null,
        intervalLabel: null,
        onlineTopNodesData: [],
        selectedNodeInfoData: null,
        selectedNodeAllEventsData: null,
        selectedNodeLogsData: null,
        selectedNodeLogsRemarks: null,
        selectedNodeLogsLimit: 25,
        selectedNodeLogsPageToken: "",
        selectedNodeLogsLoading: false,
        selectedNodeTimelineSubdist: "",
        selectedNodeTimelineDistributionData: null,
        selectedNodeTimelineAggregatorType: generalConfig.event_type_filter_key,
        dashboardAvailableStatus : false,
        selectedNodeTimelineDistributionDataLoading: false,
        selectedNodeParamsMeta: null,
        selectedNodeParamsList: null,
        selectedNodeParamsListLoading: false,
        selectedNodeTagsListLoading: false,
        selectedNodeTagsList:null,
        selectedNodeOnlineStatus: null,
        selectedNodeMetricsStartTime: defaultMetricsStartTimestamp,
        selectedNodeMetricsEndTime: defaultMetricsEndTimestamp,
        billingUsageMetaData: null,
        billingUsageMetaDataLoading: false,
        currentMonthUsageData: null,
        currentMonthUsageCreditsData: null,
        currentMonthUsageDataLoading: false,
        usageInsightsDate: getUnixTime(startOfMonth(new Date())),
        usageInsightsFilter: null,
        usageInsightsSubFilter: null,
        usageInsightsSelectedAdminId: Cookies.get('id'),
        usageInsightsSelectedInterface: 'all',
        authKeysList: null,
        usageInsightsData: null,
        usageInsightsDataLoading: false,
        transformedUsageInsightsData: null,
        invitationsForUser: null,
        invitationsForUserLoading: false,
        invitationsByUser: null,
        invitationsByUserLoading: false,
        tenantsList: null,
        tenantsListLoading: false,
        accountsList: null,
        accountsListLoading: false,
        nodeConfigMeta: null,
        transformedNodeConfigMeta: null,
        nodeConfigMetaLoading: false,
    }
    
    const [state, dispatch] = useReducer(reducer, initialState);
    
    const setDateTimeRange = range => {
        dispatch({
            type: SET_DATE_TIME_RANGE,
            payload: range
        });
    }
    
    const addToSelectedFilters = filterData => {
        dispatch({
            type: ADD_TO_SELECTED_FILTERS,
            payload: filterData
        });
    }
    
    const setInitialLoadCompleteStatus = status => {
        dispatch({
            type: SET_INITIAL_LOAD_COMPLETE_STATUS,
            payload: status
        });
    }
    
    const addMultipleFiltersToSelectedFilters = filterData => {
        dispatch({
            type: ADD_MULTIPLE_FILTERS_TO_SELECTED_FILTERS,
            payload: filterData
        });
    }
    
    const removeFromSelectedFilters = filterData => {
        dispatch({
            type: REMOVE_FROM_SELECTED_FILTERS,
            payload: filterData
        });
    }
    
    const clearAllSelectedFilters = () => {
        dispatch({
            type: CLEAR_ALL_SELECTED_FILTERS
        });
    }
    
    const setNodeFilters = filterData => {
        dispatch({
            type: SET_NODE_FILTERS,
            payload: filterData
        });
    }
    
    const clearNodeFilters = () => {
        dispatch({
            type: CLEAR_NODE_FILTERS
        });
    }
    
    const getAllFiltersList = async (requestData) => {
        let response = await getFiltersList(requestData);
        response?.data && setAllFiltersList(response.data);
    }

    const setAllFiltersList = async (data) => {
        dispatch({
            type: SET_FILTERS_LIST,
            payload: data
        });
    }

    const getSuggestions = async ({ value : fieldName, isNestedTermFilter, attributes }, partialvalue = null, prevSuggestion) => {
        const requestData = {
            from_ts: localTimeStampToUtcTimeStamp(state.startTimestamp),
            to_ts: localTimeStampToUtcTimeStamp(state.endTimestamp),
            filters: getSerializedFiltersList(state.selectedFilters, state.nodeFilters),
            fieldname: fieldName
        }
        if(isNestedTermFilter)
        {
            const nestedTermFilterSuggestions = {...prevSuggestion};
            let attrname = null
            if(prevSuggestion.length === 0 )
            {
                attrname = attributes[0];
                requestData['attrname'] = attrname;
            }else{
                const prevSelectedAttributePosition = attributes.indexOf(partialvalue.attrname)
                
                // if selected field is last attribute then don't fetch suggestions
                if(prevSelectedAttributePosition + 1 === attributes.length ) return;
                attrname = attributes[prevSelectedAttributePosition + 1];
                requestData['attrname'] = attrname;

                // following syntax is to make object with dynamic key { [key]: value } is same as { key : value }
                requestData['partialvalue'] = { [partialvalue.attrname]: partialvalue.value}; 
            }
            let response = await getFilterSuggestions(requestData);
            nestedTermFilterSuggestions[attrname] = response.data;
            setSuggestions(nestedTermFilterSuggestions);
            return;
        }
        let response = await getFilterSuggestions(requestData);
        if(!response?.data ) return;
        setSuggestions(response.data);
    }

    const setSuggestions = async (data) => {
        dispatch({
            type: SET_FILTER_SUGGESTION,
            payload: data
        });
    }

    const getAllEventsData = async () => {
        setAllEventsDataLoading(true);

        let response = await getFilterSuggestions({
            from_ts: localTimeStampToUtcTimeStamp(state.startTimestamp),
            to_ts: localTimeStampToUtcTimeStamp(state.endTimestamp),
            filters: getSerializedFiltersList(state.selectedFilters),
            fieldname: generalConfig.event_type_filter_key
        });

        if (response?.data) {
            setAllEventsData(response.data)
        }
        else {
            setAllEventsDataLoading(false);
        }
    }

    const setAllEventsData = async (data) => {
        dispatch({
            type: SET_ALL_EVENTS_DATA,
            payload: data
        });
    }

    const getEventCountData = async ({eventType, interval}) => {
        let loadingDispatchType = interval ? SET_EVENT_COUNT_DISTRIBUTION_BY_INTERVAL_LOADING : SET_EVENT_COUNT_DISTRIBUTION_BY_CATEGORY_LOADING;
        setEventCountDataLoading(loadingDispatchType, true);

        const eventTypeFilterKey = generalConfig.event_type_filter_key;
        const currentSelections = cloneDeep(state.selectedFilters);
        const isTypeFilterApplied = currentSelections.filter(s => s.filterType === eventTypeFilterKey)?.length === 1;
        if (!isTypeFilterApplied && eventType) {
            currentSelections.push({
                filterType: eventTypeFilterKey,
                filterValue: eventType
            })
        }

        const requestData = {
            from_ts: localTimeStampToUtcTimeStamp(state.startTimestamp),
            to_ts: localTimeStampToUtcTimeStamp(state.endTimestamp),
            filters: getSerializedFiltersList(currentSelections)
        }

        let dispatchType = SET_EVENT_COUNT_BY_CATEGORY_DATA;

        if (interval) {
            requestData.interval = interval;
            dispatchType = SET_EVENT_COUNT_BY_INTERVAL_DATA;
        }

        let response = await getEventCount(requestData);
        if (response?.data) {
            setEventCountData(dispatchType, response.data);
        }
        else {
            setEventCountDataLoading(loadingDispatchType, false);
        }
    }

    const setEventCountData = async (dispatchType, data) => {
        dispatch({
            type: dispatchType,
            payload: data
        });
    }

    const setEventCountDataLoading = async (dispatchType, data) => {
        dispatch({
            type: dispatchType,
            payload: data
        });
    }

    const getTopNodes = async (limit) => {
        setTopNodesDistributionDataLoading(true);

        let response = await getEventByNodeTop({
            from_ts: localTimeStampToUtcTimeStamp(state.startTimestamp),
            to_ts: localTimeStampToUtcTimeStamp(state.endTimestamp),
            filters: getSerializedFiltersList(state.selectedFilters),
            limit: limit || state.maxTopNodesLimit
        });

        if (response?.data) {
            setTopNodesData(response.data)
        }
        else {
            setTopNodesDistributionDataLoading(false);
        }
    }

    const setTopNodesData = async (data) => {
        dispatch({
            type: SET_TOP_NODES_DATA,
            payload: data
        });
    }

    const setDashboardAvailableStatus = ( status ) => {
        dispatch({
            type: SET_DAHSBOARD_AVAILABLE_STATUS,
            payload: status
        });
    }

    const setAsDefaultDashboard = async (dashboard_id) => {
        const response = await setDefault( {dashboard_id});
        if(response.status === 200) {
            const dashboardList = state.dashboardList.map(d => {
                return {
                    ...d,
                    is_default : d.dashboard_id === dashboard_id
                }
            })

            setDashboardList(dashboardList);
            return true;
        };
        return false;
    }

    const _getDefaultDashboard = async (data) => {
        let response = await getDefaultDashboard({});
        
        if(response.status === 200 && response.data)
        {      
            setSelectedDashboard({
                dashboard_id : data.dashboard_id || null,
                dashboard_state: response.data
            })
            
        }
        setInitialLoadCompleteStatus(true)
        setDashboardAvailableStatus(false)
    }

    const getAllDashboardList = async ({dashboard_id}) => {
        let response = await getDashboardList({});
        if( !response?.data){ return }

        const list = response?.data?.list || [];
        setDashboardList(list);
        if(dashboard_id) { 
            return 
        }

        if(list?.length > 0)
        {
            setInitialLoadCompleteStatus(false)
            const defaultDashboard = list.find(d => d.is_default);
            if(defaultDashboard)
            {
                dispatch({
                    type: SET_SELECTED_DASHBOARD,
                    payload: defaultDashboard.dashboard_id
                });
            }
        }else{
            loadDashboardServices()
        }
    }

    const setDashboardList = async (data) => {
        dispatch({
            type: SET_DASHBOARD_LIST,
            payload: data
        });
    }

    const isDefaultDashboard = async(dashboard_id) => {
        if(state.dashboardList === null) return false;
        const { dashboard_id : defaultDashboard_id } = state.dashboardList.find(d => d.is_default);
        const isDefault = defaultDashboard_id === dashboard_id ;
        dispatch({
            type: SET_DASHBOARD_DEFAULT_STATUS,
            payload: isDefault
        });
        return isDefault;
    }
    const getStateData = () => {
        // if intervallabel is set then exclude startTimestamp and endTimestamp
        if( state.intervalLabel ) return pick(state, ["selectedFilters","intervalLabel"])
    
        return pick(state, generalConfig.dashboard_data_fields)
    }
    const saveAsNewDashboard = async () => {
        const dashboard_state =  await getStateData();
        const updatedDashboardList = state.dashboardList;
        if(updatedDashboardList.length >= generalConfig.max_dashboards_limit){ 
            const errMessage = transformTranslationText(state.messages.dashboard_messages["save-as-new"].failed, [generalConfig.max_dashboards_limit])
            state.messages.dashboard_messages["save-as-new"].failed =   errMessage ;
            return false 
        }

        const dashboard_name = `dashboard-${updatedDashboardList.length + 1}`; // as initially length will be 0 so add 1 for naming dashboard-1
        let dashboard_default = false 

        if(updatedDashboardList.length <= 0) dashboard_default = true;

        const data = transformDashboardStateData({dashboard_name,dashboard_state,dashboard_default});
        const response = await createDashboard(data)
        if(response.status !== 200) {
            state.messages.dashboard_messages["save-as-new"].failed = response.data.msg + ` ( ${dashboard_name} )`
             return  false ;
        }

        // if dashboard saved as new - then update dashboard list and switch to new dashboard
        const { dashboard_id } = response.data;
        if( dashboard_default ) updatedDashboardList.map(d=>{ d.is_default = false; return d})
        
        updatedDashboardList.push({
            dashboard_id,
            dashboard_name, 
            is_default: dashboard_default
        });

        updateUrlParameterByName("dashboard-id",dashboard_id)
        setDashboardList(updatedDashboardList)
        return true ;
    }

    const _saveDashboard = async(dashboard_id) => {
        const dashboard_state = await getStateData();
        const dashboard_default = await isDefaultDashboard(dashboard_id);
        const data = transformDashboardStateData({ dashboard_state, dashboard_default, dashboard_id });
        const response = await saveDashboard(data);
        if(response.status === 200) return true;
        return false;
    }

    const renameDashboard = async (dashboard_id, dashboard_name) => {
        const dashboard_default = await isDefaultDashboard(dashboard_id);
        const response = await saveDashboard({ dashboard_id, dashboard_name, is_default:dashboard_default});
        const  dashboardList= state.dashboardList;
        const  updatedDashboardList = dashboardList.map(d => {  
            if(d.dashboard_id === dashboard_id)
                d.dashboard_name = dashboard_name ;
            return d;
        })
        setDashboardList(updatedDashboardList);
        if(response.status === 200) return true;
        return false;
    }

    const switchDashboard = async (dashboard_id) => {
        if( !dashboard_id )  {
            setDashboardAvailableStatus(false)
            return
        };

        dispatch({
            type: SET_SELECTED_DASHBOARD,
            payload: dashboard_id
        });
        const response = await getDashboard({dashboard_id});
        if(response?.data) 
        {
            let interval = {}
            if(response.data?.intervalLabel){
                interval = handleQuickLinkAction(response?.data?.intervalLabel);
            }
            setSelectedDashboard({
                dashboard_id,
                dashboard_state: { ...interval, ...response.data}
            })
        }
        
        setInitialLoadCompleteStatus(true)
        isDefaultDashboard(dashboard_id);
    }

    const setSelectedDashboard = async (data) => {
        if(!data) return ;
        data.dashboard_id && dispatch({
            type: SET_SELECTED_DASHBOARD,
            payload: data.dashboard_id
        });

        data.dashboard_state && dispatch({
            type: SWITCH_DASHBOARD,
            payload: data?.dashboard_state
        });
    }

    const _deleteDashboard = async(dashboard_id) => {
        const response = await deleteDashboard({dashboard_id});
        if(response.data.status != 'SUCCESS') return false;

        let dashboardList = state.dashboardList;
        let UpdatedDashboardList = dashboardList.filter((dashboard) => dashboard.dashboard_id !== dashboard_id);

        _getDefaultDashboard();
        setDashboardList(UpdatedDashboardList)
        return true;
    }
    
    const resetToDefaultDashboard = () => {
        setIntervalLabel(null)
        // reset time to default
        setDateTimeRange({
            start: defaultStartTimestamp, 
            end: defaultEndTimestamp
        })

        // reset selected dashboard id
        dispatch({
            type: SET_SELECTED_DASHBOARD,
            payload: null
        });

        // set default dashboard status to false
        dispatch({
            type: SET_DASHBOARD_DEFAULT_STATUS,
            payload: false
        });
        
        setDashboardAvailableStatus(false)

    }

    const setIntervalLabel = (id) => {
        if(id){
        const { startTimestamp, endTimestamp} = handleQuickLinkAction(id);
        setDateTimeRange({
            start: startTimestamp, 
            end: endTimestamp
        })}
        dispatch({
            type: SET_INTERVAL_LABEL,
            payload: id
        });
    }

    const fetchAllInitialRequests = async () => {
        const eventTypeFilterKey = generalConfig.event_type_filter_key;
        const currentSelections = cloneDeep(state.selectedFilters);
        const isTypeFilterApplied = currentSelections.filter(s => s.filterType === eventTypeFilterKey).length === 1;
        if (!isTypeFilterApplied && "error") {
            currentSelections.push({
                filterType: eventTypeFilterKey,
                filterValue: "error"
            })
        }

        const allRequestsList = [
            getFiltersList({}),
            getEventCount({
                from_ts: localTimeStampToUtcTimeStamp(state.startTimestamp),
                to_ts: localTimeStampToUtcTimeStamp(state.endTimestamp),
                filters: getSerializedFiltersList(currentSelections),
            }),
            getEventCount({
                from_ts: localTimeStampToUtcTimeStamp(state.startTimestamp),
                to_ts: localTimeStampToUtcTimeStamp(state.endTimestamp),
                filters: getSerializedFiltersList(currentSelections),
                interval: 86400
            }),
            getFilterSuggestions({
                fieldname: eventTypeFilterKey,
                from_ts: localTimeStampToUtcTimeStamp(state.startTimestamp),
                to_ts: localTimeStampToUtcTimeStamp(state.endTimestamp),
            }),
            getEventByNodeTop({
                from_ts: localTimeStampToUtcTimeStamp(state.startTimestamp),
                to_ts: localTimeStampToUtcTimeStamp(state.endTimestamp),
                filters: getSerializedFiltersList(state.selectedFilters),
                limit: state.maxTopNodesLimit
            })
        ];

        return Promise.allSettled(allRequestsList);
    }

    const loadDashboardServices = async () => {
        const values = await fetchAllInitialRequests();
        if (values?.length) {
            values.forEach(({status, value}) => {
                if (status && value) {
                    const data = status === "fulfilled" ? value.data : [];
                    if (value.config.url === "/query/filters/list") {
                        setAllFiltersList(data);
                    }
                    if (value.config.url === "/query/event/bycategory/count") {
                        setEventCountData(SET_EVENT_COUNT_BY_CATEGORY_DATA, data);
                    }
                    if (value.config.url === "/query/event/byinterval/count") {
                        setEventCountData(SET_EVENT_COUNT_BY_INTERVAL_DATA, data);
                    }
                    if (value.config.url === "/query/filters/suggest") {
                        setAllEventsData(data)
                    }
                    if (value.config.url === "/query/event/bynode/top") {
                        setTopNodesData(data)
                    }
                }
            })
        }

        dispatch({
            type: SET_INITIAL_LOAD_COMPLETE_STATUS,
            payload: true
        });
    }

    const resetEventCountDistributionByInterval = async () => {
        dispatch({
            type: RESET_EVENT_COUNT_BY_INTERVAL_DATA
        });
    }
    
    const resetEventCountDistributionByCategory = async () => {
        dispatch({
            type: RESET_EVENT_COUNT_BY_CATEGORY_DATA
        });
    }
    
    const resetAllEventsData = async () => {
        dispatch({
            type: RESET_ALL_EVENTS_DATA
        });
    }
    
    const resetTopNodesDistributionData = async () => {
        dispatch({
            type: RESET_TOP_NODES_DISTRIBUTION_DATA
        });
    }
    
    const resetInitialLoadCompleteStatus = async () => {
        dispatch({
            type: RESET_INITIAL_LOAD_COMPLETE_STATUS
        });
    }

    const getTopNodesOnlineStatus = async () => {
        if (!state.topNodesDistributionData) return;

        let response = await getQueryStateList({
            from_ts: 1,
            to_ts: getTime(new Date()),
            filters: getSerializedFiltersList([], [], [{filterType: "Node.ID", filterValue: state.topNodesDistributionData.map(n => n.node_id)}]),
            limit: state.maxTopNodesLimit,
            relation: "online",
            flatten: true
        });

        response?.data && setOnlineTopNodesData(response.data);
    }

    const setOnlineTopNodesData = async (data) => {
        dispatch({
            type: SET_ONLINE_TOP_NODES_DATA,
            payload: data
        });
    }

    const getNodeInfoData = async (key) => {
        let response = await getNodeInfo({node_key: key});
        setNodeInfoData(response?.data || null);
    }

    const setNodeInfoData = async (data) => {
        dispatch({
            type: SET_NODE_INFO_DATA,
            payload: data
        });
    }

    const resetSelectedNodeData = async () => {
        dispatch({
            type: RESET_SELECTED_NODE_DATA
        });
    }

    const getSelectedNodeAllEventsData = async () => {
        const requestData = {
            from_ts: localTimeStampToUtcTimeStamp(state.startTimestamp),
            to_ts: localTimeStampToUtcTimeStamp(state.endTimestamp),
            filters: getSerializedFiltersList(state.selectedFilters, state.nodeFilters),
            fieldname: generalConfig.event_type_filter_key
        }
        let response = await getFilterSuggestions(requestData);
        setSelectedNodeAllEventsData(response?.data || null);
    }

    const setSelectedNodeAllEventsData = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_ALL_EVENTS_DATA,
            payload: data
        });
    }
    
    const resetSelectedNodeAllEventsData = async () => {
        dispatch({
            type: RESET_SELECTED_NODE_ALL_EVENTS_DATA
        });
    }

    const getSelectedNodeLogsData = async (eventTypeMultiValues, prepend) => {
        setSelectedNodeLogsLoading(true);

        const requestData = {
            from_ts: state.startTimestamp,
            to_ts: state.endTimestamp,
            filters: getSerializedFiltersList(state.selectedFilters, state.nodeFilters, eventTypeMultiValues),
            limit: state.selectedNodeLogsLimit,
            pagetoken: prepend ? state.selectedNodeLogsPageToken : ""
        }
        let response = await getEventList(requestData);
        if (response?.data) {
            if (prepend) {
                prependToSelectedNodeLogsData(response.data);
            }
            else {
                setSelectedNodeLogsData(response.data)
            }
        }
        else {
            setSelectedNodeLogsLoading(false);
        }
    }
    
    const setSelectedNodeLogsData = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_LOGS_DATA,
            payload: data
        });
    }

    const prependToSelectedNodeLogsData = async (data) => {
        dispatch({
            type: PREPEND_TO_SELECTED_NODE_LOGS_DATA,
            payload: data
        });
    }
    
    const resetSelectedNodeLogsData = async () => {
        dispatch({
            type: RESET_SELECTED_NODE_LOGS_DATA
        });
    }
    
    const setSelectedNodeLogsLoading = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_LOGS_LOADING,
            payload: data
        });
    }

    const getNodeTimelineData = async (subDist, maxBuckets) => {
        setSelectedNodeTimelineDistributionDataLoading(true);
        const aggregateData = getEventStatAggregateData({maxBuckets, subDist});
        const requestData = {
            from_ts: localTimeStampToUtcTimeStamp(state.startTimestamp),
            to_ts: localTimeStampToUtcTimeStamp(state.endTimestamp),
            filters: getSerializedFiltersList(state.selectedFilters, state.nodeFilters),
            agg: aggregateData
        }

        let response = await getQueryEventStat(requestData);
        if (response?.data) {
            setSelectedNodeTimelineDistributionData(response.data)
        }
        else {
            setSelectedNodeTimelineDistributionDataLoading(false)
        }
    }

    const setSelectedNodeTimelineDistributionData = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA,
            payload: data
        });
    }

    const setSelectedNodeTimelineDistributionDataLoading = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_TIMELINE_DISTRIBUTION_DATA_LOADING,
            payload: data
        });
    }
    
    const setSelectedNodeTimelineSubdist = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_TIMELINE_SUBDIST,
            payload: data
        });
    }
    
    const getSelectedNodeParamsMeta = async ({nodeKey}) => {
        let metaResponse = await getNodeParamsMeta({node_key: nodeKey});
        if (metaResponse?.data) {
            setSelectedNodeParamsMeta(metaResponse.data)
        }
    }

    const setSelectedNodeParamsMeta = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_PARAMS_META,
            payload: data
        });
    }
    
    const getSelectedNodeParamsList = async ({nodeKey, paramsMetaData}) => {
        setSelectedNodeParamsListLoading(true);
        let requestData = {
            node_key: nodeKey,
            start: localTimeStampToUtcTimeStamp(state.startTimestamp),
            end: localTimeStampToUtcTimeStamp(state.endTimestamp),
            limit: 150
        }
        let listResponse = await getNodeParamsList(requestData, getConsolidatedParamsList(paramsMetaData));

        if (listResponse?.data) {
            setSelectedNodeParamsList(transormNodeParamsData({
                meta: paramsMetaData, 
                list: listResponse.data
            }));
        }
        else {
            setSelectedNodeParamsListLoading(false);
        }
    }

    const setSelectedNodeParamsList = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_PARAMS_LIST,
            payload: data
        });
    }

    const setSelectedNodeParamsListLoading = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_PARAMS_LIST_LOADING,
            payload: data
        });
    }

    const getSelectedNodeParamsMetaAndListData = async ({nodeKey}) => {
        setSelectedNodeParamsListLoading(true);
        let metaResponse = await getNodeParamsMeta({node_key: nodeKey});
        const paramsMetaData = metaResponse.data;
        if (paramsMetaData && paramsMetaData.length) {
            getSelectedNodeParamsList({nodeKey, paramsMetaData});
        }
        else {
            setSelectedNodeParamsList([]);
        }
    }

    const setSelectedNodeTagsListDataLoading = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_TAGS_LIST_LOADING,
            payload: data
        });
    }

    const setSelectedNodeTagsList = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_TAGS_LIST,
            payload: data
        });
    }

    const getSelectedNodeTagsListData = async(node_key) => {
        setSelectedNodeTagsListDataLoading(true);
        const response = await getNodeTagsList({node_key})
        let tags = [] ;
        if(response.status === 200 && response.data?.node_tags)
        {
            const node_tags = response.data.node_tags.find(node_tag => node_key === node_tag.node_key)?.tags || [];
            tags = transformNodeTagsData(node_tags)
            
        }
        setSelectedNodeTagsList(tags);
        setSelectedNodeTagsListDataLoading(false)   
    }

    const removeFromSelectedNodeTagsList = async (tag) => {
        const { id: tagId, property, value, nodeKey:node_key} = tag;
        const response = await removeTagFromNodeTagsList({node_key, property, value});
        if(response.status === 200 && response.data.status === "SUCCESS"){
             const tagsList = state.selectedNodeTagsList.filter(tag => {
                 if(tag.id !== tagId)
                    return tag
            })
             setSelectedNodeTagsList(tagsList);
             return true;
        }

        return false;
    }

    const updateTagFromSelectedNodeTagsList = async (tagId, property, value, node_key) => {
        setSelectedNodeTagsListDataLoading(true);
        const tagsList = state.selectedNodeTagsList;
        const response = await addTagToNodeTagsList({node_key,body:{ tags:[`${property}:${value}`]}})
        if(response.status === 200 &&  Object.keys(response.data?.remark).length <= 0)
        {   
            const tagIndex = tagsList.findIndex(tag => tag.id === tagId)
            tagsList[tagIndex] = { tagId, property, value }
            setSelectedNodeTagsList(tagsList);
            setSelectedNodeTagsListDataLoading(false)
        }
    }

    const createTagForSelecetedNode = async ( node_key, tag ) => {
        const tagsList = state.selectedNodeTagsList;
        const response = await addTagToNodeTagsList({node_key,body:{ tags:[`${tag.property}:${tag.value}`]}});
        if(response.status === 200 &&  Object.keys(response.data?.remark).length <= 0){
            tagsList.push({
                ...tag,
                id: `${tag.property}${tag.value}`
            })
            setSelectedNodeTagsList(tagsList);
            return true;
        }
        return false;
    }

    const getSelectedNodeOnlineStatus = async () => {
        let response = await getQueryStateList({
            from_ts: 1,
            to_ts: getTime(new Date()),
            filters: getSerializedFiltersList([], state.nodeFilters),
            limit: 10,
            relation: "online",
            flatten: true
        });
        
        if (response?.data?.list?.length) {
            setSelectedNodeOnlineStatus(true);
        }
        else {
            setSelectedNodeOnlineStatus(false);
        }
    }

    const setSelectedNodeOnlineStatus = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_ONLINE_STATUS,
            payload: data
        });
    }

    const setSelectedNodeMetricsDateTimeRange = range => {
        dispatch({
            type: SET_SELECTED_NODE_METRICS_DATE_TIME_RANGE,
            payload: range
        });
    }

    const getBillingUsageMetaData = async () => {
        if (state.billingUsageMetaData !== null) return false;

        setBillingUsageMetaDataLoading(true);
        let response = await getBillingUsageMeta();
        if (response?.data) {
            setBillingUsageMetaData(response);
        }
        else {
            setBillingUsageMetaDataLoading(false);
        }
    }

    const setBillingUsageMetaData = async (data) => {
        dispatch({
            type: SET_BILLING_USAGE_META_DATA,
            payload: data
        });
    }

    const setBillingUsageMetaDataLoading = async (data) => {
        dispatch({
            type: SET_BILLING_USAGE_META_DATA_LOADING,
            payload: data
        });
    }
    
    const getCurrentMonthUsage = async () => {
        setCurrentMonthUsageDataLoading(true);

        const requestData = {
            start: getUnixTime(startOfMonth(new Date())),
            end: getUnixTime(endOfMonth(new Date())),
            interval: "1d",
            tz: state.tzOffset
        }

        let response = await getBillingUsageByInterval(requestData);
        if (response?.data) {
            setCurrentMonthUsageData(response.data);
        }
        else {
            setCurrentMonthUsageDataLoading(false);
        }
    }
    
    const setCurrentMonthUsageData = async (data) => {
        dispatch({
            type: SET_CURRENT_MONTH_USAGE,
            payload: data
        });
    }
    
    const setCurrentMonthUsageDataLoading = async (data) => {
        dispatch({
            type: SET_CURRENT_MONTH_USAGE_LOADING,
            payload: data
        });
    }
    
    const resetUsageData = async () => {
        dispatch({
            type: RESET_USAGE_DATA
        });
    }

    const setUsageInsightsDate = async (data) => {
        dispatch({
            type: SET_USAGE_INSIGHTS_DATE,
            payload: data
        });
    }

    const setUsageInsightsFilter = async (data) => {
        dispatch({
            type: SET_USAGE_INSIGHTS_FILTER,
            payload: data
        });
    }

    const setUsageInsightsSubFilter = async (data) => {
        dispatch({
            type: SET_USAGE_INSIGHTS_SUB_FILTER,
            payload: data
        });
    }

    const setUsageInsightsSelectedAdminId = async (data) => {
        dispatch({
            type: SET_USAGE_INSIGHTS_SELECTED_ADMIN_ID,
            payload: data
        });
    }

    const setUsageInsightsSelectedInterface = async (data) => {
        dispatch({
            type: SET_USAGE_INSIGHTS_SELECTED_INTERFACE,
            payload: data
        });
    }

    const getAuthKeysList = async () => {
        let response = await listAuthKeys(99);
        setAuthKeysList(response?.data);
    }

    const setAuthKeysList = async (data) => {
        dispatch({
            type: SET_AUTH_KEYS_LIST,
            payload: data
        });
    }

    const setUsageInsightsData = async (data) => {
        dispatch({
            type: SET_USAGE_INSIGHTS_DATA,
            payload: data
        });
    }

    const getUsageInsightsData = async () => {
        const requestedDataForCurrentMonth = getMonth(fromUnixTime(state.usageInsightsDate)) === getMonth((new Date()));
        setUsageInsightsDataLoading(true);
        if (requestedDataForCurrentMonth) {
            setCurrentMonthUsageDataLoading(true);
        }

        const requestData = {
            start: getUnixTime(startOfMonth(fromUnixTime(state.usageInsightsDate))),
            end: getUnixTime(endOfMonth(fromUnixTime(state.usageInsightsDate))),
            interval: "1d",
            tz: state.tzOffset
        }

        let response = await getBillingUsageByInterval(requestData);
        if (response?.data) {
            setUsageInsightsData(response.data);
            if (requestedDataForCurrentMonth) {
                setCurrentMonthUsageData(response.data);
            }

        }
        else {
            setUsageInsightsDataLoading(false);
            if (requestedDataForCurrentMonth) {
                setCurrentMonthUsageDataLoading(false);
            }
        }
    }

    const setUsageInsightsDataLoading = async (data) => {
        dispatch({
            type: SET_USAGE_INSIGHTS_DATA_LOADING,
            payload: data
        });
    }

    const setEventCountDistributionByIntervalLoading = async (data) => {
        dispatch({
            type: SET_EVENT_COUNT_DISTRIBUTION_BY_INTERVAL_LOADING,
            payload: data
        });
    }

    const setTopNodesDistributionDataLoading = async (data) => {
        dispatch({
            type: SET_TOP_NODES_DISTRIBUTION_DATA_LOADING,
            payload: data
        });
    }

    const setAllEventsDataLoading = async (data) => {
        dispatch({
            type: SET_ALL_EVENTS_DATA_LOADING,
            payload: data
        });
    }

    const setEventCountDistributionByCategoryLoading = async (data) => {
        dispatch({
            type: SET_EVENT_COUNT_DISTRIBUTION_BY_CATEGORY_LOADING,
            payload: data
        });
    }

    const setSelectedNodeTimelineAggregatorType = async (data) => {
        dispatch({
            type: SET_SELECTED_NODE_TIMELINE_AGGREGATOR_TYPE,
            payload: data
        });
    }
    
    /* Multi admin state update methods - start */

    const setInvitationsForUserLoading = async (data) => {
        dispatch({
            type: SET_INVITATIONS_FOR_USER_LOADING,
            payload: data
        });
    }
    
    const setInvitationsForUser = async (data) => {
        dispatch({
            type: SET_INVITATIONS_FOR_USER,
            payload: data
        });
    }

    const getInvitationsForUser = async (pagetoken = "") => {
        setInvitationsForUserLoading(true);

        const requestData = {
            limit: 4,
            pagetoken
        }
        let response = await listTenantInvite(requestData);
        if (response?.data) {
            setInvitationsForUser(response.data)
        }
        else {
            setInvitationsForUserLoading(false);  
        }
    }
    
    const setInvitationsByUserLoading = async (data) => {
        dispatch({
            type: SET_INVITATIONS_BY_USER_LOADING,
            payload: data
        });
    }
    
    const setInvitationsByUser = async (data) => {
        dispatch({
            type: SET_INVITATIONS_BY_USER,
            payload: data
        });
    }

    const getInvitationsByUser = async (pagetoken = "") => {
        setInvitationsByUserLoading(true);

        const requestData = {
            limit: 4,
            pagetoken
        }
        let response = await listAccountInvite(requestData);
        if (response?.data) {
            setInvitationsByUser(response.data)
        }
        else {
            setInvitationsByUserLoading(false);  
        }
    }
    
    const setTenantsListLoading = async (data) => {
        dispatch({
            type: SET_TENANTS_LIST_LOADING,
            payload: data
        });
    }
    
    const setTenantsList = async (data) => {
        dispatch({
            type: SET_TENANTS_LIST,
            payload: data
        });
    }

    const getTenantsList = async (pagetoken = "") => {
        setTenantsListLoading(true);

        const requestData = {
            limit: 6,
            pagetoken
        }
        let response = await listTenants(requestData);
        if (response?.data) {
            setTenantsList(response.data);
        }
        else {
            setTenantsListLoading(false);    
        }
    }

    const setAccountsListLoading = async (data) => {
        dispatch({
            type: SET_ACCOUNTS_LIST_LOADING,
            payload: data
        });
    }
    
    const setAccountsList = async (data) => {
        dispatch({
            type: SET_ACCOUNTS_LIST,
            payload: data
        });
    }

    const getAccountsList = async (pagetoken) => {
        setAccountsListLoading(true);

        const requestData = {
            limit: 6,
            pagetoken
        }
        let response = await listAccounts(requestData);
        if (response?.data) {
            setAccountsList(response.data);
        }
        else {
            setAccountsListLoading(false);  
        }
    }


    /* Multi admin state update methods - end */

        /* Command/Response state update methods - start */
    
        const getNodeConfigurationMetaData = async (requestData) => {
            setNodeConfigMetaLoading(true);
    
            let response = await getNodeConfigMeta(requestData);
            if (response?.data && !isEmpty(response.data)) {
                setNodeConfigMeta(response.data);
            }
            else {
                setNodeConfigMeta(null);
            }
        }
    
        const setNodeConfigMeta = async (data) => {
            dispatch({
                type: SET_NODE_CONFIG_META,
                payload: data
            });
        }
        
        const setNodeConfigMetaLoading = async (data) => {
            dispatch({
                type: SET_NODE_CONFIG_META_LOADING,
                payload: data
            });
        }
    
        /* Command/Response state update methods - end */

    return (
        <AdvancedInsightsContext.Provider
            value = {{
                selectedDashboard: state.selectedDashboard,
                dashboardList: state.dashboardList,
                startTimestamp: state.startTimestamp,
                endTimestamp: state.endTimestamp,
                messages: state.messages,
                filtersData: state.filtersData,
                filtersList: state.filtersList,
                selectedFilters: state.selectedFilters,
                nodeFilters: state.nodeFilters,
                filterSuggestionData: state.filterSuggestionData,
                allEventsData: state.allEventsData,
                eventCountDistributionByInterval: state.eventCountDistributionByInterval,
                allEventsCountDistributionByInterval: state.allEventsCountDistributionByInterval,
                eventCountDistributionByCategory: state.eventCountDistributionByCategory,
                topNodesDistributionData: state.topNodesDistributionData,
                distinctNodeCount: state.distinctNodeCount,
                initialLoadCompleteStatus: state.initialLoadCompleteStatus,
                disabledIntervalsList: state.disabledIntervalsList,
                eventCountDistributionByIntervalResetStatus: state.eventCountDistributionByIntervalResetStatus,
                allEventsDataResetStatus: state.allEventsDataResetStatus,
                topNodesDistributionDataResetStatus: state.topNodesDistributionDataResetStatus,
                eventCountDistributionByCategoryResetStatus: state.eventCountDistributionByCategoryResetStatus,
                intervalLabel : state.intervalLabel,
                onlineTopNodesData: state.onlineTopNodesData,
                selectedNodeInfoData: state.selectedNodeInfoData,
                selectedNodeAllEventsData: state.selectedNodeAllEventsData,
                selectedNodeLogsData: state.selectedNodeLogsData,
                selectedNodeLogsRemarks: state.selectedNodeLogsRemarks,
                selectedNodeLogsPageToken: state.selectedNodeLogsPageToken,
                selectedNodeLogsLimit: state.selectedNodeLogsLimit,
                selectedNodeLogsLoading: state.selectedNodeLogsLoading,
                selectedNodeTimelineSubdist: state.selectedNodeTimelineSubdist,
                selectedNodeTimelineDistributionData: state.selectedNodeTimelineDistributionData,
                dashboardAvailableStatus:state.dashboardAvailableStatus,
                isDashboardDefault:state.isDashboardDefault,
                selectedNodeTimelineDistributionDataLoading: state.selectedNodeTimelineDistributionDataLoading,
                selectedNodeParamsList: state.selectedNodeParamsList,
                selectedNodeParamsListLoading: state.selectedNodeParamsListLoading,
                selectedNodeTagsList: state.selectedNodeTagsList,
                selectedNodeTagsListLoading: state.selectedNodeTagsListLoading,
                selectedNodeOnlineStatus: state.selectedNodeOnlineStatus,
                selectedNodeMetricsStartTime: state.selectedNodeMetricsStartTime,
                selectedNodeMetricsEndTime: state.selectedNodeMetricsEndTime,
                billingUsageMetaData: state.billingUsageMetaData,
                billingUsageMetaDataLoading: state.billingUsageMetaDataLoading,
                currentMonthUsageData: state.currentMonthUsageData,
                currentMonthUsageCreditsData: state.currentMonthUsageCreditsData,
                currentMonthUsageDataLoading: state.currentMonthUsageDataLoading,
                usageInsightsDate: state.usageInsightsDate,
                usageInsightsFilter: state.usageInsightsFilter,
                usageInsightsSubFilter: state.usageInsightsSubFilter,
                usageInsightsSelectedAdminId: state.usageInsightsSelectedAdminId,
                usageInsightsSelectedInterface: state.usageInsightsSelectedInterface,
                authKeysList: state.authKeysList,
                usageInsightsData: state.usageInsightsData,
                usageInsightsDataLoading: state.usageInsightsDataLoading,
                transformedUsageInsightsData: state.transformedUsageInsightsData,
                allEventsDataLoading: state.allEventsDataLoading,
                topNodesDistributionDataLoading: state.topNodesDistributionDataLoading,
                eventCountDistributionByCategoryLoading: state.eventCountDistributionByCategoryLoading,
                eventCountDistributionByIntervalLoading: state.eventCountDistributionByIntervalLoading,
                selectedNodeTimelineAggregatorType: state.selectedNodeTimelineAggregatorType,
                invitationsForUser: state.invitationsForUser,
                invitationsForUserLoading: state.invitationsForUserLoading,
                invitationsByUser: state.invitationsByUser,
                invitationsByUserLoading: state.invitationsByUserLoading,
                tenantsList: state.tenantsList,
                tenantsListLoading: state.tenantsListLoading,
                accountsList: state.accountsList,
                accountsListLoading: state.accountsListLoading,
                nodeConfigMeta: state.nodeConfigMeta,
                transformedNodeConfigMeta: state.transformedNodeConfigMeta,
                nodeConfigMetaLoading: state.nodeConfigMetaLoading,
                setDateTimeRange,
                getAllFiltersList,
                getSuggestions,
                addToSelectedFilters,
                addMultipleFiltersToSelectedFilters,
                removeFromSelectedFilters,
                clearAllSelectedFilters,
                getAllEventsData,
                getEventCountData,
                getTopNodes,
                loadDashboardServices,
                setInitialLoadCompleteStatus,
                resetEventCountDistributionByInterval,
                resetEventCountDistributionByCategory,
                resetAllEventsData,
                resetTopNodesDistributionData,
                setNodeFilters,
                clearNodeFilters,
                resetInitialLoadCompleteStatus,
                saveAsNewDashboard,
                switchDashboard,
                getDefaultDashboard: _getDefaultDashboard,
                getAllDashboardList,
                deleteDashboard: _deleteDashboard,
                setAsDefaultDashboard,
                saveDashboard:_saveDashboard,
                renameDashboard:renameDashboard,
                resetToDefaultDashboard:resetToDefaultDashboard,
                setIntervalLabel,
                getTopNodesOnlineStatus,
                getNodeInfoData,
                resetSelectedNodeData,
                getSelectedNodeAllEventsData,
                resetSelectedNodeAllEventsData,
                getSelectedNodeLogsData,
                setSelectedNodeLogsData,
                resetSelectedNodeLogsData,
                getNodeTimelineData,
                setSelectedNodeTimelineSubdist,
                getSelectedNodeParamsMetaAndListData,
                getSelectedNodeOnlineStatus,
                setSelectedNodeMetricsDateTimeRange,
                getBillingUsageMetaData,
                getCurrentMonthUsage,
                resetUsageData,
                setUsageInsightsDate,
                setUsageInsightsFilter,
                setUsageInsightsSubFilter,
                setUsageInsightsSelectedAdminId,
                setUsageInsightsSelectedInterface,
                getAuthKeysList,
                getUsageInsightsData,
                getSelectedNodeTagsListData,
                removeFromSelectedNodeTagsList,
                updateTagFromSelectedNodeTagsList,
                createTagForSelecetedNode,
                setSelectedNodeTimelineAggregatorType,
                getInvitationsForUser,
                getInvitationsByUser,
                getTenantsList,
                getAccountsList,
                getNodeConfigurationMetaData
            }}
        >
            { props.children }
        </AdvancedInsightsContext.Provider>
    );
}

export default InsightsStore;
