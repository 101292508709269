import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { isStrongPassword } from '../../../utils/validationSchemas';
import { changePasswordCall, loginCall } from '../../../utils/apiCalls';
import CustomToast from '../../../components/CustomToast/CustomToast';
import Password from '../../../components/Password/Password';
import { PrimaryLineButton } from '../../../components/Button/Button';

const Settings = (props) => {
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState({});
	const [username, setUsername] = useState('');
	const [error, setError] = useState('');

	const inputValues = [
		{
			name: 'password',
			placeholder: '',
			type: 'password',
			label: 'Old Password',
			tab: '1',
			id: 'old-password',
		},
		{
			name: 'newPassword',
			placeholder: '',
			type: 'password',
			label: 'New Password',
			tab: '2',
			id: 'new-password',
		},
		{
			name: 'confirmPassword',
			placeholder: '',
			type: 'password',
			label: 'Confirm Password',
			tab: '3',
			id: 'confirm-password',
		},
	];

	useEffect(() => {
		setUsername(Cookies.get('user_name'));
	});

	const handleInputChange = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		setError('');
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const validateChangePasswordRequest = (
		password,
		newpassword,
		confirmPassword
	) => {
		let error = '';
		if (!password || !newpassword || !confirmPassword) {
			return (error = 'Please enter all the details.');
		}
		if (newpassword !== confirmPassword) {
			error = 'Passwords did not match';
			return error;
		}
		if (!isStrongPassword(newpassword)) {
			error =
				'Password must be atleast 8 characters long. It should contain atleast one uppercase, one lowercase character and a number';
			return error;
		}
		return error;
	};

	const handleChangePassword = (data) => {
		let password = data.password;
		let newpassword = data.newPassword;
		let confirmPassword = data.confirmPassword;
		let error = validateChangePasswordRequest(
			password,
			newpassword,
			confirmPassword
		);
		setError(error);

		if (!error) {
			setLoading(true);
			changePasswordCall(password, newpassword).then((response) => {
				if (response.statusCode === 401) {
					props.sessionExpire();
					return;
				}
				if (response.status === 'success') {
					loginCall(username, newpassword).then((response) => {
						setLoading(false);
						setState({});
						setUsername('');
						setError('');
						if (response) {
							props.sessionExpire();
							return;
						}
					});
					CustomToast.success(
						'Password changed successfully.',
						{
							autoClose: 5000,
						},
						'password-toast'
					);
				} else {
					setLoading(false);
					setError(response);
				}
			});
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleChangePassword(state);
		}
	};
	return (
		<>
			<div className='app--navbar'>
				<div className='d-flex justify-content-between align-items-center'>
					<div>
						<p className='small--heading'>Options</p>
						<div className='d-flex'>
							<p className='main--heading'>User Settings</p>
						</div>
					</div>
				</div>
			</div>
			<hr className='mx-3 my-0' />
			<div className='home-card card--container d-flex flex-column'>
				<div className='row'>
					<div className='col-lg-12 d-flex justify-content-between mb-3'>
						<h1 className='main--heading'>Change Password</h1>
					</div>
				</div>

				<div className='row mb-3'>
					<div className='col-lg-2 '>
						<label className='label'>Username:</label>
					</div>
					<div className='col-lg-10'>
						<label className='label'>{username}</label>
					</div>
				</div>

				{inputValues.map((input, index) => {
					return (
						<div key={`${input.name}${index}`} className='row mb-3'>
							<div className='col-lg-2'>
								<label className='label'>
									{input.label}
									<span>&nbsp;*</span>
								</label>
							</div>
							<div className='col-lg-10 change-password-column'>
								<Password
									name={input.name}
									placeholder={input.placeholder}
									key={`${input.name}${index}`}
									onChange={(e) => handleInputChange(e)}
									maxLength='64'
									onKeyPress={handleKeyPress}
									tabIndex={input.tab}
									value={state[input.name] || ''}
								/>
							</div>
						</div>
					);
				})}

				{error && <p className={'text-error mb-3'}>{error}</p>}

				<div className='row'>
					<div className='col-lg-2'></div>
					<div className='col-lg-10 d-flex justify-content-end change-password-column'>
						<PrimaryLineButton
							loading={loading}
							onClick={() => handleChangePassword(state)}
							id='change-password-btn'
							minWidth='154px'
						>
							Change Password
						</PrimaryLineButton>
					</div>
				</div>
			</div>
		</>
	);
};

export default Settings;
