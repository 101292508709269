export const findChildrenByType = (arr, targetKey, targetValue) => {
    let allParamValuesMeta = []
    function getParamValues(metaData) {
        for (let key in metaData) {
            if (Object.prototype.hasOwnProperty.call(metaData, key)) {
                if (key === targetKey) {
                    if (metaData[key] === targetValue) {
                        allParamValuesMeta.push(metaData);
                    }
                }
                if (metaData[key] instanceof Object || metaData[key] instanceof Array) {
                    getParamValues(metaData[key]);
                }
            }
        }
    }
    getParamValues(arr);
    return allParamValuesMeta
};