import React, {useEffect, useState} from 'react';
import { LinkButton, PrimaryLineButton } from '../../../../../components/Button/Button';
import CustomSelect from '../../../../../components/CustomSelect/CustomSelect';
import { getDependentFiltersList, getTransformedFiltersList } from '../../../data-transform/data-transform';
import './AddFilterInterface.scss';

function AddFilterInterface({
  setShow, 
  filtersList, 
  getSuggestions,
  filterSuggestionData,
  addToSelectedFilters,
  selectedFilters,
  messages
}) {
  const options = getTransformedFiltersList(filtersList);

  const [isNestedKeywordFilter, setIsNestedKeywordFilter] = useState(false);
  const [isNestedTermFilter, setIsNestedTermFilter] = useState(false);
  const [selectedFilterTypeOption, setSelectedFilterTypeOption] = useState(null);
  const [selectedFilterValueOption, setSelectedFilterValueOption] = useState(null);
  const [suggestionOptions, setSuggestionOptions] = useState([]);
  const [error, setError] = useState("");
  const [allowFreeTextInFilterValue, setAllowFreeTextInFilterValue] = useState(false);

  useEffect(() => {
    if (selectedFilterTypeOption?.value) {
        getSuggestions(selectedFilterTypeOption, selectedFilterValueOption, suggestionOptions);
    }
  }, [selectedFilterTypeOption, selectedFilters]);


  const transformSuggestionOptionsData = (data) => {
      if(!data) return null;
      const options = data.filter(s => s.key)
                      .map(s => generateOptionMarkup(s));
      return options;
  }
  useEffect(() => {
    if(isNestedTermFilter)
    { 
      const suggestionOptions = {}
      selectedFilterTypeOption.attributes.forEach(attrname => {
        const options = transformSuggestionOptionsData(filterSuggestionData[attrname]?.list)
        if(options)
          suggestionOptions[attrname] = options;
      })
      setSuggestionOptions(suggestionOptions)
      return
    }

    if (filterSuggestionData?.list?.length) {
      const options = transformSuggestionOptionsData(filterSuggestionData?.list)
      setSuggestionOptions(options)
    }
  }, [filterSuggestionData]);

  const handleFilterTypeSelection = option => {
    setIsNestedTermFilter(option.isNestedTermFilter);
    setSelectedFilterTypeOption(option);
    setAllowFreeTextInFilterValue(option.allowFreeText === true);
    setSuggestionOptions(prevOptions => []);
    setSelectedFilterValueOption(null);
  }
  
  const handleFilterValueSelection = ({value}) => {
    setSelectedFilterValueOption({ label: value, value });
  }
  
  const handleNestedFilterTermValueSelection = ({value, attribute}) => {

    const selectedFilterAttributes = selectedFilterTypeOption?.attributes;
    const attributeIndex = selectedFilterAttributes.indexOf(attribute);
    const resetAttributesValue = selectedFilterAttributes.slice(attributeIndex + 1) || [];
    resetAttributesValue.map(attr =>{
      setSelectedFilterValueOption(prev => {
        const _prev = { ... prev};
        delete _prev[attr];
        return _prev
      })

      setSuggestionOptions(prev => {
        const _prev = { ... prev};
        delete _prev[attr];
        return _prev
      })
    })

    setSelectedFilterValueOption((preSelected) => {
      const updatedSelected = {...preSelected} ;
      updatedSelected[attribute] = { label: value, value, partialValue: {[attribute] : value} };
      return updatedSelected;
    })
    const pratialValue = { "attrname" : attribute, value} 
    getSuggestions(selectedFilterTypeOption, pratialValue, filterSuggestionData);
  }

  const handleCustomTextFilterValueSelection = value => {
    setSelectedFilterValueOption({ label: value, value, customText: true });
  }

  const generateOptionMarkup = data => {
    const result = {};
    result.value = data.key;
    result.label = (
      <div className='option-container'>
        <div className='label'>{data.key}</div>
        <div className='count'>{data.count || ""}</div>
      </div>
    );

    return result;
  }

  const resetFilterInterface = () => {
      setError("");
      setShow(false);
      setIsNestedTermFilter(false)
      setSelectedFilterTypeOption(null);
      setAllowFreeTextInFilterValue(false);
      setSuggestionOptions(prevOptions => []);
      setSelectedFilterValueOption(null);
      setIsNestedKeywordFilter(false)
  }
 
  const validFilterSelection = () => {

    if(!selectedFilterTypeOption || !selectedFilterValueOption)
    {
      setError("invalid_selection");
      return;
    }

    if(isNestedTermFilter)
    {
      const filterValue = { }
      for (let [key, value] of Object.entries(selectedFilterValueOption)) {
        filterValue[key] = value.value
      }
      // validation : Is all the attrinutes in filter are selected or not
      if(selectedFilterTypeOption.attributes.join() !== Object.keys(selectedFilterValueOption).join() )
      {
        setError(messages.invalid_combination);
        return;
      }
      const filterOption = {
          filterType: selectedFilterTypeOption.value,
          filterValue,
          operator:"nestedkeyword"
      }
      addToSelectedFilters([filterOption]);
      resetFilterInterface()
      return
    }
    // if the filter type is not nestedterm
    setError("");
    const filtersList = getDependentFiltersList(selectedFilterTypeOption.value, selectedFilterValueOption.value, selectedFilterValueOption.customText);
    addToSelectedFilters(filtersList);
    resetFilterInterface()
    
  }

  return (
    <div className='add-filter-interface'>
      <div className='control-container'>
        <label>{messages.label_type}</label>
        <div className='control'>
          <CustomSelect 
            options={options} 
            placeholder={messages.placeholder_type }
            value={selectedFilterTypeOption}
            onChange={handleFilterTypeSelection}
          />
        </div>
      </div>
      
      {
        isNestedTermFilter ? (
          // if the selected filter is nested term then handle custom input based on attributes nested term accept
          selectedFilterTypeOption.attributes.map( attribute => (
              <div className='control-container'>
                <label>{attribute}</label>
                <div className='control'>
                  <CustomSelect 
                    options={suggestionOptions[attribute] || []} 
                    placeholder={"Select Value"}
                    isDisabled={ suggestionOptions[attribute] === undefined || suggestionOptions[attribute] === null}
                    value={ selectedFilterValueOption && (selectedFilterValueOption[attribute] || null)}
                    onChange={ ({value}) => handleNestedFilterTermValueSelection({attribute,value})}
                    freeText={allowFreeTextInFilterValue}
                    onFreeTextSelection={handleCustomTextFilterValueSelection}
                    freeFormTextCreateLabel={messages.free_form_text_create_label}
                    isLoading={suggestionOptions[attribute] === undefined || suggestionOptions[attribute] === null}
                  />
                </div>
              </div>
            ))
        ) : (
        // if selected filter is not nested term
        <div className='control-container'>
          <label>{isNestedKeywordFilter ? messages.property_label : messages.label_value}</label>
          <div className='control'>
            <CustomSelect 
              options={suggestionOptions} 
              placeholder={allowFreeTextInFilterValue ? messages.free_form_text_placeholder_value : ( isNestedKeywordFilter ? messages.nested_placeholder_type :  messages.placeholder_value)}
              isDisabled={suggestionOptions.length === 0 && !allowFreeTextInFilterValue}
              value={selectedFilterValueOption}
              onChange={handleFilterValueSelection}
              freeText={allowFreeTextInFilterValue}
              onFreeTextSelection={handleCustomTextFilterValueSelection}
              freeFormTextCreateLabel={messages.free_form_text_create_label}
              isLoading={suggestionOptions.length === 0 && !allowFreeTextInFilterValue}
            />
          </div>
        </div>)
      }
      { error ? <div className="error-message"> {messages[error] || error} </div> : null }

      <div className='control-container actions d-flex'>
        <LinkButton id='cancel-btn' onClick={() => resetFilterInterface()}>{messages.button_cancel}</LinkButton>
        <PrimaryLineButton id='apply-btn' onClick={validFilterSelection}>{messages.button_apply}</PrimaryLineButton>
      </div>
    </div>
  )
}

export default AddFilterInterface