import React, { useContext } from "react";
import "./SettingsChangeHistory.scss";
import WidgetBox from "../../../Widgets/WidgetBox/WidgetBox";
import InfoMessageWrapper from "../../../Widgets/InfoMessageWrapper/InfoMessageWrapper";
import CommandStatusInfo from "./CommandStatusInfo/CommandStatusInfo";
import { isArray } from "lodash";
import AdvancedInsightsContext from "../../../context/insights-context";

function SettingsChangeHistory({ changeLog }) {
  const { messages } = useContext(AdvancedInsightsContext);
  const widgetMessages = messages.node_settings.settings_change_history_widget;
  return (
    <WidgetBox heading={widgetMessages.title}>
      <div className="settings-change-history-wrapper">
        <InfoMessageWrapper type="info">
          {!changeLog || !changeLog.length
            ? widgetMessages.no_history_message
            : widgetMessages.info_message}
        </InfoMessageWrapper>

        {isArray(changeLog) && changeLog.length ? (
          <div className="settings-change-list">
            {changeLog.map((command) => (
              <CommandStatusInfo data={command} key={command.id} />
            ))}
          </div>
        ) : null}
      </div>
    </WidgetBox>
  );
}

export default SettingsChangeHistory;
