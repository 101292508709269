import React from 'react';
import './PendingInvitations.scss';
import WidgetTabs from '../../../Widgets/WidgetTabs/WidgetTabs';
import ForUser from './ForUser/ForUser';
import ByUser from './ByUser/ByUser';

function PendingInvitations() {
    const tabsData = [
        {
            id: "for_you",
            title: "For you",
            component: ForUser
        },
        {
            id: "by_you",
            title: "By you",
            component: ByUser
        }
    ]
    return (
        <div className='pending-invitations-wrapper'>
            <WidgetTabs data={tabsData} />
        </div>
    )
}

export default PendingInvitations