import React from 'react';
import Modal from '../../../../../components/CustomModal/Modal';
import { ErrorLineButton } from '../../../../../components/Button/Button';
import InfoMessageWrapper from '../../../Widgets/InfoMessageWrapper/InfoMessageWrapper';

function ConfirmEndAccess({ showModal, setShowModal, onConfirm }) {
    return (
        <Modal showModal={showModal} setShowModal={setShowModal} title="End session?">
            <div className='m-3'>
                <p className='mt-3' style={{paddingBottom: "10px"}}>
                    Are you sure you want to end this session?
				</p>
            </div>
            
            <div className='m-3'>
                <InfoMessageWrapper type="info">
                    Once you end this session you will return to your account.<br />
                    You may return back later, by accessing via <b>Manage Multi Admins</b> settings.
                </InfoMessageWrapper>
            </div>

            <div className='modal--footer d-flex justify-content-end'>
				<ErrorLineButton
					onClick={onConfirm}
					id="delete-key"
				>
					End session
				</ErrorLineButton>
			</div>
        </Modal>
    )
}

export default ConfirmEndAccess