import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PrimaryWidgets from './PrimaryWidgets/PrimaryWidgets';
import SideWidgets from './SideWidgets/SideWidgets';
import './Widgets.scss';

export default function Widgets() {
  return (
    <div className='widgets-container'>
      <Container fluid>
        <Row>
          <Col xl={8} lg={7} md={12} sm={12} xs={12}>
            <PrimaryWidgets />
          </Col>
          <Col xl={4} lg={5} md={12} sm={12} xs={12}>
            <SideWidgets />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
