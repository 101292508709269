import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import LinkIconButton from '../../LinkIconButton/LinkIconButton';
import { UilCheck, UilTimes } from '@iconscout/react-unicons';
import './Actions.scss';

function Actions({
    acceptActionText,
    acceptActionHandler,
    rejectActionText,
    rejectActionHandler,
    acceptAction,
    acceptActionInfoMessage
}) {
    return (
        <div className={`actions-wrapper ${acceptAction !== null ? (acceptAction ? 'accepted' : 'rejected') : ''}`}>
            {
                acceptAction === null ? (
                    <ButtonGroup>
                        {
                            (acceptActionText && acceptActionHandler) && (
                                <LinkIconButton 
                                    iconComponent={<UilCheck />}
                                    text={acceptActionText}
                                    clickHandler={acceptActionHandler}
                                    buttonIntent='success'
                                    size='small'
                                    variant='with-light-border'
                                />
                            )
                        }
                        {
                            (rejectActionText && rejectActionHandler) && (
                                <LinkIconButton 
                                    iconComponent={<UilTimes />}
                                    text={rejectActionText}
                                    clickHandler={rejectActionHandler}
                                    buttonIntent='error'
                                    size='small'
                                    variant='with-light-border'
                                />
                            )
                        }
                    </ButtonGroup>
                ) : (
                        <div className='info-message'>
                            { acceptActionInfoMessage }
                        </div>
                    )
                }
        </div>
    )
}

export default Actions