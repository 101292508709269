import React,{ useState, useEffect, useContext} from 'react';
import './NodeMetrics.scss';
import DiagMetrics from "../../../Diagnostics/Metrics/DiagMetrics"
import AdvancedInsightsContext from '../../context/insights-context';
import WidgetWrapper from '../../Widgets/WidgetWrapper/WidgetWrapper';

function NodeMetrics() {
  const {
    startTimestamp,
    endTimestamp,
    selectedNodeMetricsStartTime,
    selectedNodeMetricsEndTime,
    selectedNodeInfoData,
} = useContext(AdvancedInsightsContext);

  return (
      <div>
        { 
          selectedNodeInfoData ?
          <DiagMetrics nodeId={selectedNodeInfoData.node_id} nodeKey={selectedNodeInfoData.node_key} startTimestamp={selectedNodeMetricsStartTime} endTimestamp={selectedNodeMetricsEndTime} />: 
          <WidgetWrapper loading={true} containerStyles={{padding: '0px', borderRadius: '10px'}} />
        }
      </div>
  )
}

export default NodeMetrics