import React, { createContext, useReducer } from 'react';
import { getNodes } from '../utils/api';
import { COMMON_API_ACTIONS as ACTIONS } from '../utils/constants';

export const nodesContext = createContext();

const nodeReducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.CALL_API:
			return {
				...state,
				loading: true,
				error: '',
			};
		case ACTIONS.RESOLVE: {
			let newState;
			if (action.searchedNode) {
				newState = {
					...state,
					loading: false,
					error: '',
					searchedNode: action.searchedNode,
				};
			} else {
				newState = {
					...state,
					loading: false,
					error: '',
					response: action.response,
					allNodes: action.allNodes,
					totalPages: action.totalPages,
					searchedNode: null,
				};
			}
			return newState;
		}
		case ACTIONS.REJECT:
			return {
				...state,
				loading: false,
				error: action.error,
			};
		case ACTIONS.RESET_SEARCH:
			return {
				...state,
				searchedNode: null,
				error: '',
			};
		case ACTIONS.NEXT:
			return {
				...state,
				allNodes: [],
				prevKey: state.prevKey.add(state.response.next_id),
				pageNo: state.pageNo + 1,
				nextKey: state.response.next_id,
			};
		case ACTIONS.PREV: {
			let newPrevKey = [...state.prevKey];
			newPrevKey.pop();
			return {
				...state,
				allNodes: [],
				prevKey: new Set(newPrevKey),
				pageNo: state.pageNo - 1,
				nextKey: [...state.prevKey][state.pageNo - 2],
			};
		}
		case ACTIONS.PAGE_SIZE: {
			return {
				...state,
				allNodes: [],
				nextKey: '',
				pageNo: 1,
				pageSize: action.pageSize,
			};
		}
		case ACTIONS.RESET:
			return initialNodeState;
		default:
			return state;
	}
};

const initialNodeState = {
	loading: false,
	error: '',
	response: {},
	allNodes: [],
	searchedNode: null,
	totalPages: 0,
	pageSize: 10,
	prevKey: new Set(['']),
	nextKey: '',
	pageNo: 1,
};

const NodesProvider = (props) => {
	const [nodeState, setNodeState] = useReducer(nodeReducer, initialNodeState);

	const handleGetNodes = async (nodeid, pagesize = null) => {
		setNodeState({ type: ACTIONS.CALL_API });
		try {
			let response = await getNodes(nodeid, pagesize);
			let data = response.data;
			let state;
			if (data.node_info) {
				state = {
					type: ACTIONS.RESOLVE,
					response: data,
					allNodes: data.node_info,
					searchedNode: null,
					contextPageSize: pagesize,
					totalPages: data.total
						? Math.ceil(data.total / pagesize)
						: 1,
				};
			} else {
				state = {
					type: ACTIONS.RESOLVE,
					response: data,
					searchedNode: [data],
				};
			}
			setNodeState(state);
		} catch (error) {
			let errorText;
			if (error.response) {
				errorText = error.response.data.description;
				if (error.response.status === 404 && !nodeid) {
					errorText = 'No nodes found. Please start by claiming.';
				}
			}
			if (error.response.status === 401) {
				errorText = 401;
			}
			setNodeState({
				type: ACTIONS.REJECT,
				error: errorText,
			});
		}
	};

	const handleReset = () => {
		setNodeState({
			type: ACTIONS.RESET_SEARCH,
		});
	};

	const handleNext = () => {
		setNodeState({
			type: ACTIONS.NEXT,
		});
	};

	const handlePrev = () => {
		setNodeState({
			type: ACTIONS.PREV,
		});
	};

	const handlePageSizeChange = (newPageSize) => {
		setNodeState({
			type: ACTIONS.PAGE_SIZE,
			pageSize: newPageSize,
		});
	};

	return (
		<nodesContext.Provider
			value={{
				nodeState: nodeState,
				getNodes: handleGetNodes,
				searchedNodeReset: handleReset,
				next: handleNext,
				prev: handlePrev,
				changePageSize: handlePageSizeChange,
			}}
		>
			{props.children}
		</nodesContext.Provider>
	);
};

export default NodesProvider;
