import React, { useReducer } from 'react';
import Modal from '../../../../../../../components/CustomModal/Modal';
import InfoMessageWrapper from '../../../../../Widgets/InfoMessageWrapper/InfoMessageWrapper';
import { createAccountInvite } from '../../../../../../../utils/diagQueryAPI';
import InviteUserForm from './InviteUserForm/InviteUserForm';
import InviteSent from './InviteSent/InviteSent';

function InviteNewUser({ showModal, setShowModal, refreshTenantsList }) {
    const componentInitialLocalState = {
        email: "",
        error: "",
        loading: false,
        success: false
    }

    const [componentLocalState, updateComponentLocalState] = useReducer((state, newState) => ({
        ...state, ...newState
    }), componentInitialLocalState);

    const handleInviteUser = async (formData) => {
        updateComponentLocalState({error: "", loading: true, email: formData.email});

        let inviteData = { 
            tenant_email_id: formData.email,
            message: formData.message
        };

        const inviteResponse = await createAccountInvite(inviteData);
        if (!inviteResponse?.status || (inviteResponse?.status !== 200) || (inviteResponse?.data?.status === "failure")) {
            updateComponentLocalState({
                error: inviteResponse?.data?.description || "Something went wrong!",
                loading: false
            });
            return;
        }

        updateComponentLocalState({
            error: "",
            loading: false,
            success: true
        });
    }

    const handleSuccessPopupClose = () => {
        updateComponentLocalState(componentInitialLocalState);
        setShowModal(false);
        refreshTenantsList();
    }

    const handleModalDisplay = () => {
        updateComponentLocalState(componentInitialLocalState);
        setShowModal(!showModal);
    }

    return (
        <Modal showModal={showModal} setShowModal={handleModalDisplay} title="Invite a new user to access your account">
            {
                componentLocalState.error && (
                    <div className='pl-3 pr-3 pt-3'>
                        <InfoMessageWrapper type="error">{componentLocalState.error}</InfoMessageWrapper>
                    </div>
                )
            }

            {
                componentLocalState.success ? (
                    <InviteSent 
                        email={componentLocalState.email}
                        onSubmit={handleSuccessPopupClose} 
                    />
                ) : (
                    <InviteUserForm 
                        loading={componentLocalState.loading}
                        onSubmit={handleInviteUser}
                    />
                )
            }
        </Modal>
    )
}

export default InviteNewUser