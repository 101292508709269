import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Checkbox from '../Checkbox/Checkbox';
import './MultiSelectDropdown.scss';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';

const MultiSelectDropdown = props => {
    const {
        placeholder = "Select",
        appendSelectionsToPlaceholder = true,
        options = [],
        onSelect = null,
        onDropdownClose = null,
        values = [],
        requiredValues = [],
        className = ""
    } = props;

    const [ currentSelections, setCurrentSelections ] = useState(values);
    const [ menuOpen, setMenuOpen ] = useState(false);
    const getLabelByValue = value => options.filter(option => option.value === value)[0]?.label || "";
    const selectionsText = currentSelections.reduce((prev, curr) => prev ? `${prev}, ${getLabelByValue(curr)}` : getLabelByValue(curr), "");

    const buttonPlaceholderText = selectionsText.length && appendSelectionsToPlaceholder ? `${placeholder} (${selectionsText})` : placeholder;
    const buttonIcon = menuOpen ? <UilAngleUp size={12} color={'#fff'} title='Close' /> : <UilAngleDown size={12} color={'#fff'} title='Open' />;

    const hanldeMenuSelection = event => {
        const { id, checked } = event.target;
        let updatedSelections = [];
        if (checked && !currentSelections.includes(id)) {
            updatedSelections = [...currentSelections, id];
        }
        if (!checked && currentSelections.includes(id)) {
            updatedSelections = currentSelections.filter(selection => selection !== id);
        }

        setCurrentSelections(updatedSelections);
        onSelect && onSelect(updatedSelections);
    }

    const handleDropdownToggle = (show, event, calledBy) => {
        if (!show && calledBy.source === "rootClose" && onDropdownClose) {
            onDropdownClose(currentSelections);
        }
        setMenuOpen(show);
    }

    useEffect(() => {
        if (values?.length) {
            setCurrentSelections(values);
        }
    }, [values])

    return (
        <Dropdown 
            className={`multi-select-dropdown ${className}`}
            onToggle={handleDropdownToggle}
        >
            <Dropdown.Toggle id="dropdown-autoclose-false">
                <span> {  buttonPlaceholderText } </span>
                { buttonIcon }
            </Dropdown.Toggle>
            <Dropdown.Menu align="right">
                {
                    options.map(option => {
                        return (
                            <div 
                                key={option.value}
                                className="menu-item"
                            >
                                <Checkbox 
                                    id={option.value}
                                    label={option.label}
                                    onClick={hanldeMenuSelection}
                                    checked={currentSelections.includes(option.value)}
                                    disabled={requiredValues.includes(option.value)}
                                />
                            </div>
                        )
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default MultiSelectDropdown;
