import React from "react";
import "./ConfigForm.scss";
import ConfigFormElement from "./ConfigFormElement/ConfigFormElement";

function ConfigForm(props) {
  const { label, value, data_type } = props.data;
  if (!props.id) return null;

  const handleUpdate = (e) => {
    let updatedValue = "";
    if (data_type === 0) {
      updatedValue = e.target.checked;
    } else if (data_type === 7) {
      updatedValue = "";
    } else {
      updatedValue = e;
    }

    props.onUpdate && props.onUpdate(updatedValue);
  };

  return (
    <div className="config-form-wrapper">
      <div className="form d-flex">
        <div className="element-wrapper">
          <ConfigFormElement
            id={props.id}
            dataType={data_type}
            label={label}
            value={value}
            onUpdate={handleUpdate}
          />
        </div>
      </div>
      <div className="config-info"></div>
    </div>
  );
}

export default ConfigForm;
