import React from 'react';
import './EventIcon.scss';
import { 
    UilAndroidPhoneSlash, 
    UilSyncExclamation, 
    UilProcess, 
    UilExclamationCircle, 
    UilExclamationTriangle 
} from '@iconscout/react-unicons';
import * as generalConfig from './../../../../general-config.json';

function EventIcon({type, size}) {
    if (!type) return null;

    const defaultSize = size || 21;
    const colorHexValue = (function(){
        const eventTypesData = generalConfig.event_types;
        const configIndex = generalConfig.event_types.findIndex(e => e.id === type);
        
        if (configIndex === -1) return "#5330b9";
        return eventTypesData[configIndex].color;
    })();

    const iconProps = {color: colorHexValue, size: defaultSize};
    
    if (type === "crash") {
        return <UilAndroidPhoneSlash {...iconProps} />
    }
    else if (type === "reboot") {
        return <UilSyncExclamation {...iconProps} />
    }
    else if (type === "event") {
        return <UilProcess {...iconProps} />
    }
    else if (type === "error") {
        return <UilExclamationCircle {...iconProps} />
    }
    else {
        return <UilExclamationTriangle {...iconProps} />
    }
}

export default EventIcon