import React, { useState, useEffect } from 'react';
import {
	BarChart,
	CartesianGrid,
	XAxis,
	Tooltip,
	Legend,
	Bar,
	ResponsiveContainer,
} from 'recharts';
import Loader from '../../../components/Loader/Loader';
import errorMessage from '../../../utils/errorMessages';
import IntervalSelectorTags from '../../AdvancedInsights/Widgets/IntervalSelectorTags/IntervalSelectorTags';

const NodeHistogram = (props) => {
	const { loading, graphData, getSelectedBar, time, intervalSelectorLabels, intervalSelectorClickHandler, defaultSelectedInterval, disabledIntervalsList } = props;
	const [cardLoading, setCardLoading] = useState(loading);

	useEffect(() => {
		if (graphData) {
			setCardLoading(false);
		}
	}, [graphData]);

	return (
		<div className='row'>
			<div className='col-lg-12'>
				<div className='card--container diagnostic--card align-items-center'>
					<div className='w-100'>
						<div className='d-flex mb-2'>
							<p className='card--title'>Node Timeline</p>
							<div className='position-absolute right-0 mr-3'>
								<IntervalSelectorTags 
									defaultSelectedInterval={defaultSelectedInterval}
									clickHandler={intervalSelectorClickHandler}
									labels={intervalSelectorLabels}
									disabledIntervalsList={disabledIntervalsList}
								/>
							</div>
						</div>
						{cardLoading ? (
							<Loader minHeight='300px' />
						) : graphData && graphData.length ? (
							<ResponsiveContainer width={'100%'} height={300}>
								<BarChart
									data={graphData}
								>
									<CartesianGrid
										strokeDasharray='2 2'
										vertical={false}
									/>
									<XAxis
										dataKey='timestamp'
										interval={0}
										angle={0}
										style={{ fontSize: '10px' }}
									/>
									{/* <YAxis /> */}
									<Tooltip />
									<Legend />

									<Bar
										dataKey='crash'
										fill='#EF5350'
										barSize={8}
										stackId='a'
										style={{
											cursor:
												time !== 'Day'
													? 'pointer'
													: 'default',
										}}
									/>
									<Bar
										dataKey='error'
										fill='#FF8A65'
										barSize={8}
										stackId='a'
										style={{
											cursor:
												time !== 'Day'
													? 'pointer'
													: 'default',
										}}
									/>
									<Bar
										dataKey='warning'
										fill='#FFB74D'
										barSize={8}
										stackId='a'
										style={{
											cursor:
												time !== 'Day'
													? 'pointer'
													: 'default',
										}}
									/>

									<Bar
										dataKey='reboot'
										fill='#82B1FF'
										barSize={8}
										stackId='a'
										style={{
											cursor:
												time !== 'Day'
													? 'pointer'
													: 'default',
										}}
									/>
									<Bar
										dataKey='event'
										fill='#81C784'
										barSize={8}
										stackId='a'
										style={{
											cursor:
												time !== 'Day'
													? 'pointer'
													: 'default',
										}}
									/>
								</BarChart>
							</ResponsiveContainer>
						) : (
							<div
								className='d-flex w-100 justify-content-center align-items-center'
								style={{ minHeight: '300px' }}
							>
								<p className='mb-0'>
									{errorMessage.DIAGNOSTIC.enableDiagnostic}
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NodeHistogram;
