import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import PieCharts from "./PieCharts";
import CountUp from 'react-countup';
import { UilAndroidPhoneSlash, UilSyncExclamation, UilProcess, UilExclamationOctagon, UilChartLine } from '@iconscout/react-unicons';
import Loader from '../../components/Loader/Loader';
import { Alert } from 'react-bootstrap';

const InsightsMetrics = (props) => {

	const {
		allEventsCount,
		loading,
	} = props


	const [nodesByVersion, setNodesByVersion] = useState();
	const [eventsCardLoading, setEventsCardLoading] = useState(loading);
	const [nodesCardLoading, setNodesCardLoading] = useState(loading);

	useEffect(() => {
		if (allEventsCount.length > 0) {
			setEventsCardLoading(false);
		}
	}, [allEventsCount]);


	useEffect(() => {
		if (props.nodesByVersion) {
			setNodesCardLoading(false);
			getNodesByVersion(props.nodesByVersion);
		}
	}, [props.nodesByVersion])

	function getNodesByVersion(nodesByVersion) {
		nodesByVersion.distribution = nodesByVersion.distribution.map((item) => ({ "name": item.version, "count": item.count }))
		setNodesByVersion(nodesByVersion)
	}

	function totalCount(data) {
		let count = 0;
		data.forEach(item => {
			count += item.count;
		})
		return count;
	}

	function getEventIcon(event) {
		if (event === "crash") {
			return <div
				className='diagnostic--icon--container'
				style={{
					background: `#EF535033`,
				}}
			>
				<UilAndroidPhoneSlash size='16' color='#EF5350' />
			</div>
		}
		else if (event === "reboot") {
			return <div
				className='diagnostic--icon--container'
				style={{
					background: `#82B1FF33`,
				}}
			>
				<UilSyncExclamation size='16' color='#82B1FF' />
			</div>
		}
		else if (event === "event") {
			return <div
				className='diagnostic--icon--container'
				style={{
					background: `#81C78433`,
				}}
			>
				<UilProcess size='16' color='#81C784' />
			</div>
		}
		else if (event === "ota_upgrade") {
			return <div
				className='diagnostic--icon--container'
				style={{
					background: `#82B1FF33`,
				}}
			>
				<UilChartLine size='16' color='#81C784' />
			</div>
		}
		else {
			return <div
				className='diagnostic--icon--container'
				style={{
					background: `#FF8A6533`,
				}}
			>
				<UilExclamationOctagon size='16' color='#FF8A65' />
			</div>
		}
	}

	return (
		<>
			<div className=' d-flex flex-row '>
				<Card style={{ width: '21rem' }}>
					{nodesCardLoading ? (
						<Loader minHeight='300px' />
					) : (<Card.Body className="p-2">
						<div className="d-flex flex-row justify-content-center align-items-center">
							{nodesByVersion && <PieCharts data={nodesByVersion.distribution} legend={true} text='Nodes by version' />}
						</div>
					</Card.Body>)}
				</Card>
				<Card className="ml-3">
					{eventsCardLoading ? (
						<Loader minHeight='300px' minWidth='750px' />
					) : (<Card.Body className="d-flex  align-content-center p-2 ">
						<div className='d-flex flex-column'>
							<div className="d-flex flex-wrap align-content-center ml-4 justify-content-start  py-4">
								{allEventsCount && allEventsCount.map((item) => {
									return <div key={item.event_types} className='card--container diagnostic--card align-items-center mx-1'>
										<div className='d-flex align-items-center '>
											<div
												className='diagnostic--icon--container'
												style={{
													background: `#EF535033`,
												}}
											>
												{getEventIcon(item.event_type)}
											</div>
											<p className='card--title mx-1'>{item.event_type}</p>
											<CountUp
												end={item.count}
												duration={1}
												className='card--count ml-3 pl-3'
											/>
										</div>
									</div>
								})}
							</div>
								<Alert variant="light" className='text-align-right text-small mb-0'>
									These statistics represent data across all nodes for <span className='highlight'>{props.project}</span>
								</Alert>
							</div>
					</Card.Body>)}
				</Card>
			</div>
		</>
	)
}

export default InsightsMetrics;