import React, { useEffect, useState, useContext, useRef } from 'react';
import { getParamsMetadata, getParamsdata } from '../../../utils/diagAPI';
import { findChildrenByType } from '../../../utils/findChildrenByType';
import Accordion from 'react-bootstrap/Accordion';
import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import Card from 'react-bootstrap/Card';
import { cloneDeep, find, findIndex, sortBy } from 'lodash';
import moment from 'moment';
import { getUrlParameterByName } from '../../../utils/commonFunctions';

const ParamValues = (props) => {
	const [locationState, setLocationState] = useState(() => props.location.state);
	const nodeId = props.nodeId || props.match.params.node_id;
	const nodeKey = locationState && locationState.nodeKey ? locationState.nodeKey : getUrlParameterByName("key") || null;

	// API Responses
	const [metaData, setMetaData] = useState(null);
	const [paramValuesList, setParamValuesList] = useState([]);

	// Loaders
	const [loading, setLoading] = useState(true);
	const initExpandedData = localStorage.getItem('expandedData')
		? JSON.parse(localStorage.getItem('expandedData'))
		: [];
	const expandedData = useRef(initExpandedData);

	useEffect(() => {
		//passing props to parent
		if (!props.nodeId) {
			props.setNodeId(nodeId);
		}
		if(!props.nodeKey){
			props.setNodeKey(nodeKey)
		}
	}, []);

	useEffect(() => {
		if (!metaData) {
			setLoading(true);
			init();
		}
		return syncExpandedStateWithLocalStorage;
	}, []);

	const syncExpandedStateWithLocalStorage = () => {
		localStorage.setItem(
			'expandedData',
			JSON.stringify(expandedData.current)
		);
	};

	const init = async () => {
		let response = await getParamsMetadata({node_id: nodeId, node_key: nodeKey});
		if (response.description === 'Unauthorized') {
			props.sessionExpire();
			return;
		}
		if (response.status === 'failure') {
			setLoading(false);
			return;
		}
		const metaWithId = setIdsForFolder(response);
		setMetaData(sortBy(metaWithId, 'label'));

		/**
		 * recursively get all valid param values from the api response
		 * valid param values would have
		 * storageType = 'ParamValue'
		 *
		 * final paramvalues are stored in
		 *
		 * allParamValuesMeta object
		 */

		const allParamValuesMeta = findChildrenByType(
			response,
			'storageType',
			'ParamValue'
		);
		getActualParamValues(allParamValuesMeta);
		setLoading(false);
	};

	const setIdsForFolder = (paramsMetaData) => {
		function setId(nodes, tag) {
			nodes.forEach((node) => {
				if (node.children) {
					node.children = sortBy(node.children, 'label');
					node.tag = `${tag}#${node.label}`;
					return setId(node.children, node.tag);
				} else {
					return;
				}
			});
		}
		setId(paramsMetaData, '');
		return cloneDeep(paramsMetaData);
	};

	async function getActualParamValues(allParamValues) {
		const startTime = 1612637655;
		const endTime = 2255669404;
		const paramValuesIds = allParamValues.map((pv) => pv.id);

		if (paramValuesIds.length > 0) {
			let responses = await getParamsdata(
				{node_id: nodeId, node_key: nodeKey},
				paramValuesIds,
				endTime,
				startTime
			);

			setParamValuesList(responses);
		}
	}

	return (
		<>
			<hr className='mx-3 my-0' />
			<div className='diagnostic--page--wrapper metrics'>
				<div className='row'>
					<div className='col-lg-12'>
						<div className='card--container diagnostic--card flex-column overflow-hidden'>
							<div className='d-flex mb-3'>
								<p className='card--title'>Variables</p>
							</div>
							{loading ? (
								<p>Loading please wait...</p>
							) : (
								<div className='d-flex flex-column'>
									{metaData &&
										metaData.map((data) => (
											<div key={data.label}>
												{/*
                        As in the paramValuesList we have all the objects which have storageType='ParamValue',
                        thats why we are passing it to NestedList component in order to compare the ids
                        and show their respective values.
                    */}
												<NestedList
													nestedData={data}
													list={paramValuesList}
													expandedData={expandedData}
												/>
											</div>
										))}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

function NestedList(props) {
	const { nestedData, list } = props;
	const expandedData = props.expandedData;

	function handleDefaultActiveKey() {
		let activeKey;
		if (expandedData.current.length > 0) {
			expandedData.current.forEach((item) => {
				if (item.tag === nestedData.tag) {
					if (item.expanded) {
						activeKey = nestedData.tag;
					} else {
						activeKey = '1';
					}
				}
			});
		} else {
			activeKey = '1';
		}
		return activeKey;
	}

	function handleOnSelect(tag, e) {
		const tagExpandedState = { expanded: !!e, tag };
		const i = findIndex(expandedData.current, { tag });
		if (i > -1) {
			expandedData.current.splice(i, 1, tagExpandedState);
		} else {
			expandedData.current.push(tagExpandedState);
		}
	}

	return (
		<div className='nestedlist-wrapper'>
			<div className='nestedlist-ul'>
				{nestedData.type === 'folder' &&
				nestedData.children.length > 0 &&
				findChildrenByType(
					nestedData.children,
					'storageType',
					'ParamValue'
				).length > 0 ? (
					<Accordion
						defaultActiveKey={handleDefaultActiveKey}
						onSelect={(e) => {
							handleOnSelect(nestedData.tag, e);
						}}
					>
						<Card>
							<Card.Header className='cursor-pointer'>
								<ContextAwareToggle eventKey={nestedData.tag}>
									{nestedData.label}
								</ContextAwareToggle>
							</Card.Header>
							<Accordion.Collapse eventKey={nestedData.tag}>
								<Card.Body>
									{nestedData.children.map((child) => {
										return (
											<div key={child.label}>
												<NestedList
													nestedData={child}
													list={list}
													expandedData={expandedData}
												/>
											</div>
										);
									})}
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>
				) : (
					nestedData.storageType === 'ParamValue' && (
						<div>
							<div className='row py-2 px-4'>
								<div className='col-sm-4 font-weight-bold fs-3'>
									{nestedData.label} :
								</div>
								<div className='col-sm-4 fs-3'>
									{getParamDataValue(nestedData.id, list)}
								</div>
								<div className='col-sm-4 fs-3'>
									{getParamDataValueLastUpdated(
										nestedData.id,
										list
									)}
								</div>
							</div>
						</div>
					)
				)}
			</div>
		</div>
	);
}

function ContextAwareToggle({ children, eventKey, callback }) {
	const currentEventKey = useContext(AccordionContext);

	const decoratedOnClick = useAccordionToggle(
		eventKey,
		() => callback && callback(eventKey)
	);

	const isCurrentEventKey = currentEventKey === eventKey;

	return (
		<div
			onClick={decoratedOnClick}
			className='d-flex justify-content-between'
		>
			{children}
			{isCurrentEventKey ? (
				<span className='icon-container ml-0'>
					<UilAngleUp size='20' color='#5330b9' />
				</span>
			) : (
				<span className='icon-container ml-0'>
					<UilAngleDown size='20' color='#5330b9' />
				</span>
			)}
		</div>
	);
}

function getParamDataValue(id, list) {
	if (list.length > 0) {
		const value = list.map((paramValueData) => {
			if (paramValueData.id == id) {
				if (paramValueData.data[0] && paramValueData.data[0].val) {
					return paramValueData.data[0].val.toString();
				} else {
					return ' -';
				}
			}
		});
		return value;
	}
}

const getLastUpdated = (paramValueData) => {
	const parsedTime = moment(paramValueData.data[0].ts / 1000)
	let formattedTime = parsedTime.format('HH:mm') 
	let formattedDate = parsedTime.format('DD MMM YYYY')
	if(parsedTime.isSame(moment(),'day')) {
		formattedDate = 'Today'
	} else if (parsedTime.subtract(1,'day').isSame(moment('day'))) {
		formattedDate = 'Yesterday'
	} else if(parsedTime.isSame(moment(),'year')) {
		formattedDate = parsedTime.format('DD MMM')
	}
	return (
		<div>
			<span className="text-muted mr-1">Last updated</span>
			<span>{formattedDate}</span>
			<span className="text-muted mx-1">at</span>
			<span>{formattedTime}</span>
		</div>
	)
}

function getParamDataValueLastUpdated(id, list) {
	if (list.length > 0) {
		const value = list.map((paramValueData) => {
			if (paramValueData.id == id) {
				if (paramValueData.data[0] && paramValueData.data[0].ts) {
					return getLastUpdated(paramValueData)
				} else {
					return ' -';
				}
			}
		});
		return value;
	}
}

export default ParamValues;
