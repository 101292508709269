import React, {useContext} from 'react';
import WidgetWrapper from '../../../Widgets/WidgetWrapper/WidgetWrapper';
import './PrimaryWidgets.scss';
import AdvancedInsightsContext from '../../../context/insights-context';
import NodeLogs from './NodeLogs/NodeLogs';
import NodeTimeline from './NodeTimeline/NodeTimeline';

function PrimaryWidgets() {
    const { 
      selectedNodeTimelineDistributionData,
      selectedNodeLogsData
      } = useContext(AdvancedInsightsContext);

    return (
        <div className='primary-widgets'>
          <WidgetWrapper 
            loading={selectedNodeTimelineDistributionData === null}
            containerStyles={{padding: '0px', borderRadius: '10px'}}
          >
            <NodeTimeline />
          </WidgetWrapper>
          
          <WidgetWrapper 
            loading={selectedNodeLogsData === null}
            containerStyles={{padding: '0px', borderRadius: '10px'}}
          >
            <NodeLogs />
          </WidgetWrapper>
        </div>
    )
}

export default PrimaryWidgets