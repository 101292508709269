import React, { useContext } from "react";
import WidgetBox from "../../../Widgets/WidgetBox/WidgetBox";
import InfoMessageWrapper from "../../../Widgets/InfoMessageWrapper/InfoMessageWrapper";
import "./TimeoutConfiguration.scss";
import TextFieldWithIconButton from "../../../../../components/TextFieldWithIconButton/TextFieldWithIconButton";
import AdvancedInsightsContext from "../../../context/insights-context";

function TimeoutConfiguration({ defaultTimeout, onUpdate }) {
  const { messages } = useContext(AdvancedInsightsContext);
  const widgetMessages = messages.node_settings.timeout_configuration_widget;

  return (
    <WidgetBox heading={`Configure Timeout (${defaultTimeout} minutes)`}>
      <div className="timeout-configuration-wrapper">
        <InfoMessageWrapper type="info">
          {widgetMessages.info_message}
        </InfoMessageWrapper>
        <div className="config-form">
          <label>{widgetMessages.timeout_field_label}</label>
          <TextFieldWithIconButton
            id="command-timeout"
            value={defaultTimeout}
            type="number"
            onFormSubmit={onUpdate}
          />
        </div>
      </div>
    </WidgetBox>
  );
}

export default TimeoutConfiguration;
