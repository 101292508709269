import React, { useState, useEffect, useMemo} from 'react';
import { RefreshButton, SuccessButton } from '../../components/Button/Button';
import AddAuthKeyModal from '../../components/CustomModal/AddAuthKeyModal';
import DeleteAuthKeyModal from '../../components/CustomModal/DeleteAuthKeyModal';
import CustomToast from '../../components/CustomToast/CustomToast';
import Table from '../../components/Table/Table';
import { 
    listAuthKeys, 
    createAuthKey, 
    deleteAuthKeyByID,
    downloadAuthKeyByID 
} from '../../utils/manageAuthKeysAPI';
import KeyActions from './KeyActions/KeyActions';
import './ManageAuthKeys.scss';
import Pagination from './Pagination/Pagination';
import ToggleKeyTokenDisplay from './ToggleKeyTokenDisplay/ToggleKeyTokenDisplay';

function ManageAuthKeys(props) {

    const [ authKeysData, setAuthKeysData ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ limit, setLimit ] = useState(10);
    const [ showAddAuthKeyModal, setShowAddAuthKeyModal ] = useState(false);
    const [ showDeleteAuthKeyModal, setShowDeleteAuthKeyModal ] = useState(false);
    const [ selectedAuthKeyId, setSelectedAuthKeyId ] = useState(null);
    const [ pagetoken, setPagetoken ] = useState("");
    const [ prevPagetoken, setPrevPagetoken ] = useState("");
    const [ nextPagesTokens, setNextPagesTokens ] = useState([""]);
    const pageSize = 10;

    const getAllKeys = () => {
        setLoading(true);
        listAuthKeys(limit, pagetoken).then(({data = [], pagetoken = ""}) => {
            setLoading(false);
            setAuthKeysData(data);

            setPrevPagetoken(pagetoken);
        });
    }
    
    const createNewKey = (name, description) => {
        const data = {name: name};
        if (description) data.description = description;

        createAuthKey(data).then(data => {
            setShowAddAuthKeyModal(false);
            if (data.status && data.status === "SUCCESS") {
                getAllKeys();
            }
            else {
                if (data.msg) {
                    CustomToast.error(data.msg, { autoClose: 10000 },'add-key-error');
                }
            }
        });
    }

    const warnBeforeDelete = id => {
        setSelectedAuthKeyId(id);
        setShowDeleteAuthKeyModal(true);
    }

    const deleteAuthKey = () => {
        if (selectedAuthKeyId) {
            deleteAuthKeyByID(selectedAuthKeyId).then(data => {
                if (data === "Deleted") {
                    setSelectedAuthKeyId(null);
                    setShowDeleteAuthKeyModal(false);
                    getAllKeys();
                }
            });
        }
    }

    const downloadAuthKey = (id, name) => {
        downloadAuthKeyByID(id, name).then(data => {
        });
    }

    const handlePrevious = () => {
        setPagetoken(prevPagetoken);
        setNextPagesTokens(currentTokens => [...currentTokens, prevPagetoken]);
    }

    const handleNext = () => {
        const newTokensList = [...nextPagesTokens];
        let newPagetoken = newTokensList[newTokensList.length - 2];
        newTokensList.pop();
        setNextPagesTokens(newTokensList);
        setPagetoken(newPagetoken);
    }

    const handleLimitChange = limit => {
        setLimit(limit);
        setPagetoken("");
        setNextPagesTokens([""]);
    }

    useEffect(function(){
        getAllKeys();
    }, [pagetoken, limit]);

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                style: {
                    width: "15%"
                }
            },
            {
                Header: 'Description',
                accessor: 'description',
                style: {
                    width: "25%"
                }
            },
            {
                Header: "Key Token",
                accessor: row => <ToggleKeyTokenDisplay data={row} />,
                style: {
                    width: "40%"
                }
            },
            {
                Header: "Actions",
                accessor: row => <KeyActions data={row} onDownload={downloadAuthKey} onDelete={warnBeforeDelete} />,
                style: {
                    width: "20%",
                    textAlign: "right"
                }
            }
        ], []
    );

    return (
        <div className="manage-auth-keys">
            <div className='app--navbar'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
						<div className='d-flex'>
							<p className='main--heading'>Manage Auth Keys</p>
                            <RefreshButton
								loading={loading}
								onClick={() => getAllKeys('', pageSize)}
							/>
						</div>
					</div>
                    <div className='d-flex align-items-center'>
                        <SuccessButton
							title='Generate New Auth Key'
							id='create-new-key'
                            onClick={() => setShowAddAuthKeyModal(true)}
						>
							Generate New Auth Key
						</SuccessButton>
                    </div>
                </div>
            </div>

			<hr className='mx-3 my-0' />

            <div className='home-card card--container d-flex flex-column justify-content-between'>
                <div className='row'>
                    <div className='col-lg-12 h-100'>
                        <Table 
                            data={authKeysData}
                            columns={columns}
                            loading={loading}
                            loaders={limit}
                        />
                    </div>
                </div>
            </div>

            <Pagination 
                onPrev={prevPagetoken ? handlePrevious : null}
                onNext={nextPagesTokens.length > 1 ? handleNext : null}
                pageSize={limit}
                minPageSize={10}
                maxPageSize={25}
                showPageSizeControl={true}
                onPageSizeChange={handleLimitChange}
            />
            
            <AddAuthKeyModal 
                showModal={showAddAuthKeyModal} 
                setShowModal={setShowAddAuthKeyModal}
                onCreate={createNewKey} 
            />

            <DeleteAuthKeyModal 
                showModal={showDeleteAuthKeyModal} 
                setShowModal={setShowDeleteAuthKeyModal}
                authKeyData={authKeysData.filter(k => k.id === selectedAuthKeyId)[0]}
                onConfirm={deleteAuthKey} 
            />
        </div>
    )
}

export default ManageAuthKeys;