import React from 'react';
import { Navbar } from 'react-bootstrap';
import './WidgetStickyToolbar.scss';

function WidgetStickyToolbar(props) {
    return (
        <Navbar className='widget-sticky-toolbar' sticky="top">
            <div className='main-content' style={props.containerStyles || null}>
                {props.children}
            </div>
            
            <div className='loader'>
                { props.loading ? <div className="bar"></div> : null }
            </div>
        </Navbar>
    )
}

export default WidgetStickyToolbar