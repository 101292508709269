import React from "react";
import { debounce } from "lodash";
import { UilMessage } from "@iconscout/react-unicons";
import "./TextFieldWithIconButton.scss";

const TextFieldWithIconButton = (props) => {
  const { onFormSubmit, inputPlaceholder, id, onChange, type, value } = props;
  const handleInputChange = (e) => {
    let value = e.target.value.trim();
    if (type === "number") {
      value = e.target.valueAsNumber;
    }
    onChange && onChange(value, e);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (id && onFormSubmit) {
      let value = e.target.elements[id].value.trim();
      if (type === "number") {
        value = e.target.elements[id].valueAsNumber;
      }
      onFormSubmit(value, e);
    }
  };
  return (
    <form onSubmit={handleFormSubmit}>
      <div className="text-field-with-icon-button-input-container">
        <div className="text-input">
          <input
            type={type || "text"}
            placeholder={inputPlaceholder}
            name={id}
            defaultValue={value}
            onChange={debounce(handleInputChange, 500)}
          />
        </div>
        <button className="btn primary-button" type="submit">
          <UilMessage size={16} color="#fff" />
        </button>
      </div>
    </form>
  );
};

export default TextFieldWithIconButton;
