import React, { useEffect, useState, useRef} from "react";
import { TimeSeries, TimeRange } from "pondjs";
import {
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  LineChart,
  MultiBrush,
  Resizable,
  Brush,
  Baseline,
  ScatterChart,
  EventMarker,
  Legend,
  styler,
} from "react-timeseries-charts";
import _ from "lodash";
import { GRAPH_COLORS } from '../../../utils/constants';
import moment from "moment";
import { IconButton } from "../../../components/Button/Button";
import { UilQuestionCircle, UilFilter, UilSearchPlus, UilEye, UilAngleUp, UilAngleDown } from "@iconscout/react-unicons";
import FilterPanel from "./FilterPanel";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip"
import { Switch } from "react-router-dom";

const TimeSeriesGraph = (props) => {
  const { metaData, data, interval, logData, setChartsTimeRange, chartTimeRange } = props;
  const [metricOptions, setMetricOptions] = useState(null)
  const multiBrushRef = useRef()

  const tagColorsBack = {
    error: "#FF8A65",
    warning: "#FFB74D",
    event: "#81C784",
    crash: "#EF5350",
    reboot: "#82B1FF",
  };

  const tagColors = {
    error: "#c52f13",
    warning: "#986403",
    event: "#07780c",
    crash: "#c30a06",
    reboot: "#1c65de",
  };

  const [time_interval, setTime_interval] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeSeries, setTimeSeries] = useState(null);
  const [timeRange, setTimeRange] = useState(null);
  const [chartsVisibility, setchartsVisibility] = useState(null);
  const [tracker, setTracker] = useState(null);
  const [legend, setLegend] = useState(null);
  const [logSelections, setLogSelections] = useState(null);
  const [selectedLog, setselectedLog] = useState(null);
  const [filterListVisible, setFilterListVisible] = useState(false);
  const [zoomFlag, setZoomFlag] = useState(false);
  const [logFlag, setlogFlag] = useState(true);
  const [minimisedState, setMinimisedState] = useState(false);
  

  const _calculateTimeRange = (interval) => {
    if (interval) {
      const beginTime = moment.unix(interval.start).utc();
      const endTime = moment.unix(interval.end).utc();
      const _timeRange = new TimeRange([beginTime, endTime]);
      setTime_interval(_timeRange);
    }
  };

  const _convertToSeries = (data, metricOptions) => {
    const timelineSeries = {};
    metricOptions.forEach((metric) => {
      const sortedDataPoints = _.sortBy(data[metric.id], (o) => o.ts);
      const dataPoints = sortedDataPoints.map((dataPoint) => {
        const event = Object.values(dataPoint);
        return [event[0] / 1000, event[1]];
      });

      const meticUnit = metric?.unit ? metric.unit : "units";

      const series = new TimeSeries({
        name: metric.label,
        columns: ["time", "value"],
        points: dataPoints,
        unit: meticUnit
      });

      timelineSeries[metric.id] = series;
    });
    setTimeSeries(timelineSeries);
  };
        
  const _convertLogDataToSelections = (logData) => {
    const selections = logData.map((log) => {
      const beginTime = moment.unix(log.time).utc();
      const endTime = moment.unix(log.time + 10).utc();
      const timeRange = new TimeRange(beginTime, endTime);
      return {
        ...log,
        selection: timeRange,
      };
    });
    setLogSelections(selections);
  };

  const _createLegends = (metricOptions) => {
    const legendConfig = metricOptions.map((metric) => ({
      key: metric.id,
      label: metric.label,
      disabled: false,
    }));
    setLegend(legendConfig);
  };

  const _configureChartVisibility = (metricOptions) => {
    const _chartsVisibility = {};
    metricOptions.forEach((metric) => {
      _chartsVisibility[`${metric.id}`] = true;
    });
    setchartsVisibility(_chartsVisibility);
  };

  function getMetricTypes(data) {
    let alltypes = [];
  
    function getTypes(data) {
      let metricType = data.map((dataItem) => {
        if (dataItem.type === 'folder' && dataItem.children.length > 0) {
          getTypes(dataItem.children);
        }
        if (dataItem.type === 'file' && dataItem.storageType === 'Metric') {
          return dataItem;
        }
      });
      let sortMetricType = metricType.filter((i) => i !== undefined);
      if (sortMetricType.length > 0) {
        alltypes.push(...sortMetricType);
      }
    }
    getTypes(data);
  
    let allMetrics = alltypes.map((type, index) => {
      return {
        id: type.id,
        label: type.label,
        color: GRAPH_COLORS[index],
        threshold: type.threshold || null,
        unit: type.unit || ""
      };
    });
    return allMetrics;
   
  }

  useEffect(() => {
    const metricOptions = getMetricTypes(metaData)
    if(metricOptions)
    {  
      setMetricOptions(metricOptions); 
      _configureChartVisibility(metricOptions);
    }
  }, [])

  useEffect(() => {
    if (interval != null && Object.keys(data).length > 0 && metricOptions) {
      setLoading(true);
      _calculateTimeRange(interval);
      _convertLogDataToSelections(logData);
      _convertToSeries(data, metricOptions);
      _createLegends(metricOptions);
      setLoading(false);
      setselectedLog(null)
    }
  }, [data, metricOptions, logData]);

  useEffect(() => {
    setTimeRange(()=>chartTimeRange)
  }, [chartTimeRange])
  
  const legendStyle = (id) =>{
    
    const stroke = chartsVisibility[id] ? data[id + "-color"] : null;

    let styleSymbol = {
      opacity: 0.9,
      fill: stroke,
      strokeWidth: 5,
      cursor: "pointer",
    };

    const labelStyle = {
      fontSize: "15px",
      color: "#333",
      paddingRight: 15,
      cursor: "pointer",
    };
    return {
      symbol: {
        normal: { ...styleSymbol, opacity: 0.7 },
        highlighted: { ...styleSymbol, opacity: 0.8 },
        selected: { ...styleSymbol, opacity: 0.8 },
        muted: { ...styleSymbol, opacity: 0.2 },
      },
      label: {
        normal: { ...labelStyle, opacity: 0.7 },
        highlighted: { ...labelStyle, opacity: 0.8 },
        selected: { ...labelStyle, opacity: 0.8 },
        muted: { ...labelStyle, opacity: 0.5 },
      },
    };
  }

  const LogMoodle = (props) => {
    const { log } = props;
    return (
      <div className="d-flex node--logs border p-2">
        <div className="logtime flex">
          <p className="log--event--time">
            {moment.unix(log.time).format("DD MMM, HH:mm:ss")}
          </p>
        </div>
        <div className="tag flex">
          <p
            className="log--type--tag mb-0 ml-2"
            style={{
              background: `${tagColorsBack[log.type]}33`,
              color: tagColors[log.type],
            }}
          >
            {log.type || ""}
          </p>
        </div>
        <p className="log--event--title">{log.message || ""}</p>
      </div>
    );
  };

  return (
    <div style={{ background: "White", padding: "15px" , marginBottom : "20px"}}>
      {!loading && (
        <>
          
          <div className='card-heading'>
            <div className='title text-center flex-grow-1'>
                <span className='primary'>{metaData[0].label}</span>
            </div>
          </div> 

          <div className="d-flex justify-content-between align-items-center"> 
            {/* Logs legend */}
            { logSelections?.length > 0 && 
              <div className="tooltip-container border-right mr-2">
                <span className="tooltiptext"> Click vertical bars in chart to view event cause </span>
                <div className="d-flex">
                  <div className="log-legend" style={{ borderColor:"#EF5350"}}> Crashes</div>
                  <div className="log-legend" style={{ borderColor:"#82B1FF"}}> Reboots</div>
                  
                  {/* Log hide/show button */}
                  <IconButton
                    data-tip="Zoom Chart"
                    data-delay-hide="100"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    data-html="true"
                    // based on logFlag the vertical bars in chart will be rendered
                    onClick={() => setlogFlag((visible) => !visible)}
                  >
                    <span className={` icon-container ${logFlag ? "color-primary" : "color-inactive" } `}>
                      <UilEye size="16" />
                    </span>
                  </IconButton>
                </div>
              </div>
            }
            
            {/* charts legend */}
            <div className="flex-grow-1 tooltip-container">  
              <span className="tooltiptext"> Click to toggle chart visibility </span>
              <Legend
                type={"dot"}
                style={legendStyle}
                categories={legend}
                onSelectionChange={(id) => {
                  setchartsVisibility((prevState) => {
                    const _chartsVisibility = {
                      ...prevState,
                    };
                    _chartsVisibility[id] = !_chartsVisibility[id];
                    return _chartsVisibility;
                  });
                }}
                marginBottom={"0px"}
              />
            </div>

            {/* filter Button */}
            {metaData.length > 0 && (
              <IconButton
                onClick={() => setFilterListVisible((visible) => !visible)}
                className="primary-icon-btn"
              >
                <UilFilter size={16} className="mr-2" />
                Filters
              </IconButton>
            )}
            <CustomTooltip  id="view-metric-zoom-tooltip" title={`${zoomFlag ? "Disable zoom" : "Enable zoom"}`} > 
              {/* Zoom activate/deactivate button */}
              <IconButton
                data-tip="Zoom Chart"
                data-delay-hide="100"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-html="true"
                onClick={() => setZoomFlag((visible) => !visible)}
              >
                <span className={`mx-1 icon-container ${ zoomFlag ? "color-primary" : "color-inactive" } `}>
                  <UilSearchPlus size="16" />
                </span>
              </IconButton>
            </CustomTooltip>

            {/* Instruction model */}
            <div className="instructions--modal--container">
              <IconButton
                data-tip="Add Chart"
                data-delay-hide="1000"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-html="true"
              >
                <span className={`mx-0 icon-container color-primary`}>
                  <UilQuestionCircle size="16" />
                </span>
              </IconButton>

              {/* instructions contents */}
              <div className="modal--body">
                  <ul>
                    <li>Use the time slider at the top of the chart to adjust to a particular time span for the selected day.</li>
                    <li>User can zoom in / out and adjust time granularity.</li>
                    <li>Use the brush at the bottom of the chart to scroll to left / right within the time chart.</li>
                    <li>User can show / hide particular metrics by using the filter widget or clicking the metric name within the legend.</li>
                    <li>Note: The vertical bars, if appearing denote presence of an event that occurred and may have some significance on the metric values.</li>
                  </ul>
              </div>
            </div>

            <CustomTooltip  id="view-metric-mininize/maximise-tooltip" title={`${ minimisedState ? "Maximize window" : "Minimize window" }`} > 
              {/* minimize/maximize button */}
              <IconButton
                data-tip="Zoom Chart"
                data-delay-hide="100"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-html="true"
                onClick={() => setMinimisedState((isMinimized) => !isMinimized)}
              >
                <span className={`mx-1 icon-container  color-primary`}>
                { !minimisedState ? <UilAngleUp size={16} /> : <UilAngleDown size={16} />}
                </span>
              </IconButton>
            </CustomTooltip>

          </div>

          {/* filter panel */}
          {filterListVisible && (
            <FilterPanel
              metadata={metaData}
              userSelection={chartsVisibility}
              updateUserSelection={(id) => {
                setchartsVisibility((prevState) => {
                  const _chartsVisibility = {
                    ...prevState,
                  };
                  _chartsVisibility[id] = !_chartsVisibility[id];
                  return _chartsVisibility;
                });
              }}
            />
          )}
          <hr />
          {
            !minimisedState &&
            <>
              {/* Display the time of cursor position */}
              <div className="col text-center mb-0 ">
                <span>
                  {tracker ? `${moment(tracker).format("HH:mm:ss a")}` : "-:--:--"}
                </span>
              </div>

              {/* moodle to display the event cause{crash, reboot} */}
              <div className="col text-center mb-0 ">
                {selectedLog && <div>{<LogMoodle log={selectedLog} />}</div>}
              </div>
            
            <div>
            {/* multicharts of metric */}
            <Resizable>
              
              <ChartContainer
                utc={false}
                className="metric-chart-container"
                format="%H:%M %p"
                enablePanZoom={zoomFlag}
                style={{borderRight:"1px solid #ccc"}}
                timeRange={chartTimeRange}
                trackerPosition={tracker}
                onTimeRangeChanged={timeRange => {
                  // Do not set timerange if selected time interval is less than 5 seconds
                  if(timeRange.duration() < (5 * 1000)) return;
                  setChartsTimeRange(timeRange);
                }}
                onTrackerChanged={(tracker) => setTracker(tracker)}
                paddingLeft={10}
                paddingRight={0}
              >
                {Object.keys(timeSeries).map((metric) => {

                  let series = timeSeries[metric];
                  const style = styler([
                    {
                      key: "value",
                      color: data[metric + "-color"],
                      width: 1,
                      opacity: 1,
                    },
                  ]);

                  let value = 0;
                  let event = null;
                  let seriesUnit = series?.toJSON()?.unit || "";
                  if (tracker) {
                    const approx = (+tracker - +timeRange.begin()) /  (+timeRange.end() - +timeRange.begin());
                    const ii = Math.floor(approx * series.size());
                    const i = series.bisect(new Date(tracker), ii);
                    const v = i < series.size() ? series.at(i).get("value") : null;
                    if (v) {
                      if(Number.isInteger(v))
                      {
                        value = parseInt(v,10);
                        value += ` ${seriesUnit}`
                      }else{
                        value = v;
                      }
                    }
                    event = series.atTime(tracker);
                  }

                  const metricThresholdData =
                    _.find(metricOptions, { id: metric })?.threshold || {};
                  let minYAxisValue = series.min();
                  let maxYAxisValue = series.max();
                  if (
                    (metricThresholdData.min || metricThresholdData.min === 0) &&
                    metricThresholdData.min < minYAxisValue
                  ) {
                    minYAxisValue = metricThresholdData.min;
                  }
                  if (
                    (metricThresholdData.max || metricThresholdData.max === 0) &&
                    metricThresholdData.max > minYAxisValue
                  ) {
                    maxYAxisValue = metricThresholdData.max;
                  }

                  return (
                    <ChartRow
                      height="120"
                      visible={chartsVisibility[metric]}
                      key={`row-${metric}`}
                      axisMargin={10}
                      title={`${series.name()} ${seriesUnit ? `(${seriesUnit})` : ""}`}
                      titleStyle={{
                        color: "black",
                        fontWeight: 500,
                        textAlign: "center",
                      }}
                    >    
                      <YAxis
                        id={`${metric}_axis`}
                        min={minYAxisValue}
                        max={maxYAxisValue}
                        type="linear"
                        format=","
                      />
                      <Charts>
                        <Baseline axis="value" value={0} />
                        <ScatterChart
                          key={`line-${metric}`}
                          axis={`${metric}_axis`}
                          series={series}
                          columns={["value"]}
                          style={style}
                          info={[{ label: "", value: 34 }]}
                          breakLine
                          radius={() => 2}
                        />

                        <LineChart
                          key={`line-${metric}`}
                          axis={`${metric}_axis`}
                          series={series}
                          columns={["value"]}
                          style={style}
                          breakLine
                        />
                        <EventMarker
                          type="flag"
                          axis={`${metric}_axis`}
                          event={event}
                          column="value"
                          info={[{ label: series.name(), value: value}]}
                          infoWidth={185}
                          infoTimeFormat={"%d/%m/%y %X"}
                          markerRadius={2}
                          markerStyle={{ fill: "#000000" }}
                          markerLabelStyle={{ fill: "#000000" }}
                        />
                        <Baseline
                          axis={`${metric}_axis`}
                          value={metricThresholdData.min || 0}
                          label={
                            metricThresholdData.min || metricThresholdData.min === 0
                              ? `Minimum threshold (${metricThresholdData.min})`
                              : ""
                          }
                          position="right"
                          style={{
                            line: {
                              stroke: "#E0777D",
                              strokeWidth:
                                metricThresholdData.min || metricThresholdData.min === 0
                                  ? 1
                                  : 0,
                            },
                          }}
                        />

                        <Baseline
                          axis={`${metric}_axis`}
                          value={metricThresholdData.max || 0}
                          label={
                            metricThresholdData.max || metricThresholdData.max === 0
                              ? `Maximum threshold (${metricThresholdData.max})`
                              : ""
                          }
                          position="right"
                          style={{
                            line: {
                              stroke: "#849483",
                              strokeWidth:
                                metricThresholdData.max || metricThresholdData.max === 0
                                  ? 1
                                  : 0,
                            },
                          }}
                        />

                      </Charts>
                      {logFlag && <MultiBrush
                        timeRanges={logSelections.map((log) => log.selection)}
                        handleSize={"0px"}
                        allowFreeDrawing={false}

                        
                        ref = {multiBrushRef}
                        onMouseOver={(i)=>console.log(i)}
                        style={(i) => {
                          const type = logSelections[i].type;
                          const tagColors = {
                            error: "#FF8A65",
                            warning: "#FFB74D",
                            event: "#81C784",
                            crash: "#EF5350",
                            reboot: "#82B1FF",
                          };

                          return {
                            fill: tagColors[type],
                            fillOpacity: 1,
                            stroke: tagColors[type],
                            "stroke-width": 0,
                            shapeRendering: "crispEdges",
                            cursor: "pointer",
                          };
                        }}
                        
                        onTimeRangeClicked={(i) => {
                          i !== undefined
                            ? setselectedLog(logSelections[i])
                            : setselectedLog(null);
                        }}
                      />}
                    </ChartRow>
                  );
                })}
              </ChartContainer>
            </Resizable>

            {/* brush to scroll the chart */}
            <div className="border">
              <Resizable>
                <ChartContainer
                  timeRange={time_interval}
                  hideTimeAxis={true}
                  padding={5}
                >
                  <ChartRow height="20" debug={false}>
                    <Charts>
                      <Brush
                        timeRange={chartTimeRange}
                        allowSelectionClear
                        onTimeRangeChanged={(selectedTimeRange) => {
                          // Do not set timerange if invalid time range returned 
                          if(selectedTimeRange === null) return;

                          setTimeRange(selectedTimeRange);
                          setChartsTimeRange(selectedTimeRange);
                        }}
                        style={{
                          fill: "#5330b9",
                          stroke: "#ffff",
                          shapeRendering: "crispedges",
                          cursor: "grabbing",
                          fillOpacity: 0.5,
                        }}
                      />
                    </Charts>
                  </ChartRow>
                </ChartContainer>
              </Resizable>
            </div>
            <p className="text-muted mb-0">
              <small>Drag to scroll chart horizontally</small>
            </p>
          </div>
          </>
          }
        </>
      )}
    </div>
  );
};

export default TimeSeriesGraph;
