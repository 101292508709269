import React, { useContext } from 'react';
import AdvancedInsightsContext from '../../context/insights-context';
import { transformTranslationText } from '../../data-transform/data-transform';
import WidgetWrapper from '../WidgetWrapper/WidgetWrapper';
import EventsCount from './EventsCount/EventsCount';
import TopNodes from './TopNodes/TopNodes';

function PrimaryWidgets() {
  const { 
    messages,
    distinctNodeCount,
    topNodesDistributionData,
    topNodesDistributionDataLoading,
    eventCountDistributionByInterval,
    eventCountDistributionByIntervalLoading
  } = useContext(AdvancedInsightsContext);

  const topNodes = topNodesDistributionData ? topNodesDistributionData.length : 0;
  let topNodesWidgetTitle = messages.widgets.top_nodes.title;
  if (distinctNodeCount > topNodes) {
    topNodesWidgetTitle = transformTranslationText(messages.widgets.top_nodes.title_with_details, [topNodes, distinctNodeCount]);
  }


  return (
    <div className='primary-widgets'>
        
        <WidgetWrapper 
          title={messages.widgets.events_timeline.title} 
          loading={eventCountDistributionByInterval === null}
          backgroundDataRefreshStatus={eventCountDistributionByIntervalLoading}
        >
            <EventsCount />
        </WidgetWrapper>
        
        <WidgetWrapper 
          title={topNodesWidgetTitle} 
          loading={topNodesDistributionData === null}
          backgroundDataRefreshStatus={topNodesDistributionDataLoading}
        >
            <TopNodes />
        </WidgetWrapper>
    </div>
  )
}

export default PrimaryWidgets