import React from 'react'
import "./TagChip.scss"
const TagChip = (props) => {
  const { tag, className, onTagSelect  } = props
  return (
    <div className={`node-tag ${className}`} onClick={onTagSelect}>
        <div className='tag-container'>
            <div className='property'>{tag.property}</div>
            <div className='value'>{tag.value}</div>
        </div>
    </div>
  )
}

export default TagChip