import React, { useState, useContext, useEffect } from "react";
import "./NodeSettings.scss";

import WidgetWrapper from "../../Widgets/WidgetWrapper/WidgetWrapper";
import AdvancedInsightsContext from "../../context/insights-context";
import { getUrlParameterByName } from "../../../../utils/commonFunctions";
import InfoMessageWrapper from "../../Widgets/InfoMessageWrapper/InfoMessageWrapper";
import { Container, Row, Col } from "react-bootstrap";
import SettingPanel from "./SettingPanel/SettingPanel";
import useCommandResponse from "../../hooks/useCommandResponse";
import SettingsChangeHistory from "./SettingsChangeHistory/SettingsChangeHistory";
import { orderBy } from "lodash";
import WidgetBox from "../../Widgets/WidgetBox/WidgetBox";
import TimeoutConfiguration from "./TimeoutConfiguration/TimeoutConfiguration";
import * as generalConfig from "./../../general-config.json";

function NodeSettings() {
  const {
    getNodeConfigurationMetaData,
    nodeConfigMetaLoading,
    transformedNodeConfigMeta,
    selectedNodeInfoData,
    messages,
  } = useContext(AdvancedInsightsContext);

  const nodeKey = getUrlParameterByName("key");
  const defaultCommandTimeout = generalConfig.commandResponseConfig.timeout;
  const [commandTimeout, setCommandTimeout] = useState(defaultCommandTimeout);

  const { sendCommand, sentCommandsInCurrentSession } = useCommandResponse();
  const settingsChangeLog = sentCommandsInCurrentSession.filter((c) =>
    c.nodeList.includes(selectedNodeInfoData.node_id)
  );

  useEffect(() => {
    getNodeConfigurationMetaData({ node_key: nodeKey });
  }, []);

  if (
    !nodeConfigMetaLoading &&
    (!transformedNodeConfigMeta || !transformedNodeConfigMeta.length)
  ) {
    return (
      <InfoMessageWrapper>
        {messages.node_settings.no_settings_message}
      </InfoMessageWrapper>
    );
  }

  const handleSettingsUpdate = (settingData, value) => {
    sendCommand({
      nodeId: selectedNodeInfoData.node_id,
      name: settingData.name,
      value: value,
      timeout:
        typeof commandTimeout === "number" ? commandTimeout * 60 : undefined,
    });
  };

  return (
    <Container fluid>
      <Row>
        <Col xl={8} lg={7} md={12} sm={12} xs={12}>
          <WidgetWrapper
            loading={nodeConfigMetaLoading}
            wrapperStyles={{ borderRadius: "10px" }}
            containerStyles={{ padding: "0px" }}
          >
            <WidgetBox
              heading={
                messages.node_settings.available_node_settings_widget.title
              }
              showRefreshControl={true}
              disableRefreshControl={nodeConfigMetaLoading === true}
              onRefresh={() =>
                getNodeConfigurationMetaData({ node_key: nodeKey })
              }
            >
              <div className="advanced-settings-wrapper">
                <div className="settings-container">
                  {transformedNodeConfigMeta
                    ? transformedNodeConfigMeta.map((settingData) => (
                        <div
                          className="setting-panel-container"
                          key={settingData.key}
                        >
                          <SettingPanel
                            data={settingData}
                            root
                            onSettingsUpdate={handleSettingsUpdate}
                          />
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </WidgetBox>
          </WidgetWrapper>
        </Col>
        <Col xl={4} lg={5} md={12} sm={12} xs={12}>
          <WidgetWrapper>
            <TimeoutConfiguration
              defaultTimeout={commandTimeout}
              onUpdate={(v) => setCommandTimeout(v)}
            />
          </WidgetWrapper>
          <WidgetWrapper
            wrapperStyles={{ borderRadius: "10px" }}
            containerStyles={{ padding: "0px" }}
          >
            <SettingsChangeHistory
              changeLog={orderBy(settingsChangeLog, ["ts"], ["desc"])}
            />
          </WidgetWrapper>
        </Col>
      </Row>
    </Container>
  );
}

export default NodeSettings;
