import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { UilArrowLeft, UilTrashAlt, UilCloudDownload } from '@iconscout/react-unicons';
import {
	ErrorButton,
	PrimarySmallButton,
} from '../../components/Button/Button';
import { getDownloadPackageUrl, getFirmwarePackageDetails } from '../../utils/diagAPI';
import DeletePackageModal from '../../components/CustomModal/DeletePackageModal';
import { createFormAndSubmit } from '../../utils/commonFunctions';
import CustomToast from '../../components/CustomToast/CustomToast';

const FirmwarePackageDetails = (props) => {
	const [showStartOTA, setShowStartOTA] = useState(false);

	const otaPackage = props.match.params.package_id;
	const [response, setResponse] = useState({});
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setError('');
		setLoading(true);
		getFirmwarePackageDetails(otaPackage, 'id')
			.then((resp) => {
				if (resp.statusCode === 401) {
					props.sessionExpire();
					return;
				}
				setLoading(false);
				if (resp.status === 'failure') {
					setError(resp.description);
				} else {
					setResponse(resp);
				}
			})
			.catch(() => {
				setError('Something went wrong!');
				setLoading(false);
			});
	}, []);

	const showDownloadPackageError = () => {
		CustomToast.error(
			'There was an error downloading file! Please try again later.',
			{
				autoClose: 5000,
			},
			'session-toast'
		);
	}

	const handleDownloadPackage = () => {
		getDownloadPackageUrl(otaPackage)
		.then(resp => {
			if (resp.status === "SUCCESS") {
				const downloadURL = resp.url;
				const downloadURLQueryParams = new URLSearchParams(downloadURL.split("?")[1]);
				const fieldsData = Object.fromEntries(downloadURLQueryParams.entries());
				createFormAndSubmit("GET", "_blank", downloadURL, fieldsData);
			}
			else {
				showDownloadPackageError();
			}
		})
		.catch(() => {
			showDownloadPackageError();
		})
	}

	return (
		<>
			<div className='app--navbar'>
				<div className='d-flex justify-content-between align-items-center'>
					<div>
						{/* <p className='small--heading'>Overview</p> */}
						<div className='d-flex'>
							<p className='main--heading'>Package Details</p>
						</div>
					</div>
				</div>
			</div>
			<hr className='mx-3 my-0' />

			<div className='home-card card--container d-flex flex-column justify-content-between'>
				<div className='d-flex flex-column justify-content-between'>
					<div className='row'>
						<div className='col-lg-12 d-flex justify-content-between mb-2'>
							<Link
								to={`/home/firmware-packages`}
								className='d-flex align-items-center'
								id='back-to-page-link'
							>
								<span className='icon-container ml-0'>
									<UilArrowLeft color='#5330b9' size={16} />
								</span>
								<p className='back-link'>
									Back to Firmware Packages
								</p>
							</Link>

							<div className='d-flex align-items-center'>
								<div className="pr-2">
									<PrimarySmallButton onClick={handleDownloadPackage}>
										<UilCloudDownload size={20} /> Download Package
									</PrimarySmallButton>
								</div>

								<div>
									<ErrorButton onClick={() => setShowDeleteModal(true)}>
										<UilTrashAlt size={20} /> Delete
									</ErrorButton>
								</div>
							
							</div>
						</div>
					</div>

					{loading ? (
						<div className='w-100'>
							<div className='placeholder transition'>
								<div
									className='animated-background d-flex align-items-center justify-content-center'
									style={{ height: '65vh' }}
								>
									<p className='mb-0'>Loading...</p>
								</div>
							</div>
						</div>
					) : error ? (
						<div className='error-container'>
							<p className='color-primary'>{error}</p>
						</div>
					) : (
						<div className='row'>
							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Name:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{response.package_name}</p>
							</div>

							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Package Id:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{response.package_id}</p>
							</div>

							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Type:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{response.type}</p>
							</div>

							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Model:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{response.model}</p>
							</div>
						
							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Firmware Version:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{response.fw_version}</p>
							</div>
							
							<div className='col-lg-2 image-details-column'>
								<p className='mb-0'>Firmware SHA:</p>
							</div>
							<div className='col-lg-9 image-details-column'>
								<p className='mb-0'>{response.fw_sha}</p>
							</div>

							{
								showDeleteModal && (
									<DeletePackageModal
										showModal={showDeleteModal}
										setShowModal={setShowDeleteModal}
										packageId={response.package_id}
										sessionExpire={props.sessionExpire}
									/>
								)
							}
						</div>
					)}
				</div>

				{/* {showStartOTA && (
					<StartOTA
						showModal={showStartOTA}
						setShowModal={setShowStartOTA}
						otaPackage={response}
						sessionExpire={props.sessionExpire}
					/>
				)*/}
			</div>
		</>
	);
};

export default FirmwarePackageDetails;
