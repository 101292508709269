import React from 'react';
import './BasicLightCardWrapper.scss';

function BasicLightCardWrapper(props) {
    return (
        <div className='basic-light-card-wrapper'>
            <div className='card-content'>
                { props.children }

                {
                    props.actions && (
                        <div className='actions'>
                            {
                                props.actions.map(action => (
                                    <button onClick={action.clickHandler} key={action.id}>
                                        {action.label}
                                    </button>
                                ))
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default BasicLightCardWrapper