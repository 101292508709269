import React from 'react';
import './TimelineToolbar.scss';
import SimpleSelect from '../../../../../Widgets/WidgetStickyToolbar/FormComponents/SimpleSelect/SimpleSelect';
import WidgetStickyToolbar from '../../../../../Widgets/WidgetStickyToolbar/WidgetStickyToolbar';
import { UilLayerGroup, UilEye } from '@iconscout/react-unicons';

function TimelineToolbar({
    termAggregators,
    aggregatorType,
    setAggregatorType,
    supportedChartTypes,
    chartType,
    setChartType,
    timelineDataLoading
}) {
    return (
        <WidgetStickyToolbar loading={timelineDataLoading}>
            <div className='timeline-toolbar-actions d-flex'>
                <div className='chart-type action'>
                    <SimpleSelect 
                        label="Visualize as"
                        options={supportedChartTypes} 
                        value={chartType} 
                        onChange={o => setChartType(o)}
                        icon={<UilEye size={16} />}
                        width="100px"
                        isSearchable={false}
                    />
                </div>
                <div className='dist-type action'>
                    <SimpleSelect 
                        label="Group by"
                        options={termAggregators} 
                        value={aggregatorType} 
                        onChange={o => setAggregatorType(o)}
                        icon={<UilLayerGroup size={16} />}
                        width="200px"
                        isDisabled={timelineDataLoading}
                    />
                </div>
            </div>
        </WidgetStickyToolbar>
    )
}

export default TimelineToolbar