import React from 'react';
import './NoDataMessage.scss';
import { UilCloudTimes } from '@iconscout/react-unicons';
import * as messages from './../../messages/en.json';

function NoDataMessage({text = messages.no_data.primaryText, iconSize = 36}) {
    return (
        <div className='no-data-message'>
            <div className="icon"><UilCloudTimes size={iconSize} /></div>
            <div className="text">{text}</div>
        </div>
    )
}

export default NoDataMessage