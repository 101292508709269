import React, { useState } from 'react';
import Modal from './Modal';
import { Input } from '../Input/Input';
import { getBase64 } from '../../utils/commonFunctions';
import {
	uploadOtaImagesCall,
	getPresignedURLCall,
	uploadURLCall,
	uploadOtaPackageCall,
} from '../../utils/apiCalls';
import {
	UilPlusCircle,
	UilTimesCircle,
	UilCheck,
} from '@iconscout/react-unicons';
import { isValidOTAImageName } from '../../utils/validationSchemas';
import { LinkButton, PrimaryLineButton } from '../Button/Button';

// eslint-disable-next-line react/display-name
const AddImageModal = (props) => {
	const { showModal, setShowModal } = props;

	const [state, setState] = useState({});
	const [image, setImage] = useState(null);
	const [imageName, setImageName] = useState(null);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [advanced, setAdvanced] = useState(false);

	const close = () => {
		if (success) {
			props.refreshParent();
		}
		setError('');
		setState({});
		setSuccess(false);
		setImage(null);
		setImageName(null);
		setShowModal(false);
	};

	const handleInputChange = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		setError('');
		setState((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleAddOtaImage = async () => {
		if (
			!state.image_name ||
			state.image_name === ' ' ||
			!state.image_type ||
			state.image_type === ' ' ||
			!image
		) {
			setError('Please provide all details.');
			return;
		}
		if (!isValidOTAImageName(state.image_name)) {
			setError('Please provide a valid image name.');
			return;
		}
		setLoading(true);
		imageName.indexOf('.zip') > -1 ? uploadPackage() : uploadImage();
	};

	const uploadImage = async () => {
		const base64_fwimage = await getBase64(image);
		uploadOtaImagesCall(state, base64_fwimage)
			.then((response) => {
				setLoading(false);
				if (response.statusCode === 401) {
					props.sessionExpire();
					return;
				}
				if (response.status === 'success') {
					setError('');
					setSuccess(true);
				} else {
					setError(response.description);
				}
			})
			.catch(() => {
				setError('Something went wrong!');
				setLoading(false);
			});
	};

	const uploadPackage = async () => {
		let presignedURL;
		await getPresignedURLCall(imageName)
			.then((response) => {
				if (response.statusCode === 401) {
					props.sessionExpire();
					return;
				}
				if (response.status === 'success') {
					presignedURL = response.url;
				} else {
					setError(response.description);
					return;
				}
			})
			.catch(() => {
				setError('Something went wrong!');
			});
		const res = await uploadURLCall(presignedURL, image);

		if (res.status === 200) {
			await uploadOtaPackageCall(state, imageName)
				.then((response) => {
					if (response.statusCode === 401) {
						props.sessionExpire();
						return;
					}
					if (
						response.status === 'success' ||
						response.statusCode === 200
					) {
						setError('');
						setSuccess(true);
					} else {
						setError(response.description);
					}
				})
				.catch(() => {
					setError('Something went wrong!');
				});
		}

		setLoading(false);
	};

	const handleFileUpload = async (e) => {
		setError('');
		const {
			target: { files },
		} = e;
		if (files[0]) {
			setImageName(files[0].name);
			setImage(files[0]);
		}
	};

	return (
		<Modal showModal={showModal} setShowModal={close} title='Add Image'>
			{!success ? (
				<>
					<div className='p-3'>
						<div className='w-75 mb-3'>
							<Input
								type='text'
								name='image_name'
								placeholder='e.g. Light-2.0'
								label='Image Name'
								onChange={(e) => handleInputChange(e)}
								maxLength='64'
								required
							/>
						</div>
						<div className='w-75 mb-3'>
							<Input
								type='text'
								name='image_type'
								placeholder='e.g. Lightbulb'
								label='Type'
								onChange={(e) => handleInputChange(e)}
								maxLength='64'
								required
							/>
						</div>

						<LinkButton
							onClick={() => setAdvanced((advanced) => !advanced)}
						>
							Advanced
						</LinkButton>

						{advanced && (
							<div className='advanced-section'>
								<p className='mb-2'>
									Firmware version and model will be read from
									the image. Set these only if you want to
									override.
								</p>

								<div className='w-75 mb-3'>
									<Input
										type='text'
										name='fw_version'
										placeholder='e.g. 2.0'
										label='Firmware Version'
										onChange={(e) => handleInputChange(e)}
										maxLength='64'
									/>
								</div>

								<div className='w-75 mb-3'>
									<Input
										type='text'
										name='image_model'
										placeholder='e.g. led_light'
										label='Model'
										onChange={(e) => handleInputChange(e)}
										maxLength='64'
									/>
								</div>
							</div>
						)}
						{imageName ? (
							<span className='modal-imagename'>
								{imageName}
								<span className='close-icon'>
									<UilTimesCircle
										color='#5330b9'
										size={14}
										onClick={() => {
											setImageName(null);
											setImage(null);
										}}
									/>
								</span>
							</span>
						) : (
							<>
								<label
									htmlFor='attachment'
									className='upload-file-label'
									style={{
										display: imageName
											? 'none'
											: 'inline-block',
									}}
								>
									<>
										<span>
											<UilPlusCircle
												color='#5330b9'
												size={16}
											/>
										</span>
										Select Image
									</>
								</label>
								<input
									type='file'
									id='attachment'
									accept='.bin, .zip'
									name='image_file'
									onChange={(e) => handleFileUpload(e)}
								/>
							</>
						)}
						{error && (
							<p className='text-error mt-3 mb-0'>{error}</p>
						)}
					</div>
					<div className='modal--footer d-flex justify-content-end'>
						<PrimaryLineButton
							onClick={handleAddOtaImage}
							id='add-image-btn'
							loading={loading}
						>
							Add Image
						</PrimaryLineButton>
					</div>
				</>
			) : (
				<>
					<div className='text-center py-3'>
						<span className='icon-container success-check'>
							<UilCheck color='#fff' size={16} />
						</span>
						<p>Firmware Image added successfully.</p>
					</div>
					<div className='modal--footer d-flex justify-content-end'>
						<PrimaryLineButton onClick={close} id='ok-btn'>
							Ok
						</PrimaryLineButton>
					</div>
				</>
			)}
		</Modal>
	);
};

export default AddImageModal;
