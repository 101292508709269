import React, {useEffect, useContext, useState} from 'react';
import './NodeStatsCard.scss';
import AdvancedInsightsContext from './../../../../context/insights-context';
import * as generalConfig from './../../../../general-config.json';
import { formatLargeNumbers } from '../../../../../../utils/commonFunctions';
import EventIcon from '../../../../Widgets/SideWidgets/EventsTable/EventIcon/EventIcon';
import { UilArrowUpRight } from '@iconscout/react-unicons';
import BasicLightCardWrapper from '../../../../Widgets/BasicLightCardWrapper/BasicLightCardWrapper';
import useDidMountEffect from '../../../../hooks/useDidMountEffect';

function NodeStatsCard() {
    const { 
        selectedNodeAllEventsData,
        getSelectedNodeAllEventsData,
        nodeFilters,
        messages,
        startTimestamp, 
        endTimestamp,
        selectedFilters
    } = useContext(AdvancedInsightsContext);

    const [viewDetails, setViewDetails] = useState(false);

    useEffect(() => {
        if (selectedNodeAllEventsData === null && nodeFilters.length) {
            getSelectedNodeAllEventsData();
        }
    }, [nodeFilters]);

    useDidMountEffect(() => {
        getSelectedNodeAllEventsData();
    }, [startTimestamp, endTimestamp, selectedFilters]);
    
    const eventTypes = generalConfig.event_types;

    const totalEventsCount = selectedNodeAllEventsData ? selectedNodeAllEventsData.reduce((prev, curr) => prev + curr.count, 0) : 0;
    const getCount = eventKey => {
        const eventDataIndex = selectedNodeAllEventsData.findIndex(e => e.key === eventKey);
        if (eventDataIndex === -1) return 0;
        return selectedNodeAllEventsData[eventDataIndex].count.toLocaleString();
    }

    const cardActions = [
        { 
            id: "view-details", 
            label: viewDetails ? "Hide details" : "View details", 
            clickHandler: () => setViewDetails(!viewDetails) 
        }
    ]

    if (selectedNodeAllEventsData === null) return null;

    return (
        <BasicLightCardWrapper actions={cardActions}>
            <div className='stats'>
                <div className='total-count'>
                    <label>Total events count</label>
                    <h1>{formatLargeNumbers(totalEventsCount)}</h1>
                    {/* {<div className='guage'>
                        <UilArrowUpRight />5.69%
                    </div>} */}
                </div>
                <div className='items'>
                    {
                        eventTypes.map(e => (
                            <div 
                                className={`item ${!e.primary && !viewDetails ? "hide" : "show"} `} 
                                key={e.id}
                            >
                                <EventIcon type={e.id} size={15} />
                                <label>{messages.event_types[e.id].label_plural}</label>
                                <div className='count'>{getCount(e.id)}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </BasicLightCardWrapper>
    )
}

export default NodeStatsCard