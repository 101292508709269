import React from 'react';

const Footer = () => {
	return (
		<div className='d-flex justify-content-center align-items-center py-2 text-muted footer'>
			<p className='mb-0'>
				Dashboard Version: {window.VERSION} &nbsp;|&nbsp;
				<a
					href='https://insights.espressif.com/docs/privacy-policy'
					className='text-muted'
					target='_blank'
					rel='noopener noreferrer'
					id='terms-of-use-link'
				>
					Privacy Policy
				</a>
				&nbsp;|&nbsp;
				<a
					href='https://insights.espressif.com/docs/terms-of-use'
					className='text-muted'
					target='_blank'
					rel='noopener noreferrer'
					id='terms-of-use-link'
				>
					Terms of use
				</a>
				&nbsp;|&nbsp;
				<a
					href='https://insights.espressif.com'
					className='text-muted'
					target='_blank'
					rel='noopener noreferrer'
					id='terms-of-use-link'
				>
					More Info
				</a>
			</p>
		</div>
	);
};

export default Footer;
