import React from 'react';
import { useTable } from 'react-table';
import { Input } from '../Input/Input';
import { PrimaryLineButton } from '../Button/Button';
import './table.scss';

export default function Table({
	columns,
	data,
	lastElementRef,
	loading,
	loaders,
	form,
	onFieldChange,
	onSubmit,
	formError,
	formLoading,
	className
}) {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns,
		data,
	});

	return (
		<div>
			<table
				{...getTableProps()}
				style={{ opacity: loading ? 0.5 : 1 }}
				className={`transition ${className}`}
			>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							key={headerGroup.headers}
						>
							{headerGroup.headers.map((column, i) => (
								<th
									{...column.getHeaderProps()}
									key={`column${i}`}
									style={column.style ? column.style : null}
								>
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
				</thead>
				{!loading && (
					<tbody {...getTableBodyProps()}>
						{
							form ? <tr className="table-form-container">
								{
									form.inputs.map( (input,index) => {
									
										let colspan  = 1;
										if(input.type == "submit") {
											colspan = columns.length - index
											return (
												<td colSpan={colspan}>
													<PrimaryLineButton
														title={input.field}
														id={input.id}
                        							    onClick={onSubmit}
														disabled={formLoading}
														style={{float:"right"}}
													>
														{(formLoading && "Creating..." )|| input.field}
													</PrimaryLineButton>
												</td>
											)
										}
										return (
											<td colspan={colspan}>
												<Input
													label={input.label}
													required={input.required}
													type={input.type}
													name= {input.field}
													placeholder= {input.placeholder}
													onChange={onFieldChange}
													disabled={input.disabled}
													hasError={formError[input.id] && true}
													error={formError[input.id]}
													maxLength={64}
													id= {input.id}
												/>
											</td>	
										)
									})
								}
							</tr> : ""
						}
						{rows.map((row, i) => {
							prepareRow(row);
							if (data.length === i + 1) {
								return (
									<tr
										{...row.getRowProps()}
										ref={lastElementRef}
									>
										{row.cells.map((cell, i) => {
											return (
												<td
													{...cell.getCellProps()}
													key={`headerCell${i}`}
												>
													{cell.render('Cell')}
												</td>
											);
										})}
									</tr>
								);
							} else {
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell, i) => {
											return (
												<td
													{...cell.getCellProps()}
													key={`cell.value${i}`}
												>
													{cell.render('Cell')}
												</td>
											);
										})}
									</tr>
								);
							}
						})}
					</tbody>
				)}
			</table>
			{loading && (
				<div className='placeholder transition'>
					{[...Array(Number(loaders))].map((i, index) => (
						<div
							key={`loader-${index}`}
							className='animated-background'
						></div>
					))}
				</div>
			)}
		</div>
	);
}
