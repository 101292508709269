import React, { useState, useEffect } from "react";
import "./SwitchButton.scss";
import { Form } from "react-bootstrap";

function SwitchButton({ label, id, value, onChange }) {
  const [checked, setChecked] = useState(value === true);
  const handleSwitchChange = (e) => {
    setChecked((checked) => !checked);
    onChange(e);
  };

  useEffect(() => {
    setChecked(value === true);
  }, [value]);
  return (
    <Form noValidate>
      <Form.Check
        custom
        type="switch"
        className="switch-input"
        id={id}
        label={label || undefined}
        checked={checked}
        onChange={handleSwitchChange}
      />
    </Form>
  );
}

export default SwitchButton;
