import axios from 'axios';
import Cookies from 'js-cookie';
// import { refreshSession } from './api';

const instance = axios.create({
	baseURL: window.BASE_URL,
});

const transformRequestURL = url => {
	if (process?.env?.REACT_APP_USE_LOCAL_JSON_DATA === "1") {
		return (url.includes("?") ? url.split("?")[0] : url) + ".json";
	}
	return url;
}

instance.interceptors.request.use((config) => {
	const token = Cookies.get('ATK');
	const tenantToken = Cookies.get('TATK');

	config.headers.Authorization = token;
	if (tenantToken) {
		config.headers.TenantToken = tenantToken;
	}
	config.url = transformRequestURL(config.url);
	return config;
});

instance.interceptors.response.use(
	(response) => {
		return response;
	},
	function (error) {
		// if (error.response && error.response.status === 401) {
		// 	const originalReq = error.config;
		// 	const refreshToken = Cookies.get('RTK');
		// 	const user_name = Cookies.get('user_name');
		// 	refreshSession(user_name, refreshToken).then(({ data }) => {
		// 		const { status } = data;
		// 		if (status === 'success') {
		// 			const { accesstoken } = data;
		// 			Cookies.set('ATK', accesstoken);
		// 			originalReq.headers.Authorization = accesstoken;
		// 			return instance(originalReq);
		// 		} else {
		// 			window.location = '/login';
		// 			localStorage.clear();
		// 			return Promise.reject(error);
		// 		}
		// 	});
		// } else {
		return Promise.reject(error);
		// }
	}
);

export const diagnostics = axios.create({
	baseURL: window.DIAG_API_URL
});

diagnostics.interceptors.request.use((config) => {
	const token = Cookies.get('ATK');
	const tenantToken = Cookies.get('TATK');

	config.headers.Authorization = token;
	if (tenantToken) {
		config.headers.TenantToken = tenantToken;
	}
	config.url = transformRequestURL(config.url);
	return config;
});

export default instance;
