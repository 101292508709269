import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import UserWrapper from '../../../components/UserWrapper/UserWrapper';
import { loginCall, getUserCall, redirectUserToESPLogin } from '../../../utils/apiCalls';
import { isValidEmail } from '../../../utils/validationSchemas';
import { Input } from '../../../components/Input/Input';
import {
	GoogleLogin,
	AppleLogin,
	GithubLogin,
} from '../../../utils/socialLinks';
import googleImg from '../../../assets/google-logo.png';
import appleImg from '../../../assets/apple-logo.png';
import githubImg from '../../../assets/github.png';
import Password from '../../../components/Password/Password';
import { PrimaryDarkButton } from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import * as generalConfig from './../../AdvancedInsights/general-config.json';

const Login = () => {
	let history = useHistory();
	const isCustomer = window.CUSTOMER_DEPLOYMENT;
	const enableSocialLogin = window.ENABLE_SOCIAL_LOGIN_OPTIONS;
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);
	const [keepSignedIn, setKeepSignedIn] = useState(Cookies.get(generalConfig.keep_signed_in_cookie_key) === "yes");
	const enableESPLogin = window.ENABLE_ESP_LOGIN_AUTHENTICATION === true && window.ESP_LOGIN_SERVICE_URL.trim() !== "";

	const socialOptions = [
		{
			socialLink: GithubLogin,
			name: 'GitHub',
			img: githubImg,
			tab: '1',
			enable: window.GITHUB_LOGIN_OPTION,
		},

		{
			socialLink: GoogleLogin,
			name: 'Google',
			img: googleImg,
			tab: '2',
			enable: true,
		},
		{
			socialLink: AppleLogin,
			name: 'Apple',
			img: appleImg,
			tab: '3',
			enable: true,
		},
	];

	useEffect(() => {
		if (Cookies.get('ATK')) {
			history.push({
				pathname: '/home/insights',
				state: {enableESPLogin}
			});
		}
		else {
			enableESPLogin && redirectUserToESPLogin();
		}

		return () => {
			setLoading(false);
			setError('');
			setKeepSignedIn(Cookies.get(generalConfig.keep_signed_in_cookie_key) === "yes");
		};
	}, []);

	const handleLogin = async (e) => {
		e.preventDefault();

		let user_name = e.target.elements.user_name.value;
		let password = e.target.elements.password.value;
		let keep_signed_in = e.target.elements.keep_signed_in.checked;
		setLoading(true);

		if (!user_name || !isValidEmail(user_name)) {
			setError('Please enter a valid username.');
			setLoading(false);
			return;
		} else if (
			password === '' ||
			user_name === ' ' ||
			!password ||
			password.length < 8
		) {
			setError('Please enter a valid password.');
			setLoading(false);
			return;
		}

		let response = await loginCall(user_name, password, keep_signed_in);
		if (response) {
			setError(response);
			setLoading(false);
			return;
		}

		let user = await getUserCall();

		if (isCustomer) {
			if (user.super_admin) {
				history.push({
					pathname: '/home/insights',
					state: {enableESPLogin}
				});
				return;
			}
			history.push('/unauthorised');
			return;
		}
		history.push({
			pathname: '/home/insights',
			state: {enableESPLogin}
		});
		setLoading(false);
	};

	return (
		<UserWrapper>
			{(!isCustomer && enableSocialLogin) && (
				<div>
					<div className='text-center'>
						{socialOptions.map(
							(option, i) =>
								option.enable && (
									<a
										key={`social${i}`}
										href={option.socialLink}
										className='btn social-btn cursor-pointer'
										id={option.name}
										tabIndex={option.tab}
									>
										<img
											src={option.img}
											className='social-icon'
											alt='social-login'
										/>
										<span>Sign in with {option.name}</span>
									</a>
								)
						)}
					</div>

					<p className='text-muted text-center mt-3 mb-0'>
						or use your email id
					</p>
				</div>
			)}
			<form onSubmit={handleLogin} className='pt-3'>
				<Input
					type='email'
					name='user_name'
					placeholder='Username'
					onChange={() => error && setError('')}
					id='username'
					tabIndex='4'
					className='mb-2'
				/>
				<Password
					className='input'
					name='password'
					placeholder='Password'
					onChange={() => error && setError('')}
					maxLength='64'
					id='password'
					tabIndex='5'
				/>

				{error && <p className={'text-error'}>{error}</p>}
				
				<div className='mt-2'>
					<Checkbox 
						id="keep_signed_in" 
						label="Keep me signed in" 
						checked={keepSignedIn}
						onChange={e => setKeepSignedIn(e.target.checked)}
						tabIndex='6'
					/>
				</div>

				<div className='d-flex justify-content-between align-items-center mt-2'>
					<a
						href='/forgot-password'
						className='text-link cursor-pointer'
						id='forgot-password-link'
						tabIndex='8'
					>
						Forgot Password?
					</a>

					<PrimaryDarkButton
						loading={loading}
						minWidth='70px'
						disabled={loading ? true : false}
						id='login-btn'
						tabIndex='7'
						type='submit'
					>
						Login
					</PrimaryDarkButton>
				</div>
			</form>

			{!isCustomer && (
				<>
					<div className='d-flex justify-content-center mt-5'>
						<p className='text-muted mb-0'>
							Don&apos;t have account?&nbsp;
							<a
								href='/sign-up'
								className='text-link cursor-pointer'
								id='signup-link'
							>
								Sign Up
							</a>
						</p>
					</div>
					<div className='d-flex justify-content-center'>
						<p className='mb-0'>
							<a
								href='https://insights.espressif.com/docs/privacy-policy'
								className='text-link'
								target='_blank'
								rel='noopener noreferrer'
								id='terms-of-use-link'
							>
								Privacy Policy
							</a>
							&nbsp;and&nbsp;
							<a
								href='https://insights.espressif.com/docs/terms-of-use'
								className='text-link'
								target='_blank'
								rel='noopener noreferrer'
								id='terms-of-use-link'
							>
								Terms of use.
							</a>
						</p>
					</div>
				</>
			)}
		</UserWrapper>
	);
};

export default Login;
