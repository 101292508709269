import React from 'react'
import { UilExclamationTriangle, UilInfoCircle, UilExclamationOctagon } from '@iconscout/react-unicons';
import './Notice.scss';

export default function Notice({type, text, alignText, children}) {

const getNoticeTypeIcon = type => {
    const defaultIcon = <UilExclamationTriangle />;
    let icon = null;

    switch (type) {
        case "warning":
            icon = <UilExclamationTriangle />;
            break;
        
        case "error":
            icon = <UilExclamationOctagon />;
            break;
        
        case "info":
            icon = <UilInfoCircle />;
            break;
        
        default:
            icon = defaultIcon;
    }

    return icon;
}

const flexAlignPosition = alignText || "start"; // start, center or end

    return (
        <div className={`notice ${type} alert alert-${type === "error" ? "danger" : type} d-flex align-items-center justify-content-${flexAlignPosition}`}>
            { getNoticeTypeIcon(type) }
            { text ? <div className='text'>{ text }</div> : children }
            
        </div>
    )
}
