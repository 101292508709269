import React from 'react';
import { UilSync, UilTrashAlt } from '@iconscout/react-unicons';

const Button = (props) => {
	const {
		loading,
		loadingClass,
		className = '',
		id,
		title,
		minWidth = '74px',
		children,
		...buttonProps
	} = props;
	return (
		<button
			className={`btn d-flex justify-content-center transition-ease  ${
				loading && loadingClass
			} ${className}`}
			id={id}
			title={title}
			style={{
				minWidth: minWidth,
			}}
			{...buttonProps}
		>
			{loading ? (
				<span className='spinner-border' role='status'>
					<span className='sr-only'>Loading...</span>
				</span>
			) : (
				children
			)}
		</button>
	);
};

export const PrimaryDarkButton = (props) => {
	const { className = '', children, ...buttonProps } = props;
	return (
		<Button
			className={`primary-button primary-dark-button ${className}`}
			{...buttonProps}
		>
			{children}
		</Button>
	);
};

export const PrimaryLineButton = (props) => {
	const { className = '', children, ...buttonProps } = props;
	return (
		<Button
			className={`primary-button ${className}`}
			loadingClass='primary-dark-button'
			{...buttonProps}
		>
			{children}
		</Button>
	);
};

export const PrimarySmallButton = (props) => {
	const { className = '', children, ...buttonProps } = props;
	return (
		<Button
			className={`primary-button primary-button-small ${className}`}
			loadingClass='primary-dark-button'
			{...buttonProps}
		>
			{children}
		</Button>
	);
};

export const SuccessButton = (props) => {
	const { className = '', children, ...buttonProps } = props;
	return (
		<Button className={`success-button ${className}`} {...buttonProps}>
			{children}
		</Button>
	);
};

export const ErrorButton = (props) => {
	const { className = '', children, ...buttonProps } = props;
	return (
		<Button
			className={`primary-button primary-button-small error-button-small ${className}`}
			{...buttonProps}
		>
			{children}
		</Button>
	);
};

export const ErrorLineButton = (props) => {
	const { className = '', children, ...buttonProps } = props;
	return (
		<Button
			className={`error-button ${className}`}
			loadingClass={'error-dark-button text-light'}
			{...buttonProps}
		>
			{children}
		</Button>
	);
};

export const LinkButton = (props) => {
	const { className = '', children, ...buttonProps } = props;
	return (
		<Button className={`link-btn ${className}`} {...buttonProps}>
			{children}
		</Button>
	);
};

export const RefreshButton = (props) => {
	const { loading, ...buttonProps } = props;
	return (
		<button
			className={`custom-btn icon-container icon-container-hover ${
				loading ? 'rotating' : ''
			}`}
			{...buttonProps}
			id='refresh-btn'
			title='Refresh'
		>
			<UilSync size={14} color='#5330b9' className='refresh-icon' />
		</button>
	);
};

export const DeleteButton = (props) => {
	const { ...buttonProps } = props;
	return (
		<button
			className={`custom-btn icon-container delete-icon-container`}
			id='delete-btn'
			{...buttonProps}
			title='Delete'
		>
			<UilTrashAlt size={16} color='#c2303e' title='Delete Image' />
		</button>
	);
};

export const IconButton = (props) => {
	const { id, className = '', title = '', children, ...buttonProps } = props;
	return (
		<button
			className={`icon-button ${className}`}
			id={id}
			{...buttonProps}
			title={title}
		>
			{children}
		</button>
	);
};
