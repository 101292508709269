import React from 'react';
import {  UilPlusSquare, UilMinusSquare } from '@iconscout/react-unicons';
import ExpandableTable from '../../../Widgets/ExpandableTable/ExpandableTable';
import InfoMessageWrapper from '../../../Widgets/InfoMessageWrapper/InfoMessageWrapper';
import CustomTooltip from '../../../../../components/CustomTooltip/CustomTooltip';

function VariablesTable({data}) {
    const columns = React.useMemo(
    () => [
        {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <span {...getToggleAllRowsExpandedProps()}>
            { isAllRowsExpanded ? <UilMinusSquare size={15} /> : <UilPlusSquare size={15} /> }
            </span>
        ),
        width: "10%",
        Cell: ({ row }) =>
            row.canExpand ? (
            <span
                {
                ...row.getToggleRowExpandedProps({
                    style: {marginLeft: `${row.depth * 10}px`}
                })
                }
            >
                {row.isExpanded ? <UilMinusSquare size={15} /> : <UilPlusSquare size={15} /> }
            </span>
            ) : null
        },
        {
        Header: " ",
        columns: [
                {
                    Header: "Title",
                    accessor: (row) => row.type === "file" ? (
                        <CustomTooltip id={`${row.id}`} title={`ID: ${row.id}`}>
                            { row.label }
                        </CustomTooltip>
                    ) : row.label,
                    width: "50%",
                    indicateHeirarchy: true
                },
                {
                    Header: "Value",
                    accessor: "value",
                    width: "25%"
                },
                {
                    Header: "Last updated at",
                    accessor: "formattedDate",
                    width: "25%"
                }
            ]
        }
    ], []);

    if (!data.length) return (
        <InfoMessageWrapper>
            Sorry, we couldn't find any variables for this node.
        </InfoMessageWrapper>
    );

    return (
        <div className='variables-table-wrapper'>
            <ExpandableTable columns={columns} data={data} /> 
        </div>
    )
}

export default VariablesTable