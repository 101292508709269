import CustomToast from '../components/CustomToast/CustomToast';
import Cookies from 'js-cookie';
import Amplify, { API } from 'aws-amplify';
import awsconfig from '../aws-exports';
import { createFormAndSubmit } from './commonFunctions';
import { getUnixTime } from 'date-fns';
import * as generalConfig from './../pages/AdvancedInsights/general-config.json';

Amplify.configure(awsconfig);

const apiNameUser = 'user';
const apiNameAdmin = 'admin';
const isCustomer = window.CUSTOMER_DEPLOYMENT === true;

// Login
export const loginCall = (user_name, password, keep_signed_in) => {
	let path = '/login2';
	let myInit = {
		body: {
			user_name,
			password,
		},
	};

	return API.post(apiNameUser, path, myInit)
		.then((data) => {
			const { accesstoken, refreshtoken } = data;
			Cookies.set('ATK', accesstoken);
			Cookies.set('RTK', refreshtoken);
			Cookies.set('user_name', user_name);
			localStorage.setItem('email', true);
			Cookies.set(generalConfig.keep_signed_in_cookie_key, keep_signed_in ? "yes" : "no", { expires: 365 });
			Cookies.set('last_logged_in_ts', getUnixTime(new Date()));
		})
		.catch((resp) => {
			let error = resp.response.data;
			return error.description;
		});
};

export const refreshSessionCall = () => {
	let refreshtoken = Cookies.get('RTK');
	let user_name = Cookies.get('user_name');

	let path = '/login2';
	let myInit = {
		body: {
			user_name,
			refreshtoken,
		},
	};

	return API.post(apiNameUser, path, myInit)
		.then((data) => {
			const { accesstoken } = data;
			Cookies.set('ATK', accesstoken);
			Cookies.set('last_logged_in_ts', getUnixTime(new Date()));
		})
		.catch((resp) => {
			let error = resp.response.data;
			return error;
		});
};

export const logoutCall = () => {
	let path = '/logout2';
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`
		},
	};

	return API.post(apiNameUser, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

// send verification
export const verificationCall = (user_name, verification_code) => {
	let path = '/user2';
	let myInit = {
		body: {
			user_name,
			verification_code,
		},
	};

	return API.post(apiNameUser, path, myInit)
		.then(() => {
			return;
		})
		.catch((resp) => {
			let error = resp.response.data;
			return error.description;
		});
};

// Signup
export const signupCall = (user_name, password) => {
	let path = '/user2';
	let myInit = {
		body: {
			user_name,
			password,
		},
	};

	return API.post(apiNameUser, path, myInit)
		.then((resp) => {
			Cookies.set('user_name', user_name);
			CustomToast.success(
				'We have sent verification code to your provided email address.',
				{
					autoClose: 5000,
				},
				'verification-toast'
			);
			return resp;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

// Forgot Password
export const forgotPasswordCall = (user_name) => {
	let path = '/forgotpassword2';
	let myInit = {
		body: {
			user_name,
		},
	};

	return API.put(apiNameUser, path, myInit)
		.then(() => {
			CustomToast.success(
				'We have sent verification code to your provided email address.',
				{
					autoClose: 5000,
				},
				'verification-toast'
			);
		})
		.catch((resp) => {
			let error = resp.response.data;
			return error.description;
		});
};
export const forgotPasswordConfirmCall = (
	user_name,
	password,
	verification_code
) => {
	let path = '/forgotpassword2';
	let myInit = {
		body: {
			user_name,
			password,
			verification_code,
		},
	};

	return API.put(apiNameUser, path, myInit)
		.then(() => {
			return;
		})
		.catch((resp) => {
			let error = resp.response.data;
			return error.description;
		});
};

export const getUserCall = () => {
	let path = '/user2';

	let myInit = {
		headers: { Authorization: `${Cookies.get('ATK')}` }
	};

	return API.get(apiNameUser, path, myInit)
		.then((resp) => {
			Cookies.set('user_name', resp.user_name);
			Cookies.set('id', resp.user_id);
			if (resp.super_admin) {
				// localstorage kept for backward compatibility, remove this later.
				localStorage.setItem('admin', true);
				Cookies.set('admin', true);
			}
			if (window.CUSTOMER_DEPLOYMENT) {
				Cookies.set('cst', window.CUSTOMER_DEPLOYMENT);
			}
			return resp;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const changePasswordCall = (password, newpassword) => {
	let path = '/password2';
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`
		},
		body: {
			password,
			newpassword,
		},
	};

	return API.put(apiNameUser, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			return resp.response.data.description;
		});
};

export const getNodesCall = (nodeid = '', records) => {
	let path;
	if (records === null) {
		path = `/nodes?node_id=${nodeid}`;
	} else if (records) {
		path = `/nodes?start_id=${nodeid}&num_records=${records}`;
	}

	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	};

	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getOtaJobsCall = (otajob = '', pagesize) => {
	let path;

	if (pagesize === null) {
		path = `/otajob?ota_job_name=${otajob}`;
	} else if (pagesize) {
		path = `/otajob?start_id=${otajob}&num_records=${pagesize}`;
	}

	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	};

	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const cancelOtaJobCall = (ota_job_id) => {
	let path = `/otajob`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
		body: { ota_job_id },
	};

	return API.put(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const searchOtaJobId = (otajobname) => {
	let path = `/otajob?ota_job_id=${otajobname}`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	};

	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getOtaImagesCall = (otaImageInfo, searchParam) => {
	let path;
	if (Number.isInteger(searchParam) || !isNaN(parseInt(searchParam))) {
		path = `/otaimage?start_id=${otaImageInfo}&num_records=${searchParam}`;
	} else if (searchParam === 'type') {
		path = `/otaimage?type=${otaImageInfo}`;
	} else if (searchParam === 'model') {
		path = `/otaimage?model=${otaImageInfo}`;
	} else if (searchParam === 'id') {
		path = `/otaimage?ota_image_id=${otaImageInfo}`;
	} else {
		path = `/otaimage?image_name=${otaImageInfo}`;
	}

	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	};

	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const uploadOtaImagesCall = (imageDetails, base64_fwimage) => {
	let path = `/otaimage`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
		body: {
			image_name: imageDetails.image_name,
			fw_version: imageDetails.fw_version,
			type: imageDetails.image_type,
			model: imageDetails.image_model,
			base64_fwimage,
		},
	};

	return API.post(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getPresignedURLCall = (otaImageName) => {
	let path = `/otaimage/package/upload?file_name=${otaImageName}`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	};

	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const uploadURLCall = async (url, data) => {
	let res = await fetch(url, {
		method: 'put',
		headers: {
			'Content-Type': 'application/octet-stream',
		},
		body: data,
	});
	return res;
};

export const uploadOtaPackageCall = (imageDetails, name) => {
	let path = `/otaimage/package`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
		body: {
			file_path: name,
			image_name: imageDetails.image_name,
			fw_version: imageDetails.fw_version,
			type: imageDetails.image_type,
			model: imageDetails.image_model,
		},
	};

	return API.post(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const pushOtaImageCall = (
	ota_image_id,
	jobName,
	pushTo,
	list,
	forcePush
) => {
	let path;
	path = forcePush ? `/otajob?force_push=true` : `/otajob`;

	let payload =
		forcePush && pushTo === 'nodes'
			? {
					ota_image_id: ota_image_id,
					ota_job_name: jobName,
					nodes: [list],
			  }
			: {
					ota_image_id: ota_image_id,
					ota_job_name: jobName,
					nodes: pushTo === 'nodes' ? [list] : [],
					groups: pushTo === 'groups' ? [list] : [],
			  };
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
		body: payload,
	};

	return API.post(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const deleteOtaImageCall = (ota_image_id) => {
	let path = `/otaimage?ota_image_id=${ota_image_id}`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	};

	return API.del(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const deleteOtaPackageCall = (ota_image_id) => {
	let path = `/otaimage/package?ota_image_id=${ota_image_id}`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	};

	return API.del(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getJobSummaryCall = (otajobid) => {
	let path = `/otajob/status/summary?ota_job_id=${otajobid}`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	};

	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getNodesListForJobCall = (otajobid, records, startid) => {
	let path = startid
		? `/otajob/status?ota_job_id=${otajobid}&num_records=${records}&start_id=${startid}`
		: `/otajob/status?ota_job_id=${otajobid}&num_records=${records}`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	};
	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getNodeDetailsForJobCall = (nodeid, otajobid) => {
	let path = `/otajob/status?node_id=${nodeid}&ota_job_id=${otajobid}`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	};
	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const setIdentityProviders = (data) => {
	let path = `/identity_provider`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
		body: data,
	};
	return API.post(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const editIdentityProviders = (data) => {
	let path = `/identity_provider`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
		body: data,
	};
	return API.put(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getIdentityProviders = () => {
	let path = `/identity_provider`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	};
	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const setEmailTemplateCall = (data) => {
	let path = `/message_template`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
		body: data,
	};
	return API.post(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getEmailTemplateCall = (template_type = 'INVITE_MESSAGE') => {
	let path = `/message_template?template_type=${template_type}`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') }) 
		},
	};
	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getCognitoAppClientCall = () => {
	let path = `/cognito_appclient`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
	};
	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};
export const updateCognitoAppClientCall = (data) => {
	let path = `/cognito_appclient`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
		body: data,
	};
	return API.put(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const checkUpdateCall = () => {
	let path = `/dashboard/update?current_version=${window.VERSION}`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
	};
	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const updateDashboardCall = (version) => {
	let path = `/dashboard/update`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
		body: { version },
	};
	return API.put(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getSesVerifiedEmailCall = () => {
	let path = `/ses_verified_email`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
	};
	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getCognitoDomainCall = () => {
	let path = `/cognito_domain`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
	};
	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const setCognitoDomain = (data) => {
	let path = `/cognito_domain`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
		body: { domain_name: data },
	};
	return API.post(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const deleteCognitoDomain = () => {
	let path = `/cognito_domain`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
	};

	return API.del(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getPrivacyPolicy = () => {
	let path = `/terms_policy`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
	};
	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const putPrivacyPolicy = (data) => {
	let path = `/terms_policy`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
		body: data,
	};
	return API.put(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const deletePrivacyPolicy = (file) => {
	let path = `/terms_policy?file=${file}`;
	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
	};

	return API.del(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const getStatisticsServiceData = (duration, time1, time2) => {
	let path;
	if (duration === 'daily_count') {
		path = `/stats_info?duration=${duration}&start_date=${time1.startDate}&end_date=${time1.endDate}`;
	} else if (duration === 'weekly_count' || duration === 'monthly_count') {
		path = `/stats_info?duration=${duration}&start_month=${time1.startMonth}&start_year=${time2.startYear}&end_month=${time1.endMonth}&end_year=${time2.endYear}`;
	} else if (duration === 'yearly_count') {
		path = `/stats_info?duration=${duration}&start_year=${time1.startYear}&end_year=${time1.endYear}`;
	}

	let myInit = {
		headers: { 
			Authorization: `${Cookies.get('ATK')}`,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
	};

	return API.get(apiNameAdmin, path, myInit)
		.then((data) => {
			return data;
		})
		.catch((resp) => {
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};

export const redirectUserToESPLogin = (action = "login") => {
	const fieldsData = {
		redirect_uri: window.location.href,
		action: action
	};

	createFormAndSubmit("GET", "_self", window.ESP_LOGIN_SERVICE_URL, fieldsData);
}

export const validateToken = token => {
	let path = '/user2';

	let myInit = {
		headers: { 
			Authorization: token,
			...(Cookies.get('TATK') && { TenantToken: Cookies.get('TATK') })  
		},
	};

	return API.get(apiNameUser, path, myInit)
		.then((resp) => {
			console.log(resp)
			Cookies.set('ATK', token);
			Cookies.set('user_name', resp.user_name);
			Cookies.set('id', resp.user_id);
			if (resp.super_admin) {
				localStorage.setItem('admin', true);
				Cookies.set('admin', true);
			}
			if (window.CUSTOMER_DEPLOYMENT) {
				Cookies.set('cst', window.CUSTOMER_DEPLOYMENT);
			}
			return resp;
		})
		.catch((resp) => {
			console.log(resp)
			let error = resp.response.data;
			let errorResponse = {
				status: 'failure',
				statusCode: resp.response.status,
				description: error.description,
			};
			return errorResponse;
		});
};
