import React, { useState } from 'react';
import './UsageBreakdown.scss';
import UsageRow from './UsageRow/UsageRow';

function UsageBreakdown({data, maxRecords, variant, paginateData = false, textLabels}) {
    const variantClassName = variant || "";
    const totalRecords = data?.length || 0;
    const [numOfRecordsToDisplay, setNumOfRecordsToDisplay] = useState(maxRecords);

    if (!data?.length) return null;

    return (
        <div className={`usage-breakdown-wrapper ${variantClassName}`}>
            <div className='list'>
                { 
                    [...data].reverse().slice(0, numOfRecordsToDisplay).map(row => (
                            <UsageRow 
                                key={row.ts} 
                                data={row} 
                                variant={variant} 
                                textLabels={textLabels}
                            />
                        )
                    ) 
                }
            </div>
            {
                (paginateData === true) && (
                    <div className='actions'>
                        <div className='action'>
                            <button 
                                type="button"
                                onClick={() => setNumOfRecordsToDisplay(numOfRecordsToDisplay + maxRecords)}
                                disabled={numOfRecordsToDisplay >= totalRecords}
                            >{textLabels.view_more || "View more"}</button>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default UsageBreakdown