import React, { useState, useEffect, useContext } from 'react';
import './ForUser.scss';
import WidgetListItem from '../../../../Widgets/WidgetListItem/WidgetListItem';
import advancedInsightsContext from './../../../../context/insights-context';
import NoDataMessage from './../../../../Widgets/NoDataMessage/NoDataMessage';
import { formatPastTime } from '../../../../../../utils/commonFunctions';
import { respondTenantInvite } from '../../../../../../utils/diagQueryAPI';
import CustomToast from '../../../../../../components/CustomToast/CustomToast';
import LoadingSpinner from '../../../../../../components/LoadingSpinner/LoadingSpinner';
import usePagination from '../../../../hooks/usePagination';

function ForUser() {
    const { 
        messages,
        invitationsForUser,
        invitationsForUserLoading,
        getInvitationsForUser
    } = useContext(advancedInsightsContext);

    const [userActions, setUserActions] = useState({});
    const [nextPagetoken, setNextPagetoken] = useState("");

    const { pagetoken, Pagination } = usePagination(nextPagetoken);

    useEffect(() => {
        getInvitationsForUser(pagetoken);
    }, [pagetoken]);

    useEffect(() => {
        setNextPagetoken(invitationsForUser?.page_token || "");
    }, [invitationsForUser]);

    const invitationsList = (invitationsForUser && invitationsForUser.list) ? invitationsForUser.list.filter(u => u.account_id) : [];
    
    const respondInvite = async (account_id, is_accepted) => {
        const respondInviteResponse = await respondTenantInvite({account_id, is_accepted});
        if (!respondInviteResponse?.status || (respondInviteResponse?.status !== 200) || (respondInviteResponse?.data?.status === "failure")) {
            const errorMessage = is_accepted ? "Error accepting this invite. Please try again!" : "Error ignoring this invite. Please try again!"
            CustomToast.error(errorMessage, { autoClose: 5000 }, 'respond-invite-toast');
            return;
        }
        
        setUserActions({ ...userActions, [account_id]: is_accepted })
    }

    if (invitationsForUserLoading) return <LoadingSpinner wrapperClassName='for-user-wrapper' />

    if (!(invitationsForUserLoading && invitationsForUser === null) && invitationsList.length === 0) return (
        <div className='for-user-wrapper'>
            <NoDataMessage text={messages.manage_multi_admins.pending_invitations.no_invites.for_user} />
            <Pagination />
        </div>
    )
    
    return (
        <div className='for-user-wrapper'>
            {
                invitationsList.map(({
                    account_id, account_email_id, message, created_at
                }) => (
                    <WidgetListItem 
                        key={account_id}
                        title={account_email_id}
                        text={message}
                        title_tip={formatPastTime(created_at)}
                        acceptActionText={messages.common_labels.accept}
                        acceptActionHandler={() => respondInvite(account_id, true)}
                        rejectActionText={messages.common_labels.ignore}
                        rejectActionHandler={() => respondInvite(account_id, false)}
                        acceptAction={userActions.hasOwnProperty(account_id) ? userActions[account_id] : null}
                        acceptActionInfoMessage={userActions[account_id] ? "You accepted this invite" : "You ignored this invite"}
                    />
                ))
            }
            <Pagination />
        </div>
    )
}

export default ForUser