import React from "react";
import "./ConfigFormElement.scss";
import SwitchButton from "../../../../../../../components/SwitchButton/SwitchButton";
import { PrimaryLineButton } from "../../../../../../../components/Button/Button";
import TextFieldWithIconButton from "../../../../../../../components/TextFieldWithIconButton/TextFieldWithIconButton";

function ConfigFormElement({
  dataType,
  id,
  value,
  label = undefined,
  onUpdate,
}) {
  return (
    <div className="config-form-element-wrapper">
      <Element
        dataType={dataType}
        id={id}
        value={value}
        label={label || id}
        onUpdate={onUpdate}
      />
    </div>
  );
}

const Element = ({ dataType, label, onUpdate, ...rest }) => {
  if (dataType === 0) return <SwitchButton onChange={onUpdate} {...rest} />;

  if (dataType === 1 || dataType === 2 || dataType === 3)
    return (
      <TextFieldWithIconButton
        type="number"
        inputPlaceholder={label}
        onFormSubmit={onUpdate}
        {...rest}
      />
    );

  if (dataType === 4 || dataType === 5 || dataType === 6)
    return (
      <TextFieldWithIconButton
        type="text"
        inputPlaceholder={label}
        onFormSubmit={onUpdate}
        {...rest}
      />
    );

  return (
    <PrimaryLineButton {...rest} onClick={onUpdate}>
      {rest.label || rest.id}
    </PrimaryLineButton>
  );
};

export default ConfigFormElement;
